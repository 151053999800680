import { useState } from "react";
import { GenericIntegration, GenericIntegrationType, InvalidationStrategyEnum } from "src/services/integrations/types";
import RadioButton from "src/components/form/RadioButton";
import Select, { Option } from "src/components/form/Select";

const timeframeOptions = {
    1: '1 day',
    2: '2 days',
    3: '3 days',
    4: '4 days',
    5: '5 days',
    6: '6 days',
    7: '7 days',
};
const radioButtonText = {
    specificTimeframe: {
        label: 'Time-Based Cleanup',
    },
    onSuccessfulRuns: {
        label: 'Immediate Cleanup',
        child: <span className="text-tertiary font-normal text-base">Remove resources not detected in the most recent successful source integration run.</span>
    },
    doNotRemove: {
        label: 'No Cleanup',
        child: <span className="text-tertiary font-normal text-base">Keep all resources indefinitely, even if they are no longer detected.</span>
    },
};
type InvalidationStrategyBlockProps = {
    integration: GenericIntegration,
    setIntegration: (integration: GenericIntegration) => void,
}


const InvalidationStrategyBlock = ({ integration, setIntegration }: InvalidationStrategyBlockProps) => {
    const [timeframe, setTimeframe] = useState<number>((integration.invalidation_strategy && integration.invalidation_strategy.ttl_days > 0) ? integration.invalidation_strategy.ttl_days : 7);

    const getCurrentStrategy = (): InvalidationStrategyEnum => {
        if (integration.invalidation_strategy) {
            const ttlDays = integration.invalidation_strategy.ttl_days;
            if (ttlDays !== null && ttlDays !== 0) return InvalidationStrategyEnum.SPECIFIC_TIMEFRAME;
            if (ttlDays === 0) return InvalidationStrategyEnum.ON_SUCCESSFUL_RUNS;
        }
        return InvalidationStrategyEnum.DO_NOT_REMOVE;
    };

    const currentStrategy = getCurrentStrategy();

    return (
        <div className="flex justify-start flex-col w-[640px]">
            <span className="text-lg">Resource Cleanup</span>
            <span className="text-tertiary font-normal text-base">To keep your data relevant and free of outdated resources, Euno provides automatic resource cleanup options.</span>
            <div className="flex flex-col justify-start items-start mt-2">
                {[GenericIntegrationType.dbt_cloud, GenericIntegrationType.dbt_core].includes(integration.integration_type) && <>
                    <RadioButton
                        value={currentStrategy === InvalidationStrategyEnum.SPECIFIC_TIMEFRAME}
                        setValue={() => {
                            setIntegration({ ...integration, invalidation_strategy: { ttl_days: timeframe } });
                        }}
                        label={radioButtonText.specificTimeframe.label}
                        className='mt-2'
                    />
                    <div className='ml-6 flex flex-wrap items-center '>
                        <span className='text-tertiary font-normal'>
                            Remove resources that were last detected
                        </span>
                        <Select
                            options={Object.entries(timeframeOptions).map(([value, label]) => ({ value, label }))}
                            value={`${integration.invalidation_strategy?.ttl_days || timeframe}`}
                            onChange={(value) => {
                                setIntegration({ ...integration, invalidation_strategy: { ttl_days: Number((value as Option).value) } });
                                setTimeframe(Number((value as Option).value));
                            }}
                            className='border rounded-md min-w-[80px] ml-1'
                            isSearchable={false}
                            height="30px"
                        />
                        <span className='text-tertiary font-normal'>
                            before the most recent successful source integration run.
                        </span>
                    </div>
                </>}
                <RadioButton
                    value={currentStrategy === InvalidationStrategyEnum.ON_SUCCESSFUL_RUNS}
                    setValue={() => {
                        setIntegration({ ...integration, invalidation_strategy: { ttl_days: 0 } });
                    }}
                    label={radioButtonText.onSuccessfulRuns.label}
                    child={radioButtonText.onSuccessfulRuns.child}
                    className='mt-2'
                />
                <RadioButton
                    value={currentStrategy === InvalidationStrategyEnum.DO_NOT_REMOVE}
                    setValue={() => {
                        setIntegration({ ...integration, invalidation_strategy: null });
                    }}
                    label={radioButtonText.doNotRemove.label}
                    child={radioButtonText.doNotRemove.child}
                    className='mt-2'
                />
            </div>
        </div>
    );
};


export default InvalidationStrategyBlock;