import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import Layout from "src/components/layout/Layout";
import TopBar from "src/components/layout/TopBar";
import PageLoader from "src/components/loaders/PageLoader";
import Modal from "src/components/Modal/Modal";
import { StickyBar } from "src/components/StickyBar";
import { notify } from "src/components/Toaster";
import { getSyncJobValidationError } from "src/features/dataModelSync/syncJobs/getSyncJobValidationError";
import { SyncJobForm } from "src/features/dataModelSync/syncJobs/SyncJobForm";
import { SyncJob, SyncTarget } from "src/features/dataModelSync/types";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useCreateTargetMutation, useGetJobQuery, useLazyGetTargetQuery, useRunJobMutation, useUpdateJobMutation, useUpdateTargetMutation } from "src/services/actions/actions";
import { emptySyncTarget } from "src/services/actions/transformers";
import { extractErrorMessage } from "src/services/api";

export const EditSyncJobPage = () => {
    const [syncJob, setSyncJob] = useState<SyncJob | null>(null);
    const accountId = useSelector(selectActiveAccountId);
    const [syncTarget, setSyncTarget] = useState<SyncTarget | null>({ ...emptySyncTarget });
    const jobId = Number(useParams().id);
    const getSyncJob = useGetJobQuery({ accountId, jobId });
    const navigate = useNavigate();
    const [updateSyncJob] = useUpdateJobMutation();
    const [updateSyncTarget] = useUpdateTargetMutation();
    const [createSyncTarget] = useCreateTargetMutation();
    const [runSyncJob] = useRunJobMutation();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [getTarget, targetData] = useLazyGetTargetQuery();

    useEffect(() => {
        setSyncTarget(targetData.data || null);
    }, [targetData.data]);

    useEffect(() => {
        if (getSyncJob.data) {
            setSyncJob(getSyncJob.data);
            if (getSyncJob.data.targetId) {
                getTarget({ accountId, targetId: getSyncJob.data.targetId });
            }
        }
        if (getSyncJob.error) {
            notify(`Failed to load sync job: ${extractErrorMessage(getSyncJob.error).message}`, 'error');
        }
    }, [getSyncJob, accountId, getTarget]);

    const onCancel = () => {
        navigate(-1);
    };

    const onSave = async (withRun: boolean) => {
        if (!syncJob) {
            return;
        }
        const validateError = getSyncJobValidationError(syncJob, syncTarget);
        if (validateError) {
            notify(validateError, 'error');
            return;
        }
        try {
            let targetId = syncTarget?.id || null;
            if (syncTarget && targetId) {
                await updateSyncTarget({ accountId, targetId, target: { ...syncTarget } }).unwrap();
            } else if (syncTarget && !syncTarget.id) {
                targetId = await createSyncTarget({ accountId, target: { ...syncTarget } }).unwrap();
            }
            await updateSyncJob({ accountId, jobId, job: { ...syncJob, targetId } }).unwrap();
            notify('Sync job updated successfully', 'success');
            if (withRun) {
                await runSyncJob({ accountId, jobId }).unwrap();
                notify('Sync run started', 'success');
            }
            navigate('/data-model-sync?tab=Jobs');
        }
        catch (e) {
            notify(`Failed to update sync job: ${extractErrorMessage(e).message}`, 'error');
        }
    };


    if (!syncJob) {
        return <PageLoader />;
    }

    return (
        <Layout>
            <div className="flex flex-col h-full">
                <StickyBar position="top">
                    <div className="pl-4">
                        <TopBar>
                            <div className="text-lg">
                                Edit {syncJob.name}
                            </div>
                        </TopBar>
                    </div>
                </StickyBar>
                <div className="mt-12">
                    <SyncJobForm syncJob={syncJob} setSyncJob={setSyncJob} syncTarget={syncTarget} setSyncTarget={setSyncTarget} />
                </div>
                <StickyBar position="bottom">
                    <div className="flex gap-4 bg-white px-8 py-3 border-t border-border sticky bottom-0 w-full">
                        <Button type={ButtonTypes.secondary} className="w-24" text="Back" onClick={() => setShowCancelModal(true)} />
                        <Button isLoading={getSyncJob.isFetching} type={ButtonTypes.primary} className="w-24 ml-auto" text="Save" onClick={() => onSave(false)} />
                    </div>
                </StickyBar>
            </div>
            <Modal
                isOpen={showCancelModal}
                onClose={() => setShowCancelModal(false)}
                title="Leave without saving?"
                buttons={[{ text: "Exit", onClick: onCancel, type: ButtonTypes.secondary }, { text: "Continue editing", onClick: () => setShowCancelModal(false), type: ButtonTypes.primary }]}>
                <div className="text-text-primary">You have unsaved changes in “{syncJob.name}”,
                    If you leave this page, any changes you have made will be lost.</div>
            </Modal>
        </Layout>
    );
};
