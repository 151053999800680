import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsMenuCollpased } from 'src/infrastructure/state/slices/isMenuCollpasedSlice';

interface StickyBarProps {
    children: React.ReactNode;
    position?: 'top' | 'bottom';
    className?: string;
}

export const StickyBar: React.FC<StickyBarProps> = ({
    children,
    position = 'top',
    className = ''
}) => {
    const isMenuCollapsed = useSelector(selectIsMenuCollpased);

    return (
        <div className={`fixed ${position === 'top' ? 'top-0' : 'bottom-0'} z-10 bg-white shadow-md ${className}`} style={{ 
            left: isMenuCollapsed ? "60px" : "250px", 
            width: `calc(100% - ${isMenuCollapsed ? "40px" : "250px"})`,
            transition: "left 0.2s, width 0.2s"
        }}>
                {children}
        </div>
    );
};
