import React, { useState, useRef, useEffect } from 'react';
import Layout from 'src/components/layout/Layout';
import { AssistantRuntimeProvider } from "@assistant-ui/react";
import { ResourceSidepane } from 'src/features/models/discover/resourceSidepane/ResourceSidepane';
import { useLangGraphRuntime } from '@assistant-ui/react-langgraph';
import { Assistant } from './Assistant';
import { EunoLangGraphThreadConfig, createThread, sendMessage, getThreadState, listThreads, EunoThreadState } from 'src/services/langgraph/langgraphClient';
import { useAccountInfo } from 'src/features/account/AccountHooks';
import { useUser } from "@descope/react-sdk";
import { Thread } from '@langchain/langgraph-sdk';

export const AssistantPage: React.FC = () => {
  const threadIdRef = useRef<string | undefined>();
  const [selectedResourceId, setSelectedResourceId] = useState<string | null>(null);
  const [threads, setThreads] = useState<Thread<EunoThreadState>[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  // Function to show a resource in the sidepane
  const showInSidepane = (resourceId: string) => {
    setSelectedResourceId(resourceId);
  };
  
  // Check if sidepane is open
  const isSidepaneOpen = !!selectedResourceId;
  const { activeAccountId, activeAccountName } = useAccountInfo();
  const { user } = useUser();
  
  // Fetch threads when component mounts
  useEffect(() => {
    const fetchThreads = async () => {
      if (!activeAccountId || !user?.email) return;
      
      try {
        setIsLoading(true);
        const threadsData = await listThreads(activeAccountId, user.email);
        setThreads(threadsData);
      } catch (error) {
        console.error("Error fetching threads:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchThreads();
  }, [activeAccountId, user?.email]);
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const runtime = useLangGraphRuntime({
    stream: async (messages) => {
      if (!threadIdRef.current) {
        const result = await createThread(activeAccountId, activeAccountName);
        threadIdRef.current = result.thread_id;
        
        // Add the new thread to the list
        setThreads(prevThreads => [result, ...prevThreads]);
      }
      
      const threadId = threadIdRef.current;
      if (!threadId) {
        throw new Error('Thread ID is undefined');
      }
      
      // Create the config with account information
      const config: EunoLangGraphThreadConfig = {
        configurable: {
          model_name: "openai",
          account_name: activeAccountName,
          account_id: activeAccountId,
        }
      };
      
      return sendMessage({
        threadId,
        messages,
        config,
      });
    },
    onSwitchToThread: async (threadId: string | undefined) => {
      if (threadIdRef.current !== threadId) {
        threadIdRef.current = threadId;
      }
      if (!threadId) {
        return {
          messages: [],
          interrupts: []
        };
      }
      return getThreadState(threadId).then((state) => {
        return {
          messages: state.values.messages,
          interrupts: []
        };
      });
    }
  });
  
  function switchToThread(threadId: string | undefined) {
    threadIdRef.current = threadId;
    if (threadId) {
      runtime.switchToThread(threadId);
    }
    setIsDropdownOpen(false);
  }
  
  function startNewConversation() {
    runtime.switchToThread(undefined);
    setIsDropdownOpen(false);
  }
  
  return (
    <Layout>
      <div className="overflow-y-auto max-h-[100vh]">
        <div className="flex flex-col bg-white border-b  pt-12">
          <div className={`${isSidepaneOpen ? 'w-[40rem] ml-4' : 'w-[50rem] m-auto'} h-36 flex flex-col transition-all duration-300`}>
            <div className="text-2xl mt-5">Talk to your metadata with Euno Assistant</div>
            <div className="text-tertiary mt-1 mb-auto">
              Ask questions about your data model, get help with tasks, or explore Delphi features.
            </div>
            
            {/* History and New Conversation buttons */}
            <div className="flex items-center justify-end mb-4 space-x-2">
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                  title="Conversation history"
                  disabled={isLoading}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 18 18">
                    <path fill="#494c4e" d="M17 10a8 8 0 0 1-16 0 1 1 0 0 1 2 0 6 6 0 1 0 6.5-5.98V5.5a.477.477 0 0 1-.27.44A.466.466 0 0 1 9 6a.52.52 0 0 1-.29-.09l-3.5-2.5a.505.505 0 0 1 0-.82l3.5-2.5a.488.488 0 0 1 .52-.03.477.477 0 0 1 .27.44v1.52A7.987 7.987 0 0 1 17 10z"/>
                    <path fill="#494c4e" d="M11.71 8.71L10 10.42V13a1 1 0 0 1-2 0v-3a.69.69 0 0 1 .04-.25.37.37 0 0 1 .04-.14.988.988 0 0 1 .21-.32l2-2a1.004 1.004 0 0 1 1.42 1.42z"/>
                  </svg>
                </button>
                
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-64 bg-white rounded-md shadow-lg z-10 max-h-96 overflow-y-auto">
                    <div className="py-1">
                      {threads.length === 0 ? (
                        <div className="px-4 py-2 text-sm text-gray-500">
                          {isLoading ? 'Loading conversations...' : 'No conversations found'}
                        </div>
                      ) : (
                        threads.map((thread) => (
                          <button
                            key={thread.thread_id}
                            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                            onClick={() => switchToThread(thread.thread_id)}
                          >
                            {thread.created_at 
                              ? `Conversation on ${new Date(thread.created_at).toLocaleString()}`
                              : `Conversation ${thread.thread_id.substring(0, 8)}`}
                          </button>
                        ))
                      )}
                    </div>
                  </div>
                )}
              </div>
              
              <button
                onClick={startNewConversation}
                className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                title="New conversation"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div 
          className={`${
            isSidepaneOpen 
              ? 'w-[40rem] ml-4 mr-auto' 
              : 'w-[50rem] mx-auto'
          } mt-4 bg-white rounded-lg border shadow-sm h-[calc(100vh-12rem)] overflow-hidden transition-all duration-300`}
        >
          <AssistantRuntimeProvider runtime={runtime}>
            <Assistant showInSidepane={showInSidepane} />
          </AssistantRuntimeProvider>
        </div>
      </div>
      {selectedResourceId && (
        <ResourceSidepane 
          resourceId={selectedResourceId} 
          onClose={() => setSelectedResourceId(null)} 
        />
      )}
    </Layout>
  );
}; 