import { Client } from "@langchain/langgraph-sdk";
import { LangChainMessage } from "@assistant-ui/react-langgraph";
import { getSessionToken } from "@descope/react-sdk";
import { Thread, ThreadState } from "@langchain/langgraph-sdk";
import { RemoteGraph } from "@langchain/langgraph/remote";
import { HumanMessage } from "@langchain/core/messages";

export interface EunoLangGraphThreadConfig {
  configurable: {
    model_name: string;
    account_name: string;
    account_id: number;
  };
}

// Define the structure of the thread state
interface EunoThreadStateValues {
  messages: LangChainMessage[];
}
export interface EunoThreadState extends ThreadState<EunoThreadStateValues> {
  values: EunoThreadStateValues;
}

const createClient = () => {
  const apiUrl = import.meta.env.VITE_LANGGRAPH_API_URL || '';
  
  const normalizedUrl = apiUrl;
  
  return new Client({
    apiUrl: normalizedUrl,
    apiKey: getSessionToken()
  });
};

export const invokeText2Eql = async (account_id: number, prompt: string) => {
  const client = createClient();
  const remoteGraph = new RemoteGraph({
    graphId: 'text2eql',
    client: client,
  });
  const response = await remoteGraph.invoke(
    {messages: [new HumanMessage(prompt)]},
    {
      configurable: {
        account_id: account_id,
      },
    }
  );
  return response.structured_response;
};

export const createThread = async (account_id: number, account_name: string): Promise<Thread<EunoThreadState>> => {
  const client = createClient();
  const thread = await client.threads.create({
    metadata: {
      account_id: account_id,
      account_name: account_name,
    }
  });
  return thread as unknown as Thread<EunoThreadState>;
};

export const listThreads = async (account_id: number, owner: string): Promise<Thread<EunoThreadState>[]> => {
  const client = createClient();
  return client.threads.search({
    metadata: {
      account_id: account_id,
      owner: owner,
    }
  });
};

export const getThreadState = async (
  threadId: string
): Promise<ThreadState<EunoThreadStateValues>> => {
  const client = createClient();
  return client.threads.getState(threadId);
};

export const sendMessage = async (params: {
  threadId: string;
  messages: LangChainMessage[];
  config?: EunoLangGraphThreadConfig;
}) => {
  const client = createClient();
  const assistantId = import.meta.env.VITE_LANGGRAPH_ASSISTANT_ID || '';
  
  const input = {
    messages: params.messages,
  };
  
  const config = params.config;
  
  return client.runs.stream(
    params.threadId,
    assistantId,
    {
      input,
      config,
      streamMode: "messages",
    }
  );
};

export const checkHealth = async () => {
  try {
    const apiUrl = import.meta.env.VITE_LANGGRAPH_API_URL || '';
    
    // If the URL starts with 'https://' but we're on localhost, use 'http://' instead
    let normalizedUrl = apiUrl;
    if (normalizedUrl.startsWith('https://localhost')) {
      normalizedUrl = normalizedUrl.replace('https://', 'http://');
    }
    
    // Try to access the info endpoint as a health check
    const response = await fetch(`${normalizedUrl}/info`);
    
    if (!response.ok) {
      throw new Error(`Server responded with status: ${response.status}`);
    }
    
    return { status: 'ok' };
  } catch (error) {
    console.error('Health check failed:', error);
    return { status: 'error', error };
  }
}; 