import { Outlet, useLocation } from 'react-router-dom';
import { resetIdentity, identifyUser, trackEvent, events } from '../infrastructure/analytics';
import { useSession, useUser } from '@descope/react-sdk';
import { useEffect, useState } from 'react';
import api from '../services/api';
import { reset as resetLocalStorage } from '../infrastructure/localStorage/localStorage';
import { store } from '../infrastructure/state/store';
import { useAppDispatch } from '../infrastructure/state/hooks';
import * as sentry from '../infrastructure/sentry';
import { Environment, getCurrentEnv } from '../infrastructure/env';
import historyStackSlice from 'src/infrastructure/state/slices/historyStackSlice';
import { LiveblocksProvider } from '@liveblocks/react';
import { useGetLiveblocksCredentialsQuery } from 'src/services/liveblocks';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { useSelector } from 'react-redux';
import { RoomProvider } from '@liveblocks/react';
import { usersApi } from 'src/services/users';
const DESCOPE_TIMEOUT = 3000;

const RouterRoot = () => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [startTime] = useState(new Date().getTime());
  const accountId = useSelector(selectActiveAccountId);
  const { data: liveblocksCredentials } = useGetLiveblocksCredentialsQuery(accountId, {
    skip: !accountId
  });

  //On page change
  useEffect(() => {
    trackEvent(events.pageView, { page: location.pathname });
  }, [location.pathname]);

  //On URL chage
  useEffect(() => {
    dispatch(historyStackSlice.actions.setHistoryStack(location.pathname + location.search));
  }, [location, dispatch]);

  //On auth change
  useEffect(() => {
    if (!(isSessionLoading || isUserLoading) && getCurrentEnv() !== Environment.Testing) {
      if (isAuthenticated) {
        identifyUser(user.email || '', {email: user.email, name: user.name });
        sentry.identify(user);
      } else {
        store.dispatch(api.util.resetApiState());
        resetIdentity();
        resetLocalStorage();
        console.error('Descope session expired');
      }
    } else if (new Date().getTime() - startTime > DESCOPE_TIMEOUT) {
      console.error('Descope timeout');
      window.location.reload();
    }
  }, [isAuthenticated, user, isSessionLoading, isUserLoading, startTime]);

  return (
    <LiveblocksProvider 
      authEndpoint={async () => {
        if (!liveblocksCredentials?.token) {
          throw new Error('No token available');
        }
        return { token: liveblocksCredentials.token };
      }}
      resolveUsers={async ({ userIds }) => {
        return userIds.map((userId) => ({
          id: userId,
          name: userId
        }));
      }}
      resolveMentionSuggestions={async ({ text }) => {
        if (!accountId) return [];
        const { data: stakeholders } = await store.dispatch(
          usersApi.endpoints.getAccountStakeholders.initiate({ 
            accountId, 
            query: text 
          })
        );
        return stakeholders?.map(s => s.user) || [];
      }}
    >
      <RoomProvider id={`account-${accountId}`} initialPresence={{}}>
        <Outlet />
      </RoomProvider>
    </LiveblocksProvider>
  );
};

export default RouterRoot;
