interface RadioButtonProps {
  value: boolean;
  setValue: (value: boolean) => void;
  label?: string;
  className?: string;
  disabled?: boolean;
  child?: React.ReactNode;
}

const RadioButton = ({ value, setValue, label, className, disabled = false, child }: RadioButtonProps) => {
  return (
    <div className={`flex items-start justify-start ${className} gap-x-2`}>
      <div className="pt-1">
      <label className=" relative flex cursor-pointer items-center rounded" data-ripple-dark="true">
        <input
          type="radio"
          className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-300 transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:-translate-x-2/4 before:-translate-y-2/4 before:opacity-0 before:transition-opacity checked:border-lilac-600 checked:bg-lilac-600  hover:before:opacity-10"
          checked={value}
          onChange={() => setValue(!value)}
          disabled={disabled}
        />
        <div className="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
          <div className="h-1.5 w-1.5 rounded-full bg-white"></div>
        </div>
      </label>
      </div>
      {label && <div className="flex flex-col">
        <span className="text-text-primary">{label}</span>
        {child}
      </div>}

    </div>
  );
};

export default RadioButton;
