import { useSession } from '@descope/react-sdk';
import { Navigate, Outlet, useLoaderData } from 'react-router-dom';
import PageLoader from '../components/loaders/PageLoader';
import { Environment, getCurrentEnv } from '../infrastructure/env';

interface LoaderData {
  redirect?: string;
}

const PrivateRoute = () => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const loaderData = useLoaderData() as LoaderData | null;

  if (loaderData && loaderData.redirect) {
    return <Navigate to={loaderData.redirect} replace />;
  }

  if (isSessionLoading) {
    return <PageLoader />;
  }

  if (isAuthenticated || getCurrentEnv() === Environment.Testing) {
    return <Outlet />;
  }

  return <Navigate to="/login" replace />;
};

export default PrivateRoute;
