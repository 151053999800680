import { ArrowUpRightIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { IExpandedNode, NodeType, nodeTypePlurals, CONTAINER_TYPES } from "src/features/models/discover/INode";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";
import { mapNodeTypeToBackendNodeType } from "src/services/nodes/transformers";

export const ResourceSidepaneContentsOverview = ({ resource }: { resource: IExpandedNode }) => {
    const accountId = useSelector(selectActiveAccountId);
    const shouldFetchDownstream =  !CONTAINER_TYPES.includes(resource.type);
    const resourceHasDownstream = resource.containedNodes.length === 0;
    const getDownstreamResources = useGetDataModelResourcesQuery(
        { 
            accountId, 
            page: 1, 
            pageSize: 100, 
            eql: `parent_container = '${resource.id}'`, 
            withSubResources: true 
        },
        { skip: shouldFetchDownstream }
    );
    const containedNodes = resourceHasDownstream ? getDownstreamResources.data?.items || [] : resource.containedNodes;
    if (containedNodes.length === 0) {
        return null;
    }

    const goToDataModel = () => {
        window.open(`/data-model?Parent container URI=${resource.id}`, '_blank');
    };

    const containedNodeTypes = Array.from(new Set(containedNodes.map(n => n.type))).sort();

    return (
        <div className="p-4 bg-surface-light flex flex-col gap-4 flex-1 basis-full justify-end">
            <div className="flex items-center justify-between">
                <div className="font-semibold text-base">Contents overview</div>
                <Button onClick={goToDataModel} type={ButtonTypes.secondary} text={"Isolate in DAG"} icon={<ViewfinderCircleIcon width="16" height="16" />} />
            </div>
            <div className="px-6 py-8 border border-border rounded">
                <div className="text-secondary font-semibold mb-4">{containedNodes.length} items</div>
                <div className="flex flex-wrap gap-4">
                    {
                        containedNodeTypes.map(type => <ContentGroup resource={resource} key={type} type={type} count={containedNodes.filter(n => n.type === type).length} />)
                    }
                </div>
            </div>
        </div>
    );
};

const ContentGroup = ({ type, count, resource }: { type: NodeType, count: number, resource: IExpandedNode }) => {
    const openInDataModel = () => {
        window.open(`/data-model?Type=${mapNodeTypeToBackendNodeType.get(type)}&Parent container URI=${resource.id}`, '_blank');
    };
    return (
        <div className="rounded-lg bg-white border border-border p-3 w-28">
            <div className="flex justify-between">
                <div className="text-slate-800 font-semibold">{count}</div>
                <ArrowUpRightIcon width="12" height="12" className="text-slate-400 cursor-pointer hover:text-slate-500" onClick={openInDataModel} />
            </div>
            <div className="text-secondary">{nodeTypePlurals[type]}</div>
        </div>
    );
};