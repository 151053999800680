import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { BookOpenIcon, EnvelopeIcon, UserIcon, ChevronDownIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CustomProperty, PROPERTY_VALUE_INFO, PROPERTY_CLASS_INFO } from "src/services/customProperties/types";
import { format } from "date-fns";
import { useGetUserQuery } from "src/services/users";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { Toggle } from "src/components/Toggle";
import Input from "src/components/form/Input";
import { useGetCustomPropertiesListQuery } from "src/services/customProperties/customProperties";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import Modal from "src/components/Modal/Modal";

interface InformationPanelProps {
    property: CustomProperty;
    editMode: boolean;
    onSaveEditing: (updatedProperty: CustomProperty) => Promise<void>;
    editedProperty: CustomProperty;
    onPropertyChange: (updatedProperty: CustomProperty) => void;
}
const BUILTIN_OWNER = "Euno";

export const InformationPanel = ({ property, editMode, onSaveEditing, editedProperty, onPropertyChange }: InformationPanelProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
    const [newGroupName, setNewGroupName] = useState('');
    const [showOwnerModal, setShowOwnerModal] = useState(false);
    const { data: currentUser } = useGetUserQuery();
    const canTakeOwnership = useMemo(() => 
        property.owner !== currentUser?.email, 
    [property.owner, currentUser?.email]);
    
    const isBuiltInCustomizable = useMemo(() => 
        property?.owner === BUILTIN_OWNER, 
    [property?.owner]);
    
    const accountId = useSelector(selectActiveAccountId);
    const { data: properties } = useGetCustomPropertiesListQuery({ accountId });
    
    
    const groups = useMemo(() => 
        properties 
            ? [...new Set(properties.map(p => p.group).filter(Boolean))]
            : [],
    [properties]);

    const handleAddNewGroup = useCallback(() => {
        if (newGroupName.trim()) {
            const updatedProperty = {
                ...editedProperty,
                group: newGroupName.trim()
            };
            onPropertyChange(updatedProperty);
            setNewGroupName('');
            setIsAddingNewGroup(false);
        }
    }, [newGroupName, editedProperty, onPropertyChange]);

    const handleShowOwnerModal = useCallback(() => {
        setShowOwnerModal(prev => !prev);
    }, []);

    const handleTakeOwnership = useCallback(async () => {
        if (!currentUser?.email) return;
        try {
            const updatedProperty = {
                ...editedProperty,
                owner: currentUser.email
            };
            
            await onSaveEditing(updatedProperty);
        } catch (error) {
            console.error("Error taking ownership:", error);
        } finally {
            setShowOwnerModal(false);
        }
    }, [currentUser?.email, editedProperty, onSaveEditing]);

    const handleDescriptionChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onPropertyChange({
            ...editedProperty,
            description: e.target.value
        });
    }, [editedProperty, onPropertyChange]);

    const handleVisibilityChange = useCallback((value: boolean) => {
        const updatedGroup = value ? editedProperty.group : '';
        onPropertyChange({ 
            ...editedProperty, 
            showInOverview: value,
            group: updatedGroup
        });
    }, [editedProperty, onPropertyChange]);

    return (
        <div className="p-6">
            <div className="flex items-center justify-between gap-2 mb-4">
                <div className="flex items-center gap-2">
                    <div className="p-1 rounded-md bg-slate-200 border border-slate-300">   
                        <BookOpenIcon className="h-5 w-5 text-slate-600" />
                    </div>
                    <h2 className="text-lg font-semibold">Information</h2>
                </div>
            </div>
            <div className="grid grid-cols-3 w-full gap-x-2 mb-2">
                <div className="col-span-2">
                    {editMode ? (
                            <textarea
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-lilac-500 focus:border-lilac-500 bg-white min-h-[60px]"
                                placeholder="Enter description"
                                value={editedProperty.description || ''}
                                onChange={handleDescriptionChange}
                            />
                    ) : (
                        <p className="text-slate-700">
                            {property.description || 'No description provided'}
                        </p>
                    )}
                </div>
                <div className="space-y-1">
                    <div className="flex items-center gap-2 text-sm px-2 py-1">
                        <EnvelopeIcon className="h-4 w-4 text-slate-500" />
                        <span className="text-slate-700">{property.owner}</span>
                        <span className="text-xs bg-slate-100 text-slate-500 px-1.5 py-0.5 rounded uppercase">owner</span>
                    </div>
                    <div className="w-full">
                    {canTakeOwnership && (
                        <Button
                            className="w-full"
                            type={ButtonTypes.secondary}
                            text="Take Ownership"
                            onClick={handleShowOwnerModal}
                            isDisabled={isBuiltInCustomizable}
                            tooltip={isBuiltInCustomizable ? 'Cannot take ownership of built-in customizable properties' : ''}
                        />
                    )}
                    </div>
                </div>
            </div>
            <div className="mb-3 bg-slate-200 rounded-lg py-3 px-4 flex justify-around gap-2 text-md">
                <div className="flex flex-col items-start justify-center gap-2">
                    <div className="flex items-start gap-2">
                        <div className="text-slate-500">Created by:</div>
                        <div className="flex items-center">
                            <div className="h-4 w-4 rounded-full bg-lilac-200 flex items-center justify-center text-xs text-slate-600 mr-2">
                                <UserIcon className="h-3 w-3" />
                            </div>
                            <span>{property.owner}</span>
                        </div>
                    </div>
                    <div className="flex items-start gap-2">
                        <div className="text-slate-500">Created at:</div>
                        <div>{format(new Date(property.createdAt), 'MMM d, yyyy / h:mma')}</div>
                    </div>
                </div>
                <div className="flex flex-col items-start gap-2">
                    <div className="flex items-start gap-2">
                        <div className="text-slate-500">Last Updated:</div>
                        <div className="flex items-center">
                            <div className="h-4 w-4 rounded-full bg-lilac-200 flex items-center justify-center text-xs text-slate-600 mr-2">
                                <UserIcon className="h-3 w-3" />
                            </div>
                            <span>{property.owner}</span>
                        </div>
                    </div>
                    <div className="flex items-start gap-2">
                        <div className="text-slate-500">Updated at:</div>
                        <div>{format(new Date(property.lastUpdatedAt), 'MMM d, yyyy / h:mma')}</div>
                    </div>
                </div>
            </div>
            <div className="rounded-md border border-slate-200 overflow-hidden">
                <table className="w-full text-md">
                    <tbody>
                        <tr className="border-b border-slate-200">
                            <td className="px-4 py-3 text-slate-500">EQL Name</td>
                            <td className="px-4 py-3">{property.eqlName}</td>
                        </tr>
                        <tr className="border-b border-slate-200">
                            <td className="px-4 py-3 text-slate-500">Property Class</td>
                            <td className="px-4 py-3">{PROPERTY_CLASS_INFO[property.class]?.label || property.class}</td>
                        </tr>
                        <tr className="border-b border-slate-200">
                            <td className="px-4 py-3 text-slate-500">Property Type</td>
                            <td className="px-4 py-3">{PROPERTY_VALUE_INFO[property.content.type]?.label || property.content.type}</td>
                        </tr>
                        <tr className="border-b border-slate-200">
                            <td className="px-4 py-3 text-slate-500">Group</td>
                            <td className="px-4 py-3 relative max-w-[100px] overflow-visible">
                                {editMode && editedProperty.showInOverview ? (
                                
                                        <GroupEditField 
                                            editedProperty={editedProperty}
                                            setEditedProperty={onPropertyChange}
                                            groups={groups}
                                            newGroupName={newGroupName}
                                            setNewGroupName={setNewGroupName}
                                            isAddingNewGroup={isAddingNewGroup}
                                            setIsAddingNewGroup={setIsAddingNewGroup}
                                            isDropdownOpen={isDropdownOpen}
                                            setIsDropdownOpen={setIsDropdownOpen}
                                            handleAddNewGroup={handleAddNewGroup}
                                        />
                                    
                                ) : (
                                    editedProperty.group || ''
                                )}
                            </td>
                        </tr>
                        <tr className="border-b border-slate-200">
                            <td className="px-4 py-3 text-slate-500">Visibility</td>
                            <td className="px-4 py-3">
                                    <VisibilityToggle
                                        showInOverview={editedProperty.showInOverview}
                                        isEditing={editMode}
                                        onChange={handleVisibilityChange}
                                        displayValue={property.showInOverview}
                                    />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {showOwnerModal && (
                <Modal
                title="Take Ownership"
                isOpen={showOwnerModal}
                onClose={() => setShowOwnerModal(false)}
                buttons={[
                    {
                        text: "Cancel",
                        onClick: () => setShowOwnerModal(false),
                        type: ButtonTypes.secondary,
                        position: "left",
                        isDisabled: false
                    },
                    {
                        text: "Yes, take ownership",
                        onClick: handleTakeOwnership,
                        type: ButtonTypes.primary,
                        position: "right",
                        isDisabled: false
                    }
                ]}
            >   
                <div className="text-gray-600 my-4">
                    <p>This action will replace the current owner and assign ownership of this property to you.</p>
                </div>
            </Modal>
            )}
        </div>
    );
};

interface GroupEditFieldProps {
    editedProperty: CustomProperty;
    setEditedProperty: (updatedProperty: CustomProperty) => void;
    groups: string[];
    newGroupName: string;
    setNewGroupName: React.Dispatch<React.SetStateAction<string>>;
    isAddingNewGroup: boolean;
    setIsAddingNewGroup: React.Dispatch<React.SetStateAction<boolean>>;
    isDropdownOpen: boolean;
    setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleAddNewGroup: () => void;
}

const GroupEditField = ({
    editedProperty,
    setEditedProperty,
    groups,
    newGroupName,
    setNewGroupName,
    isAddingNewGroup,
    setIsAddingNewGroup,
    isDropdownOpen,
    setIsDropdownOpen,
    handleAddNewGroup
}: GroupEditFieldProps) => {
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (isDropdownOpen && buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width
            });
        }
    }, [isDropdownOpen]);

    useEffect(() => {
        if (!isDropdownOpen) return;
        
        const handleClickOutside = (event: MouseEvent) => {
            if (isAddingNewGroup) return;
            
            const dropdownElement = document.querySelector('.group-dropdown-menu');
            if (dropdownElement && dropdownElement.contains(event.target as Node)) {
                return;
            }
            
            if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside, { capture: true });
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, { capture: true });
        };
    }, [isDropdownOpen, setIsDropdownOpen, isAddingNewGroup]);

    const handleToggleDropdown = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen, setIsDropdownOpen]);

    const handleStartAddingNewGroup = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        setIsAddingNewGroup(true);
        setIsDropdownOpen(false);
    }, [setIsAddingNewGroup, setIsDropdownOpen]);

    const handleCancelAddingNewGroup = useCallback(() => {
        setIsAddingNewGroup(false);
        setNewGroupName('');
    }, [setIsAddingNewGroup, setNewGroupName]);

    const handleNewGroupNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setNewGroupName(e.target.value);
    }, [setNewGroupName]);

    const handleSelectGroup = useCallback((group: string) => (e: React.MouseEvent) => {
        e.stopPropagation();
        setEditedProperty({
            ...editedProperty,
            group
        });
        setIsDropdownOpen(false);
    }, [editedProperty, setEditedProperty, setIsDropdownOpen]);

    if (isAddingNewGroup) {
        return (
            <div className="flex items-center gap-2 w-full" >
                <div className="relative flex-1">
                    <Input
                        placeholder="Enter new group name"
                        type="text"
                        value={newGroupName}
                        onInputChange={handleNewGroupNameChange}
                        className="w-full"
                    />
                    <button
                        onClick={handleCancelAddingNewGroup}
                        className="absolute right-2 top-1/2 -translate-y-1/2 p-1 text-slate-400 hover:text-slate-600"
                    >
                        <XMarkIcon className="h-4 w-4" />
                    </button>
                </div>
                <Button
                    type={ButtonTypes.secondary}
                    text="Add"
                    onClick={handleAddNewGroup}
                    className="px-4 whitespace-nowrap"
                />
            </div>
        );
    }
    
    return (
        <div className="relative w-full">
            <button
                ref={buttonRef}
                onClick={handleToggleDropdown}
                className="flex w-full items-center justify-between rounded-md border border-border bg-white px-3 py-2 text-left text-sm text-slate-700 shadow hover:border-primary"
            >
                <span>{editedProperty.group || 'Select a group'}</span>
                <ChevronDownIcon className="h-4 w-4 flex-shrink-0 ml-2" />
            </button>
            {isDropdownOpen && (
                <div 
                    className="fixed rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999] max-h-80 overflow-y-auto group-dropdown-menu"
                    style={{
                        top: `${dropdownPosition.top}px`,
                        left: `${dropdownPosition.left}px`,
                        width: `${dropdownPosition.width}px`
                    }}
                >
                    <div className="py-1">
                        <button
                            onClick={handleStartAddingNewGroup}
                            className="flex w-full items-center px-3 py-2 text-left text-sm hover:bg-lilac-100 border-b border-slate-100"
                        >
                            <PlusIcon className="h-4 w-4 mr-2" />
                            <span>Add new group</span>
                        </button>
                        <button
                            onClick={handleSelectGroup('General')}
                            className="flex w-full items-center px-3 py-2 text-left text-sm hover:bg-lilac-100"
                        >
                            <span>General</span>
                        </button>
                        {groups.filter(group => group !== 'General').map((group) => (
                            <button
                                key={group}
                                onClick={handleSelectGroup(group)}
                                className="flex w-full items-center px-3 py-2 text-left text-sm hover:bg-lilac-100"
                            >
                                <span>{group}</span>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

interface VisibilityToggleProps {
    showInOverview: boolean;
    onChange: (value: boolean) => void;
    isEditing?: boolean;
    displayValue?: boolean;
}

const VisibilityToggle = ({ showInOverview, onChange, isEditing = false, displayValue }: VisibilityToggleProps) => {
    let valueToDisplay = showInOverview;
    
    if (!isEditing && displayValue !== undefined) {
        valueToDisplay = displayValue;
    }
    
    const handleToggleChange = useCallback(() => {
        onChange(!showInOverview);
    }, [onChange, showInOverview]);
    
    return (
        <div className="flex items-center">
            {isEditing ? (
                <>
                    <Toggle
                        onChange={handleToggleChange}
                        value={showInOverview}
                    />
                    <span className="ml-2">
                        {valueToDisplay ? 'Visible' : 'Hidden'}
                    </span>
                </>
            ) : (
                <span className="text-slate-700">
                    {valueToDisplay ? 'Visible' : 'Hidden'}
                </span>
            )}
        </div>
    );
};

