export enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger'
}

export interface ButtonProps {
  type: ButtonTypes;
  text: string;
  isLoading?: boolean;
  isLoadingText?: string;
  onClick?: () => void;
  className?: string;
  icon?: JSX.Element;
  dataTestId?: string;
  isDisabled?: boolean;
  enterToSubmit?: boolean;
  tooltip?: string;
}
