import { dateFormats, utcToLocal } from "src/infrastructure/dateUtilities";
import { IExpandedNode, NodeType } from "../models/discover/INode";
import { ChangeType, Pat } from "./IChange";

export const generateRefreshDataModelPatFromNode = (node: IExpandedNode, projectId: number): Pat | null => {
    const modelUniqueId = node.identifier;
    if (!modelUniqueId) {
        console.error('Missing node unique id');
        return null;
    }
    if (!node.syncedRawCode?.rawCode || !node.syncedRawCode.isOutdated) {
        console.error('Node does not have outdated raw code');
        return null;
    }

    const currentDateFormattedForTitle = utcToLocal(new Date().toLocaleDateString('en-US', {month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true}), dateFormats.monthsDaysHoursAndMinutes);
    const refreshedModelSQL = node.syncedRawCode?.rawCode;

    return {
        projectId: projectId,
        title: `Refresh data model ${node.name} on ${currentDateFormattedForTitle}`,
        description: `This change automatically refreshes the underlying SQL query for the data model "${node.name}" to reflect the latest metric definitions on ${currentDateFormattedForTitle}.`,
        changeType: ChangeType.RefreshDataModel,
        changeData: {
            refreshedModelSQL,
            modelUniqueId
        },
        targetBranch: '',
        sourceUtl: node.id,
        sourceType: NodeType.DataModel,
        sourceName: node.name,
        rawContext: {
            source_utl: node.id,
            created_by_resource_name: node.dbtProjectName,
            type_specific_content: {
                creation_context_type: 'dbt_project'
            }
        }
    };
};
