import { ChangeEvent } from "react";
import Checkbox from "src/components/form/Checkbox";
import { FormField } from "src/components/form/FormField";
import Input from "src/components/form/Input";
import { GenericIntegration, GenericIntegrationConfiguration } from "src/services/integrations/types";
import { Link } from "src/components/Link";
import { eunoLinks } from "src/features/eunoLinks";

type SnowflakeAdvanceBlockProps = {
    integration: GenericIntegration,
    setIntegration: (integration: GenericIntegration) => void,
}


const SnowflakeAdvanceBlock = ({ integration, setIntegration }: SnowflakeAdvanceBlockProps) => {
    const snowflakeFields = {
    table :{ value: "table_to_use_for_query_history", label: "Query history table", placeholder: "e.g snowflake.account_usage.query_history" },
    views:{ value: "extract_views", label: "Auto discover views & tables metadata", },
    tables:{ value: "extract_tables", label: "Auto discover tables metadata" },
    tableau:{ value: "extract_tableau_usage", label: "Auto discover Tableau usage" },
    database:{ value: "use_snowflake_database", label: "Use Snowflake system database to collect views metadata"}
    };

    const getValueFromConfig = (value: string) => {
        return integration.configuration ? integration.configuration[value as keyof GenericIntegrationConfiguration] : '';
    };

    return (
        <div className="flex justify-start flex-col mt-4 max-w-[640px] ">
            <span className="text-lg">Auto discovery of Snowflake views & tables</span>
            <div className="flex flex-col justify-start items-start my-2 gap-y-2">
                <Checkbox
                    value={Boolean(getValueFromConfig(snowflakeFields.views.value)) && Boolean(getValueFromConfig(snowflakeFields.tables.value))}
                    setValue={(value: boolean) =>
                        setIntegration({ ...integration, configuration: { ...integration.configuration, [snowflakeFields.views.value]: value, [snowflakeFields.tables.value]: value  } })}
                    label={snowflakeFields.views.label}
                />
                <span className="text-sm text-tertiary ml-5">Enable this for auto discovery of Snowflake views & tables data. You’ll also need to authorize in snowflake.</span>
                <Checkbox
                    value={Boolean(getValueFromConfig(snowflakeFields.database.value))}
                    setValue={(value: boolean) =>
                        setIntegration({ ...integration, configuration: { ...integration.configuration, [snowflakeFields.database.value]: value } })}
                    label={snowflakeFields.database.label}
                />
                <span className="text-sm text-tertiary max-w-[580px] ml-5 ">Choose this to discover all views defined in the snowflake account. If you prefer not to grant the integration permissions to query the views definitions on all databases, See <Link text={`Control which views metadata Euno has access to.`} onClick={() => window.open(eunoLinks.SNOWFLAKE_CONTROL_VIEWS_ACCESS_DOCUMENTATION, "_blank")} /> </span>
            </div>
            <div className="my-2 flex flex-col justify-start items-start">
            <span className="text-lg">Tableau usage</span>
            <span className="text-tertiary font-normal text-base">Tableau queries to Snowflake</span>
            </div>
            <div className="flex flex-col justify-start items-start mt-2 gap-y-2">
                <Checkbox
                    value={Boolean(getValueFromConfig(snowflakeFields.tableau.value))}
                    setValue={(value: boolean) =>
                        setIntegration({ ...integration, configuration: { ...integration.configuration, [snowflakeFields.tableau.value]: value } })}
                    label={snowflakeFields.tableau.label}
                />
                <span className="text-sm text-tertiary ml-5">Enable this to auto discover Tableau usage data</span>
                <div className="w-full">
                <FormField
                    label={snowflakeFields.table.label}
                    labelClassName='text-secondary mb-1 w-44 ml-5'
                >
                    <Input
                        className="w-full"
                        placeholder={snowflakeFields.table.placeholder}
                        value={getValueFromConfig(snowflakeFields.table.value)}
                        type={'text'}
                        onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setIntegration({ ...integration, configuration: { ...integration.configuration, [snowflakeFields.table.value]: e.target.value } })}
                    />
                </FormField>
                </div>
            </div>
        </div>
    );
};


export default SnowflakeAdvanceBlock;