import { useSelector } from 'react-redux';
import Layout from 'src/components/layout/Layout';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { DataModelOverview } from './components/DataModelOverview';
import { AccountIntegrations } from './components/AccountIntegrations';
import { useGetGenericIntegrationsQuery } from 'src/services/integrations/integrations';
import { useState, useEffect } from 'react';


export const HomePage = () => {
    const accountId = useSelector(selectActiveAccountId);
    const [hasIntegrationError, setHasIntegrationError] = useState(false);

    const { data: integrations = [], isLoading: isIntegrationsLoading, error: isIntegrationsError } = useGetGenericIntegrationsQuery({ accountId }, {
        skip: !accountId || hasIntegrationError
    });

    useEffect(() => {
        if (isIntegrationsError) {
            setHasIntegrationError(true);
        }
    }, [isIntegrationsError]);

    return (
        <Layout>
            <div className="overflow-y-auto max-h-[100vh]">
                <div className={`m-4 grid grid-cols-2 gap-x-2 ${!hasIntegrationError ? 'grid-cols-1' : ''}`}>
                    <div className={`${!hasIntegrationError ? 'col-span-1' : 'col-span-2'}`}>
                        <DataModelOverview accountId={accountId} />
                    </div>
                    {!hasIntegrationError &&
                        <AccountIntegrations accountId={accountId} integrations={integrations} isLoading={isIntegrationsLoading} error={isIntegrationsError} />
                    }
                </div>
            </div>
        </Layout>
    );
};