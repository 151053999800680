import { useCallback, useEffect, useMemo, useRef, memo } from "react";
import { AggregateRelationshipContent, AISelectContent, CustomPropertyFEType } from "src/services/customProperties/types";
import EQLInput from "src/components/eql/input/EQLInput";
import Checkbox from "src/components/form/Checkbox";
import { FormField } from "src/components/form/FormField";
import { EllipsisVerticalIcon, ChevronUpIcon, ChevronDownIcon, XMarkIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import DropdownMenu from "src/components/DropdownMenu/DropdownMenu";
import Input from "src/components/form/Input";
import { Button } from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { CustomProperty } from "src/services/customProperties/types";

export interface ConfigurationItem {
    value: string;
    eql: string;
}

interface CategoryAssignedItemProps {
    value: string;
    eql: string;
    index: number;
    totalItems: number;
    onMove: (index: number, direction: 'up' | 'down') => void;
    onRemove: (index: number) => void;
    onDuplicate: (index: number) => void;
    onChange: (index: number, field: 'value' | 'eql', value: string) => void;
    editMode: boolean;
    showEql: boolean;
    onEqlValidationChange?: (index: number, isValid: boolean) => void;
}

interface PropertyConfigDisplayProps {
    propertyType: CustomPropertyFEType;
    items: ConfigurationItem[];
    editMode: boolean;
    onPropertyChange: (updatedProperty: CustomProperty) => void;
    handleMove: (index: number, direction: 'up' | 'down') => void;
    handleRemove: (index: number) => void;
    handleChange: (index: number, field: 'value' | 'eql', value: string) => void;
    handleAddValue: () => void;
    editedProperty: CustomProperty;
    onEqlValidationChange?: (key: string, isValid: boolean) => void;
}

interface AggregateRelationshipSectionProps {
    content: AggregateRelationshipContent;
}

interface AISelectSectionProps {
    content: AISelectContent;
}

interface PropertyConfigContentProps {
    property: CustomProperty;
    items: ConfigurationItem[];
    editMode: boolean;
    handleMove: (index: number, direction: 'up' | 'down') => void;
    handleRemove: (index: number) => void;
    handleDuplicate: (index: number) => void;
    handleChange: (index: number, field: 'value' | 'eql', value: string) => void;
    handleAddValue: () => void;
    editedProperty: CustomProperty;
    supportsEditing: () => boolean;
    showsEql: () => boolean;
    onEqlValidationChange: (key: string, isValid: boolean) => void;
    handleCategoryAssignedValidationChange: (index: number, isValid: boolean) => void;
    updateEditedProperty: (updatedProperty: CustomProperty) => void;
}

interface PropertyScopeInputProps {
    isFixedType: boolean;
    getCurrentEql: () => string;
    editedProperty: CustomProperty;
    editMode: boolean;
    handleScopeChangeForSpecialType: (newValue: string) => void;
    handleScopeChange: (newValue: string) => void;
    onValidationChange?: (isValid: boolean) => void;
}

interface LiveBooleanSectionProps {
    isLiveBoolean: boolean;
    getCurrentValue: () => string;
    handleLiveBooleanValueChange: (newValue: string) => void;
    editMode: boolean;
    onValidationChange?: (isValid: boolean) => void;
}

interface ValuesListProps {
    items: ConfigurationItem[];
    editMode: boolean;
    handleValueChange: (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleRemoveValue: (index: number) => () => void;
}

interface AddValueButtonProps {
    editMode: boolean;
    isMultiSelect: boolean;
    handleAddValue: () => void;
}

interface ValuesSectionProps {
    isMultiSelect: boolean;
    isSingleSelect: boolean;
    items: ConfigurationItem[];
    editMode: boolean;
    handleValueChange: (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleRemoveValue: (index: number) => () => void;
    handleAddValue: () => void;
}

interface LiveBooleanPropertySectionProps {
    items: ConfigurationItem[];
    editMode: boolean;
    editedProperty: CustomProperty;
    handleChange: (index: number, field: 'value' | 'eql', value: string) => void;
    onPropertyChange: (updatedProperty: CustomProperty) => void;
    onEqlValidationChange: (key: string, isValid: boolean) => void;
}

interface PropertyTypeFlags {
    isFixedType: boolean;
    isMultiSelect: boolean;
    isSingleSelect: boolean;
    isLiveBoolean: boolean;
    isCategoryAssigned: boolean;
    isAggregateRelationship: boolean;
    isAISelect: boolean;
}

function usePropertyTypeFlags(propertyType: CustomPropertyFEType): PropertyTypeFlags {
    return useMemo(() => ({
        isFixedType: [
            CustomPropertyFEType.Numeric,
            CustomPropertyFEType.Datetime,
            CustomPropertyFEType.FixedBoolean,
        ].includes(propertyType),
        isMultiSelect: propertyType === CustomPropertyFEType.MultiSelect,
        isSingleSelect: propertyType === CustomPropertyFEType.SingleSelect,
        isLiveBoolean: propertyType === CustomPropertyFEType.LiveBoolean,
        isCategoryAssigned: propertyType === CustomPropertyFEType.CategoryAssigned,
        isAggregateRelationship: propertyType === CustomPropertyFEType.AggregateRelationships,
        isAISelect: propertyType === CustomPropertyFEType.AISelect
    }), [propertyType]);
}

export const PropertyConfigContent = memo(({
    property,
    items,
    editMode,
    handleMove,
    handleRemove,
    handleDuplicate,
    handleChange,
    handleAddValue,
    editedProperty,
    supportsEditing,
    showsEql,
    onEqlValidationChange,
    handleCategoryAssignedValidationChange,
    updateEditedProperty
}: PropertyConfigContentProps) => {
    switch (property.content.type) {
        case CustomPropertyFEType.CategoryAssigned:
            return items.length === 0 ? (
                <div className="bg-white rounded-lg border border-gray-200">
                    <EmptyState />
                </div>
            ) : (
                <div className="space-y-4">
                    {items.map((item, index) => (
                        <CategoryAssignedItem
                            key={index}
                            value={item.value}
                            eql={item.eql}
                            index={index}
                            totalItems={items.length}
                            onMove={handleMove}
                            onRemove={handleRemove}
                            onDuplicate={handleDuplicate}
                            onChange={handleChange}
                            editMode={editMode && supportsEditing()}
                            showEql={showsEql()}
                            onEqlValidationChange={handleCategoryAssignedValidationChange}
                        />
                    ))}
                </div>
            );
        case CustomPropertyFEType.LiveBoolean:
            return (
                <LiveBooleanPropertySection
                    items={items}
                    editMode={editMode}
                    editedProperty={editedProperty}
                    handleChange={handleChange}
                    onPropertyChange={updateEditedProperty}
                    onEqlValidationChange={onEqlValidationChange}
                />
            );
        case CustomPropertyFEType.MultiSelect:
        case CustomPropertyFEType.SingleSelect:
        case CustomPropertyFEType.Numeric:
        case CustomPropertyFEType.Datetime:
        case CustomPropertyFEType.FixedBoolean:
            return (
                <FixedPropertyConfigDisplay
                    propertyType={property.content.type}
                    items={items}
                    editMode={editMode}
                    onPropertyChange={updateEditedProperty}
                    handleMove={handleMove}
                    handleRemove={handleRemove}
                    handleChange={handleChange}
                    handleAddValue={handleAddValue}
                    editedProperty={editedProperty}
                    onEqlValidationChange={onEqlValidationChange}
                />
            );
        case CustomPropertyFEType.AggregateRelationships:
            return <AggregateRelationshipSection content={property.content.content as AggregateRelationshipContent} />;
        case CustomPropertyFEType.AISelect:
            return <AISelectSection content={property.content.content as AISelectContent} />;
        default:
            return null;
    }
});

PropertyConfigContent.displayName = 'PropertyConfigContent';

export const CategoryAssignedItem = memo(({ value, eql, index, totalItems, onMove, onRemove, onDuplicate, onChange, editMode, showEql, onEqlValidationChange }: CategoryAssignedItemProps) => {
    const menuOptions = useCallback((index: number) => [
        {
            name: 'Duplicate', className: 'hover:bg-slate-50',
            onClick: () => {
                onDuplicate(index);
            }
        },
        {
            name: 'Delete Value', className: 'hover:bg-slate-50 !text-danger-strong',
            onClick: () => {
                onRemove(index);
            }
        }
    ], [onDuplicate, onRemove]);

    const handleValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(index, 'value', e.target.value);
    }, [onChange, index]);

    const handleEqlChange = useCallback((newValue: string) => {
        onChange(index, 'eql', newValue);
    }, [onChange, index]);

    const handleMoveUp = useCallback(() => {
        onMove(index, 'up');
    }, [onMove, index]);

    const handleMoveDown = useCallback(() => {
        onMove(index, 'down');
    }, [onMove, index]);

    const eqlValidationCallbackRef = useRef<(isValid: boolean) => void>();
    useEffect(() => {
        eqlValidationCallbackRef.current = (isValid: boolean) => {
            onEqlValidationChange?.(index, isValid);
        };
    }, [onEqlValidationChange, index]);

    return (
        <div className="w-full grid grid-cols-12 gap-x-2 bg-white rounded-lg border border-gray-200 py-4 px-2 transition-all duration-200 ease-in-out">
            <div className={`col-span-2 flex flex-col items-start justify-start gap-y-2 ${editMode ? 'justify-center' : 'justify-start'}`}>
                {editMode ? (
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-lilac-500 focus:border-lilac-500 bg-white"
                        placeholder="Enter value name"
                        value={value || ''}
                        onChange={handleValueChange}
                    />
                ) : (
                    <span className="text-slate-600 ml-2 mt-1 font-semibold text-lg">{value}</span>
                )}
            </div>
            {showEql && (
                <div className="col-span-9 relative flex flex-col items-start gap-y-2">
                    <span className="text-slate-600 ml-2">EQL</span>
                    <EQLInput
                        value={eql || ''}
                        onChange={handleEqlChange}
                        placeholder="Enter EQL query..."
                        isDisabled={!editMode}
                        onValidationChange={eqlValidationCallbackRef.current}
                        showSearchButton={false}
                    />
                </div>
            )}
            {
                editMode && (
                    <div className="col-span-1 flex items-center ml-8 gap-x-2 ">
                        <div className="flex items-center">
                            <DropdownMenu items={menuOptions(index)} className="p-1 text-slate-400 hover:text-slate-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors" withStopPropogation>
                                <EllipsisVerticalIcon className="text-slate-400" width={20} height={20} />
                            </DropdownMenu>
                        </div>
                        <div className="flex flex-col gap-y-2">
                            <button
                                onClick={handleMoveUp}
                                disabled={index === 0}
                                className="p-1 text-slate-400 hover:text-slate-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                            >
                                <ChevronUpIcon className="h-4 w-4" />
                            </button>
                            <button
                                onClick={() => onRemove(index)}
                                className="p-1 text-slate-400 hover:text-red-600 transition-colors"
                            >
                                <XMarkIcon className="h-4 w-4" />
                            </button>
                            <button
                                onClick={handleMoveDown}
                                disabled={index === totalItems - 1}
                                className="p-1 text-slate-400 hover:text-slate-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                            >
                                <ChevronDownIcon className="h-4 w-4" />
                            </button>
                        </div>
                    </div>
                )}
        </div>
    );
});

CategoryAssignedItem.displayName = 'CategoryAssignedItem';

export const EmptyState = () => {
    const getMessage = () => {
        return "No values configured";
    };

    return (
        <div className="flex flex-col items-center justify-center py-12 px-4">
            <div className="bg-slate-50 rounded-full p-3 mb-4">
                <Cog6ToothIcon className="h-8 w-8 text-slate-400" />
            </div>
            <h3 className="text-lg font-medium text-slate-900 mb-1">{getMessage()}</h3>
        </div>
    );
};

const PropertyScopeInput = memo(({
    isFixedType,
    getCurrentEql,
    editedProperty,
    editMode,
    handleScopeChangeForSpecialType,
    handleScopeChange,
    onValidationChange
}: PropertyScopeInputProps) => {
    if (isFixedType) {
        return (
            <EQLInput
                value={getCurrentEql()}
                onChange={handleScopeChangeForSpecialType}
                placeholder="Enter EQL query..."
                isDisabled={!editMode}
                onValidationChange={onValidationChange}
                allowRelationships={false}
                showSearchButton={false}
            />
        );
    } else {
        return (
            <EQLInput
                value={editedProperty.propertyScope || ''}
                onChange={handleScopeChange}
                placeholder="Enter EQL query for property scope..."
                isDisabled={!editMode}
                onValidationChange={onValidationChange}
                allowRelationships={false}
                showSearchButton={false}
            />
        );
    }
});

PropertyScopeInput.displayName = 'PropertyScopeInput';

const LiveBooleanSection = memo(({
    isLiveBoolean,
    getCurrentValue,
    handleLiveBooleanValueChange,
    editMode,
    onValidationChange
}: LiveBooleanSectionProps) => {
    if (!isLiveBoolean) return null;
    
    return (
        <div className="flex items-center gap-4 mt-4 mb-2">
            <div className="w-40 font-medium">
                True if
            </div>
            <div className="flex-1 max-w-full mr-5">
                <EQLInput
                    value={getCurrentValue()}
                    onChange={handleLiveBooleanValueChange}
                    placeholder="Enter condition..."
                    isDisabled={!editMode}
                    onValidationChange={onValidationChange}
                    showSearchButton={false}
                />
            </div>
        </div>
    );
});

LiveBooleanSection.displayName = 'LiveBooleanSection';

const ValuesList = memo(({
    items,
    editMode,
    handleValueChange,
    handleRemoveValue
}: ValuesListProps) => {
    if (items.length === 0) {
        return (
            <div className="bg-white rounded-lg border border-gray-200 p-4 text-center text-slate-500">
                No values configured
            </div>
        );
    }

    return (
        <div className="bg-white rounded-lg border border-gray-200 p-4">
            <div className="space-y-3">
                {items.map((item: ConfigurationItem, index: number) => (
                    <div key={index} className="flex items-center gap-2">
                        {editMode ? (
                            <input
                                type="text"
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-lilac-500 focus:border-lilac-500 bg-white"
                                placeholder="Enter value"
                                value={item.value || ''}
                                onChange={handleValueChange(index)}
                            />
                        ) : (
                            <span className="flex-1 text-slate-700">{item.value}</span>
                        )}
                        {editMode && (
                            <div className="flex items-center gap-2">
                                <button
                                    onClick={handleRemoveValue(index)}
                                    className="p-1 text-slate-400 hover:text-red-600 transition-colors"
                                >
                                    <XMarkIcon className="h-4 w-4" />
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
});

ValuesList.displayName = 'ValuesList';

const AddValueButton = memo(({
    editMode,
    isMultiSelect,
    handleAddValue
}: AddValueButtonProps) => {
    if (editMode && isMultiSelect) {
        return (
            <Button
                type={ButtonTypes.secondary}
                text="Add Value"
                onClick={handleAddValue}
                className="text-sm py-1"
            />
        );
    }
    return null;
});

AddValueButton.displayName = 'AddValueButton';

const ValuesSection = memo(({
    isMultiSelect,
    isSingleSelect,
    items,
    editMode,
    handleValueChange,
    handleRemoveValue,
    handleAddValue
}: ValuesSectionProps) => {
    if (!isMultiSelect && !isSingleSelect) return null;
    
    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <h3 className="text-md font-medium">Values</h3>
                <AddValueButton 
                    editMode={editMode} 
                    isMultiSelect={isMultiSelect} 
                    handleAddValue={handleAddValue} 
                />
            </div>
            <ValuesList 
                items={items} 
                editMode={editMode} 
                handleValueChange={handleValueChange} 
                handleRemoveValue={handleRemoveValue} 
            />
        </div>
    );
});

ValuesSection.displayName = 'ValuesSection';

export const FixedPropertyConfigDisplay = memo(({
    propertyType,
    items,
    editMode,
    onPropertyChange,
    handleRemove,
    handleChange,
    handleAddValue,
    editedProperty,
    onEqlValidationChange
}: PropertyConfigDisplayProps) => {
    
    const { isFixedType, isMultiSelect, isSingleSelect } = usePropertyTypeFlags(propertyType);

    const getCurrentEql = useCallback(() => {
        if (!items || items.length === 0) return '';
        return items[0]?.eql || '';
    }, [items]);

    const handleScopeChangeForSpecialType = useCallback((newValue: string) => {
        handleChange(0, 'eql', newValue);
        onPropertyChange({ ...editedProperty, propertyScope: newValue });
    }, [handleChange, onPropertyChange, editedProperty]);

    const handleScopeChange = useCallback((newValue: string) => {
        onPropertyChange({ ...editedProperty, propertyScope: newValue });
    }, [onPropertyChange, editedProperty]);

    const handleValueChange = useCallback((index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(index, 'value', e.target.value);
    }, [handleChange]);

    const handleRemoveValue = useCallback((index: number) => () => {
        handleRemove(index);
    }, [handleRemove]);

    const propertyScopeValidationRef = useRef<(isValid: boolean) => void>();

    useEffect(() => {
        propertyScopeValidationRef.current = (isValid: boolean) => {
            onEqlValidationChange?.('propertyScope', isValid);
        };
    }, [onEqlValidationChange]);

    return (
        <>
            <div className="mb-6 bg-white rounded-lg border border-gray-200 p-4">
                <div className="flex items-center gap-4">
                    <div className="w-40 font-medium">
                        Property Scope
                    </div>
                    <div className="flex-1 max-w-full mr-5 mb-2">
                        <PropertyScopeInput
                            isFixedType={isFixedType}
                            getCurrentEql={getCurrentEql}
                            editedProperty={editedProperty}
                            editMode={editMode}
                            handleScopeChangeForSpecialType={handleScopeChangeForSpecialType}
                            handleScopeChange={handleScopeChange}
                            onValidationChange={propertyScopeValidationRef.current}
                        />
                    </div>
                </div>
            </div>
            <ValuesSection
                isMultiSelect={isMultiSelect}
                isSingleSelect={isSingleSelect}
                items={items}
                editMode={editMode}
                handleValueChange={handleValueChange}
                handleRemoveValue={handleRemoveValue}
                handleAddValue={handleAddValue}
            />
        </>
    );
});

FixedPropertyConfigDisplay.displayName = 'FixedPropertyConfigDisplay';

export const AggregateRelationshipSection = ({ content }: AggregateRelationshipSectionProps) => {
    const { applies_to_eql, type_of_relationship, path_length, target_eql, aggregation, target_property } = content;


    const getEql = useCallback((eql: string) => {
        if (!eql) return '';
        return eql;
    }, []);


    return (
        <div className="mb-6 bg-white rounded-lg border border-gray-200 p-4 flex flex-col gap-y-6">
            <div className="flex gap-4 items-start text-text-primary">
                <div className="w-36 mr-2">
                    <div className="text-text-primary">Applies to EQL</div>
                    <div className="text-sm text-slate-500 mt-1">RFEQL expression that determines which resources this property applies to.</div>
                </div>
                <div className="flex-1">
                    <EQLInput
                        value={getEql(applies_to_eql)}
                        placeholder="Enter EQL query..."
                        isDisabled={true}
                        showSearchButton={false}
                        showValidation={false}
                        onChange={() => { }}
                    />
                </div>
            </div>

            <FormField label="Relationship" labelClassName="w-44" helper="The type of relationship to follow.">
                <Input
                    type="text"
                    value={type_of_relationship}
                    disabled={true}
                    placeholder="Enter value"
                />
            </FormField>

            <FormField label="Path Length" labelClassName="w-44" helper="The length of the relationship path to follow. If not specified, a path length of 1 is used." helperClassName="mt-1 w-36 break-words" >
                <Input
                    type="text"
                    value={String(path_length)}
                    disabled={true}
                    placeholder="Enter value"
                />
            </FormField>

            <div className="flex gap-4 items-start text-text-primary">
                <div className="w-36 mr-2">
                    <div className="text-text-primary">Target EQL</div>
                    <div className="text-sm text-slate-500 mt-1">RFEQL expression that filters the target resources to include in the aggregation.</div>
                </div>
                <div className="flex-1">
                    <EQLInput
                        value={getEql(target_eql)}
                        placeholder="Enter EQL query..."
                        isDisabled={true}
                        showSearchButton={false}
                        showValidation={false}
                        onChange={() => { }}
                    />
                </div>
            </div>

            <FormField label="Aggregation" labelClassName="w-44" helper="The type of aggregation to perform on the target resources." helperClassName="mt-1 w-36 break-words" >
                <Input
                    type="text"
                    value={aggregation}
                    disabled={true}
                    placeholder=""
                />
            </FormField>

            <div className="flex gap-4 items-start text-text-primary">
                <div className="w-36 mr-2">
                    <div className="text-text-primary">Properties To Include</div>
                    <div className="text-sm text-slate-500 mt-1">The property to aggregate.</div>
                </div>
                <div className="flex-1 flex items-center gap-2">
                    {target_property && <div className="bg-slate-200 text-slate-800 px-2 py-1 rounded text-sm font-semibold">{target_property}</div>}
                </div>
            </div>

        </div>
    );
};


export const AISelectSection = ({ content }: AISelectSectionProps) => {
    const { options, prompt, properties, scope, is_open_ended } = content;


    const getEql = useCallback((eql: string) => {
        if (!eql) return '';
        return eql;
    }, []);


    return (
        <div className="mb-6 bg-white rounded-lg border border-gray-200 p-4 flex flex-col gap-y-6">
            <div className="flex gap-4 items-start text-text-primary">
                <div className="w-36 mr-2">
                    <div className="text-text-primary">Scope</div>
                    <div className="text-sm text-slate-500 mt-1">Expression that determines which resources this property applies to.</div>
                </div>
                <div className="flex-1">
                    <EQLInput
                        value={getEql(scope)}
                        placeholder="Enter EQL query..."
                        isDisabled={true}
                        showSearchButton={false}
                        showValidation={false}
                        onChange={() => { }}
                    />
                </div>
            </div>

            <div className="flex items-center gap-4">
                <div className="w-40"></div>
                <div className="flex items-center">
                    <Checkbox
                        value={!!is_open_ended}
                        label="Selected from a pre-defined set of options"
                        setValue={() => { }}
                        disabled={true}
                    />
                </div>
            </div>

            <div className="flex gap-4 items-start text-text-primary">
                <div className="w-36 mr-3">
                    <div className="text-text-primary">Prompt</div>
                    <div className="text-sm text-slate-500 mt-1">The natural language expression to which the aggregation applies.</div>
                </div>
                <div className="flex-1">
                    <textarea
                        disabled={true}
                        value={prompt}
                        rows={7}
                        className="w-full rounded-md border border-grey-300 shadow-sm outline-0 hover:border-primary focus:border-primary active:border-primary px-3 py-2 bg-white min-h-[100px] resize-none"
                    />
                </div>
            </div>


            <div className="flex gap-4 items-start text-text-primary">
                <div className="w-44 mr-2">
                    <div className="text-text-primary">Options</div>
                    <div className="text-sm text-slate-500 mt-1">The natural language expression to which the aggregation applies.</div>
                </div>
                <div className="w-full  mr-1 flex items-center gap-2 flex-wrap">
                    {options.map((option, index) => (
                        <div key={index} className="bg-slate-200 text-slate-800 px-2 py-1 rounded text-sm font-semibold">{option}</div>
                    ))}
                </div>
            </div>

            <div className="flex gap-4 items-start text-text-primary">
                <div className="w-44 mr-2">
                    <div className="text-text-primary">Properties To Include</div>
                    <div className="text-sm text-slate-500 mt-1">The natural language expression to which the aggregation applies.</div>
                </div>
                <div className="w-full mr-1 flex items-center gap-2 flex-wrap">
                    {properties.map((property, index) => (
                        <div key={index} className="bg-slate-200 text-slate-800 px-2 py-1 rounded text-sm font-semibold">{property}</div>
                    ))}
                </div>
            </div>

        </div>
    );
};


const LiveBooleanPropertySection = memo(({
    items,
    editMode,
    editedProperty,
    handleChange,
    onPropertyChange,
    onEqlValidationChange
}: LiveBooleanPropertySectionProps) => {

    const getCurrentEql = useCallback(() => {
        if (!items || items.length === 0) return '';
        return items[0]?.eql || '';
    }, [items]);

    const getCurrentValue = useCallback(() => {
        if (!items || items.length === 0) return 'true';
        return items[0]?.value || 'true';
    }, [items]);

    const handleScopeChange = useCallback((newValue: string) => {
        onPropertyChange({ ...editedProperty, propertyScope: newValue });

        if (items.length > 0) {
            handleChange(0, 'eql', newValue);
        }
    }, [onPropertyChange, editedProperty, items, handleChange]);

    const handleLiveBooleanValueChange = useCallback((newValue: string) => {
        handleChange(0, 'value', newValue);
    }, [handleChange]);

    const propertyScopeValidationRef = useRef<(isValid: boolean) => void>();
    const liveBooleanValueValidationRef = useRef<(isValid: boolean) => void>();

    useEffect(() => {
        propertyScopeValidationRef.current = (isValid: boolean) => {
            onEqlValidationChange?.('propertyScope', isValid);
        };

        liveBooleanValueValidationRef.current = (isValid: boolean) => {
            onEqlValidationChange?.('liveBooleanValue', isValid);
        };
    }, [onEqlValidationChange]);

    return (
        <div className="mb-6 bg-white rounded-lg border border-gray-200 p-4">
            <div className="flex items-center gap-4">
                <div className="w-40 font-medium">
                    Property Scope
                </div>
                <div className="flex-1 max-w-full mr-5 mb-2">
                    <EQLInput
                        value={getCurrentEql()}
                        onChange={handleScopeChange}
                        placeholder="Enter EQL query..."
                        isDisabled={!editMode}
                        onValidationChange={propertyScopeValidationRef.current}
                        showSearchButton={false}
                    />
                </div>
            </div>
            <div className="flex items-center gap-4 mt-4 mb-2">
                <div className="w-40 font-medium">
                    True if
                </div>
                <div className="flex-1 max-w-full mr-5">
                    <EQLInput
                        value={getCurrentValue()}
                        onChange={handleLiveBooleanValueChange}
                        placeholder="Enter condition..."
                        isDisabled={!editMode}
                        onValidationChange={liveBooleanValueValidationRef.current}
                        showSearchButton={false}
                    />
                </div>
            </div>
        </div>
    );
});

LiveBooleanPropertySection.displayName = 'LiveBooleanPropertySection';