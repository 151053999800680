import  EQLInput  from "src/components/eql/input/EQLInput";
import Input from "src/components/form/Input";
import { useState, useRef, useEffect } from "react";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { AISelectContent } from "src/services/customProperties/types";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import AISelectTestModal from "./AISelectTestModal";
import Checkbox from "src/components/form/Checkbox";
import { useGetAllAccountPropertiesQuery } from "src/services/eql/eql";

type AISelectEditorProps = {
    content: AISelectContent;
    onChange: (content: AISelectContent) => void;
};

const AISelectEditor = ({ content, onChange }: AISelectEditorProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const [newOption, setNewOption] = useState("");
    const [newProperty, setNewProperty] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isTestModalOpen, setIsTestModalOpen] = useState(false);
    const promptRef = useRef<HTMLTextAreaElement>(null);
    
    // Auto-resize the prompt textarea when content changes
    useEffect(() => {
        if (promptRef.current) {
            const textarea = promptRef.current;
            // Set height to auto to get the correct scrollHeight
            textarea.style.height = 'auto';
            // Set the height to the scrollHeight
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [content.prompt]);
    
    // Determine if using predefined options (opposite of is_open_ended)
    const usePredefinedOptions = !content.is_open_ended;
    
    // Fetch all properties including non-indexed ones
    const { data: allProperties, isLoading } = useGetAllAccountPropertiesQuery({ 
        accountId, 
        includeNonIndexedProperties: true 
    });
    
    // Filter properties based on search term
    const filteredProperties = allProperties?.filter(prop => 
        prop.name.toLowerCase().includes(searchTerm.toLowerCase()) && 
        !content.properties.includes(prop.name)
    ) || [];

    const addOption = () => {
        if (newOption && !content.options.includes(newOption)) {
            onChange({
                ...content,
                options: [...content.options, newOption]
            });
            setNewOption("");
        }
    };

    const removeOption = (option: string) => {
        onChange({
            ...content,
            options: content.options.filter(o => o !== option)
        });
    };

    const addProperty = (property: string = newProperty) => {
        if (property && !content.properties.includes(property)) {
            onChange({
                ...content,
                properties: [...content.properties, property]
            });
            setNewProperty("");
            setSearchTerm("");
        }
    };

    const removeProperty = (property: string) => {
        onChange({
            ...content,
            properties: content.properties.filter(p => p !== property)
        });
    };

    const openTestModal = () => {
        setIsTestModalOpen(true);
    };

    const closeTestModal = () => {
        setIsTestModalOpen(false);
    };

    const togglePredefinedOptions = (value: boolean) => {
        onChange({
            ...content,
            is_open_ended: !value
        });
    };

    const canTestProperty = content.prompt && 
        (usePredefinedOptions ? content.options.length > 0 : true) && 
        content.properties.length > 0;

    return (
        <div className="border-t border-slate-200 pt-6 mt-6">
            <div className="flex justify-between items-center mb-4">
                <div className="text-sm font-medium text-slate-700">AI Select Configuration</div>
                <div className="relative group">
                    <Button
                        type={ButtonTypes.secondary}
                        text="Test Property"
                        onClick={openTestModal}
                        isDisabled={!canTestProperty}
                    />
                    {!canTestProperty && (
                        <div className="absolute right-0 bottom-full mb-2 hidden group-hover:block bg-slate-800 text-white text-xs rounded p-2 w-64">
                            You need to add a prompt, {usePredefinedOptions ? "at least one option, " : ""}and at least one property to test
                        </div>
                    )}
                </div>
            </div>
            
            <div className="flex flex-col gap-6">
                {/* Prompt */}
                <div className="flex items-start gap-4">
                    <div className="w-24 pt-2">Prompt</div>
                    <div className="flex-1">
                        <textarea
                            ref={promptRef}
                            value={content.prompt}
                            onChange={(e) => {
                                // Adjust height immediately on change
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                                
                                onChange({
                                    ...content,
                                    prompt: e.target.value
                                });
                            }}
                            placeholder="Enter the prompt for the AI to use when selecting options"
                            rows={4}
                            className="w-full rounded-md border border-grey-300 shadow-sm outline-0 hover:border-primary focus:border-primary active:border-primary px-3 py-2 bg-white min-h-[100px] resize-none"
                        />
                    </div>
                </div>

                {/* Scope */}
                <div className="flex items-start gap-4">
                    <div className="w-20 pt-2">Scope</div>
                    <div className="flex-1 max-w-[600px] mr-1">
                        <EQLInput
                            value={content.scope}
                            onChange={(value) => onChange({
                                ...content,
                                scope: value
                            })}
                            placeholder="Enter RFEQL expression to determine which resources this property applies to"
                            showDocumentationButton={true}
                            showSearchButton={false}
                        />
                    </div>
                </div>

                {/* Predefined Options Checkbox */}
                <div className="flex items-center gap-4">
                    <div className="w-32"></div>
                    <div className="flex items-center">
                        <Checkbox
                            value={usePredefinedOptions}
                            setValue={togglePredefinedOptions}
                            label="Select from a pre-defined set of options"
                        />
                    </div>
                </div>

                {/* Options - Only show if using predefined options */}
                {usePredefinedOptions && (
                    <div className="flex flex-col gap-4">
                        <div className="text-sm font-medium text-slate-700">Options</div>
                        <div className="flex items-center gap-2">
                            <Input
                                value={newOption}
                                onInputChange={(e) => setNewOption(e.target.value)}
                                placeholder="Add a new option"
                                className="flex-1"
                            />
                            <Button
                                type={ButtonTypes.secondary}
                                text="Add"
                                onClick={addOption}
                                isDisabled={!newOption || content.options.includes(newOption)}
                            />
                        </div>
                        <div className="flex flex-wrap gap-2 mt-2">
                            {content.options.map((option, index) => (
                                <div key={index} className="flex items-center gap-1 bg-slate-100 px-3 py-1 rounded-full">
                                    <span>{option}</span>
                                    <button
                                        onClick={() => removeOption(option)}
                                        className="text-slate-500 hover:text-slate-700"
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                            {content.options.length === 0 && (
                                <div className="text-slate-500 text-sm">No options added yet</div>
                            )}
                        </div>
                    </div>
                )}

                {/* Properties */}
                <div className="flex flex-col gap-4">
                    <div className="text-sm font-medium text-slate-700">Properties to Include</div>
                    <div className="flex items-center gap-2">
                        <Input
                            value={searchTerm}
                            onInputChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search for properties"
                            className="flex-1"
                        />
                    </div>
                    
                    {/* Property search results */}
                    {searchTerm && (
                        <div className="max-h-60 overflow-y-auto border border-slate-200 rounded-md mt-2">
                            {(() => {
                                if (isLoading) {
                                    return <div className="p-3 text-slate-500">Loading properties...</div>;
                                }
                                
                                if (filteredProperties.length === 0) {
                                    return <div className="p-3 text-slate-500">No matching properties found</div>;
                                }
                                
                                return (
                                    <div className="divide-y divide-slate-200">
                                        {filteredProperties.map((property) => (
                                            <div 
                                                key={property.name} 
                                                className="p-2 hover:bg-slate-50 cursor-pointer flex justify-between items-center"
                                                onClick={() => addProperty(property.name)}
                                            >
                                                <div>
                                                    <div className="font-medium">{property.name}</div>
                                                    {property.description && (
                                                        <div className="text-xs text-slate-500">{property.description}</div>
                                                    )}
                                                </div>
                                                <div className="text-xs px-2 py-1 rounded-full bg-slate-100">
                                                    {property.type}
                                                    {!property.is_indexed && <span className="ml-1 text-amber-600">(non-indexed)</span>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })()}
                        </div>
                    )}
                    
                    {/* Selected properties */}
                    <div className="flex flex-wrap gap-2 mt-2">
                        {content.properties.map((property, index) => (
                            <div key={index} className="flex items-center gap-1 bg-slate-100 px-3 py-1 rounded-full">
                                <span>{property}</span>
                                <button
                                    onClick={() => removeProperty(property)}
                                    className="text-slate-500 hover:text-slate-700"
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                        {content.properties.length === 0 && (
                            <div className="text-slate-500 text-sm">No properties added yet</div>
                        )}
                    </div>
                </div>
            </div>

            {/* Test Modal */}
            <AISelectTestModal
                isOpen={isTestModalOpen}
                onClose={closeTestModal}
                content={content}
            />
        </div>
    );
};

export default AISelectEditor;