
export type HealthType = 'healthy' | 'unhealthy' | 'pending' | 'error' | 'problem';

export const HealthLabel = ({ health, className }: { health: HealthType | null, className?: string }) => {
  if (!health) {
    return null;
  }
  const healthLabelMap = {
    healthy: {
      label: 'Healthy',
      background: 'bg-green-200',
      textColor: 'text-green-700'
    },
    error: {
      label: 'Error',
      background: 'bg-red-100',
      textColor: 'text-red-600'
    },
    problem: {
      label: 'Problem',
      background: 'bg-red-100',
      textColor: 'text-red-600'
    },
    unhealthy: {
      label: 'Failed',
      background: 'bg-red-100',
      textColor: 'text-red-600'
    },
  };
  if(health === 'pending') return <div className="w-16"></div>;
  return (
    <span
      className={`mt-1 rounded-full px-2 py-0.5 ${className} ${healthLabelMap[health].background} ${healthLabelMap[health].textColor}`}>
      {healthLabelMap[health].label}
    </span>
  );
};

export const HealthBadge = ({ health, count  = 1, className }: { health: HealthType | null, count: number, className?: string }) => {
  if (!health) {
      return null;
  }
  const healthLabelMap = {
      healthy: {
          label: 'Healthy',
          background: 'bg-green-200',
          textColor: 'text-green-700'
      },
      error: {
          label: 'Error',
          background: 'bg-red-100',
          textColor: 'text-red-600'
      },
      problem: {
          label: 'Problem',
          background: 'bg-red-100',
          textColor: 'text-red-600'
      },
      unhealthy: {
          label: 'Failed',
          background: 'bg-red-100',
          textColor: 'text-red-600'
      },
      pending: {
          label: 'Pending',
          background: 'bg-yellow-100',
          textColor: 'text-yellow-600'
      }
  };
  return (
      <span
          className={`mt-1 rounded-full px-2 py-0.5 ${className} ${healthLabelMap[health].background} ${healthLabelMap[health].textColor}`}>
          {count} {healthLabelMap[health].label}
      </span>
  );
};