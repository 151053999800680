import { RefreshIcon } from "src/assets/images/icons/DelphiIcons";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { NodeType } from "src/features/models/discover/INode";
import { RefreshDataModelChangeData } from "../../IChange";
interface RefreshDataModelFormProps {
  changeData: RefreshDataModelChangeData;
}

// There is no actual input here, it's just a placeholder to show the model card
export const RefreshDataModelForm = ({ changeData }: RefreshDataModelFormProps) => {
  const modelUniqueId = changeData.modelUniqueId;
  
  return (
    <div className="flex gap-2">
    <div className="text-secondary text-sm font-medium w-24">Model Changes</div>
    <div className="flex-1">
      <div
        id={modelUniqueId}
        className="relative w-64 rounded-lg border bg-white px-2 py-1 shadow transition-opacity duration-300 hover:border-lilac-500"
      >
        <div className="flex cursor-pointer items-center justify-between">
          <div className="flex items-center">
            <NodeIcon type={NodeType.DataModel} withBackground={true} withPadding={true} greyVersion  />
            <div>
              <div
                title={modelUniqueId}
                className="ml-2 w-44 whitespace-nowrap overflow-hidden text-ellipsis text-sm font-medium text-text-primary"
              >
                {modelUniqueId}
              </div>
              <div className="ml-2 w-44 text-xs text-tertiary whitespace-nowrap overflow-hidden text-ellipsis flex items-center">Model</div>
            </div>
          </div>
          <div>
            <RefreshIcon width="16" height="16" className="absolute -top-1 -left-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
    
  );
};