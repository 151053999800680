import { memo } from 'react';

const PageLoader = memo(({ label, labelLocation = "right" }: { label?: string, labelLocation?: "top" | "bottom" | "left" | "right" }) => {
  const labelCssLocation = {
    top: 'flex flex-col-reverse min-h-screen items-center justify-center',
    bottom: 'flex flex-col min-h-screen items-center justify-center',
    left: 'flex flex-row min-h-screen items-center justify-center',
    right: 'flex min-h-screen items-center justify-center'
  };
  
  return (
    <div className={labelCssLocation[labelLocation]}>
      <div className="h-16 w-16 animate-spin rounded-full border-t-2 border-lilac-500 border-opacity-40"></div>
      {label && <div className="m-4 text-slate-600">{label}</div>}
    </div>
  );
});

PageLoader.displayName = 'PageLoader';

export default PageLoader;
