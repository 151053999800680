import { useState } from 'react';
import Input from 'src/components/form/Input';
import Button from 'src/components/button/Button';
import { ButtonTypes } from 'src/components/button/types';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

type SelectOptionsEditorProps = {
    options: string[];
    onChange: (options: string[]) => void;
};

const SelectOptionsEditor = ({ options, onChange }: SelectOptionsEditorProps) => {
    const [newOption, setNewOption] = useState('');

    const handleAddOption = () => {
        if (!newOption.trim() || options.includes(newOption.trim())) return;
        onChange([...options, newOption.trim()]);
        setNewOption('');
    };

    const handleRemoveOption = (index: number) => {
        onChange(options.filter((_, i) => i !== index));
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddOption();
        }
    };

    return (
        <div className="border-t border-slate-200 pt-6 mt-6">
            <div className="text-sm font-medium text-slate-700 mb-4">Options Configuration</div>
            <div className="flex flex-col gap-4">
                <div className="flex items-start gap-2">
                    <Input
                        value={newOption}
                        onInputChange={(e) => setNewOption(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter option value"
                        className="flex-1"
                    />
                    <Button
                        type={ButtonTypes.secondary}
                        icon={<PlusIcon width={16} height={16} />}
                        onClick={handleAddOption}
                        text="Add"
                        isDisabled={!newOption.trim() || options.includes(newOption.trim())}
                    />
                </div>

                <div className="flex flex-col gap-2">
                    {options.map((option, index) => (
                        <div key={index} className="flex items-center gap-2 p-2 bg-slate-50 rounded">
                            <div className="flex-1">{option}</div>
                            <button
                                onClick={() => handleRemoveOption(index)}
                                className="text-slate-400 hover:text-slate-600"
                            >
                                <XMarkIcon width={16} height={16} />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SelectOptionsEditor; 