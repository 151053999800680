import Input from "src/components/form/Input";
import {FormField} from "src/components/form/FormField";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {useState} from "react";

type allowDenyType = {
	allowList: Array<string>,
	denyList: Array<string>,
	setAllowDeny: ({allow, deny} : {allow: Array<string>, deny: Array<string>}) => void
}

const AllowDeny = ({allowList = [], denyList = [], setAllowDeny }: allowDenyType) => {
	const [allow, setAllow] = useState(allowList);
	const [deny, setDeny] = useState(denyList);
	const [allowItem, setAllowItem] = useState("");
	const [denyItem, setDenyItem] = useState("");

	const tagsList = ({list, onDelete} : {list:Array<string>, onDelete: (tag:string) => void}) => {
		return list.map((f) => (
			<div
				key={f}
				className="flex h-6 items-center justify-between rounded-lg bg-purple-100 ml-2 px-2 text-purple-900">
				<div className="flex cursor-pointer items-center gap-1 whitespace-nowrap">
					{f}
				</div>
				<XMarkIcon
					width="16"
					height="16"
					className="ml-1 cursor-pointer text-purple-900 hover:text-purple-600"
					onClick={() => onDelete(f)}
					id='remove-filter-button'
				/>
			</div>
		));
	};

	return (
		<div className="flex flex-col justify-between">
			<FormField
				label="Include"
				className="flex-col !items-start"
				fieldWrapperClassName='w-full  text-sm'
				labelClassName='text-secondary mb-1'
			>
				<Input
					placeholder="Enter each pattern individually and press Enter to add it. Use regular expression patterns for matching."
					value={allowItem}
					onEnter={() => {
						if (!allow.includes(allowItem)) {
							setAllow([...allow, allowItem]);
						}
						setAllowItem('');
						setAllowDeny({allow: [...allow, allowItem], deny});
					}}
					onInputChange={(e) => setAllowItem(e.target.value)}
				/>
			</FormField>
			<div className="flex flex-row mt-2">
				{tagsList({list: allow, onDelete: (tag) => {
					const newList = allow.filter(item => item !== tag);
					setAllow(newList);
					setAllowDeny({allow: newList, deny});
				}})}
			</div>
			<FormField
				label="Exclude"
				className="flex-col !items-start mt-3"
				fieldWrapperClassName='w-full text-sm'
				labelClassName='text-secondary mb-1'
			>
				<Input
					placeholder="Enter each pattern individually and press Enter to add it. Use regular expression patterns for matching."
					value={denyItem}
					onEnter={() => {
						setDeny([...deny, denyItem]);
						setDenyItem('');
						setAllowDeny({allow, deny: [...deny, denyItem]});
					}}
					onInputChange={(e) => setDenyItem(e.target.value)}
				/>
			</FormField>
			<div className="flex flex-row mt-2">
				{tagsList({list: deny, onDelete: (tag) => {
					const newList = deny.filter(item => item !== tag);
					setDeny(newList);
					setAllowDeny({allow, deny: newList});
				}})}
			</div>
		</div>
	);
};

export default AllowDeny;