import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Layout from 'src/components/layout/Layout';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useGetCustomPropertiesListQuery } from 'src/services/customProperties/customProperties';
import { InformationPanel } from './components/InformationPanel';
import { InsightsPanel } from './components/InsightsPanel';
import { ConfigurationPanel } from './components/ConfigurationPanel';
import { notify } from 'src/components/Toaster';
import { extractErrorMessage } from 'src/services/api';
import { useUpdateCustomPropertyMutation } from 'src/services/customProperties/customProperties';
import { CustomProperty } from 'src/services/customProperties/types';
import PageLoader from 'src/components/loaders/PageLoader';

export const PropertyDetailsPage = () => {
    const accountId = useSelector(selectActiveAccountId);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams<{ id: string }>();
    const { data: properties, isLoading: isLoadingProperties } = useGetCustomPropertiesListQuery({ accountId });
    const [updateProperty] = useUpdateCustomPropertyMutation();

    const [editMode, setEditMode] = useState(new URLSearchParams(location.search).get('edit') === 'true');
    const [editedProperty, setEditedProperty] = useState<CustomProperty | null>(null);

    const property = useMemo(() =>
        properties?.find(p => p.id === Number(id)),
        [properties, id]);

    useEffect(() => {
        if (property) {
            const freshCopy = JSON.parse(JSON.stringify(property));
            setEditedProperty(freshCopy);
        }
    }, [property]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setEditMode(queryParams.get('edit') === 'true');
    }, [location.search]);

    const handleStartEditing = useCallback(() => {
        if (property) {
            const freshCopy = JSON.parse(JSON.stringify(property));
            setEditedProperty(freshCopy);
            setEditMode(true);
            navigate(`/properties/${id}?edit=true`, { replace: true });
        }
    }, [property, id, navigate]);

    const handleCancelEditing = useCallback(() => {
        setEditMode(false);
        if (property) {
            const freshCopy = JSON.parse(JSON.stringify(property));
            setEditedProperty(freshCopy);
        }
        navigate(`/properties/${id}`, { replace: true });
    }, [property, id, navigate]);

    const handleSaveEditing = useCallback(async (updatedProperty: CustomProperty) => {
        try {
            await updateProperty({
                accountId,
                propertyId: updatedProperty.id,
                property: updatedProperty
            }).unwrap();
            notify('Property configuration updated successfully', 'success');
            setEditMode(false);
            setEditedProperty(updatedProperty);
            navigate(`/properties/${id}`, { replace: true });
        } catch (e) {
            notify(`Error updating property configuration: ${extractErrorMessage(e).message}`, 'error');
            throw e;
        }
    }, [accountId, id, navigate, updateProperty]);

    const handlePropertyChange = useCallback((updatedProperty: CustomProperty) => {
        setEditedProperty({ ...updatedProperty });
    }, []);

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (editedProperty) {
            handlePropertyChange({
                ...editedProperty,
                name: e.target.value
            });
        }
    }, [editedProperty, handlePropertyChange]);

    const handleNavigateBack = useCallback(() => {
        navigate('/properties');
    }, [navigate]);

    const handleNavigateToGroup = useCallback((group: string | undefined) => {
        navigate(`/properties?group=${group || ''}`);
    }, [navigate]);

    if (isLoadingProperties) {
        return <PageLoader />;
    }

    if (!property || !editedProperty) {
        return (
            <Layout>
                <div className="h-screen">
                    <div className="p-6">
                        <div className="flex items-center gap-2 text-sm text-slate-600">
                            <ArrowLeftIcon
                                className="h-5 w-5 cursor-pointer"
                                onClick={handleNavigateBack}
                            />
                            <span className="hover:underline text-lg font-semibold text-tertiary">
                                Property not found
                            </span>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="">
                <div className="flex-1 p-4 overflow-auto">
                    <div className="flex items-center gap-2 text-sm text-slate-600 mb-2 ml-6">
                        <ArrowLeftIcon
                            className="h-5 w-5 cursor-pointer"
                            onClick={handleNavigateBack}
                        />
                        <span className="text-lg font-semibold text-tertiary hover:underline hover:cursor-pointer"
                            onClick={() => handleNavigateToGroup(property.group)}>
                            {property.group || '_'}
                        </span>
                        <span className='text-lg'>/</span>
                        {!editMode ? (<span className="font-bold text-lg">{property.name}</span>) :
                            (<input
                                type="text"
                                className="w-96 px-3 py-2 border-b border-slate-600 bg-transparent focus:outline-none focus:ring-0 focus:border-lilac-500"
                                value={editedProperty.name || ''}
                                onChange={handleNameChange}
                            />)
                        }
                    </div>
                    <div className="grid grid-cols-2 gap-2 p-2 w-full">
                        <InformationPanel
                            property={property}
                            editMode={editMode}
                            onSaveEditing={handleSaveEditing}
                            editedProperty={editedProperty}
                            onPropertyChange={handlePropertyChange}
                        />
                        <InsightsPanel accountId={accountId} property={property} />
                        <div className="col-span-2">
                            <ConfigurationPanel
                                property={property}
                                editMode={editMode}
                                onStartEditing={handleStartEditing}
                                onCancelEditing={handleCancelEditing}
                                onSaveEditing={handleSaveEditing}
                                editedProperty={editedProperty}
                                originalProperty={property}
                                onPropertyChange={handlePropertyChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
