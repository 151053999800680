import { CustomProperty, CustomPropertyResponse } from './types';
import { transformCustomPropertyResponseToCustomProperty, transformCustomPropertyToResponse } from './transformers';
import api from '../api';

export interface CustomPropertyCalculationEvent {
    id: number;
    account_id: number;
    custom_property_id: number;
    database_name: string;
    reason: string;
    start_time: string;
    end_time: string | null;
    timeout: boolean;
}

export const customPropertiesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAccountsCustomProperties: build.query<CustomPropertyResponse[], { accountId: number }>({
            query: ({ accountId }) => `accounts/${accountId}/custom_properties?page_size=1000`,
            providesTags: ['CustomProperties']
        }),
        createAccountsCustomProperty: build.mutation<CustomPropertyResponse, { accountId: number, property: Omit<CustomPropertyResponse, 'id' | 'last_updated_at' | 'last_updated_by' | 'created_by' | 'created_at' | 'last_calculation_timeout'> }>({
            query: ({ accountId, property }) => ({
                url: `accounts/${accountId}/custom_properties`,
                method: 'POST',
                body: property
            }),
            invalidatesTags: ['CustomProperties']
        }),
        updateAccountsCustomProperty: build.mutation<CustomPropertyResponse, { accountId: number, propertyId: number, property: Partial<CustomPropertyResponse> }>({
            query: ({ accountId, propertyId, property }) => ({
                url: `accounts/${accountId}/custom_properties/${propertyId}`,
                method: 'PATCH',
                body: property
            }),
            invalidatesTags: ['CustomProperties']
        }),
        getCustomPropertiesList: build.query<CustomProperty[], { accountId: number }>({
            query: ({ accountId }) => `accounts/${accountId}/custom_properties?page_size=1000`,
            transformResponse: (response: CustomPropertyResponse[]) => {
                const customProperties = response.map(transformCustomPropertyResponseToCustomProperty);
                return customProperties;
            },
            providesTags: ['CustomProperties']
        }),
        getCustomProperty: build.query<CustomProperty, { accountId: number, propertyId: number }>({
            query: ({ accountId, propertyId }) => `accounts/${accountId}/custom_properties/${propertyId}`,
            transformResponse: (response: CustomPropertyResponse) => transformCustomPropertyResponseToCustomProperty(response),
        }),
        getCustomPropertyForResource: build.query<CustomProperty[], { accountId: number, uri: string }>({
            query: ({ accountId, uri }) => {
                return `accounts/${accountId}/custom_properties?uri=${uri}&page=1&page_size=1000`;
            },
            transformResponse: (response: CustomPropertyResponse[]) => {
                const customProperties = response.map(transformCustomPropertyResponseToCustomProperty);
                return customProperties;
            },
        }),
        createCustomProperty: build.mutation<CustomProperty, {
            accountId: number,
            property: Omit<CustomProperty, 'id' | 'last_updated_at' | 'last_updated_by' | 'created_by' | 'created_at' | 'last_calculation_timeout'>
        }>({
            query: ({ accountId, property }) => ({
                url: `accounts/${accountId}/custom_properties`,
                method: 'POST',
                body: transformCustomPropertyToResponse(property)
            }),
            transformResponse: (response: CustomPropertyResponse) => transformCustomPropertyResponseToCustomProperty(response),
            invalidatesTags: ['CustomProperties']
        }),
        updateCustomProperty: build.mutation<CustomProperty, {
            accountId: number,
            propertyId: number,
            property: CustomProperty
        }>({
            query: ({ accountId, propertyId, property }) => ({
                url: `accounts/${accountId}/custom_properties/${propertyId}`,
                method: 'PATCH',
                body: transformCustomPropertyToResponse(property)
            }),
            transformResponse: (response: CustomPropertyResponse) => transformCustomPropertyResponseToCustomProperty(response),
            invalidatesTags: ['CustomProperties']
        }),
        deleteCustomProperty: build.mutation<void, { accountId: number, propertyId: number }>({
            query: ({ accountId, propertyId }) => ({
                url: `accounts/${accountId}/custom_properties`,
                method: 'DELETE',
                params: { custom_property_id: propertyId }
            }),
            invalidatesTags: ['CustomProperties']
        }),
        setCustomPropertyValue: build.mutation<null, {
            accountId: number,
            nodeId: string,
            propertyId: number,
            value: string | boolean | number | string[] | null
        }>({
            query: ({ accountId, nodeId, propertyId, value }) => ({
                url: `accounts/${accountId}/custom_properties/${propertyId}/values`,
                method: 'POST',
                body: {
                    uris: [nodeId],
                    value: value
                }
            }),
            transformResponse: () => null,
            invalidatesTags: ['Nodes', 'CustomProperties']
        }),
        testAICustomProperty: build.mutation<{ result: string, explanation: string }, {
            accountId: number,
            uri: string,
            content: {
                options: string[];
                prompt: string;
                properties: string[];
                scope: string;
                is_open_ended?: boolean;
            }
        }>({
            query: ({ accountId, uri, content }) => ({
                url: `accounts/${accountId}/custom_properties/test_ai`,
                method: 'POST',
                body: {
                    uri,
                    content
                }
            })
        }),
        getCalculationEvents: build.query<CustomPropertyCalculationEvent[], { accountId: number, propertyId: number }>({
            query: ({ accountId, propertyId }) => `accounts/${accountId}/custom_properties/${propertyId}/calculation_events`,
            providesTags: (_result, _error, arg) => [{ type: 'CustomProperties', id: arg.propertyId }]
        }),
    })
});

export const {
    useGetAccountsCustomPropertiesQuery,
    useGetCustomPropertyForResourceQuery,
    useCreateAccountsCustomPropertyMutation,
    useUpdateAccountsCustomPropertyMutation,
    useGetCustomPropertiesListQuery,
    useGetCustomPropertyQuery,
    useCreateCustomPropertyMutation,
    useUpdateCustomPropertyMutation,
    useDeleteCustomPropertyMutation,
    useSetCustomPropertyValueMutation,
    useTestAICustomPropertyMutation,
    useGetCalculationEventsQuery
} = customPropertiesApi; 
