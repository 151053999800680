import { SQLDialect } from "src/features/projects/IProject";
import { BackendTriggerType } from "src/services/actions/types";

export type SyncRun = {
    id: number;
    title: string;
    targetId: number;
    jobId: number;
    actionType: 'sync_lookml';
    state: SyncRunStatus;
    createdBy: string;
    createdAt: string;
    doneAt: string | null;
    artifacts: {
        name: string;
        type: string;
    }[];
    hasRunReport: boolean;
    triggerType: SyncJobTriggerType;
    configuration: object;
}

export type SyncJobTriggerType = 'manual' | 'automatic';

export type SyncRunStatus = 'draft' | 'running' | 'completed' | 'failed' | 'cancelled' | 'publishing';

export type SyncJob = {
    id: number;
    name: string;
    description: string;
    configuration: SyncConfiguration;
    targetId: number | null;
}

export type IncludeEunoLinkOption = 'include_links' | 'include_links_in_description' | 'exclude_links';

export type SyncConfiguration = {
    accountId: number;
    sqlDialect: SQLDialect;
    automaticallySyncDataModel: boolean;
    dbtProjectName: string;
    eunoProjectId: number;
    modelSyncType: ModelSyncType;
    metricSyncType: MetricSyncType;
    selectedModels: string[];
    selectedModelTags: string[];
    selectedModelEql: string;
    targetType: 'github' | 'gitlab';
    targetBranch: string;
    targetDirectory: string;
    includeEunoLinks: IncludeEunoLinkOption;
    pushType: SyncPushType;
    triggerType: BackendTriggerType;
    integrationId?: number;
}

export enum DataModelSyncTab {
    Runs = 'Runs',
    Jobs = 'Jobs'
  }  

export type SyncTarget = {
    id?: number;
    name: string;
    targetType: 'lookml_repository';
    configuration: {
        targetRepositoryUrl: string;
        gitHostingService: 'github' | 'gitlab';
    }
}

export type ModelSyncType = 'all' | 'selected_models' | 'selected_tags' | 'by_eql';
export type MetricSyncType = 'all' | 'none';
export type SyncPushType = 'pull_request' | 'commit';
