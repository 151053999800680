import api from './api';

export interface User {
  email: string;
  user_id: number;
  github_login: string;
}

export interface StakeholderRecord {
  user: string;
  role: string;
}

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<User, void>({
      query: () => 'user',
      providesTags: ['Users']
    }),
    getAccountStakeholders: build.query<StakeholderRecord[], { accountId: number; query?: string }>({
      query: ({ accountId, query }) => ({
        url: `/accounts/${accountId}/stakeholders${query ? `?query=${query}` : ''}`,
      }),
    }),
  })
});

export const { useGetUserQuery, useGetAccountStakeholdersQuery } = usersApi;