import IBuild from '../builds/IBuild';
import { HealthType } from 'src/components/HealthLabel';

export enum SQLDialect {
  SPARK = 'spark',
  DATABRICKS = 'databricks',
  SNOWFLAKE = 'snowflake',
  PRESTO = 'presto',
  TRINO = 'trino',
  BIGQUERY_STANDARD_SQL = 'bigquery_standard_sql',
  BIGQUERY_LEGACY_SQL = 'bigquery_legacy_sql'
}

export interface IProject {
  id: number;
  name: string;
  parent: number;
  repository: {
    provider: string;
    id: number;
    name: string;
    ownerId: string;
    ownerLogin: string;
    cloneUrl: string;
    branch: string;
    defaultTargetBranch: string;
  };
  dbtCloudDeploymentJob?: IDbtCloudJob;
  userAllowedToPromotePats: boolean;
}

export interface IProjectConfiguration {
  schemaVersion: string;
  changes: {
    pullRequest: {
      title: string;
      body: string;
      branchName: string;
    };
    commitMessage: string;
  };
  health: HealthType;
  changesCount: number;
  lastSuccessfulBuild: IBuild | null;
}

export interface IDbtCloudJob {
  id: string;
  account_id: string;
  project_id: string;
  project_name: string;
  environment_id: string;
  name: string;
}
