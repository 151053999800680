import { ButtonTypes } from 'src/components/button/types';
import Modal from 'src/components/Modal/Modal';
import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { notify } from "src/components/Toaster";
import { Alert } from "src/components/Alert";
import { useState } from 'react';
import { useResetTriggerUrlMutation } from 'src/services/integrations/integrations';
import { GenericIntegration } from 'src/services/integrations/types';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";

interface IntegrationTriggerModalProps {
    isOpen: boolean;
    onClose: () => void;
    triggerUrl?: string;
    integration?: GenericIntegration | null;
}

const useModalText = (hasTriggerUrl: boolean, hasGeneratedUrl: boolean) => {
    let title = 'Generate a new trigger URL';
    let info = 'Generating a new trigger URL will immediately invalidate your existing URL. This action cannot be undone.';
    if (hasTriggerUrl) {
        title = 'Trigger URL';
        info = 'New trigger url created successfully. Once generated, this URL will be shown only once. Please copy and store it securely as you will not be able to see it again.';
    } else if (hasGeneratedUrl) {
        title = 'New Trigger URL';
        info = 'New trigger url created successfully.';
    }

    return { title, info };
};
const TIMEOUT_DELAY = 1000; 
const wait = (ms:number) => new Promise(resolve => setTimeout(resolve, ms));
export const IntegrationTriggerModal = ({ isOpen, onClose, triggerUrl, integration }: IntegrationTriggerModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [generatedUrl, setGeneratedUrl] = useState<string | undefined>(undefined);
    const accountId = useSelector(selectActiveAccountId);
    const { title, info } = useModalText(!!triggerUrl, !!generatedUrl);
    const [resetTrigger] = useResetTriggerUrlMutation();

    const copy = (url: string) => {
        navigator.clipboard.writeText(url);
        notify('Copied to clipboard', 'success');
    };

    const handleGenerate = async () => {
        if (!integration) {
            return;
        }
        setIsLoading(true);
            try {
                const [{ trigger_url }] = await Promise.all([
                    resetTrigger({ accountId, integrationId: integration.id }).unwrap(),
                    wait(TIMEOUT_DELAY)
                ]);
                setGeneratedUrl(trigger_url);
            } catch (error) {
                notify('Failed to generate URL', 'error');
            } finally {
                setIsLoading(false);
            }
    };

    const CopyUrlDisplay = ({ url }: { url: string }) => (
        <div className="flex items-center gap-2 bg-slate-100 rounded-lg border border-slate-200 p-4 my-4">
            <div className="flex-1 bg-white rounded-md px-3 py-2 overflow-hidden">
                <code className="text-sm font-mono text-tertiary whitespace-nowrap overflow-hidden text-ellipsis block">
                    {url.toLocaleUpperCase()}
                </code>
            </div>
            <button
                onClick={() => copy(url)}
                className="px-4 py-2 text-lilac-600 font-medium rounded-md border border-lilac-400 bg-white hover:bg-slate-50 active:bg-slate-100 transition-colors duration-150 flex items-center"
            >
                <ClipboardDocumentIcon width={14} height={14} className="text-lilac-600 mr-1" />
                Copy
            </button>
        </div>
    );
    const GenerateButton = ({ onClick, isLoading }: { onClick: () => void, isLoading: boolean }) => (
        <div className="flex items-center justify-center gap-2 bg-slate-100 rounded-lg border border-slate-200 p-4 my-4">
            <button
                onClick={onClick}
                disabled={isLoading}
                className="px-4 py-2 text-lilac-600 font-medium rounded-md border border-lilac-400 bg-white hover:bg-slate-50 active:bg-slate-100 transition-colors duration-150 flex items-center"
            >
                {isLoading ? 'Generating...' : 'Generate'}
            </button>
        </div>
    );

    const UrlSection = ({ triggerUrl, generatedUrl, isLoading, handleGenerate }: { triggerUrl?: string, generatedUrl?: string, isLoading: boolean, handleGenerate: () => void }) => {
        if (triggerUrl) {
            return <CopyUrlDisplay url={triggerUrl} />;
        }
        if (generatedUrl) {
            return <CopyUrlDisplay url={generatedUrl} />;
        }
        return <GenerateButton onClick={handleGenerate} isLoading={isLoading} />;
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { setGeneratedUrl(undefined); onClose(); }}
            title=""
            buttons={[{
                type: ButtonTypes.primary, text: 'Done', onClick: () => {
                    onClose();
                    setGeneratedUrl(undefined);
                }
            }]}
            maxWidth="max-w-xl"
            withHeader={false}
        >
            <div className="flex flex-col text-center mt-2 px-2">
                <div className="text-text-primary text-2xl">{title}</div>
                <div className="text-tertiary mt-2 ">{info}</div>
                <UrlSection
                    triggerUrl={triggerUrl}
                    generatedUrl={generatedUrl}
                    isLoading={isLoading}
                    handleGenerate={handleGenerate}
                />
                <Alert
                    className="!border-0 rounded-lg mb-4 text-start gap-x-2"
                    text="Please save this URL somewhere safe and accessible. for security reasons, you won’t be able to view it later. If you lose this URL you’ll need to generate a new one."
                    type="warning"
                />
            </div>
        </Modal>
    );
};

