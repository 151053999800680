import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { BranchIcon } from '../../../../assets/images/icons/DelphiIcons';
import DropdownMenu from '../../../../components/DropdownMenu/DropdownMenu';
import Container from '../../../../components/layout/Container';
import IBranch from '../../branches/IBranch';
import HealthStatusLabel from '../../HealthStatusLabel';
import { IProject, IProjectConfiguration } from '../../IProject';

interface BranchesListProps {
  branches: IBranch[];
  projectConfiguration: IProjectConfiguration;
  project: IProject;
  setActionBranch: (branch: IBranch) => void;
  setShowInviteModal: (show: boolean) => void;
  setShowDisconnectModal: (show: boolean) => void;
  onSetDefaultTargetBranch: (branch: IBranch) => void;
}

const BranchesList = ({
  branches,
  project,
  setActionBranch,
  setShowInviteModal,
  setShowDisconnectModal,
  onSetDefaultTargetBranch
}: BranchesListProps) => {
  const getBranchMenuOptions = (branch: IBranch) => {
    const isDefaultTarget = branch.name == project.repository.defaultTargetBranch;
    return [
      {
        name: 'Set as default branch',
        className: isDefaultTarget || branch.status != 'active' ? 'text-tertiary' : 'hover:bg-slate-50',
        onClick: isDefaultTarget ? () => {} : () => onSetDefaultTargetBranch(branch)
      },
      {
        name: 'Manage roles',
        className: 'hover:bg-slate-50',
        onClick: () => {
          setShowInviteModal(true);
          setActionBranch(branch);
        }
      },
      {
        name: 'Disconnect branch',
        className: 'hover:bg-slate-50 !text-danger',
        onClick: () => {
          setShowDisconnectModal(true);
          setActionBranch(branch);
        }
      }
    ];
  };
  return (
    <Container dataTestId="branch-list" className="mt-6 flex w-full flex-col items-start rounded-md px-6 py-6">
      {branches.map((branch, index: number) => {
        return (
          <div key={index} className="w-full">
            {index !== 0 && <div className="mb-6 mt-6 w-full border-b border-slate-200"></div>}
            <div className="flex w-full justify-between">
              <div className="flex">
                <span className="mr-3 flex items-center font-medium">
                  <BranchIcon fill="#94A3B8" width="16" height="16" className="mr-2" /> {branch.name}
                </span>
                {branch.name == project.repository.defaultTargetBranch && (
                  <>
                    <span
                      className={`mt-1 self-center rounded-sm bg-slate-100 px-2 text-sm font-normal tracking-wider text-slate-500`}
                    >
                      DEFAULT
                    </span>
                    &nbsp;
                  </>
                )}
                <HealthStatusLabel status={branch.status} />
              </div>
              <div className="flex gap-4">
                <div className="flex items-center gap-1 text-sm text-slate-500">
                </div>
                <DropdownMenu items={getBranchMenuOptions(branch)} className="-ml-36 bg-white">
                  <EllipsisVerticalIcon className="text-slate-400" width={25} height={25} />
                </DropdownMenu>
              </div>
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default BranchesList;
