import { BookOpenIcon } from "@heroicons/react/24/outline";
import { Link } from "src/components/Link";

type HelpCardProps = {
    documentation: {
        label: string;
        link: string;
    };
    className?: string;
    position?: string;
};
const HelpCard = ({
    documentation,
    className = "",
    position = "absolute right-8 top-0"
}: HelpCardProps) => {
    return (
        <div className={`max-w-[236px] p-4 rounded-lg border bg-white h-fit ${position} ${className}`} >
            <div className="text-lg text-text-primary mb-4 flex gap-2 items-center"><BookOpenIcon width="18" height="18" />Need Help?</div>
            <div>Visit our <Link text={`${documentation.label} integration documentation`} onClick={() => window.open(documentation.link, "_blank")} /></div>
        </div>
    );
};


export default HelpCard;