import { damaProperties, DamaProperty } from "src/features/models/discover/damaProperties";
import { EqlToken } from "src/components/eql/suggestions/types";
import { findLast, findLastIndex } from "src/utils/arrayUtils";
import { ExternalProperty } from "src/services/eql/types";
const propRegex = '(CNAME|EXTENDED_CNAME)';
const valueRegex = '(STRING_LITERAL|RELATIVE_TIME|SIGNED_NUMBER)';

type TokenCompletions = {
  suggetions: (input: EqlToken[], facets?: Facets, properties?: ExternalProperty[]) => Completion[];
  match: string[];
  notMatch?: string[];
}

export type Completion = {
  text: string;
  description?: string;
  displayText?: string;
}

type Facets = { [facet: string]: string[]; };

export const getEqlCompletion = (input: EqlToken[], facets?: Facets, properties?: ExternalProperty[]): Completion[] => {
  const currentToken: EqlToken | null = input[input.length - 1] || null;
  const items: Completion[] = [];

  // Special case: property followed by whitespace should get operator suggestions
  if (input.length > 0) {
    const propertyNameIndex = findLastIndex(input, t => t.type === 'CNAME' || t.type === 'EXTENDED_CNAME');
    if (propertyNameIndex >= 0) {
      const tokensAfterProperty = input.slice(propertyNameIndex + 1);
      const onlyWhitespace = tokensAfterProperty.every(t => t.type === 'WHITESPACE');

      if (propertyNameIndex === input.length - 1 || onlyWhitespace) {
        const operatorSuggestions = getOperatorCompletion(input);
        if (operatorSuggestions.length > 0) {
          return operatorSuggestions;
        }
      }
    }
  }

  // Check tokens against registered completion patterns
  for (const tokenCompletion of tokenCompletions) {
    const tokenTypes = input.map(t => t.type).join(',');
    if (tokenCompletion.match.some(r => tokenTypes.match(r)) && (!tokenCompletion.notMatch || !tokenCompletion.notMatch.some(r => tokenTypes.match(r)))) {
      const suggestions = tokenCompletion.suggetions(input, facets, properties);
      items.push(...suggestions);
    }
  }

  // Fallback: check for property name followed by whitespace to suggest operators
  if (items.length === 0 && input.length > 0) {
    const lastToken = input[input.length - 1];
    const secondLastToken = input.length > 1 ? input[input.length - 2] : null;

    if ((lastToken.type === 'CNAME' || lastToken.type === 'EXTENDED_CNAME') ||
      (lastToken.type === 'WHITESPACE' && secondLastToken &&
        (secondLastToken.type === 'CNAME' || secondLastToken.type === 'EXTENDED_CNAME'))) {
      const operatorSuggestions = getOperatorCompletion(input);
      if (operatorSuggestions.length > 0) {
        return operatorSuggestions;
      }
    }
  }

  // Fallback: check for property names without operators
  if (items.length === 0 && input.length > 0) {
    for (let i = Math.max(0, input.length - 3); i < input.length; i++) {
      if (input[i].type === 'CNAME' || input[i].type === 'EXTENDED_CNAME') {
        let hasOperator = false;
        for (let j = i + 1; j < input.length; j++) {
          if (['=', '!=', '~', '=~', '>', '>=', '<', '<=', 'IS', 'IN', 'BETWEEN'].includes(input[j].text)) {
            hasOperator = true;
            break;
          }
        }

        if (!hasOperator) {
          return ['=', '!=', '~', 'IS', 'IN'].map(o => ({ text: `${o} `, displayText: o }));
        }
      }
    }
  }

  const filteredByPrefix = filterByPrefix(items, currentToken);
  const withoutExactMatch = filteredByPrefix.filter(i => i.text.toLowerCase() !== currentToken?.text.toLowerCase());
  return withoutExactMatch;
};

// Suggests appropriate operators based on property type
const getOperatorCompletion = (input: EqlToken[]): Completion[] => {
  const lastDamaProperty = getLastDamaProperty(input);
  let operators: string[] = [];
  switch (lastDamaProperty?.type) {
    case 'array':
      operators = ['IN'];
      break;
    case 'string':
      operators = ['=', '!=', '=~', '~', 'IS', 'IN'];
      break;
    case 'boolean':
      operators = ['IS'];
      break;
    case 'number':
      operators = ['=', '!=', '>', '>=', '<', '<=', 'IS', 'IN', 'BETWEEN'];
      break;
    case 'date':
      operators = ['=', '!=', '>', '>=', '<', '<=', 'IS', 'BETWEEN'];
      break;
    case 'object':
      operators = ['IN', '='];
      break;
  }
  return operators.map(o => ({ text: `${o} `, displayText: o }));
};

const filterByPrefix = (items: Completion[], currentToken: EqlToken | null): Completion[] => {
  const ignoredCurrentTokens = ["WHITESPACE", "HAS"];
  return items.filter(i => !currentToken || ignoredCurrentTokens.includes(currentToken.type) || (i.displayText || i.text).toLowerCase().startsWith(currentToken.text.toLowerCase()));
};

const getPropertyCompletion = (properties?: ExternalProperty[]): Completion[] => {
  if (properties && properties.length > 0) {
    //  This is for having "type" and "name" at the top of the list
    const typeProperty = properties.find(p => p.name === "type");
    const nameProperty = properties.find(p => p.name === "name");
    const nonCustomProps = properties.filter(p => 
      !p.is_custom && p.name !== "type" && p.name !== "name"
    );
    const customProps = properties.filter(p => p.is_custom);
    const orderedProperties = [
      ...(typeProperty ? [typeProperty] : []),
      ...(nameProperty ? [nameProperty] : []),
      ...nonCustomProps,
      ...customProps
    ];
    return orderedProperties.map(p => ({ 
      text: `${p.name} `, 
      description: `${p.type} ${p.is_custom ? '(custom property)' : ''}`, 
      displayText: p.name 
    }));
  }
  return damaProperties.map(p => ({ text: `${p.name} `, description: p.type, displayText: p.name }));
};

// Identify the property being referenced in the query
const getLastDamaProperty = (input: EqlToken[]): DamaProperty | null => {
  const lastTokenType = findLast(input, t => t.type === 'CNAME' || t.type === 'EXTENDED_CNAME')?.text || null;
  const lastProperty = damaProperties.find(p => p.name === lastTokenType?.split('.')[0]);
  return lastProperty || null;
};

const getComparisonValueCompletion = (input: EqlToken[], facets?: Facets): Completion[] => {
  const lastDamaProperty = getLastDamaProperty(input);
  if (lastDamaProperty?.type === 'string') {
    return (facets?.[lastDamaProperty.name] || []).map(f => ({
      text: `"${f}" `,
      displayText: f
    }));
  }
  return [];
};

// Suggests values for IN arrays, filtering out already used values
const getArrayValueCompletion = (input: EqlToken[], facets?: Facets): Completion[] => {
  const lastDamaProperty = getLastDamaProperty(input);
  const usedFacets = input.slice(findLastIndex(input, t => t.type === 'IN') + 1).filter(t => t.type === 'STRING_LITERAL').map(t => t.text.replace(/"/g, ''));
  if (lastDamaProperty?.type === 'string' || lastDamaProperty?.type === 'array') {
    const propertyFacets = facets?.[lastDamaProperty.name] || [];
    return propertyFacets.filter(f => !usedFacets.includes(f)).map(f => ({
      text: `"${f}", `,
      displayText: f
    }));
  }
  return [];
};

const getBooleanValueCompletion = (input: EqlToken[]): Completion[] => {
  const lastDamaProperty = getLastDamaProperty(input);
  if (lastDamaProperty?.type === 'boolean') {
    return [{ text: 'TRUE ', displayText: 'TRUE' }, { text: 'FALSE ', displayText: 'FALSE' }];
  }
  return [];
};

// Pattern-based completions for different query contexts
const tokenCompletions: TokenCompletions[] = [
  {
    suggetions: () => [{ text: 'HAS ', displayText: 'HAS' }],
    match: [
      `^(${propRegex})?$`,
      `NOT,WHITESPACE(,.)?$`,
      `AND,WHITESPACE(,.)?$`,
      `OR,WHITESPACE(,.)?$`,
    ],
    notMatch: [
      `IS,WHITESPACE,NOT,WHITESPACE(,.)?$`, //ignore is not
      `IN,WHITESPACE,LPAREN(?!.*RPAREN)`, //ignore in array
    ]
  },
  {
    suggetions: () => [{ text: 'NULL ', displayText: 'NULL' }],
    match: [
      `IS,WHITESPACE(,NOT,WHITESPACE)?(,.)?$`
    ]
  },
  {
    suggetions: () => [{ text: '( ', displayText: '(' }],
    match: [
      `IN,WHITESPACE$`
    ]
  },
  {
    suggetions: () => [{ text: 'NOT ', displayText: 'NOT' }],
    match: [
      `^(${propRegex})?$`,
      `IS,WHITESPACE(,.)?$`,
    ]
  },
  {
    suggetions: () => [{ text: 'AND ', displayText: 'AND' }],
    match: [
      `(${valueRegex}|NULL),WHITESPACE(,.)?$`,
      `BETWEEN,WHITESPACE,${valueRegex},WHITESPACE(,.)?$`,
      `RPAREN,WHITESPACE(,.)?$`,
    ],
    notMatch: [
      `IN,WHITESPACE,LPAREN(?!.*RPAREN)`, //ignore in array
    ]
  },
  {
    suggetions: () => [{ text: 'OR ', displayText: 'OR' }],
    match: [
      `(${valueRegex}|NULL),WHITESPACE(,.)?$`,
      `RPAREN,WHITESPACE(,.)?$`,
    ],
    notMatch: [
      `BETWEEN,WHITESPACE,${valueRegex},WHITESPACE(,.)?$`, //ignore between
      `IN,WHITESPACE,LPAREN(?!.*RPAREN)` //ignore in array
    ]
  },
  {
    suggetions: getOperatorCompletion,
    match: [`${propRegex},WHITESPACE(,.)?$`]
  },
  //Property completion
  {
    suggetions: (_input, _facets, properties) => getPropertyCompletion(properties),
    match: [
      `(AND|OR|LPAREN|NOT),WHITESPACE(,CNAME)?$`,
      `^(${propRegex})?$` //start of string
    ],
    notMatch: [
      `BETWEEN,WHITESPACE,${valueRegex},WHITESPACE,AND,WHITESPACE(,.)?$`, //ignore between
      `IS,WHITESPACE,NOT,WHITESPACE(,.)?$`, //ignore is not
      `IN,WHITESPACE,LPAREN(?!.*RPAREN)`, //ignore in array
    ]
  },
  //Boolean value completion
  {
    suggetions: getBooleanValueCompletion,
    match: [
      //IS / COMPARSION OPERATOR
      `IS,WHITESPACE(,NOT,WHITESPACE)?(,.)?$`
    ]
  },
  //Comparison value completion
  {
    suggetions: getComparisonValueCompletion,
    match: [
      //IS / COMPARSION OPERATOR
      `${propRegex},WHITESPACE,COMPARISON_OP,WHITESPACE(,.){0,2}$`,
    ]
  },
  //Array value completion
  {
    suggetions: getArrayValueCompletion,
    match: [
      `${propRegex},WHITESPACE,IN,WHITESPACE,LPAREN,WHITESPACE(,${valueRegex},COMMA,WHITESPACE)*(,.){0,2}$`,
    ]
  },
  //Has relation completion
  {
    suggetions: () => ['UPSTREAM', 'DOWNSTREAM', 'PARENT', 'CHILD'].map(r => ({ text: `${r}(`, displayText: r })),
    match: [
      `HAS(,.)?$`
    ]
  }
];