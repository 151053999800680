import {
    CustomPropertyResponse,
    CustomProperty,
    CustomPropertyClass,
    CustomPropertyFEType,
    CustomPropertyResponseType,
    CustomPropertyResponseContent,
    CustomPropertyContent,
    MultiSelectOption,
    CustomPropertyContentType,
    CustomPropertyType,
    ScopedBooleanContent,
    MultiSelectContent,
    ClosedListManualPropertyContent,
    CustomPropertyMetadata,
    AggregateRelationshipContent,
    AISelectContent,
    AggregateType,
    RelationshipType
} from './types';

export const transformCustomPropertyResponseToCustomProperty = (response: CustomPropertyResponse): CustomProperty => {
    const mappedType = mapResponseTypeToCustomPropertyType(response.content.type);
    const content = transformResponseContentToCustomProperty(response.content, mappedType);

    let propertyScope = 'true';
    if (response.content.content && typeof response.content.content === 'object' && 'scope' in response.content.content) {
        if (mappedType !== CustomPropertyFEType.LiveBoolean) {
            propertyScope = response.content.content.scope;
        }
    }

    return {
        id: response.id,
        name: response.metadata.display_name || response.name,
        owner: response.metadata.owner,
        description: response.metadata.description ?? '',
        group: response.metadata.properties_group ?? 'General',
        showInOverview: response.metadata.show_in_overview,
        class: getPropertyClass(mappedType),
        eqlName: response.name,
        propertyScope: propertyScope,
        allowMultipleValues: isMultiSelectType(mappedType),
        content,
        orderInGroup: response.metadata.order_in_group,
        lastUpdatedAt: response.last_updated_at,
        lastUpdatedBy: response.last_updated_by,
        createdBy: response.created_by,
        createdAt: response.created_at,
        lastCalculationTimeout: response.last_calculation_timeout
    };
};

export const transformCustomPropertyToResponse = (
    property: Omit<CustomProperty, 'id'> | CustomProperty
): Omit<CustomPropertyResponse, 'id'> | CustomPropertyResponse => {
    const content = transformCustomPropertyContentToResponse(property);

    const groupValue = property.group;
    const id = 'id' in property ? property.id : undefined;

    const metadata: CustomPropertyMetadata = {
        description: property.description || null,
        properties_group: groupValue,
        order_in_group: property.orderInGroup,
        display_name: property.name || null,
        owner: property.owner,
        show_in_overview: property.showInOverview,
    };
    const result = {
        id: id,
        name: property.eqlName.trim(),
        metadata,
        content: {
            type: content.type as CustomPropertyType,
            content: content.content as CustomPropertyContentType
        },
        last_updated_at: property.lastUpdatedAt,
        last_updated_by: property.lastUpdatedBy,
        created_by: property.createdBy,
        created_at: property.createdAt,
        last_calculation_timeout: property.lastCalculationTimeout
    };
    return result;
};

export const getPropertyClass = (contentType: CustomPropertyFEType): CustomPropertyClass => {
    switch (contentType) {
        case CustomPropertyFEType.SingleSelect:
        case CustomPropertyFEType.MultiSelect:
        case CustomPropertyFEType.Datetime:
        case CustomPropertyFEType.FixedBoolean:
        case CustomPropertyFEType.Numeric:
            return CustomPropertyClass.Fixed;
        default:
            return CustomPropertyClass.Live;
    }
};

const isMultiSelectType = (type: CustomPropertyFEType): boolean => {
    return type === CustomPropertyFEType.MultiSelect;
};

const mapResponseTypeToCustomPropertyType = (type: string): CustomPropertyFEType => {
    switch (type) {
        case 'manual_boolean_field':
            return CustomPropertyFEType.FixedBoolean;
        case 'manual_numeric_field':
            return CustomPropertyFEType.Numeric;
        case 'manual_datetime_field':
            return CustomPropertyFEType.Datetime;
        case 'manual_single_select':
            return CustomPropertyFEType.SingleSelect;
        case 'manual_multi_select':
            return CustomPropertyFEType.MultiSelect;
        case 'multi_select':
            return CustomPropertyFEType.CategoryAssigned;
        case 'live_boolean':
        case 'scoped_boolean':
            return CustomPropertyFEType.LiveBoolean;
        case 'aggregate_relationships':
            return CustomPropertyFEType.AggregateRelationships;
        case 'ai_select':
            return CustomPropertyFEType.AISelect;
        default:
            return CustomPropertyFEType.SingleSelect;
    }
};

const mapCustomPropertyTypeToResponseType = (
    feType: CustomPropertyFEType
): CustomPropertyResponseType => {
    switch (feType) {
        case CustomPropertyFEType.SingleSelect:
            return 'manual_single_select';
        case CustomPropertyFEType.MultiSelect:
            return 'manual_multi_select';
        case CustomPropertyFEType.LiveBoolean:
            return 'scoped_boolean';
        case CustomPropertyFEType.FixedBoolean:
            return 'manual_boolean_field';
        case CustomPropertyFEType.Numeric:
            return 'manual_numeric_field';
        case CustomPropertyFEType.Datetime:
            return 'manual_datetime_field';
        case CustomPropertyFEType.CategoryAssigned:
            return 'multi_select';
        case CustomPropertyFEType.AggregateRelationships:
            return 'aggregate_relationships';
        case CustomPropertyFEType.AISelect:
            return 'ai_select';
        default:
            return 'manual_single_select';
    }
};
export const transformResponseContentToCustomProperty = (
    responseContent: {
        type: CustomPropertyType;
        content: CustomPropertyContentType;
    },
    mappedType: CustomPropertyFEType
): CustomPropertyContent => {
    switch (mappedType) {
        case CustomPropertyFEType.AggregateRelationships: {
            let aggregateRelationshipContent: AggregateRelationshipContent = {
                applies_to_eql: "",
                type_of_relationship: RelationshipType.parent,
                path_length: 1,
                target_eql: "",
                aggregation: AggregateType.count,
                target_property: ""
            };
            if (responseContent.type === CustomPropertyType.aggregate_relationships) {
                const content = responseContent.content as AggregateRelationshipContent;
                aggregateRelationshipContent = {
                    applies_to_eql: content.applies_to_eql || "",
                    type_of_relationship: (content.type_of_relationship as RelationshipType) || RelationshipType.parent,
                    path_length: content.path_length || 1,
                    target_eql: content.target_eql || "",
                    aggregation: content.aggregation || AggregateType.count,
                    target_property: content.target_property || ""
                };
            }
            return {
                type: CustomPropertyFEType.AggregateRelationships,
                content: aggregateRelationshipContent
            };
        }
        case CustomPropertyFEType.AISelect: {
            return {
                type: CustomPropertyFEType.AISelect,
                content: responseContent.content as AISelectContent
            };
        }
        case CustomPropertyFEType.SingleSelect: {
            const options = responseContent.type === CustomPropertyType.manual_single_select
                ? (responseContent.content as ClosedListManualPropertyContent).options || []
                : [];

            return {
                type: CustomPropertyFEType.SingleSelect,
                content: { options }
            };
        }

        case CustomPropertyFEType.MultiSelect: {
            const options = responseContent.type === CustomPropertyType.manual_multi_select
                ? (responseContent.content as ClosedListManualPropertyContent).options || []
                : [];

            return {
                type: CustomPropertyFEType.MultiSelect,
                content: { options }
            };
        }


        case CustomPropertyFEType.LiveBoolean: {
            let scopedBooleanContent: ScopedBooleanContent = {
                eql: "",
                scope: ""
            };
            if (responseContent.type === CustomPropertyType.scoped_boolean) {
                const content = responseContent.content as ScopedBooleanContent;
                scopedBooleanContent = {
                    eql: content.eql || "",
                    scope: content.scope || ""
                };
            }
            return {
                type: CustomPropertyFEType.LiveBoolean,
                content: {
                    options: [],
                    eql: scopedBooleanContent.eql,
                    scope: scopedBooleanContent.scope
                }
            };
        }

        case CustomPropertyFEType.CategoryAssigned: {
            let options: MultiSelectOption[] = [];

            if (responseContent.type === CustomPropertyType.multi_select) {
                const multiContent = responseContent.content as MultiSelectContent;
                if (multiContent.options) {
                    options = multiContent.options;
                }
            }
            return {
                type: CustomPropertyFEType.CategoryAssigned,
                content: { options }
            };
        }

        case CustomPropertyFEType.Datetime:
        case CustomPropertyFEType.FixedBoolean:
        case CustomPropertyFEType.Numeric:
            return {
                type: mappedType,
                content: {}
            };

        default:
            return {
                type: CustomPropertyFEType.SingleSelect,
                content: { options: [] }
            };
    }
};

export const transformCustomPropertyContentToResponse = (property: Omit<CustomProperty, 'id'>): CustomPropertyResponseContent => {
    const backendType = mapCustomPropertyTypeToResponseType(property.content.type);
    const content = property.content.content;

    let result: CustomPropertyResponseContent;

    switch (property.content.type) {
        case CustomPropertyFEType.AggregateRelationships:
            result = {
                type: backendType,
                content: {
                    applies_to_eql: (content as AggregateRelationshipContent).applies_to_eql || '',
                    type_of_relationship: (content as AggregateRelationshipContent).type_of_relationship || RelationshipType.parent,
                    path_length: (content as AggregateRelationshipContent).path_length || 1,
                    target_eql: (content as AggregateRelationshipContent).target_eql || '',
                    aggregation: (content as AggregateRelationshipContent).aggregation || AggregateType.count,
                    target_property: (content as AggregateRelationshipContent).target_property || '',
                    scope: property.propertyScope || 'true'
                }
            };
            break;
        case CustomPropertyFEType.AISelect:
            result = {
                type: backendType,
                content: {
                    options: (content as AISelectContent).options || [],
                    prompt: (content as AISelectContent).prompt || '',
                    properties: (content as AISelectContent).properties || [],
                    scope: (content as AISelectContent).scope || 'true',
                    is_open_ended: (content as AISelectContent).is_open_ended || false
                }
            };
            break;
        case CustomPropertyFEType.MultiSelect:
        case CustomPropertyFEType.SingleSelect:
            result = {
                type: backendType,
                content: {
                    options: 'options' in content ? content.options as string[] : [],
                    scope: property.propertyScope || 'true'
                }
            };
            break;
        case CustomPropertyFEType.LiveBoolean:
            result = {
                type: backendType,
                content: {
                    eql: (content as { eql?: string }).eql || '',
                    scope: (content as { scope?: string }).scope || 'true'
                }
            };
            break;
        case CustomPropertyFEType.CategoryAssigned:
            result = {
                type: backendType,
                content: {
                    options: 'options' in content ?
                        (content.options as MultiSelectOption[]).map(opt => ({
                            value: opt.value,
                            eql: opt.eql
                        })) : [],
                    scope: property.propertyScope || 'true'
                }
            };
            break;
        case CustomPropertyFEType.Datetime:
        case CustomPropertyFEType.FixedBoolean:
        case CustomPropertyFEType.Numeric:
            result = {
                type: backendType,
                content: {
                    scope: property.propertyScope || 'true'
                }
            };
            break;
        default:
            result = {
                type: backendType,
                content: {
                    scope: property.propertyScope || 'true'
                }
            };
    }

    return result;
};