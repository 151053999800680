import api from '../api';
import { MetricStatus, PlanStatusResponse, FormattedMetricUsage } from './types';
import { MeteredMetric, METRIC_DISPLAY_NAMES } from './consts';

/**
 * Format a number for display with appropriate units
 * @param value The number to format
 * @returns Formatted string
 */
const formatNumber = (value: number): string => {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  }
  return value.toString();
};

/**
 * Format a metric status for display
 * @param metric The metric status to format
 * @returns Formatted metric usage
 */
export const formatMetricUsage = (metric: MetricStatus): FormattedMetricUsage => {
  const metricName = metric.metric_name as MeteredMetric;
  const displayName = METRIC_DISPLAY_NAMES[metricName] || metricName;
  const usagePercentage = metric.allowance ? (metric.value / metric.allowance) * 100 : null;
  
  let displayString = `${formatNumber(metric.value)}`;
  if (metric.allowance) {
    displayString += ` / ${formatNumber(metric.allowance)}`;
  }
  
  return {
    name: displayName,
    value: metric.value,
    allowance: metric.allowance,
    usagePercentage,
    displayString
  };
};

/**
 * Plans API service
 */
export const plansApi = api.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get the plan status for an account
     * This will be refreshed when CustomProperties changes
     */
    getPlanStatus: build.query<PlanStatusResponse, { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/plan/plan_status`,
      providesTags: ['PlanStatus', 'CustomProperties']
    }),
    
    /**
     * Get specific metrics for an account
     * This will be refreshed when CustomProperties changes
     */
    getMetrics: build.query<MetricStatus[], { accountId: number, metricNames: MeteredMetric[] }>({
      query: ({ accountId }) => `accounts/${accountId}/plan/plan_status`,
      transformResponse: (response: PlanStatusResponse, _, { metricNames }) => {
        return response.filter(metric => 
          metricNames.includes(metric.metric_name as MeteredMetric)
        );
      },
      providesTags: ['PlanStatus', 'CustomProperties']
    })
  })
});

export const { 
  useGetPlanStatusQuery,
  useGetMetricsQuery
} = plansApi; 