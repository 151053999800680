import { useRef } from "react";
import { useHoverDirty } from "react-use";
import { SidePaneTabDefinition } from "src/features/models/discover/resourceSidepane/types";

type ResourceSidepaneTabProps = {
    tabDefinition: SidePaneTabDefinition;
    activeTab: string;
    setActiveTab: (tab: string) => void;
}
export const ResourceSidepaneTab = ({ tabDefinition, activeTab, setActiveTab }: ResourceSidepaneTabProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const isHovering = useHoverDirty(ref);
    return (
        <div ref={ref} key={tabDefinition.name} className={`cursor-pointer text-center flex flex-col gap-1 ${activeTab === tabDefinition.label ? 'text-primary' : 'hover:text-primary'}`} onClick={() => setActiveTab(tabDefinition.label)}>
            <div className={`w-fit p-2 rounded mx-auto ${(isHovering || activeTab === tabDefinition.label) && 'bg-primary-light'}`}>
                {tabDefinition.icon}
            </div>
            {tabDefinition.label}
        </div>
    );
};