import { useRef, useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useClickAway, useKeyPressEvent } from "react-use";
import { getEqlCompletion } from "./eqlCompletion";
import { EqlToken } from "./types";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetFacetsQuery } from "src/services/nodes/nodes";
import { useGetAllAccountPropertiesQuery } from "src/services/eql/eql";

type SuggestionsPopoverProps = {
    isOpen: boolean;
    onSelect: (text: string, cursorPosition?: number) => void;
    onClose: () => void;
    tokens: EqlToken[];
    anchorRef?: React.RefObject<HTMLElement>;
}

export const EqlSuggestionsPopover = ({ isOpen, onSelect, onClose, tokens, anchorRef }: SuggestionsPopoverProps) => {
    const ref = useRef<HTMLDivElement>(null);
    useClickAway(ref, onClose);
    const [selectionIndex, setSelectionIndex] = useState<number | null>(null);
    const accountId = useSelector(selectActiveAccountId);
    const { data: facets } = useGetFacetsQuery({ accountId });
    const { data: properties } = useGetAllAccountPropertiesQuery({ accountId }, { skip: !accountId });
    const items = useMemo(() => getEqlCompletion(tokens, facets, properties), [tokens, facets, properties]);

    useEffect(() => {
        if (selectionIndex !== null && ref.current) {
            const popoverItem = ref.current.childNodes[selectionIndex] as HTMLElement;
            if (popoverItem) {
                popoverItem.scrollIntoView({ block: 'nearest' });
            }
        }
    }, [selectionIndex]);

    useKeyPressEvent('Tab', (e) => {
        e.preventDefault();
        if (items.length > 0) {
            onSelect(items[selectionIndex || 0].text);
        }
    });

    useKeyPressEvent('Enter', (e) => {
        e.preventDefault();
        if (items.length > 0 && !e.shiftKey) {
            onSelect(items[selectionIndex || 0].text);
        }
    });

    useKeyPressEvent('Escape', (e) => {
        e.preventDefault();
        if (items.length > 0) {
            onClose();
        }
    });

    useKeyPressEvent('ArrowDown', (e) => {
        if (items.length === 0) {
            return;
        }
        else if (selectionIndex === null) {
            setSelectionIndex(0);
        } else if (selectionIndex < items.length - 1) {
            setSelectionIndex(selectionIndex + 1);
        }
        else {
            setSelectionIndex(null);
        }
        e.preventDefault();
    });

    useKeyPressEvent('ArrowUp', (e) => {
        if (items.length === 0) {
            return;
        }
        else if (selectionIndex === null) {
            setSelectionIndex(items.length - 1);
        } else if (selectionIndex > 0) {
            setSelectionIndex(selectionIndex - 1);
        } else {
            setSelectionIndex(null);
        }
        e.preventDefault();
    });

    useEffect(() => {
        setSelectionIndex(null);
    }, [tokens]);

    if (!isOpen || items.length === 0) return null;

    const getPopoverPosition = () => {
        if (!anchorRef?.current) return {};
        const rect = anchorRef.current.getBoundingClientRect();
        return {
            position: 'fixed' as const,
            top: `${rect.bottom + window.scrollY + 4}px`,
            left: `${rect.left + window.scrollX}px`,
        };
    };

    return (
        <div 
            id="eql-suggestions-popover"
            ref={ref} 
            className={`max-h-[350px] overflow-auto bg-white rounded-lg border border-border shadow z-20 flex flex-col py-2 ${anchorRef ? '' : 'absolute top-10 left-0 w-60 '}`}
            style={anchorRef ? getPopoverPosition() : {}}
        >
            {
                items.map((item, index) => {
                    return (
                        <div
                            key={item.text}
                            className={`px-4 py-2 cursor-pointer hover:bg-slate-50 ${index === selectionIndex ? 'bg-slate-100' : ''}`}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => onSelect(item.text)}
                        >
                            {item.displayText || item.text}
                            {item.description && <div className="text-xs text-slate-400">{item.description}</div>}
                        </div>
                    );
                })
            }
        </div>
    );
};