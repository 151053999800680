import api from "../api";
import { ExternalProperty } from "./types";


export type CheckEQLResponse = {
    status: string;
    pretty: string;
};

export const eqlApi = api.injectEndpoints({
    endpoints: (build) => ({
        checkEQL: build.query<CheckEQLResponse, { accountId: number; eql: string; allowCustomProperties?: boolean; allowRelationships?: boolean }>({
            query: ({ accountId, eql, allowCustomProperties = true, allowRelationships = true }) => ({
                url: `/accounts/${accountId}/check_eql`,
                params: {
                    eql,
                    allow_custom_properties: allowCustomProperties,
                    allow_relationships: allowRelationships
                }
            })
        }),
        getAllAccountProperties: build.query<ExternalProperty[], { accountId: number; includeCustomProperties?: boolean; includeNonIndexedProperties?: boolean }>({
            query: ({ accountId, includeCustomProperties = true, includeNonIndexedProperties = false }) => ({
                url: `/accounts/${accountId}/data_model/all_properties`,
                params: {
                    include_custom_properties: includeCustomProperties,
                    include_non_indexed: includeNonIndexedProperties
                }
            })
        }),
        getPropertyExplanation: build.query<{ explanation: string | null }, { accountId: number, uri: string, propertyName: string }>({
            query: ({ accountId, uri, propertyName }) => ({
                url: `/accounts/${accountId}/data_model/search`,
                params: {
                    eql: `uri = "${uri}"`,
                    properties: [`${propertyName}:explanation`]
                }
            }),
            transformResponse: (response: { resources: Array<Record<string, string>> }, _meta, arg) => {
                if (response?.resources?.length > 0) {
                    const resource = response.resources[0];
                    const explanationKey = `${arg.propertyName}:explanation`;
                    if (resource[explanationKey]) {
                        return { explanation: resource[explanationKey] };
                    }
                }
                return { explanation: null };
            }
        }),
    }),

});

export const {
    useLazyCheckEQLQuery,
    useGetAllAccountPropertiesQuery,
    useLazyGetPropertyExplanationQuery
} = eqlApi;

