import { ChangeEvent } from 'react';

interface TextAreaProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  className?: string;
}

const TextArea = ({
  value,
  onChange,
  placeholder,
  rows = 4,
  disabled = false,
  className = ''
}: TextAreaProps) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      disabled={disabled}
      className={`w-full rounded-md border border-grey-300 shadow-sm outline-0 hover:border-primary focus:border-primary active:border-primary px-3 py-2 ${disabled ? 'bg-slate-50 cursor-not-allowed' : 'bg-white'} ${className}`}
    />
  );
};

export default TextArea;
