import { ChevronDownIcon, GlobeAltIcon, StarIcon, UserCircleIcon, TagIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import DropdownMenu from "src/components/DropdownMenu/DropdownMenu";
import { DBT_TYPES, IExpandedNode, NodeType } from "src/features/models/discover/INode";
import { ResourceSidepaneContentsOverview } from "src/features/models/discover/resourceSidepane/overviewTab/ResourceSidepaneContentsOverview";
import { ResourceSidepaneOverviewConnections } from "src/features/models/discover/resourceSidepane/overviewTab/ResourceSidepaneOverviewConnections";
import { ResourceSidepaneUsageOverview } from "src/features/models/discover/resourceSidepane/overviewTab/ResourceSidepaneUsageOverview";
import { dateFormats, utcToLocal } from "src/infrastructure/dateUtilities";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetCustomPropertiesListQuery, useSetCustomPropertyValueMutation } from "src/services/customProperties/customProperties";
import { EunoIcon, TagRefreshIcon } from "src/assets/images/icons/DelphiIcons";
import { shortenString } from "src/utils/stringsUtils";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";
import { useFlags } from "launchdarkly-react-client-sdk";
import { CustomPropertyClass, CustomProperty, CustomPropertyFEType } from "src/services/customProperties/types";
import { PropertiesMenu } from "src/features/models/discover/resourceSidepane/customTab/PropertiesMenu";
import RemovePropertyModal from "src/features/models/discover/resourceSidepane/customTab/RemovePropertyModal";
import { format } from 'date-fns';
import { notify } from "src/components/Toaster";
import { Tooltip } from "react-tooltip";

type ResourceSidepaneOverviewProps = {
    resource: IExpandedNode;
    setResourceId: (id: string) => void;
    setActiveTab: (tab: string) => void;
}

const MAX_TAG_LENGTH = 40;

export const ResourceSidepaneOverview = ({ resource, setResourceId, setActiveTab }: ResourceSidepaneOverviewProps) => {
    return (
        <div className="flex flex-col bg-surface-light h-full overflow-y-auto max-h-[100vh]">
            <div className="flex justify-between px-4 gap-4">
                <Description type={resource.type} text={resource.description} />
                <GeneralInfo resource={resource} />
            </div>
            <div className="flex flex-col px-4 gap-4 overflow-auto max-h-[70%]">
                <ResourceSidepaneUsageOverview setActiveTab={setActiveTab} setResourceId={setResourceId} resource={resource} />
                <PropertiesSection setActiveTab={setActiveTab} resource={resource} />
            </div>
            {
                resource.thumbnail && (
                    <div className="px-4 mt-4">
                        <img
                            src={`data:image/png;base64,${resource.thumbnail}`}
                            alt="Resource thumbnail"
                            className="w-full rounded-lg border border-border"
                        />
                    </div>
                )
            }
            <ResourceSidepaneOverviewConnections resource={resource} setResourceId={setResourceId} />
            <ResourceSidepaneContentsOverview resource={resource} />
        </div>
    );
};

const Description = ({ text, type }: { text: string, type: NodeType }) => {
    const [showMore, setShowMore] = useState(false);
    const maxWords = 40;
    if (!text && DBT_TYPES.includes(type)) {
        return (
            <div className="text-center content-center w-[61%] mt-4 min-h-[60px] border text-base text-tertiary border-border rounded">
                No description
            </div>
        );
    }
    else if (!text) {
        return null;
    }
    const numberOfWords = text.split(' ').length;
    return (
        <div className="w-[61%] mt-4 text-base">
            <span className="text-text-primary break-all">{text.split(' ').slice(0, showMore ? Infinity : maxWords).join(' ')}</span>
            {
                numberOfWords > maxWords && (
                    <span className="text-tertiary cursor-pointer ml-1" onClick={() => setShowMore(!showMore)}>{showMore ? 'Show less' : 'Show more'}</span>
                )
            }
        </div>
    );
};

const GeneralInfo = ({ resource }: { resource: IExpandedNode }) => {
    if (!resource.favouriteCount && !resource.owner && !resource.nativeLastDataUpdate && !resource.lastUpdatedAt && !resource.createdAt && !resource.externalLinks?.length) {
        return null;
    }
    return (
        <div className="w-[28%] mt-4 flex flex-col gap-1.5 text-slate-400 whitespace-nowrap flex-1 text-sm">
            {
                resource.favouriteCount && (
                    <div className="flex gap-1 items-center"><StarIcon width="14" height="14" />Favourited {resource.favouriteCount} times</div>
                )
            }
            {
                resource.owner && (
                    <div className="flex gap-1 items-center"><UserCircleIcon width="14" height="14" />{resource.owner}</div>
                )
            }
            {
                resource.nativeLastDataUpdate && (
                    <div>Last extracted on {utcToLocal(resource.nativeLastDataUpdate, dateFormats.date)}</div>
                )
            }
            {
                resource.lastUpdatedAt && (
                    <div>
                        Last updated on {utcToLocal(resource.lastUpdatedAt, dateFormats.date)}
                        {
                            resource.lastUpdatedBy && (
                                <div> by <span className="text-slate-500">{resource.lastUpdatedBy}</span></div>
                            )
                        }
                    </div>
                )
            }
            {
                resource.createdAt && (
                    <div>
                        Created on {utcToLocal(resource.createdAt, dateFormats.date)}
                        {
                            resource.createdBy && (
                                <div> by <span className="text-slate-500">{resource.createdBy}</span></div>
                            )
                        }
                    </div>
                )
            }
            {
                resource.externalLinks?.length > 0 && (
                    <DropdownMenu items={resource.externalLinks.map(link => ({ name: link.label, onClick: () => window.open(link.url, '_blank') }))}>
                        <div className="flex items-center gap-1"><GlobeAltIcon width="14" height="14" /> Links <ChevronDownIcon width="12" height="12" /></div>
                    </DropdownMenu>
                )
            }
        </div>
    );
};

type PropertiesSectionProps = {
    resource: IExpandedNode;
    setActiveTab: (tab: string) => void;
}

const PropertiesSection = ({ resource, setActiveTab }: PropertiesSectionProps) => {
    const [selectedProperty, setSelectedProperty] = useState<CustomProperty | null>(null);
    const [propertyToRemove, setPropertyToRemove] = useState<CustomProperty | null>(null);
    const [isRemovalLoading, setIsRemovalLoading] = useState(false);
    const [expandedGroup, setExpandedGroup] = useState<string | null>(null);
    const tags = resource.tags || [];
    const meta = Object.entries(resource.meta || {});
    const accountId = useSelector(selectActiveAccountId);
    const getCustomProperties = useGetCustomPropertiesListQuery({ accountId });
    const [setCustomPropertyValue] = useSetCustomPropertyValueMutation();
    const { showCustomPropertiesManagement } = useFlags();
    
    const allCustomProperties = useMemo(() => 
        getCustomProperties.data?.filter(p => 
            Object.keys(resource.customProperties).includes(p.eqlName) && p.showInOverview
        ) || [],
        [getCustomProperties.data, resource.customProperties]
    );

    const groupedProperties = useMemo(() => {
        const mergedProperties = allCustomProperties.map(prop => ({
            ...prop,
            value: resource.customProperties[prop.eqlName],
            isSet: resource.customProperties[prop.eqlName] !== null
        }));
        const filteredProperties = mergedProperties.filter(prop => (prop.class === CustomPropertyClass.Live && prop.value !== null) || prop.class === CustomPropertyClass.Fixed);
        const grouped = filteredProperties.reduce((acc, prop) => {
            if (!prop.group || prop.group.trim() === '') {
                return acc;
            }
            if (!acc[prop.group]) {
                acc[prop.group] = [];
            }
            acc[prop.group].push(prop);
            return acc;
        }, {} as Record<string, typeof mergedProperties>);

        const groups = Object.entries(grouped)
            .map(([groupName, properties]) => ({
                groupName,
                properties: properties.sort((a, b) => {
                    if (a.isSet !== b.isSet) {
                        return b.isSet ? 1 : -1;
                    }
                    if (a.class === CustomPropertyClass.Live && b.class !== CustomPropertyClass.Live) {
                        return -1;
                    }
                    if (a.class !== CustomPropertyClass.Live && b.class === CustomPropertyClass.Live) {
                        return 1;
                    }
                    return a.eqlName.localeCompare(b.eqlName);
                })
            }))
            .sort((a, b) => b.properties.length - a.properties.length);
        if (groups.length > 0) {
            setExpandedGroup(groups[0].groupName);
        }
        return groups;
    }, [allCustomProperties, resource.customProperties]);


    const toJsonIfNeeded = (value: unknown, type?: CustomPropertyFEType) => {
        if (typeof value === 'string') {
            if (type === CustomPropertyFEType.Datetime) {
                return format(new Date(value), 'dd/MM/yyyy');
            }
            return value;
        }
        if (Array.isArray(value)) {
            return value.join(', ');
        }
        return JSON.stringify(value);
    };

    const handlePropertyClick = (property: CustomProperty) => {
        if (property.class === CustomPropertyClass.Fixed) {
            setSelectedProperty(selectedProperty === property ? null : property);
        }
    };

    const handleRemoveClick = (event: React.MouseEvent, property: CustomProperty) => {
        event.stopPropagation();
        setPropertyToRemove(property);
    };

    const handleRemoveConfirm = async () => {
        if (!propertyToRemove) return;
        
        setIsRemovalLoading(true);
        try {
            await setCustomPropertyValue({
                accountId,
                nodeId: resource.id,
                propertyId: propertyToRemove.id,
                value: null
            }).unwrap();
            
            notify('Property removed successfully', 'success');
            setPropertyToRemove(null);
        } catch (error) {
            console.error(`Error removing property:`, error);
            notify('Failed to remove property', 'error');
        } finally {
            setIsRemovalLoading(false);
        }
    };

    const handleRemoveCancel = () => {
        setPropertyToRemove(null);
    };

    if (!showCustomPropertiesManagement) {
        return null;
    }

    const goToProperty = (propertyId: string | number) => {
        const url = `/properties/${propertyId}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const copy = (value: string) => {
        navigator.clipboard.writeText(value);
        notify('Copied to clipboard', 'success');
    };

    return (
        <div className="flex-1 mt-4 p-2 rounded border border-border bg-surface-light flex flex-col gap-4 overflow-auto">
            <div>
                <div className="flex items-center justify-between mb-2 bg-slate-100 p-2 rounded">
                    <div className="flex items-center">
                        <EunoIcon width="16" height="16" className="mr-2 text-slate-700" />
                        <span className="text-slate-700 text-sm font-semibold">CUSTOM PROPERTIES</span>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    {groupedProperties.length > 0 && groupedProperties.map(({ groupName, properties }) => (
                        <div key={groupName} className="flex flex-col gap-2">
                            <div className="text-slate-700 font-semibold text-sm p-1 rounded uppercase w-fit flex items-center gap-1">
                                {groupName}
                                <ChevronDownIcon 
                                    className={`cursor-pointer h-4 w-4 transition-transform duration-200 ${expandedGroup === groupName ? 'rotate-180' : ''}`} 
                                    onClick={() => {
                                        const element = document.getElementById(groupName);
                                        if (element) {
                                            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                        }
                                        setExpandedGroup(expandedGroup === groupName ? null : groupName);
                                    }} 
                                />
                            </div>
                            {expandedGroup === groupName && properties.map(property => (
                                <div key={property.eqlName} className="flex flex-row items-start gap-2">
                                    <div
                                        className="text-slate-600 text-sm font-semibold flex items-center gap-1 hover:cursor-pointer hover:text-lilac-500 hover:underline"
                                        data-tooltip-id={`tooltip-${property.eqlName}`}
                                        onClick={() => copy(property.eqlName)}
                                    >
                                        {property.class === CustomPropertyClass.Live ? (
                                            <TagRefreshIcon className="h-5 w-5" />
                                        ) : (
                                            <TagIcon className="h-5 w-5" />
                                        )}
                                        {shortenString(property.name || property.eqlName, MAX_TAG_LENGTH)}
                                        <Tooltip id={`tooltip-${property.eqlName}`} >copy {property.eqlName} </Tooltip>
                                        <ArrowTopRightOnSquareIcon className="cursor-pointer h-4 w-4" onClick={() => goToProperty(property.id)} />
                                    </div>
                                    <span className="text-slate-600 font-semibold">:</span>
                                    <div className="relative flex items-center gap-1">
                                        <div 
                                            className={`${property.class === CustomPropertyClass.Fixed ? 'cursor-pointer group hover:bg-lilac-300 ' : ''} ${property.isSet ? 'bg-lilac-200 text-lilac-800' : 'bg-slate-200 text-slate-600 hover:bg-slate-300'} flex items-center gap-1 px-2 py-1 rounded font-medium `}
                                            data-tooltip-id={`tooltip-value-${property.eqlName}`}
                                            onClick={() => handlePropertyClick(property)}
                                        >
                                            {property.isSet ? (
                                                `${toJsonIfNeeded(property.value, property.content.type)}`
                                            ) : (
                                                'None'
                                            )}
                                        </div>
                                            {property.class === CustomPropertyClass.Fixed && property.isSet && (
                                                <button 
                                                    className=" text-slate-500 hover:text-slate-700"
                                                    onClick={(e) => handleRemoveClick(e, property)}
                                                >
                                                    <XMarkIcon className="h-4 w-4" />
                                                </button>
                                            )}
                                        {selectedProperty && selectedProperty.eqlName === property.eqlName && (
                                            <div className="absolute top-full left-0 mt-1 z-[9999]" >
                                                <PropertiesMenu
                                                    property={property}
                                                    resource={resource}
                                                    onClose={() => setSelectedProperty(null)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            {(tags.length > 0 || meta.length > 0) && (
                <div className="flex items-center justify-between bg-slate-100 p-2 rounded">
                    <div className="flex items-center">
                        <TagIcon className="h-4 w-4 mr-2 text-slate-700" />
                        <span className="text-slate-700 text-sm font-semibold">META & TAGS</span>
                    </div>
                </div>
            )}
            {meta.length > 0 && (
                <div className="w-full">
                    <div className="text-slate-700 font-medium text-sm mb-2 p-1 bg-slate-200 rounded uppercase w-fit">META</div>
                    <div className="flex flex-col gap-3 flex-wrap">
                        {meta.slice(0, 10).map(([key, value], index) => (
                            <div key={index} className="flex gap-2">
                                <div className="text-slate-600 font-medium">
                                    {key}
                                </div>
                                <span className="text-slate-600 font-medium">:</span>
                                <div className="rounded bg-green-200 text-green-800 px-2 py-0.5 w-fit" data-tooltip-id={`tooltip-value-${index}`}>
                                    <TextWithEllipsisAndTooltip text={toJsonIfNeeded(value)} maxChars={MAX_TAG_LENGTH} />
                                </div>
                            </div>
                        ))}
                        {meta.length > 5 && (
                            <div className="text-slate-500 hover:text-slate-700 cursor-pointer text-sm flex items-center underline mt-2" onClick={() => setActiveTab("Tags")}>
                                Show All {meta.length} metas <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
                            </div>
                        )}
                    </div>
                </div>
            )}
            {tags.length > 0 && (
                <div>
                    <div className="text-slate-700 font-medium text-sm mb-2 bg-slate-200  p-1 rounded uppercase w-fit">TAGS</div>
                    <div className="flex flex-wrap gap-2">
                        {tags.slice(0, 10).map(tag => (
                            <div key={tag} className="rounded bg-blue-200 text-blue-800 px-2 py-1 w-fit">
                                {shortenString(tag, MAX_TAG_LENGTH)}
                            </div>
                        ))}
                        {tags.length > 10 && (
                            <div className="text-slate-500 hover:text-slate-700 cursor-pointer text-sm flex items-center underline mt-2" onClick={() => setActiveTab("Tags")}>
                                Show All {tags.length} tags <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
                            </div>
                        )}
                    </div>
                </div>
            )}
            {propertyToRemove && (
                <RemovePropertyModal 
                    isOpen={!!propertyToRemove}
                    property={propertyToRemove}
                    resource={resource}
                    onClose={handleRemoveCancel}
                    onConfirm={handleRemoveConfirm}
                    isLoading={isRemovalLoading}
                />
            )}
        </div>
    );
};