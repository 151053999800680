export enum ExternalFacingType {
    text = 'text',
    numeric = 'numeric',
    boolean = 'boolean',
    datetime = 'datetime',
    string_list = 'string_list',
    string_map = 'string_map',
    object = 'object'
}

export type ExternalProperty = {
    name: string;
    type: ExternalFacingType;
    is_custom: boolean;
    is_indexed: boolean;
    description?: string;
    labels?: string[];
    custom_property_id?: number;
};