import { MetricSyncType, ModelSyncType, SyncJob, SyncRun, SyncTarget } from "src/features/dataModelSync/types";
import { SQLDialect } from "src/features/projects/IProject";
import { BackendActionRun, BackendGitAction, BackendMetricSelector, BackendModelSelector, BackendSQLDialect, BackendSyncJob, BackendSyncJobResponse, BackendSyncTarget, BackendTriggerType } from "src/services/actions/types";

export const transformLocalSyncJobToBackend = (job: SyncJob): BackendSyncJob => {
    const triggerContent = () => {
        switch (job.configuration.triggerType) {
            case BackendTriggerType.PostBuild:
                return { project_id: job.configuration.eunoProjectId };
            case BackendTriggerType.PostSourceRun:
                return { integration_id: job.configuration.integrationId };
            case BackendTriggerType.Manual:
                return {};
            default:
                return {};
        }
    };
    return {
        name: job.name,
        description: job.description,
        target_id: job.targetId,
        action_type: 'sync_lookml',
        run_configuration: {
            dbt_project_name: job.configuration.dbtProjectName,
            account_id: job.configuration.accountId,
            sql_dialect: job.configuration.sqlDialect as BackendSQLDialect,
            target_branch: job.configuration.targetBranch,
            target_directory: job.configuration.targetDirectory,
            git_action: mapGitActionToBackend[job.configuration.pushType],
            model_selector: mapModelSelectorToBackend[job.configuration.modelSyncType],
            metric_selector: mapMetricSelectorToBackend[job.configuration.metricSyncType],
            model_selector_tags: job.configuration.modelSyncType === 'selected_tags' ? job.configuration.selectedModelTags : [],
            model_selector_names: job.configuration.modelSyncType === 'selected_models' ? job.configuration.selectedModels : [],
            model_selector_eql: job.configuration.modelSyncType === 'by_eql' ? job.configuration.selectedModelEql : '',
            lookml_explore_mode: 'create_explore_for_all_models',
            create_empty_refinements_for_views_and_explores: false,
            refinements_folder: '',
            include_euno_links: job.configuration.includeEunoLinks
        },
        trigger_type: job.configuration.triggerType,
        trigger_content: triggerContent()
    };
};

export const transformBackendSyncJobToLocal = (job: BackendSyncJobResponse): SyncJob => {
    return {
        id: job.id,
        targetId: job.target_id,
        name: job.name,
        description: job.description,
        configuration: {
            accountId: job.run_configuration.account_id,
            sqlDialect: job.run_configuration.sql_dialect as SQLDialect,
            automaticallySyncDataModel: job.trigger_type === 'post_build' || job.trigger_type === 'post_source_run',
            eunoProjectId: job.trigger_content?.project_id || 0,
            integrationId: job.trigger_content?.integration_id || 0,
            modelSyncType: mapModelSelectorToLocal[job.run_configuration.model_selector],
            metricSyncType: mapMetricSelectorToLocal[job.run_configuration.metric_selector],
            selectedModels: job.run_configuration.model_selector_names || [],
            selectedModelTags: job.run_configuration.model_selector_tags || [],
            selectedModelEql: job.run_configuration.model_selector === 'sync_by_eql' ? job.run_configuration.model_selector_eql : '',
            targetType: 'github',
            targetBranch: job.run_configuration.target_branch,
            targetDirectory: job.run_configuration.target_directory,
            pushType: job.run_configuration.git_action === 'commit_to_branch' ? 'commit' : 'pull_request',
            dbtProjectName: job.run_configuration.dbt_project_name,
            includeEunoLinks: job.run_configuration.include_euno_links,
            triggerType: job.trigger_type
        }
    };
};

const mapGitActionToBackend: { [key: string]: BackendGitAction } = {
    'pull_request': 'create_pr',
    'commit': 'commit_to_branch'
};

const mapModelSelectorToBackend: { [key: string]: BackendModelSelector } = {
    'all': 'sync_all_models',
    'selected_models': 'sync_specific_models',
    'selected_tags': 'sync_specific_tags',
    'by_eql': 'sync_by_eql'
};

const mapMetricSelectorToBackend: { [key: string]: BackendMetricSelector } = {
    'all': 'sync_all_metrics',
    'selected_tags': 'sync_specific_tags',
    'none': 'sync_no_metrics'
};

const mapModelSelectorToLocal: { [key: string]: ModelSyncType } = {
    'sync_all_models': 'all',
    'sync_specific_models': 'selected_models',
    'sync_specific_tags': 'selected_tags',
    'sync_by_eql': 'by_eql'
};

const mapMetricSelectorToLocal: { [key: string]: MetricSyncType } = {
    'sync_all_metrics': 'all',
    'sync_no_metrics': 'none'
};

export const transformBackendSyncTargetToLocal = (target: BackendSyncTarget): SyncTarget => {
    return {
        id: target.id,
        name: target.name,
        targetType: 'lookml_repository',
        configuration: {
            targetRepositoryUrl: target.configuration.target_repository_url,
            gitHostingService: target.configuration.git_hosting_service,
        }
    };
};

export const transformLocalSyncTargetToBackend = (target: SyncTarget): BackendSyncTarget => {
    return {
        name: target.configuration.targetRepositoryUrl,
        target_type: 'lookml_repository',
        configuration: {
            target_repository_url: target.configuration.targetRepositoryUrl,
            git_hosting_service: target.configuration.gitHostingService,
            allowed_target_branches: [],
            allow_direct_push: true,
        }
    };
};

export const transformBackendSyncRunToLocal = (run: BackendActionRun): SyncRun => {
    return {
        id: run.id,
        title: run.title,
        targetId: run.target_id,
        jobId: run.job_id,
        actionType: run.action_type,
        state: run.state,
        createdBy: run.created_by,
        createdAt: run.created_at,
        doneAt: run.done_at,
        artifacts: run.artifacts,
        hasRunReport: run.has_run_report,
        triggerType: run.trigger_type,
        configuration: run.configuration
    };
};

export const emptySyncTarget: SyncTarget = {
    name: '',
    targetType: 'lookml_repository',
    id: 0,
    configuration: {
        targetRepositoryUrl: '',
        gitHostingService: 'github'
    }
};

