import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Layout from "src/components/layout/Layout";
import { useGetGenericIntegrationQuery } from 'src/services/integrations/integrations';
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { DbtCoreIntegrationConfiguration, GenericIntegration, GenericIntegrationConfiguration, GenericIntegrationType, LookerIntegrationConfiguration, SnowflakeIntegrationConfiguration, TableauIntegrationConfiguration, FivetranIntegrationConfiguration, ThoughtSpotIntegrationConfiguration, BigQueryIntegrationConfiguration, DbtCloudIntegrationConfiguration, GenericInvalidationStrategy } from "src/services/integrations/types";
import PageLoader from "src/components/loaders/PageLoader";
import { InformationCircleIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";
import AdvancedBlock from "src/features/sources/connectedSources/AdvancedBlock";
import { ConfigurationDetailsBlock } from "src/features/sources/connectedSources/ConfigurationDetailsBlock";
import ScheduleBlock from "src/features/sources/connectedSources/ScheduleBlock";
import Input from "src/components/form/Input";
import { FormField } from "src/components/form/FormField";
import BottomBar from "src/features/sources/connectedSources/BottomBar";
import { SOURCE_METADATA } from "../Consts";
import InvalidationStrategyBlock from "./InvalidationStrategyBlock";
import HelpCard from "./HelpCard";
import { useNavigate } from "react-router-dom";

const ConnectSource = () => {
  const navigate = useNavigate();
  const accountId = useSelector(selectActiveAccountId);
  const integrationIdInEdit = useParams().id || '';
  const integrationTypeinCreation = (useParams().type || '') as GenericIntegrationType | null;
  const getIntegration = useGetGenericIntegrationQuery({ accountId, integrationId: integrationIdInEdit }, { skip: !integrationIdInEdit });
  const [integration, setIntegration] = useState<GenericIntegration | undefined>(!integrationIdInEdit && integrationTypeinCreation ? createEmptyIntegration(integrationTypeinCreation) : undefined);

  useEffect(() => {
    if (!integration && getIntegration.data) {
      setIntegration(getIntegration.data);
    }
  }, [getIntegration.data, integration]);

  if (!integration) {
    return <PageLoader />;
  }
  const sourceMetadata = SOURCE_METADATA.get(integration.integration_type)!;
  const SourceIcon = sourceMetadata.icon;
  const documentation = { label: sourceMetadata.label, link: sourceMetadata.documentationLink };

  return (
    <Layout>
      <div className="flex flex-col h-screen">
        <div className="flex-1 overflow-y-auto">
          <div className="m-4 grid grid-cols-6 items-start">
            <div className="grid col-start-2 col-span-4 max-w-[680px] pl-6 h-full">
              <div>
                <div onClick={() => navigate(`/sources${integrationIdInEdit ? '' : '?tab=gallery'}`)} className="cursor-pointer flex items-center font-semibold  gap-1">
                  <ChevronLeftIcon width="16" height="16" />
                  Back to {integrationIdInEdit ? 'sources' : 'gallery'}
                </div>
                <div className="flex flex-row items-center justify-start gap-x-3">
                  <SourceIcon width="64" height="64" className="mt-4 mb-4 text-black" fill="#FF694A" />
                  <div className="flex flex-col">
                    <div className="text-2xl">{sourceMetadata.label}</div>
                    <div className="text-tertiary mt-1 mb-auto break-words">{sourceMetadata.subtitle}</div>
                  </div>
                </div>
                <div className="my-10">
                  <div className="flex flex-col gap-8 relative">
                    <div>
                      <span className="text-lg">General</span>
                      <div className="mt-4">
                        <FormField label="Name" labelClassName="w-44">
                          <Input
                            placeholder={`e.g my ${integration.integration_type} integration`}
                            value={integration.name}
                            onInputChange={(e: ChangeEvent<HTMLInputElement>) => setIntegration({ ...integration, name: e.target.value })}
                          />
                        </FormField>
                      </div>
                    </div>
                    <ConfigurationDetailsBlock
                      integration={integration}
                      setIntegration={setIntegration}
                      editMode={!!integrationIdInEdit}
                    />
                    {integration.integration_type !== "dbt_core" && (
                      <ScheduleBlock
                        integration={integration}
                        setIntegration={setIntegration}
                      />
                    )}
                    <InvalidationStrategyBlock integration={integration} setIntegration={setIntegration} />
                    {integration.integration_type === "dbt_core" && (
                      <div className="flex flex-row rounded-lg py-4 px-6 w-[40rem] bg-slate-100">
                        <InformationCircleIcon width="20" height="20" className="mr-2 text-slate-400" />
                        <span className='text-text-primary'>Euno will create an integration key upon source creation</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-6 relative mb-10">
                  <AdvancedBlock editMode={!!integrationIdInEdit} integration={integration} setIntegration={setIntegration} />
                </div>
              </div>
            </div>
            <div className="grid col-span-1 col-start-6 h-full ">
                {documentation && (
                  <HelpCard position="absolute right-8 top-10" documentation={documentation} />
                )}
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md">
          <BottomBar integration={integration} />
        </div>
      </div>
    </Layout>
  );
};

const createEmptyIntegration = (type: GenericIntegrationType): GenericIntegration => {
  let configuration: GenericIntegrationConfiguration;
  let invalidation_strategy: GenericInvalidationStrategy;
  switch (type) {
    case 'dbt_core':
      configuration = {} as DbtCoreIntegrationConfiguration;
      invalidation_strategy = { ttl_days: 7 };
      break;
    case 'looker':
      configuration = { client_id: '', client_secret: '', host: '' } as LookerIntegrationConfiguration;
      invalidation_strategy = { ttl_days: 0 };
      break;
    case 'tableau':
      configuration = { connect_uri: '', site: '', token_name: '', token_value: '', ssl_verify: true, project_pattern: { allow: ['.*'], deny: [] } } as TableauIntegrationConfiguration;
      invalidation_strategy = { ttl_days: 0 };
      break;
    case 'snowflake':
      configuration = { host: '', user: '', password: '', role: '', warehouse: '', ssl_verify: true, table_to_use_for_query_history: '', use_snowflake_database: true, database_pattern: { allow: ['.*'], deny: [] }, extract_views: true, extract_tableau_usage: true, extract_tables: true } as SnowflakeIntegrationConfiguration;
      invalidation_strategy = { ttl_days: 0 };
      break;
    case 'fivetran':
      configuration = { api_key: '', api_secret: '' } as FivetranIntegrationConfiguration;
      invalidation_strategy = { ttl_days: 0 };
      break;
    case 'dbt_cloud':
      configuration = { token: '', access_url:"https://cloud.getdbt.com" ,account_id:undefined, project_id: undefined, match_jobs: { allow: ['.*'], deny: [] }, match_environments:{ allow: ['.*'], deny: [] }, match_branches: { allow: ['.*'], deny: [] }} as DbtCloudIntegrationConfiguration;
      invalidation_strategy = { ttl_days: 7 };
      break;
    case 'thoughtspot':
      configuration = { host: '', username: '', password: '', ssl_verify: true } as ThoughtSpotIntegrationConfiguration;
      invalidation_strategy = { ttl_days: 0 };
      break;
    case 'bigquery':
      configuration = { project_id: '', client_email: '', private_key: '', ssl_verify: true, service_account_key: '' } as BigQueryIntegrationConfiguration;
      invalidation_strategy = { ttl_days: 0 };
      break;
    default:
      throw new Error('Invalid integration type');
  }
  return {
    id: 0,
    name: '',
    active: true,
    configuration,
    invalidation_strategy,
    integration_type: type,
    health: '',
    created_by: '',
    created_at: new Date(),
    last_updated_by: '',
    last_updated_at: new Date(),
    last_run_status: 'draft'
  };
};

export default ConnectSource;
