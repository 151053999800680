import { SparklesIcon } from '@heroicons/react/24/solid';
import { ChangeStatus, ChangeType, IChangePage } from '../IChange';
import Table from '../../../components/Table/Table';
import { getEnumKeyByEnumValue } from '../../../utils/enumUtils';
import statusToColorScheme from './statusToColorScheme';
import { TextWithEllipsisAndTooltip } from '../../../components/TextWithEllipsisAndTooltip';
import { DbtCloudIcon, LookerIcon } from '../../../assets/images/icons/DelphiIcons';
import { dateFormats, utcToLocal } from '../../../infrastructure/dateUtilities';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useGetChangesQuery } from '../../../services/changes/changes';
import { SortOptions, ModelChangesTab, DataModelFilter } from './Types';
import { DataModelFilters } from './filters/DataModelFilters';
import { DateFilterValue } from './filters/dateFilter';
import { FilterValue } from '../../../components/Table/TableFilters/types';
import { TableColumnProps } from 'src/components/Table/types';
import { useIsCreatingPullRequest, useIsGeneratingDiff } from 'src/features/evolution/hooks';

export const ModelChangesList = ({ tab }: { tab: ModelChangesTab }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchInput, setSearchInput] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = Number(searchParams.get('projectId')) || null;
  const [sortBy, setSortBy] = useState<SortOptions>('newest');
  const [filterValues, setFilterValues] = useState<Record<string, FilterValue>>({});
  const getChangesQuery = useGetChangesQuery({
    search: searchInput,
    owner: filterValues[DataModelFilter.owner] || undefined,
    createdAt: (filterValues[DataModelFilter.createdAt] as DateFilterValue) || undefined,
    sortBy: sortBy,
    statuses: tabToStatuses[tab],
    types: filterValues[DataModelFilter.type]?.split(',').map(t => t as ChangeType) || undefined,
    page,
    pageSize,
    projectId: projectId || 0
  }, { skip: !projectId });
  const total = getChangesQuery.data?.total || 0;

  const onRowClicked = (row: IChangePage) => {
    const change = row;
    navigate(`/project/${projectId}/evolution/change/${change.id}`);
  };

  const setProjectId = (id: number) => {
    searchParams.set('projectId', id.toString());
    setSearchParams(searchParams);
  };

  const ModelChangeStatus = ({ modelChange }: { modelChange: IChangePage }) => {
    const isGeneratingDiff = useIsGeneratingDiff(modelChange.id);
    const isCreatingPR = useIsCreatingPullRequest(modelChange.id);
    let statusLabel = getEnumKeyByEnumValue(ChangeStatus, modelChange.status);
    let colorScheme = statusToColorScheme(modelChange.status);
    if (isGeneratingDiff) {
      statusLabel = 'Generating diff';
      colorScheme = 'bg-lilac-100 text-lilac-800';
    }
    if (isCreatingPR) {
      statusLabel = 'Creating PR';
      colorScheme = 'bg-lilac-100 text-lilac-800';
    }
    return (
      <div className={`rounded-full px-3 py-1 text-sm flex items-center gap-1 ${colorScheme}`} data-tag="allowRowEvents">
        {statusLabel}
        {(isGeneratingDiff || isCreatingPR) && <div className="h-2 w-2 animate-spin rounded-full border-t-2 border-lilac-500 border-opacity-40"></div>}
      </div>
    );
  };

  const tableColumns: TableColumnProps<IChangePage>[] = [
    {
      name: '#',
      selector: (row: IChangePage) => `${row.id}`,
      width: '80px'
    },
    {
      name: 'TYPE',
      selector: (row: IChangePage) => (
        <div className="flex gap-1 uppercase" data-tag="allowRowEvents">
          <div><SparklesIcon width="16" height="16" /></div><div>{row.changeType}</div>
        </div>
      ),
      width: '300px'
    },
    {
      name: 'TITLE',
      selector: (row: IChangePage) => <span className="text-text-primary" data-tag="allowRowEvents"><TextWithEllipsisAndTooltip text={row.title} maxChars={65} /></span>,
      width: 'auto'
    },
    {
      name: 'SOURCE',
      selector: SourceColumn,
      width: '250px'
    },
    {
      name: 'OWNER',
      selector: (row: IChangePage) => row.createdBy,
      width: '200px'
    },
    {
      name: 'DATE',
      selector: (row: IChangePage) => utcToLocal(row.createdAt, dateFormats.dateHoursAndMinutes),
      width: '200px'
    },
    {
      name: 'STATUS',
      selector: (row: IChangePage) => <ModelChangeStatus modelChange={row} />,
      width: '150px'
    }
  ];

  return (
    <div>
      <DataModelFilters
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        sortBy={sortBy}
        setSortBy={setSortBy}
        selectedProjectId={projectId}
        setSelectedProjectId={setProjectId}
        showSort={true}
        tableName='model-changes-table'
        onFilterValuesChange={setFilterValues}
      />
      <div className="mt-4 max-w-[78vw]">
        <Table<IChangePage> isLoading={getChangesQuery.isLoading} data={getChangesQuery.data?.items || []} columns={tableColumns} onRowClicked={onRowClicked} maxBodyHeight='72vh' pagination={{ page, pageSize, setPage, setPageSize, total }} />
      </div>
    </div>
  );
};

const SourceColumn = (row: IChangePage) => {
  const change = row as IChangePage;
  let icon;
  switch (change.changeType) {
    case ChangeType.NewAggregateTable:
    case ChangeType.RefreshDataModel:
      icon = <DbtCloudIcon width="16" height="16" color="#FF694A" />;
      break;
    default:
      icon = <LookerIcon width="16" height="16" />;
      break;
  }
  return (
    <div className="flex gap-1 text-black" data-tag="allowRowEvents">
      <div>{icon}</div>
      <TextWithEllipsisAndTooltip text={row.createdByResourceName} maxChars={20} />
    </div>
  );
};

const tabToStatuses = {
  [ModelChangesTab.Proposals]: [ChangeStatus.Proposal],
  [ModelChangesTab.InProgress]: [ChangeStatus.Deployed, ChangeStatus.Merged, ChangeStatus.Open, ChangeStatus['Pending Merge']],
  [ModelChangesTab.Archived]: [ChangeStatus.Published, ChangeStatus['PR Rejected'], ChangeStatus['Draft Rejected']]
};