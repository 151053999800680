import { useSelector } from 'react-redux';
import Layout from 'src/components/layout/Layout';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { CreatePropertyForm } from './components/CreatePropertyForm';
import { PropertyFormBottomBar } from './components/PropertyFormBottomBar';
import { CustomProperty, CustomPropertyClass, CustomPropertyFEType } from 'src/services/customProperties/types';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useGetCustomPropertiesListQuery, useGetCustomPropertyQuery } from 'src/services/customProperties/customProperties';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ComponentLoader } from 'src/components/loaders/ComponentLoader';
import { notify } from 'src/components/Toaster';
import HelpCard from '../sources/connectedSources/HelpCard';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { selectIsMenuCollpased } from 'src/infrastructure/state/slices/isMenuCollpasedSlice';

const createEmptyCustomProperty = (): CustomProperty => ({
    id: -1,
    name: '',
    description: '',
    owner: 'Euno',
    class: CustomPropertyClass.Fixed,
    content: {
        type: CustomPropertyFEType.SingleSelect,
        content: {
            options: [],
        },
    },
    group: 'General',
    showInOverview: true,
    eqlName: '',
    propertyScope: '',
    allowMultipleValues: false,
    orderInGroup: 0,
    lastUpdatedAt: '',
    lastUpdatedBy: '',
    createdBy: '',
    createdAt: '',
    lastCalculationTimeout: false,
});

export const CreatePropertyPage = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    const location = useLocation();
    const navigate = useNavigate();
    const isEditMode = !!propertyId;
    const templateProperty = location.state?.template as CustomProperty | undefined;

    const accountId = useSelector(selectActiveAccountId);
    const isMenuCollapsed = useSelector(selectIsMenuCollpased);
    const [isFormValid, setIsFormValid] = useState(false);

    const { data: property, isLoading, error } = useGetCustomPropertyQuery(
        { accountId, propertyId: Number(propertyId) },
        { skip: !isEditMode || !propertyId || isNaN(Number(propertyId)) }
    );

    const [customProperty, setCustomProperty] = useState<CustomProperty>(() => {
        if (templateProperty) {
            return templateProperty;
        }
        return createEmptyCustomProperty();
    });

    const { data: customProperties = [] } = useGetCustomPropertiesListQuery({ accountId });

    useEffect(() => {   
        if (isEditMode && property) {
            setCustomProperty(property);
        }
    }, [isEditMode, property]);

    useEffect(() => {
        if (isEditMode && error) {
            notify('Failed to load property. Please try again.', 'error');
            navigate('/properties');
        }
    }, [isEditMode, error, navigate]);

    const documentation = { label: 'custom property', link: 'euno.docs' };

    const groups = useMemo(() => {
        const uniqueGroups = new Set(customProperties.map(prop => prop.group).filter(Boolean));
        return Array.from(uniqueGroups);
    }, [customProperties]);

    const handleFormValidityChange = useCallback((isValid: boolean) => {
        setIsFormValid(isValid);
    }, []);

    if (isEditMode && isLoading) {
        return (
            <Layout>
                <div className="h-full flex items-center justify-center">
                    <ComponentLoader />
                </div>
            </Layout>
        );
    }
    
    return (
        <Layout>
            <div className="h-full overflow-auto pb-20 relative">
                <div className="m-4 grid grid-cols-6 items-start">
                    <div className="grid col-start-2 col-span-4 max-w-[680px] pl-4 h-full">
                        <div onClick={() => navigate(`/properties`)} className="cursor-pointer flex items-center font-semibold  gap-1">
                            <ChevronLeftIcon width="16" height="16" />
                            Back to Euno Properties
                        </div>
                        <CreatePropertyForm 
                            customProperty={customProperty} 
                            setCustomProperty={setCustomProperty} 
                            groups={groups} 
                            isEditMode={isEditMode}
                            onValidityChange={handleFormValidityChange}
                        />
                    </div>
                    <div className="grid col-span-1 col-start-6 h-full">
                        <HelpCard position="absolute right-8 top-10" documentation={documentation} />
                    </div>
                </div>
                <div className="fixed bottom-0 bg-white shadow-md" style={{ 
                    left: isMenuCollapsed ? "40px" : "250px", 
                    width: `calc(100% - ${isMenuCollapsed ? "40px" : "250px"})`,
                    transition: "left 0.2s, width 0.2s"
                }}>
                    <PropertyFormBottomBar 
                        customProperty={customProperty} 
                        isEditMode={isEditMode}
                        isDisabled={!isFormValid}
                    />
                </div>
            </div>
        </Layout>
    );
}; 