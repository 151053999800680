import { ArrowPathIcon, ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useHoverDirty } from "react-use";
import { OperationStatus } from "src/features/operations/Operation";
import { SOURCE_METADATA } from "src/features/sources/Consts";
import { dateFormats, utcToLocal } from "src/infrastructure/dateUtilities";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetGenericIntegrationsQuery } from "src/services/integrations/integrations";
import { GenericIntegration, IntegrationSchedule, WeekDay } from "src/services/integrations/types";
import { useGetAccountOperationsQuery } from "src/services/operations";
import { Tooltip } from "react-tooltip";
import { shortenString } from "src/utils/stringsUtils";


const replaceSpacesWithUnderscores = (text: string): string => {
    return text.replace(/ /g, "_");
};


export const SidebarSourcesStatus = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const accountId = useSelector(selectActiveAccountId);
    const getSources = useGetGenericIntegrationsQuery({ accountId });

    useEffect(() => {
        if ((getSources.data || []).some(isRunning) && getSources.data?.length || 0 > 0) {
            const timeout = setTimeout(getSources.refetch, 5000);
            return () => clearTimeout(timeout);
        }
    }, [getSources.data, getSources.refetch, getSources.fulfilledTimeStamp]);

    return (
        <div className="py-4 flex flex-col gap-2 flex-1 overflow-auto">
            <div className="px-4 flex cursor-pointer justify-between items-center mb-2" onClick={() => setIsExpanded(!isExpanded)}>
                <div className="text-slate-400">DATA FRESHNESS</div>
                <div className="w-fit text-secondary">
                    {
                        isExpanded ? (
                            <ChevronUpIcon width="20" height="20" />
                        ) : (
                            <ChevronDownIcon width="20" height="20" />
                        )
                    }
                </div>
            </div>
            <div className="flex-1 px-4 overflow-auto">
                {
                    isExpanded ? (
                        <div className="flex flex-col gap-4">
                            {
                                (getSources.data || []).map(source => <Source showNextRun={true} key={source.name} source={source} />)
                            }
                        </div>
                    ) : (
                        <div className="flex flex-col gap-4">
                            {
                                (getSources.data?.filter(isRunning) || []).map(source => <Source showNextRun={false} key={source.name} source={source} />)
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
};

const Source = ({ source, showNextRun }: { source: GenericIntegration, showNextRun: boolean }) => {
    const accountId = useSelector(selectActiveAccountId);
    const ref = useRef<HTMLDivElement>(null);
    const getRuns = useGetAccountOperationsQuery({ accountId, page: 1, pageSize: 1, integrationId: source.id.toString() });
    const isHovering = useHoverDirty(ref);
    const navigate = useNavigate();

    const lastRun = getRuns.data?.items?.find(r => [OperationStatus.Completed, OperationStatus.Publishing, OperationStatus.Running].includes(r.status));
    const isSourceRunning = isRunning(source);
    const nextRun = getNextRun(lastRun?.start_time || null, source.schedule || null);
    const sourceMetadata = SOURCE_METADATA.get(source.integration_type)!;
    const Icon = sourceMetadata.icon;

    useEffect(() => {
        if (isSourceRunning && getRuns.data?.items?.length || 0 > 0) {
            const timeout = setTimeout(getRuns.refetch, 5000);
            return () => clearTimeout(timeout);
        }
    }, [isSourceRunning, getRuns.refetch, getRuns.fulfilledTimeStamp, getRuns.data?.items?.length]);

    const goToSource = () => {
        navigate(`/sources/${source.id}`);
    };

    if (!lastRun) {
        return null;
    }

    return (
        <div key={source.id} ref={ref}>
            <div className="flex gap-1 items-center">
                <Icon width="14" height="14" className="text-slate-600" />
                <div id={`source-name_${replaceSpacesWithUnderscores(source.name)}`} className="font-semibold text-text-primary">{shortenString(source.name, 20)}</div>
                <Tooltip anchorSelect={`#source-name_${replaceSpacesWithUnderscores(source.name)}`}>{source.name}</Tooltip>
                {
                    isSourceRunning && (
                        <ArrowPathIcon width="14" height="14" className="text-slate-500 animate-spin" />
                    )
                }
                {
                    isHovering && (
                        <ArrowUpRightIcon width="12" height="12" className="text-slate-400 cursor-pointer hover:text-slate-500 ml-auto" onClick={goToSource} />
                    )
                }
            </div>
            <div className="pl-5 text-sm flex flex-col mt-1 gap-1">
                {
                    lastRun?.start_time && (
                        <div className="flex items-center justify-between">
                            <div>{isSourceRunning ? 'Start time:' : 'Last updated:'}</div>
                            <div className="font-semibold">{utcToLocal(lastRun?.start_time, dateFormats.monthsDaysHoursAndMinutes)}</div>
                        </div>
                    )
                }
                {
                    showNextRun && nextRun && (
                        <div className="flex items-center justify-between text-slate-400">
                            <div>Next update:</div>
                            <div className="font-semibold">{utcToLocal(nextRun.toString(), dateFormats.monthsDaysHoursAndMinutes)}</div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

const isRunning = (source: GenericIntegration) => {
    return source.last_run_status === 'running' || source.last_run_status === 'publishing';
};

const dayNumberToWeekDay: Record<number, WeekDay> = {
    0: WeekDay.Sun,
    1: WeekDay.Mon,
    2: WeekDay.Tue,
    3: WeekDay.Wed,
    4: WeekDay.Thu,
    5: WeekDay.Fri,
    6: WeekDay.Sat,
};

const getNextRun = (lastRun: string | null, schedule: IntegrationSchedule | null): Date | null => {
    if (!lastRun || !schedule) return null;
    let nextRun: Date | null = null;
    // If the schedule is set to repeat on specific days of the week
    if (schedule.repeat_on && schedule.repeat_time) {
        const lastRunDate = new Date(lastRun);
        const nextRunDate = new Date(lastRunDate);
        nextRunDate.setHours(Number(schedule.repeat_time.split(':')[0]));
        nextRunDate.setMinutes(Number(schedule.repeat_time.split(':')[1]));
        for (let i = 1; i < 8; i++) {
            nextRunDate.setDate(lastRunDate.getDate() + i);
            if (schedule.repeat_on.includes(dayNumberToWeekDay[nextRunDate.getDay()])) {
                nextRun = nextRunDate;
                break;
            }
        }
    }
    // If the schedule is set to repeat every N hours
    else if (schedule.repeat_period) {
        const lastRunDate = new Date(lastRun);
        const nextRunDate = new Date(lastRunDate);
        nextRunDate.setHours(lastRunDate.getHours() + schedule.repeat_period);
        nextRun = nextRunDate;
    }
    return nextRun;
};