export type CustomProperty = {
    id: number;
    name: string;
    owner: string;
    description: string;
    group: string;
    showInOverview: boolean;
    class: CustomPropertyClass;
    eqlName: string;
    propertyScope: string;
    allowMultipleValues: boolean;
    content: CustomPropertyContent;
    orderInGroup: number;
    lastUpdatedAt: string;
    lastUpdatedBy: string;
    createdBy: string;
    createdAt: string;
    lastCalculationTimeout: boolean;
};

export enum CustomPropertyClass {
    Fixed = 'fixed',
    Live = 'live'
}

export enum CustomPropertyFEType {
    MultiSelect = 'multiSelect',
    LiveBoolean = 'liveBoolean',
    SingleSelect = 'singleSelect',
    CategoryAssigned = 'categoryAssigned',
    Datetime = 'datetime',
    FixedBoolean = 'fixedBoolean',
    Numeric = 'numeric',
    AggregateRelationships = 'aggregateRelationships',
    AISelect = 'aiSelect'
}


export type AggregateRelationshipsContent = {
    applies_to_eql: string;
    type_of_relationship: RelationshipType;
    path_length: number;
    target_eql: string;
    aggregation: string;
    target_property: string;
};

export type ManualSingleSelectContent = {
    scope: string | boolean;
    options: string[];
};

export type ResponseContent = {
    scope: string | boolean;
    options?: string[];
    eql?: string;
};

export type CustomPropertyContent =
    | { type: CustomPropertyFEType.SingleSelect; content: { options: string[] } }
    | { type: CustomPropertyFEType.MultiSelect; content: { options: string[] } }
    | { type: CustomPropertyFEType.LiveBoolean; content: { options: [], eql: string, scope: string } }
    | { type: CustomPropertyFEType.CategoryAssigned; content: { options: MultiSelectOption[] } }
    | { type: CustomPropertyFEType.Datetime; content: Record<string, never> }
    | { type: CustomPropertyFEType.FixedBoolean; content: Record<string, never> }
    | { type: CustomPropertyFEType.Numeric; content: Record<string, never> }
    | { type: CustomPropertyFEType.AggregateRelationships; content: AggregateRelationshipsContent }
    | { type: CustomPropertyFEType.AISelect; content: AISelectContent };

export type CustomPropertyMetadata = {
    description: string | null;
    properties_group: string | null;
    order_in_group: number;
    display_name: string | null;
    owner: string;
    show_in_overview: boolean;
};

export type CustomPropertyResponseType =
    | 'manual_single_select'
    | 'manual_multi_select'
    | 'multi_select'
    | 'scoped_boolean'
    | 'manual_boolean_field'
    | 'manual_numeric_field'
    | 'manual_datetime_field'
    | 'ai_select'
    | 'aggregate_relationships';

export type CustomPropertyResponseContent = {
    type: CustomPropertyResponseType;
    content: {
        options?: (string | { value: string; eql: string })[];
        scope: string;
        eql?: string;
        applies_to_eql?: string;
        type_of_relationship?: RelationshipType;
        path_length?: number;
        target_eql?: string;
        aggregation?: string;
        target_property?: string;
        prompt?: string;
        properties?: string[];
        is_open_ended?: boolean;
    };
};

export type CustomPropertyResponse = {
    id: number;
    name: string;
    metadata: CustomPropertyMetadata;
    content: {
        type: CustomPropertyType;
        content: CustomPropertyContentType;
    };
    last_updated_at: string;
    last_updated_by: string;
    created_by: string;
    created_at: string;
    last_calculation_timeout: boolean;
};

export const PROPERTY_CLASS_INFO: Record<CustomPropertyClass, { label: string; description: string }> = {
    [CustomPropertyClass.Live]: {
        label: 'Live Properties',
        description: 'Dynamically calculated based on predefined conditions. Updated automatically and reflect real-time data changes.'
    },
    [CustomPropertyClass.Fixed]: {
        label: 'Fixed Properties',
        description: 'Manually assigned and remain unchanged unless updated. Help standardize and categorize data.'
    }
};

export const PROPERTY_VALUE_INFO: Record<CustomPropertyFEType, { label: string; description: string }> = {
    [CustomPropertyFEType.CategoryAssigned]: {
        label: 'Category Assigned Value',
        description: 'Automatically assigns a resource to one category from a predefined list'
    },
    [CustomPropertyFEType.LiveBoolean]: {
        label: 'Live Boolean',
        description: 'Automatically determines true/false based on a set condition'
    },
    [CustomPropertyFEType.SingleSelect]: {
        label: 'Single Select',
        description: 'Allows setting one option per resource from a predefined list'
    },
    [CustomPropertyFEType.MultiSelect]: {
        label: 'Multi Select',
        description: 'Allows setting multiple options per resource from a predefined list'
    },
    [CustomPropertyFEType.Datetime]: {
        label: 'Date & Time',
        description: 'Store and filter data using dates or timestamps'
    },
    [CustomPropertyFEType.FixedBoolean]: {
        label: 'Fixed Boolean',
        description: 'Allows setting as true/false values'
    },
    [CustomPropertyFEType.Numeric]: {
        label: 'Number',
        description: 'Store numerical values for calculations or comparisons'
    },
    [CustomPropertyFEType.AggregateRelationships]: {
        label: 'Aggregate Relationships',
        description: 'Aggregates data from a parent or child resource'
    },
    [CustomPropertyFEType.AISelect]: {
        label: 'AI Select',
        description: 'Allows setting one option per resource from a predefined list'
    }
};

export enum AggregateType {
    count = 'count',
    sum = 'sum',
    max = 'max',
    min = 'min',
    list_of_distinct_values = 'list_of_distinct_values',
    map_of_values = 'map_of_values'
}

export type ScopedBooleanContent = {
    eql: string;
    scope: string;
}

export type MultiSelectOption = {
    value: string;
    eql: string;
}

export type MultiSelectContent = {
    options: MultiSelectOption[];
}

export type ManualPropertyContent = {
    scope: string;
}

export type ClosedListManualPropertyContent = {
    scope: string;
    options: string[];
}
export enum RelationshipType {
    parent = 'parent',
    child = 'child'
}

export interface AggregateRelationshipContent {
    applies_to_eql: string;
    type_of_relationship: RelationshipType;
    path_length: number;
    target_eql: string;
    aggregation: AggregateType;
    target_property: string;
}

export type AISelectContent = {
    options: string[];
    prompt: string;
    properties: string[];
    scope: string;
    is_open_ended?: boolean;
}

export type CustomPropertyContentType =
    | ScopedBooleanContent // for scoped_boolean type
    | MultiSelectContent // for multi_select type
    | AggregateRelationshipContent // for aggregate_relationships type
    | ClosedListManualPropertyContent // for manual_multi_select and manual_single_select types
    | ManualPropertyContent // for manual_text_field, manual_numeric_field, manual_datetime_field, manual_boolean_field types
    | AISelectContent; // for ai_select type

export enum CustomPropertyType {
    manual_text_field = 'manual_text_field',
    manual_numeric_field = 'manual_numeric_field',
    manual_datetime_field = 'manual_datetime_field',
    manual_boolean_field = 'manual_boolean_field',
    manual_single_select = 'manual_single_select',
    manual_multi_select = 'manual_multi_select',
    scoped_boolean = 'scoped_boolean',
    multi_select = 'multi_select',
    aggregate_relationships = 'aggregate_relationships',
    ai_select = 'ai_select'
}

export const PROPERTY_TYPE_LABELS: Partial<Record<CustomPropertyType, string>> = {
    [CustomPropertyType.manual_text_field]: 'Text Field',
    [CustomPropertyType.manual_numeric_field]: 'Numeric Field',
    [CustomPropertyType.manual_datetime_field]: 'Date/Time Field',
    [CustomPropertyType.manual_boolean_field]: 'Boolean Field',
    [CustomPropertyType.manual_single_select]: 'Single Select',
    [CustomPropertyType.manual_multi_select]: 'Multi Select',
    [CustomPropertyType.scoped_boolean]: 'Computed Boolean',
    [CustomPropertyType.multi_select]: 'Computed Multi Select',
    [CustomPropertyType.aggregate_relationships]: 'Aggregate Relationships',
    [CustomPropertyType.ai_select]: 'AI Select'
};

export interface ChartDataItem {
    name: string;
    value: number;
    increment?: number;
    eql?: string;
}