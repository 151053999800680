import { DataModelTabConfig } from "src/features/models/discover/toolbar/tabs/types";
import { Filter } from "src/features/models/discover/toolbar/filters/Types";
import { FilterMode } from "src/features/models/discover/types";

export const getTabConfig = (filterMode: FilterMode, filters: Filter[], eql: string, showSubResources: boolean, columns: string[]): DataModelTabConfig => {
    const config: DataModelTabConfig = {
        filterMode,
        eql: filterMode === 'eql' ? eql : undefined,
        showSubResources: filterMode === 'basic' ? showSubResources : undefined,
        columns: filterMode === 'basic' && columns.length > 0 ? columns : undefined,
        filters: filterMode === 'basic' ? filters.map(f => ({ name: f.name, value: f.value })).filter(f => !!f.value) : undefined,
    };
    return config;
};
