import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { SelectFilter } from "src/components/Table/TableFilters/SelectFilter";
import { FilterValue, SelectFilterOption } from "src/components/Table/TableFilters/types";
import { Filter } from "src/features/models/discover/toolbar/filters/Types";
import { CustomProperty, CustomPropertyFEType, ClosedListManualPropertyContent } from "src/services/customProperties/types";
const optionIcon = <AdjustmentsHorizontalIcon width="16" height="16" />;

export const getCustomFilters = (customProperties: CustomProperty[]) => {
    // Anything not in this array will not be shown in the filters
    const allowedCustomPropertyFETypes = [CustomPropertyFEType.LiveBoolean, CustomPropertyFEType.FixedBoolean, CustomPropertyFEType.MultiSelect, CustomPropertyFEType.CategoryAssigned, CustomPropertyFEType.AISelect, CustomPropertyFEType.SingleSelect];

    const customFilters: Filter[] = [];
    
    for (const prop of customProperties) {
        if (!allowedCustomPropertyFETypes.includes(prop.content.type)) continue;
        let options: SelectFilterOption[];
        let thisGetEql: (value: FilterValue) => string;
        if (prop.content.type === CustomPropertyFEType.LiveBoolean || prop.content.type === CustomPropertyFEType.FixedBoolean) {
            options = booleanOptions;
            thisGetEql = (value: FilterValue) => getEql(prop.eqlName, value, 'boolean');
        } else if (prop.content.type === CustomPropertyFEType.CategoryAssigned || prop.content.type === CustomPropertyFEType.MultiSelect || prop.content.type === CustomPropertyFEType.AISelect) {
            if (prop.content.type === CustomPropertyFEType.AISelect && prop.content.content.is_open_ended) {
                // TODO: Implement open ended AI Select filter
                console.debug('Open ended AI Select not implemented');
                continue;
            }
            // Handle options regardless of whether they're strings or objects with value properties
            const content = prop.content.content as { options: unknown[] };
            
            options = content.options.map(opt => {
                // Simple extraction of value regardless of type
                let value = '';
                if (typeof opt === 'string') {
                    value = opt;
                } else if (opt && typeof opt === 'object' && 'value' in opt) {
                    value = String(opt.value);
                }
                return { label: value, value, icon: optionIcon } as SelectFilterOption;
            });
            thisGetEql = (value: FilterValue) => getEql(prop.eqlName, value, 'multi_select');
        } else if (prop.content.type === CustomPropertyFEType.SingleSelect) {
            options = (prop.content.content as ClosedListManualPropertyContent).options.map(( value ) => ({ label: value, value, icon: optionIcon } as SelectFilterOption));
            thisGetEql = (value: FilterValue) => getEql(prop.eqlName, value, 'multi_select');
        } else {
            console.error(`Invalid custom property type: ${prop.content.type}`);
            continue;
        }
        customFilters.push({
            name: prop.name,
            menuIcon: <AdjustmentsHorizontalIcon width="18" height="18" className="mr-1.5" />,
            value: null,
            badge: ({ value }: { value: FilterValue }) => `${prop.eqlName}: ${value}`,
            isDisabled: false,
            getEql: thisGetEql,
            component: ({ value, onChange }) => (
                <SelectFilter
                    label={prop.name}
                    options={options}
                    isMulti={prop.content.type === CustomPropertyFEType.MultiSelect}
                    value={value}
                    onChange={onChange}
                />
            ),
            group: 'Custom'
        });
    }
    return customFilters;
};

const getTruthyValuesForBooealnFilter = (value: string) => ['true', 'yes', '1', 'on'].includes(value.toLowerCase()) ? 'TRUE' : 'FALSE';

const booleanOptions: SelectFilterOption[] = [{ label: 'Yes', value: 'true', icon: optionIcon }, { label: 'No', value: 'false', icon: optionIcon }];
const getEql = (propertyName: string, value: FilterValue, type: 'boolean' | 'multi_select') => {
    if (!value) return '';
    switch (type) {
        case 'boolean':
            return `(${propertyName} IS ${getTruthyValuesForBooealnFilter(value)})`;
        case 'multi_select':
            return `(${propertyName} IN ('${value.split(',').join("','")}'))`;
        default:
            console.error(`Invalid filter type: ${type}`);
            return '';
    }
};
