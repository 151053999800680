import { useState, useMemo, useEffect, useCallback } from 'react';
import Layout from 'src/components/layout/Layout';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { ArrowRightIcon, ChevronUpDownIcon, ChevronUpIcon, ChevronDownIcon, EllipsisHorizontalIcon, UserIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon, TagIcon } from '@heroicons/react/24/outline';
import { TagRefreshIcon } from 'src/assets/images/icons/DelphiIcons';
import { useNavigate, useLocation } from 'react-router-dom';
import { CustomProperty, CustomPropertyClass, CustomPropertyFEType, PROPERTY_VALUE_INFO } from 'src/services/customProperties/types';
import DropdownMenu from 'src/components/DropdownMenu/DropdownMenu';
import { DropdownMenuItem } from 'src/components/DropdownMenu/types';
import { ComponentLoader } from 'src/components/loaders/ComponentLoader';
import { useGetCustomPropertiesListQuery, useDeleteCustomPropertyMutation } from 'src/services/customProperties/customProperties';
import { useAppSelector } from 'src/infrastructure/state/hooks';
import Modal from 'src/components/Modal/Modal';
import { ButtonTypes } from 'src/components/button/types';
import { notify } from 'src/components/Toaster';
import { extractErrorMessage } from 'src/services/api';
import { TextWithEllipsisAndTooltip } from 'src/components/TextWithEllipsisAndTooltip';
import { shortenString } from 'src/utils/stringsUtils';


const getSortIcon = (currentSortField: string | null, field: string, currentDirection: 'asc' | 'desc') => {
    if (currentSortField === field) {
        if (currentDirection === 'asc') {
            return <ChevronUpIcon className="h-4 w-4 text-slate-700" />;
        }
        return <ChevronDownIcon className="h-4 w-4 text-slate-700" />;
    }
    return <ChevronUpDownIcon className="h-4 w-4 text-slate-500 opacity-0 group-hover:opacity-100" />;
};
const MAX_TAG_CHARS = 50;
const MAX_DESCRIPTION_CHARS = 83;
export const PropertiesManagementPage = () => {
    const accountId = useAppSelector(selectActiveAccountId);
    const navigate = useNavigate();
    const location = useLocation();
    const { data: customProperties = [], isLoading } = useGetCustomPropertiesListQuery({ accountId });
    const [deleteProperty, { isLoading: isDeleting }] = useDeleteCustomPropertyMutation();
    
    const [sortField, setSortField] = useState<'name' | 'owner' | 'group' | 'showInOverview' | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    
    const [selectedClass, setSelectedClass] = useState<CustomPropertyClass | ''>('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedType, setSelectedType] = useState<CustomPropertyFEType | ''>('');
    const [searchTerm, setSearchTerm] = useState('');
    const [propertyToDelete, setPropertyToDelete] = useState<CustomProperty | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleSetSelectedClass = useCallback((classValue: CustomPropertyClass | '') => {
        setSelectedClass(classValue);
    }, []);

    const handleSetSelectedGroup = useCallback((group: string) => {
        setSelectedGroup(group);
    }, []);

    const handleSetSelectedType = useCallback((type: CustomPropertyFEType | '') => {
        setSelectedType(type);
    }, []);

    const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    }, []);

    const handleCreateProperty = useCallback(() => {
        navigate('/properties/create');
    }, [navigate]);

    const closeDeleteModal = useCallback(() => {
        setShowDeleteModal(false);
        setPropertyToDelete(null);
    }, []);

    const handleDeleteProperty = useCallback(async () => {
        if (!propertyToDelete) return;
        
        try {
            await deleteProperty({
                accountId,
                propertyId: propertyToDelete.id
            }).unwrap();
            notify('Custom property deleted successfully', 'success');
            setShowDeleteModal(false);
            setPropertyToDelete(null);
        } catch (error) {
            notify(`Failed to delete custom property: ${extractErrorMessage(error).message}`, 'error');
        }
    }, [propertyToDelete, deleteProperty, accountId]);

    const handleSortClick = useCallback((field: 'name' | 'owner' | 'group' | 'showInOverview') => {
        if (sortField === field) {
            setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    }, [sortField]);

    useEffect(() => {
        const classParam = queryParams.get('class');
        const groupParam = queryParams.get('group');
        const typeParam = queryParams.get('type');
        const searchParam = queryParams.get('search');
        
        if (classParam) {
            setSelectedClass(classParam as CustomPropertyClass);
        }
        
        if (groupParam) {
            setSelectedGroup(groupParam);
        }
        
        if (typeParam) {
            setSelectedType(typeParam as CustomPropertyFEType);
        }
        
        if (searchParam) {
            setSearchTerm(searchParam);
        }
    }, [queryParams]);

    useEffect(() => {
        const params = new URLSearchParams();
        
        if (selectedClass) {
            params.set('class', selectedClass);
        }
        
        if (selectedGroup) {
            params.set('group', selectedGroup);
        }
        
        if (selectedType) {
            params.set('type', selectedType);
        }
        
        if (searchTerm) {
            params.set('search', searchTerm);
        }
        
        const newSearch = params.toString();
        const currentSearch = location.search.replace(/^\?/, '');
        if (newSearch !== currentSearch) {
            navigate({
                pathname: location.pathname,
                search: newSearch ? `?${newSearch}` : ''
            }, { replace: true });
        }
    }, [selectedClass, selectedGroup, selectedType, searchTerm, navigate, location.pathname, location.search]);

    const groups = useMemo(() => {
        const uniqueGroups = new Set(customProperties.map(prop => prop.group).filter(Boolean));
        return Array.from(uniqueGroups);
    }, [customProperties]);

    const types = useMemo(() => {
        // Get unique types from current properties
        const uniqueTypes = new Set(customProperties.map(prop => prop.content.type));
        return Array.from(uniqueTypes);
    }, [customProperties]);

    const filteredProperties = useMemo(() => {
        return customProperties.filter(property => {
            const matchesClass = !selectedClass || property.class === selectedClass;
            const matchesGroup = !selectedGroup || property.group === selectedGroup;
            const matchesType = !selectedType || property.content.type === selectedType;
            const matchesSearch = !searchTerm ||
                property.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                property.eqlName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (property.group && property.group.toLowerCase().includes(searchTerm.toLowerCase()));

            return matchesClass && matchesGroup && matchesType && matchesSearch;
        });
    }, [customProperties, selectedClass, selectedGroup, selectedType, searchTerm]);

    const sortedProperties = useMemo(() => {
        if (!sortField) return filteredProperties;
        
        return [...filteredProperties].sort((a, b) => {
            let aValue = a[sortField] || '';
            let bValue = b[sortField] || '';
            
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }
            
            if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });
    }, [filteredProperties, sortField, sortDirection]);

    const classAndTypeOptions = useMemo<DropdownMenuItem[]>(() => [
        { name: 'All', className: 'hover:bg-slate-50', onClick: () => { handleSetSelectedClass(''); handleSetSelectedType(''); } },
        { name: 'Fixed', className: 'hover:bg-slate-50', onClick: () => { handleSetSelectedClass(CustomPropertyClass.Fixed); handleSetSelectedType(''); } },
        { name: 'Live', className: 'hover:bg-slate-50', onClick: () => { handleSetSelectedClass(CustomPropertyClass.Live); handleSetSelectedType(''); } },
        ...types.map(type => ({
            name: PROPERTY_VALUE_INFO[type]?.label || type,
            className: 'hover:bg-slate-50',
            onClick: () => { 
                handleSetSelectedType(type);
                handleSetSelectedClass('');
            }
        }))
    ], [types, handleSetSelectedClass, handleSetSelectedType]);

    const groupOptions = useMemo<DropdownMenuItem[]>(() => [
        { name: 'All Groups', className: 'hover:bg-slate-50', onClick: () => handleSetSelectedGroup('') },
        ...groups.map(group => ({
            name: group,
            className: 'hover:bg-slate-50',
            onClick: () => handleSetSelectedGroup(group)
        }))
    ], [groups, handleSetSelectedGroup]);
    
    const handleEditProperty = useCallback((property: CustomProperty) => {
        navigate(`/properties/${property.id}?edit=true`);
    }, [navigate]);

    const handleUseAsTemplate = useCallback((property: CustomProperty) => {
        navigate('/properties/create', { 
            state: { 
                template: {
                    ...property,
                    id: -1, 
                    name: `Copy of ${property.name}`,
                    eqlName: `copy_of_${property.eqlName}`
                } 
            } 
        });
    }, [navigate]);
    
    const handleDeleteClick = useCallback((property: CustomProperty) => {
        setPropertyToDelete(property);
        setShowDeleteModal(true);
    }, []);

    const getMenuOptions = useCallback((property: CustomProperty): DropdownMenuItem[] => [
        {
            name: 'Edit', 
            className: 'hover:bg-slate-50',
            onClick: () => handleEditProperty(property)
        }, 
        {
            name: 'Use as Template', 
            className: 'hover:bg-slate-50',
            onClick: () => handleUseAsTemplate(property)
        },
        {
            name: 'Delete', 
            className: 'hover:bg-slate-50 !text-danger-strong',
            onClick: () => handleDeleteClick(property)
        }
    ], [handleEditProperty, handleUseAsTemplate, handleDeleteClick]);

    const handleNavigateToProperty = useCallback((propertyId: number) => {
        navigate(`/properties/${propertyId}`);
    }, [navigate]);

    const getSelectedFilterLabel = () => {
        if (selectedType) {
            return PROPERTY_VALUE_INFO[selectedType]?.label || 'Type';
        }
        if (selectedClass === CustomPropertyClass.Fixed) return 'Fixed';
        if (selectedClass === CustomPropertyClass.Live) return 'Live';
        return 'Type';
    };

    return (
        <Layout>
            <div className="overflow-y-auto max-h-[100vh]">
                <div className="m-4 grid grid-rows-[auto_1fr] gap-x-2">
                    <div className="flex justify-between items-center mb-8">
                        <div>
                            <h1 className="text-2xl font-bold text-slate-900">Properties Manager</h1>
                            <p className="text-slate-600 mt-1">Manage and create Euno properties</p>
                        </div>
                        <button onClick={handleCreateProperty} className="bg-lilac-600 hover:bg-lilac-700 text-white px-4 py-2 rounded-md transition-colors">
                            Create a new property
                        </button>
                    </div>

                    <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center space-x-4">
                            <span className="font-bold text-slate-700">{filteredProperties.length} PROPERTIES</span>
                            <div className="h-5 border-r border-slate-300"></div>
                            <span className="text-slate-600">Filter by</span>
                            <div className="relative">
                                <DropdownMenu items={classAndTypeOptions} position='center' className='mt-8'>
                                    <DropdownButton>
                                        {getSelectedFilterLabel()}
                                    </DropdownButton>
                                </DropdownMenu>
                            </div>
                            <div className="relative">
                                <DropdownMenu items={groupOptions} position='center' className='mt-8'>
                                    <DropdownButton>
                                        {selectedGroup || 'Group'}
                                    </DropdownButton>
                                </DropdownMenu>
                            </div>
                        </div>
                        <div className="relative">
                            <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-slate-400" />
                            <input
                                type="text"
                                placeholder="Search for properties"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="pl-10 pr-4 py-1 border border-slate-300 rounded-lg w-64 focus:outline-none focus:ring-2 focus:ring-lilac-500 focus:border-transparent"
                            />
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="flex justify-center items-center h-full">
                            <ComponentLoader />
                        </div>
                    ) : (
                        <div className="bg-white rounded-lg border border-slate-200 shadow-sm ">
                            <div className="grid rounded-t-lg bg-slate-200 grid-cols-12 gap-4 px-4 py-3 text-sm font-medium text-slate-700 border-b border-slate-200">
                                <div 
                                    className="col-span-3 flex items-center cursor-pointer group"
                                    onClick={() => handleSortClick('name')}
                                >
                                    <span className="mr-1">NAME</span>
                                    {getSortIcon(sortField, 'name', sortDirection)}
                                </div>
                                <div className="col-span-4">DESCRIPTION</div>
                                <div 
                                    className="col-span-2 flex items-center cursor-pointer group"
                                    onClick={() => handleSortClick('owner')}
                                >
                                    <span className="mr-1">OWNER</span>
                                    {getSortIcon(sortField, 'owner', sortDirection)}
                                </div>
                                <div 
                                    className="col-span-1 flex items-center cursor-pointer group"
                                    onClick={() => handleSortClick('group')}
                                >
                                    <span className="mr-1">GROUP</span>
                                    {getSortIcon(sortField, 'group', sortDirection)}
                                </div>
                                <div className="col-span-1 flex items-center cursor-pointer group"
                                    onClick={() => handleSortClick('showInOverview')}
                                >
                                    <span className="mr-1">VISIBILITY</span>
                                    {getSortIcon(sortField, 'showInOverview', sortDirection)}
                                </div>
                                <div className="col-span-1 text-center">ACTION</div>
                            </div>

                            <div className="h-[calc(100vh-220px)] overflow-y-auto pt-2">
                                {filteredProperties.length === 0 ? (
                                    <EmptyState />
                                ) : (
                                    <div className="transition-all duration-200 ease-in-out">
                                        {sortedProperties.map((property, index: number) => (
                                            <div
                                                key={property.id}
                                                className={`grid grid-cols-12 gap-4 px-4 py-3 text-sm items-center transition-colors duration-200 ${index !== sortedProperties.length - 1 ? "border-b border-slate-200" : ""
                                                    } ${index % 2 === 1 ? "bg-slate-50" : "bg-white"}`}
                                            >
                                                <div className="col-span-3 flex items-center">
                                                    <span className={`inline-flex items-center mr-2`}>
                                                        {property.class === CustomPropertyClass.Fixed ? (
                                                            <TagIcon className="h-5 w-5" />
                                                        ) : (
                                                            <TagRefreshIcon className="h-5 w-5" />
                                                        )}
                                                    </span>
                                                    <div
                                                        onClick={() => handleNavigateToProperty(property.id)}
                                                        className="flex items-center gap-1 cursor-pointer group w-fit"
                                                    >
                                                        <span className="bg-lilac-200 text-lilac-800 px-2 py-1 rounded text-sm font-semibold">
                                                            {shortenString(property.name, MAX_TAG_CHARS)}
                                                        </span>
                                                        <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                                            <ArrowRightIcon className="h-5 w-5 text-lilac-800" />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div
                                                    className="col-span-4 text-slate-600 truncate"
                                                    >
                                                    <TextWithEllipsisAndTooltip text={property.description} maxChars={MAX_DESCRIPTION_CHARS} />
                                                </div>
                                                <div className="col-span-2 flex items-center">
                                                    {property.owner && <div className="h-4 w-4 rounded-full bg-lilac-200 flex items-center justify-center text-xs text-slate-600 mr-2">
                                                        <UserIcon className="h-3 w-3" />
                                                    </div>
                                                    }
                                                    <span className="text-slate-700">{property.owner}</span>
                                                </div>
                                                <div className="col-span-1 text-slate-700">{property.group}</div>
                                                <div className="col-span-1 text-slate-700">
                                                    {property.showInOverview ? "Visible" : "Hidden"}
                                                </div>
                                                <div className="col-span-1 flex justify-center">
                                                    <DropdownMenu 
                                                        position='left' 
                                                        items={getMenuOptions(property)} 
                                                        className="p-1 text-slate-400 hover:text-slate-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors mr-8" 
                                                        withStopPropogation
                                                    >
                                                        <span className="text-lilac-700 hover:text-lilac-300 cursor-pointer">
                                                            <EllipsisHorizontalIcon className="h-6 w-8" />
                                                        </span>
                                                    </DropdownMenu>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <Modal
                    isOpen={showDeleteModal}
                    onClose={closeDeleteModal}
                    title={`Delete ${propertyToDelete?.name || 'Property'}?`}
                    buttons={[
                        { 
                            text: "Cancel", 
                            onClick: closeDeleteModal, 
                            type: ButtonTypes.secondary 
                        },
                        { 
                            text: "Delete", 
                            onClick: handleDeleteProperty, 
                            type: ButtonTypes.danger,
                            isLoading: isDeleting
                        }
                    ]}
                >
                    <div className="text-text-primary">
                        Are you sure you want to delete this custom property? This action cannot be undone.
                    </div>
                </Modal>
            </div>
        </Layout>
    );
};

const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-full py-12 px-4">
        <TagIcon className="h-12 w-12 text-slate-300 mb-4" />
        <h3 className="text-lg font-medium text-slate-900 mb-1">No properties found</h3>
        <p className="text-sm text-slate-500">No properties match your current filters. Try adjusting your search or filters.</p>
    </div>
);

const DropdownButton = ({ children }: { children: React.ReactNode }) => (
    <span className="flex items-center justify-between w-32 h-8 text-slate-700 border border-slate-300 rounded-lg px-3 bg-white hover:bg-slate-100 transition-colors cursor-pointer">
        <span className="truncate">{children}</span>
        <ChevronUpDownIcon className="h-4 w-4 flex-shrink-0 ml-2" />
    </span>
);