/**
 * Enum for the different types of metered metrics
 */
export enum MeteredMetric {
  FIXED_PROPERTIES = 'fixed_properties',
  LIVE_PROPERTIES = 'live_properties',
  LLM_INPUT_TOKENS = 'llm_input_tokens',
  LLM_OUTPUT_TOKENS = 'llm_output_tokens',
  TOTAL_LLM_TOKENS = 'total_llm_tokens'
}

/**
 * Display names for the metered metrics
 */
export const METRIC_DISPLAY_NAMES: Record<MeteredMetric, string> = {
  [MeteredMetric.FIXED_PROPERTIES]: 'Fixed Properties',
  [MeteredMetric.LIVE_PROPERTIES]: 'Live Properties',
  [MeteredMetric.LLM_INPUT_TOKENS]: 'LLM Input Tokens',
  [MeteredMetric.LLM_OUTPUT_TOKENS]: 'LLM Output Tokens',
  [MeteredMetric.TOTAL_LLM_TOKENS]: 'Total LLM Tokens'
}; 