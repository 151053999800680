import { DASHBOARD_TYPES } from "src/features/models/discover/INode";
import { ResourceSidepaneTab } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTab";
import { ResourceSidepaneSelectorProps } from "src/features/models/discover/resourceSidepane/types";

export const ResourceSidepaneSchemaTabSelector = ({ resource, tabDefinition, setActiveTab, activeTab }: ResourceSidepaneSelectorProps) => {
    const isDashboard = DASHBOARD_TYPES.includes(resource.type);
    if (resource.subnodes.length === 0 || isDashboard) return null;
    return (
        <ResourceSidepaneTab tabDefinition={tabDefinition} setActiveTab={setActiveTab} activeTab={activeTab} />
    );
};