import { CONTAINER_TYPES, DASHBOARD_TYPES } from "src/features/models/discover/INode";
import { ResourceSidepaneTab } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTab";
import { ResourceSidepaneSelectorProps } from "src/features/models/discover/resourceSidepane/types";

export const ResourceSidepaneContentsTabSelector = ({ resource, tabDefinition, setActiveTab, activeTab }: ResourceSidepaneSelectorProps) => {
    const isContainer = CONTAINER_TYPES.includes(resource.type);
    const isDashboard = DASHBOARD_TYPES.includes(resource.type);
    if (!isContainer && !isDashboard) return null;
    return (
        <ResourceSidepaneTab tabDefinition={tabDefinition} setActiveTab={setActiveTab} activeTab={activeTab} />
    );
};