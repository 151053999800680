import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { useNavigate } from 'react-router-dom';
import { CustomProperty } from "src/services/customProperties/types";
import { extractErrorMessage } from "src/services/api";
import { notify } from "src/components/Toaster";
import { useCreateCustomPropertyMutation, useUpdateCustomPropertyMutation } from "src/services/customProperties/customProperties";
import { useAppSelector } from "src/infrastructure/state/hooks";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetUserQuery } from "src/services/users";

interface PropertyFormBottomBarProps {
    customProperty: CustomProperty;
    isEditMode?: boolean;
    isDisabled?: boolean;
}

export const PropertyFormBottomBar = ({ customProperty, isEditMode = false, isDisabled = false }: PropertyFormBottomBarProps) => {
    const navigate = useNavigate();
    const accountId = useAppSelector(selectActiveAccountId);
    const [createProperty, { isLoading: isCreating }] = useCreateCustomPropertyMutation();
    const [updateProperty, { isLoading: isUpdating }] = useUpdateCustomPropertyMutation();
    const { data: currentUser } = useGetUserQuery();

    const isLoading = isEditMode ? isUpdating : isCreating;

    const handleSave = async () => {
        if (isEditMode) {
            await updatePropertyHandler();
        } else {
            await createPropertyHandler();
        }
    };

    const createPropertyHandler = async () => {
        try {
            const propertyToSend = customProperty as Omit<CustomProperty, 'id' | 'last_updated_at' | 'last_updated_by' | 'created_by' | 'created_at' | 'last_calculation_timeout'>;
            propertyToSend.owner = currentUser?.email || 'Euno';
            await createProperty({
                accountId,
                property: propertyToSend
            }).unwrap();
            notify('Custom property created successfully', 'success');
            navigate('/properties');
        } catch (e) {
            notify(`Error creating property: ${extractErrorMessage(e).message}`, 'error');
        }
    };

    const updatePropertyHandler = async () => {
        try {
            const propertyToUpdate = customProperty as CustomProperty;
            
            await updateProperty({
                accountId,
                propertyId: customProperty.id,
                property: propertyToUpdate
            }).unwrap();
            notify('Custom property updated successfully', 'success');
            navigate('/properties');
        } catch (e) {
            notify(`Error updating property: ${extractErrorMessage(e).message}`, 'error');
        }
    };
    
    return (
        <div className="flex gap-4 bg-white px-8 py-3 border-t border-border sticky bottom-0 w-full">
            <Button
                type={ButtonTypes.secondary}
                text="Cancel"
                className="w-24"
                onClick={() => navigate('/properties')}
            />
            <Button
                type={ButtonTypes.primary}
                text="Save"
                className="ml-auto w-24"
                onClick={handleSave}
                isLoading={isLoading}
                isLoadingText="Saving..."
                isDisabled={isDisabled}
            />
        </div>
    );
};