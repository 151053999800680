import { Navigate } from 'react-router-dom';
import { store } from 'src/infrastructure/state/store';
import { accountsApi } from 'src/services/accounts/accounts';
import type { } from 'redux-thunk/extend-redux';

import Project from 'src/features/projects/Project';
import Account from 'src/features/account/Account';
import ConnectSource from 'src/features/sources/connectedSources/ConnectSource';
import Sources from 'src/features/sources/Sources';
import SourceDetails from 'src/features/sources/details/SourceDetails';
import activeAccountSlice from 'src/infrastructure/state/slices/activeAccountSlice';
import ManageProject from 'src/features/projects/manage/ManageProject';
import { Discover } from 'src/features/models/discover/Discover';
import Change from 'src/features/evolution/changePage/Change';
import { NodeLookup } from 'src/features/models/discover/NodeLookup';
import { ProjectsPage } from 'src/features/projects/ProjectsPage/ProjectsPage';
import { InternalTools } from 'src/features/internalTools/InternalTools';
import { ModelChanges } from 'src/features/evolution/changesPage/ModelChanges';
import { DashboardsPage } from 'src/features/dashboards/DashboardsPage';
import PrivateRoute from './PrivateRoute';
import { DataModelSyncPage } from 'src/features/dataModelSync/DataModelSyncPage';
import { EditSyncJobPage } from 'src/features/dataModelSync/syncJobs/EditSyncJobPage';
import { CreateSyncJobPage } from 'src/features/dataModelSync/syncJobs/CreateSyncJobPage';
import { PropertiesManagementPage } from 'src/features/properties/PropertiesManagementPage';
import {  CreatePropertyPage } from 'src/features/properties/CreatePropertyPage';
import { PropertyDetailsPage } from 'src/features/properties/PropertyDetailsPage';
import { HomePage } from 'src/features/home/Home';
import { AssistantPage } from 'src/features/assistant/AssistantPage';


const privateRoutes = () => {
  return [
    {
      element: <PrivateRoute />,
      loader: async () => {
        const { isSuccess, data } = await store.dispatch(accountsApi.endpoints.getAccounts.initiate());
        const activeAccount = store.getState().activeAccount;
        if (isSuccess && (!data || data.length === 0)) {
          return { redirect: '/user-not-found' };
        }
        if (!activeAccount && data) {
          await store.dispatch(activeAccountSlice.actions.setActiveAccount(data[0].id));
        }
        return null;
      },
      children: [
        { path: '/', element: <HomePage /> },
        { path: '/project/:projectId/team', element: <div className="p-4">Team</div> },
        { path: '/data-model', element: <Discover /> },
        { path: '/project/:projectId/manage', element: <ManageProject /> },
        { path: '/project/:projectId', element: <Project /> },
        { path: '/model-changes', element: <ModelChanges /> },
        { path: '/project/:projectId/evolution/change/:changeId', element: <Change /> },
        { path: '/account', element: <Account /> },
        { path: '/sources', element: <Sources /> },
        { path: '/data-model-sync', element: <DataModelSyncPage /> },
        { path: '/edit-sync-job/:id', element: <EditSyncJobPage /> },
        { path: '/create-sync-job', element: <CreateSyncJobPage /> },
        { path: '/sources/:id', element: <SourceDetails /> },
        { path: '/sources/connect/:type', element: <ConnectSource/> },
        { path: '/sources/connect/:type/:id', element: <ConnectSource/> },
        { path: '/node-lookup', element: <NodeLookup /> },
        { path: '/projects', element: <ProjectsPage /> },
        { path: '/internal-tools', element: <InternalTools /> },
        { path: '/dashboards', element: <DashboardsPage /> },
        { path: '/properties', element: <PropertiesManagementPage /> },
        { path: '/properties/create', element: <CreatePropertyPage /> },
        { path: '/properties/edit/:propertyId', element: <CreatePropertyPage /> },
        { path: '/properties/:id', element: <PropertyDetailsPage /> },
        { path: '/assistant', element: <AssistantPage /> },
        { path: '*', element: <Navigate to="/" /> }
      ]
    }
  ];
};

export default privateRoutes;
