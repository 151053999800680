import { useEffect, useMemo, useState } from "react";
import { useGetAccountOperationsQuery, useGetOperationRunReportQuery } from "src/services/operations";
import PageLoader from "src/components/loaders/PageLoader";
import Table from "src/components/Table/Table";
import { Operation, OperationStatus } from "src/features/operations/Operation";
import { dateFormats, utcToLocal } from "src/infrastructure/dateUtilities";
import OperationStatusLabel from "src/features/operations/OperationStatusLabel";
import { ButtonTypes } from "src/components/button/types";
import Button from "src/components/button/Button";
import { ExternalLinkIcon } from "src/assets/images/icons/DelphiIcons";
import { OperationModal } from "./OperationModal";
import { TableColumnProps } from "src/components/Table/types";
import { skipToken } from "@reduxjs/toolkit/query";
import { notify } from "src/components/Toaster";


const getTableColumns = (setLoadingOperation: (id: number) => void, loadingOperation: number | null): TableColumnProps<Operation>[] => {
  const tableColumns = [
    {
      name: 'ID',
      selector: (operation: Operation) => (
        <span className="text-text-primary">{operation.id}</span>
      ),
      width: '100px'
    },
    {
      name: 'Timestamp',
      selector: (operation: Operation) => (
        <span className="text-text-primary">{utcToLocal(operation.start_time, dateFormats.monthsDaysHoursAndMinutes)}</span>
      ),
    },
    {
      name: 'Run Trigger',
      selector: (operation: Operation) => (
        <span className="text-text-primary capitalize">{operation.trigger}</span>
      )
    },
    {
      name: 'Run Status',
      selector: (operation: Operation) => {
        const isLoading = loadingOperation === operation.id;
        return (
          <div className="flex items-center">
            <OperationStatusLabel status={operation.status} />
            <Button
              className='absolute right-3'
              type={ButtonTypes.primary}
              text={isLoading ? "Loading..." : "Open report"}
              icon={isLoading ? undefined : <ExternalLinkIcon width="16" height="16" />}
              onClick={() => setLoadingOperation(operation.id)}
              isDisabled={isLoading || operation.status === OperationStatus.Pending}
            />
          </div>
        );
      }
    }
  ];
  return tableColumns;
};


const SourceOperations = ({ accountId, integrationId }: { accountId: number, integrationId: string }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loadingOperation, setLoadingOperation] = useState<number | null>(null);
  const [openedOperation, setOpenedOperation] = useState<number | null>(null);
  const { data: runReport, isError, isFetching } = useGetOperationRunReportQuery(
    loadingOperation ? {accountId, operationId: loadingOperation} : skipToken, 
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (!loadingOperation) return;
    if (!isFetching) {
      if (isError) {
        setLoadingOperation(null);
        notify( 'Failed to fetch operation report','error');
      } else if (runReport) {
        setOpenedOperation(loadingOperation);
        setLoadingOperation(null);
      }
    }
  }, [loadingOperation, isFetching, isError, runReport]);

  const getAccountOperations = useGetAccountOperationsQuery({ accountId, page, pageSize, integrationId });
  const tableColumns = useMemo(() => getTableColumns(
    (operationId) => setLoadingOperation(operationId),
    loadingOperation
  ), [setLoadingOperation, loadingOperation]);
  const { total = 0, items: operations = [] } = getAccountOperations.data || {};
  useEffect(() => {
    const hasPendingOperation = operations.some((op) => ['pending', 'running'].includes(op.status));
    let timeout: number;
    if (hasPendingOperation) {
      timeout = setTimeout(() => getAccountOperations.refetch(), 5000);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [getAccountOperations, getAccountOperations.data, operations]);

  if (getAccountOperations.isLoading) {
    return (<PageLoader />);
  }
  return (
    <div className="px-10 py-8">
      <Table
        data={operations}
        columns={tableColumns}
        pagination={{ page, pageSize, total, setPage, setPageSize }}
        maxBodyHeight='65vh'
      />
      <OperationModal 
        isOpen={openedOperation !== null} 
        report={runReport || ''} 
        onClose={() => {
          setOpenedOperation(null);
          setLoadingOperation(null);
        }} 
      />
    </div>
  );
};

export default SourceOperations;
