import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useRef, memo } from "react";
import { useHoverDirty } from "react-use";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";
import { notify } from "src/components/Toaster";

export const CellWithTooltipAndCopy = memo(({ text, maxChars, isCopy = true }: { text: string, maxChars: number, isCopy?: boolean }) => {
    const ref = useRef<HTMLDivElement>(null);
    const isHovering = useHoverDirty(ref);
    const copyText = () => {
      navigator.clipboard.writeText(text);
      notify('Copied to clipboard', 'success');
    };
    return (
      <div className="flex gap-1 py-2 items-center" data-tag="allowRowEvents" ref={ref}>
        <TextWithEllipsisAndTooltip text={text} maxChars={maxChars} />
        {
          isHovering && isCopy ? (
            <div className="p-1 w-6 h-6 rounded border border-slate-200 bg-white hover:bg-slate-50 cursor-pointer text-slate-400" title="copy" onClick={copyText}>
              <DocumentDuplicateIcon width="14" height="14" />
            </div>
          ) : <div className="w-6"></div>
        }
      </div>
    );
});

CellWithTooltipAndCopy.displayName = 'CellWithTooltipAndCopy';