import { useState, useEffect } from 'react';
import { checkHealth } from 'src/services/langgraph/langgraphClient';

type ConnectionStatus = 'checking' | 'connected' | 'error';

/**
 * Custom hook to check the LangGraph connection status
 */
export const useLangGraphStatus = () => {
  const [status, setStatus] = useState<ConnectionStatus>('checking');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkConnection = async () => {
      try {
        // Try to make a simple request to the LangGraph API
        const result = await checkHealth();
        
        if (result.status === 'error') {
          let errorMessage = 'Unknown error connecting to LangGraph';
          
          if (result.error instanceof Error) {
            errorMessage = result.error.message;
          } else if (typeof result.error === 'string') {
            errorMessage = result.error;
          }
          
          console.error('LangGraph health check failed:', errorMessage);
          throw new Error(errorMessage);
        }
        
        setStatus('connected');
        setError(null);
      } catch (err) {
        setStatus('error');
        
        // Provide a more detailed error message
        let errorMessage = err instanceof Error ? err.message : 'Unknown error connecting to LangGraph';
        
        // Add additional context to common errors
        if (errorMessage.includes('Failed to fetch') || errorMessage.includes('NetworkError')) {
          errorMessage = `Network error: Could not connect to LangGraph server. Make sure the server is running and accessible.`;
        } else if (errorMessage.includes('CORS')) {
          errorMessage = `CORS error: The LangGraph server is not configured to allow cross-origin requests. Start the server with --cors-allow-origins "*" flag.`;
        }
        
        setError(errorMessage);
      }
    };

    checkConnection();
  }, []);

  return { status, error };
}; 