import { useMemo } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Cell,
    LabelList
} from 'recharts';
import { ChartDataItem } from "src/services/customProperties/types";
import { formatNumber, shortenString } from "src/utils/stringsUtils";

interface InsightsChartProps {
    data: ChartDataItem[];
}

const MAX_NAME_LENGTH = 15;

const nameLabelValues = {
    x: 10,
    fill: '#64748B',
    fontSize: '14',
    textAnchor: 'start',
    dominantBaseline: 'middle'
};

const valueLabelValues = {
    x: 16,
    fill: '#1E293B',
    fontSize: '16',
    fontWeight: '600',
    textAnchor: 'start',
    dominantBaseline: 'middle'
};

const incrementLabelValues = {
    x: 80,
    fill: '#7C3AED',
    fontSize: '14',
    fontWeight: '600',
    textAnchor: 'start',
    dominantBaseline: 'middle'
};
const LILAC_COLORS: Record<number, string> = {
    50: '#64748B',
    100: '#EDE9FE',
    200: '#DDD6FE',
    300: '#C4B5FD',
    400: '#A78BFA',
    500: '#8B5CF6',
    600: '#7C3AED',
    700: '#6D28D9',
    800: '#5B21B6',
    900: '#4C1D95',
    950: '#2E1065'
};

const COLOR_KEYS = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950];

interface ExtendedChartDataItem extends ChartDataItem {
    displayName: string;
    color: string;
    eql: string;
}

interface CustomLabelProps {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    value?: number | string;
    name?: string;
    displayName?: string;
    increment?: number;
    index?: number;
    offset?: number;
    viewBox?: { x: number; y: number; width: number; height: number };
    payload?: ChartDataItem[]
    data?: ChartDataItem[];
}

const goToDataModel = (value: string) => {
    const url = `/data-model?eql=${encodeURIComponent(value)}&filterMode=eql`;
    window.open(url, '_blank', 'noopener,noreferrer');
};

const NameLabel = (props: CustomLabelProps) => {
    const { y = 0, height = 0, displayName, name } = props;
    const displayText = displayName || name || '';

    return (
        <text
            x={nameLabelValues.x}
            y={y + (height / 2)}
            fill={nameLabelValues.fill}
            fontSize={nameLabelValues.fontSize}
            textAnchor={nameLabelValues.textAnchor}
            dominantBaseline={nameLabelValues.dominantBaseline}
        >
            {shortenString(displayText, MAX_NAME_LENGTH)}
        </text>
    );
};

const ValueLabel = (props: CustomLabelProps) => {
    const { x = 0, y = 0, width = 0, height = 0, value = 0 } = props;
    return (
        <text
            x={x + width + valueLabelValues.x}
            y={y + (height / 2)}
            fill={valueLabelValues.fill}
            fontSize={valueLabelValues.fontSize}
            fontWeight={valueLabelValues.fontWeight}
            textAnchor={valueLabelValues.textAnchor}
            dominantBaseline={valueLabelValues.dominantBaseline}
        >
            {formatNumber(value)}
        </text>
    );
};

const IncrementLabel = (props: CustomLabelProps) => {
    const { x = 0, y = 0, width = 0, height = 0, increment } = props;
    if (!increment) return null;

    return (
        <text
            x={x + width + incrementLabelValues.x}
            y={y + (height / 2)}
            fill={incrementLabelValues.fill}
            fontSize={incrementLabelValues.fontSize}
            fontWeight={incrementLabelValues.fontWeight}
            textAnchor={incrementLabelValues.textAnchor}
            dominantBaseline={incrementLabelValues.dominantBaseline}
        >
            +{increment}
        </text>
    );
};

export const InsightsChart = ({ data }: InsightsChartProps) => {
    const totalValue = useMemo(() =>
        data.reduce((sum, item) => sum + item.value, 0),
        [data]
    );

    const dataWithColors = useMemo<ExtendedChartDataItem[]>(() => {
        return data.sort((a, b) => b.value - a.value).map((item, index) => {
            const colorKey = COLOR_KEYS[index % COLOR_KEYS.length];
            return {
                ...item,
                displayName: shortenString(item.name, MAX_NAME_LENGTH),
                color: LILAC_COLORS[colorKey],
                eql: item.eql || ''
            };
        });
    }, [data]);
    return (
        <div className="w-full max-w-4xl mx-auto bg-white rounded-2xl p-6 shadow-sm border border-gray-100 max-h-96 overflow-y-auto">
            <div className="mb-4">
                <h2 className="text-4xl font-semibold text-gray-800">
                    {formatNumber(totalValue)}
                </h2>
                <p className="text-xl text-gray-500 mt-1">Total resources in scope</p>
            </div>

            <div style={{ width: '100%', height: Math.max(50 * dataWithColors.length, 120) }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={dataWithColors}
                        layout="vertical"
                        margin={{ top: 5, right: 150, bottom: 5, left: 150 }}
                        barGap={8}
                    >
                        <XAxis
                            type="number"
                            hide
                        />
                        <YAxis
                            type="category"
                            dataKey="displayName"
                            hide
                        />
                        <Bar
                            dataKey="value"
                            radius={4}
                            animationDuration={800}
                            barSize={30}
                            name="name"
                        >
                            {dataWithColors.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} onClick={() => goToDataModel(entry.eql)} className={`cursor-pointer`} />
                            ))}
                            <LabelList content={<NameLabel data={data} />} dataKey="displayName" position="insideLeft" />
                            <LabelList content={<ValueLabel />} />
                            <LabelList content={<IncrementLabel />} dataKey="increment" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export const InsightsLiveBooleanChart = ({ data }: InsightsChartProps) => {
    const totalValue = useMemo(() =>
        data.reduce((sum, item) => sum + item.value, 0),
        [data]
    );

    const falseValue = data[0]?.value || 0;
    const trueValue = data[1]?.value || 0;

    const chartData = [
        {
            false: falseValue,
            true: trueValue
        }
    ];

    return (
        <div className="w-full max-w-4xl mx-auto bg-white rounded-2xl p-6 shadow-sm border border-gray-100 max-h-96 overflow-y-auto">
            <div className="mb-4">
                <h2 className="text-4xl font-semibold text-gray-800">
                    {formatNumber(totalValue)}
                </h2>
                <p className="text-xl text-gray-500 mt-1">Total resources in scope</p>
            </div>

            <div style={{ width: '100%', height: 120 }} className="relative">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={chartData}
                        layout="vertical"
                        margin={{
                            top: 25,
                            right: 30,
                            left: 30,
                            bottom: 5,
                        }}
                        barSize={40}
                    >
                        <XAxis type="number" hide />
                        <YAxis type="category" dataKey="displayName" hide />
                        <Bar
                            dataKey="true"
                            stackId="a"
                            fill={LILAC_COLORS[200]}
                        >
                            <LabelList
                                position="center"
                                content={({ x = 0, y = 0, width = 0 }) => (
                                    <g>
                                        <text
                                            x={Number(x) + (Number(width) / 2)}
                                            y={Number(y) - 10}
                                            textAnchor="middle"
                                            fill="#64748B"
                                            fontSize="12"
                                            fontWeight="500"
                                            className="cursor-pointer hover:text-lilac-600 hover:underline"
                                            onClick={() => goToDataModel(String(data[1].eql))}
                                        >
                                            True

                                        </text>
                                        <text
                                            x={Number(x) + (Number(width) / 2)}
                                            y={Number(y) + 25}
                                            textAnchor="middle"
                                            fill="#1E293B"
                                            fontSize="14"
                                            fontWeight="600"
                                        >
                                            {formatNumber(trueValue)}
                                        </text>
                                    </g>
                                )}
                            />
                        </Bar>
                        <Bar
                            dataKey="false"
                            stackId="a"
                            fill={LILAC_COLORS[800]}
                        >
                            <LabelList
                                position="center"
                                content={({ x = 0, y = 0, width = 0 }) => (
                                    <g>
                                        <text
                                            x={Number(x) + (Number(width) / 2)}
                                            y={Number(y) - 10}
                                            textAnchor="middle"
                                            fill="#64748B"
                                            fontSize="12"
                                            fontWeight="500"
                                            className="cursor-pointer hover:text-lilac-600 hover:underline"
                                            onClick={() => goToDataModel(String(data[0].eql))}
                                        >
                                            False
                                        </text>
                                        <text
                                            x={Number(x) + (Number(width) / 2)}
                                            y={Number(y) + 25}
                                            textAnchor="middle"
                                            fill="#FFFFFF"
                                            fontSize="14"
                                            fontWeight="600"
                                        >
                                            {formatNumber(falseValue)}
                                        </text>
                                    </g>
                                )}
                            />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};
export const InsightsFixedChart = ({ data }: InsightsChartProps) => {
    const totalValue = useMemo(() =>
        data.slice(1).reduce((sum, item) => sum + item.value, 0),
        [data]
    );

    const dataWithColors = useMemo<ExtendedChartDataItem[]>(() => {
        return data.slice(1).sort((a, b) => b.value - a.value).map((item, index) => {
            const colorKey = COLOR_KEYS[index + 1 % COLOR_KEYS.length];
            return {
                ...item,
                displayName: shortenString(item.name, MAX_NAME_LENGTH),
                color: LILAC_COLORS[colorKey],
                eql: item.eql || ''
            };
        });
    }, [data]);
    return (
        <div className="w-full max-w-4xl mx-auto bg-white rounded-2xl p-6 shadow-sm border border-gray-100 max-h-96 overflow-y-auto">
            <div className="mb-4">
                <h2 className="text-4xl font-semibold text-gray-800">
                    {formatNumber(totalValue)} <span className="text-gray-500">/</span> {formatNumber(data[0].value)}
                </h2>
                <p className="text-xl text-gray-500 mt-1">Set resources in scope</p>
            </div>

            <div style={{ width: '100%', height: Math.max(50 * dataWithColors.length, 120) }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={dataWithColors}
                        layout="vertical"
                        margin={{ top: 5, right: 150, bottom: 5, left: 150 }}
                        barGap={8}
                    >
                        <XAxis
                            type="number"
                            hide
                        />
                        <YAxis
                            type="category"
                            dataKey="displayName"
                            hide
                        />
                        <Bar
                            dataKey="value"
                            radius={4}
                            animationDuration={800}
                            barSize={30}
                            name="name"
                        >
                            {dataWithColors.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} onClick={() => goToDataModel(entry.eql)} className={`cursor-pointer`} />
                            ))}
                            <LabelList content={<NameLabel />} dataKey="displayName" position="insideLeft" />
                            <LabelList content={<ValueLabel />} />
                            <LabelList content={<IncrementLabel />} dataKey="increment" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};