import { useState } from "react";
import Input from "src/components/form/Input";
import { ExternalProperty } from "src/services/eql/types";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetAllAccountPropertiesQuery } from "src/services/eql/eql";

type PropertySelectorProps = {
    selectedProperties: string[];
    onAddProperty: (property: string) => void;
    onRemoveProperty: (property: string) => void;
    filterPredicate?: (property: ExternalProperty) => boolean;
    includeNonIndexed?: boolean;
};

const PropertySelector = ({ 
    selectedProperties, 
    onAddProperty, 
    onRemoveProperty, 
    filterPredicate = () => true,
    includeNonIndexed = true
}: PropertySelectorProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const [searchTerm, setSearchTerm] = useState("");
    
    // Fetch all properties
    const { data: allProperties, isLoading } = useGetAllAccountPropertiesQuery({ 
        accountId, 
        includeNonIndexedProperties: includeNonIndexed
    });
    
    // Filter properties based on search term and custom filter predicate
    const filteredProperties = allProperties?.filter(prop => 
        prop.name.toLowerCase().includes(searchTerm.toLowerCase()) && 
        !selectedProperties.includes(prop.name) &&
        filterPredicate(prop)
    ) || [];

    return (
        <div className="flex flex-col gap-4">
            <div className="text-sm font-medium text-slate-700">Properties to Include</div>
            <div className="flex items-center gap-2">
                <Input
                    value={searchTerm}
                    onInputChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search for properties"
                    className="flex-1"
                />
            </div>
            
            {/* Property search results */}
            {searchTerm && (
                <div className="max-h-60 overflow-y-auto border border-slate-200 rounded-md mt-2">
                    {(() => {
                        if (isLoading) {
                            return <div className="p-3 text-slate-500">Loading properties...</div>;
                        }
                        
                        if (filteredProperties.length === 0) {
                            return <div className="p-3 text-slate-500">No matching properties found</div>;
                        }
                        
                        return (
                            <div className="divide-y divide-slate-200">
                                {filteredProperties.map((property) => (
                                    <div 
                                        key={property.name} 
                                        className="p-2 hover:bg-slate-50 cursor-pointer flex justify-between items-center"
                                        onClick={() => onAddProperty(property.name)}
                                    >
                                        <div>
                                            <div className="font-medium">{property.name}</div>
                                            {property.description && (
                                                <div className="text-xs text-slate-500">{property.description}</div>
                                            )}
                                        </div>
                                        <div className="text-xs px-2 py-1 rounded-full bg-slate-100">
                                            {property.type}
                                            {!property.is_indexed && <span className="ml-1 text-amber-600">(non-indexed)</span>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        );
                    })()}
                </div>
            )}
            
            {/* Selected properties */}
            <div className="flex flex-wrap gap-2 mt-2">
                {selectedProperties.map((property, index) => (
                    <div key={index} className="flex items-center gap-1 bg-slate-100 px-3 py-1 rounded-full">
                        <span>{property}</span>
                        <button
                            onClick={() => onRemoveProperty(property)}
                            className="text-slate-500 hover:text-slate-700"
                        >
                            ×
                        </button>
                    </div>
                ))}
                {selectedProperties.length === 0 && (
                    <div className="text-slate-500 text-sm">No properties added yet</div>
                )}
            </div>
        </div>
    );
};

export default PropertySelector; 