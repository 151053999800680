import api from '../api';
import { FolderResponse } from '../folders';
import { IDbtCloudJob } from '../../features/projects/IProject';
import { LuzmoEmbedCredentials } from 'src/features/dashboards/types';
import { TableView } from 'src/services/accounts/types';

export interface AccountResponse {
  id: number;
  name: string;
  ancestry: FolderResponse[];
}

export type EQLError = {
    error: string;
    line: number;
    column: number;
    allowed: string[];
};

export type CheckEQLResponse = {
    status: string;
    pretty: string;
};

export const accountsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query<AccountResponse[], void>({
      query: () => 'accounts',
      providesTags: ['Accounts']
    }),
    getDbtCloudJobs: build.query<IDbtCloudJob[], { accountId: number; projectId: number }>({
      query: ({ accountId, projectId }) => `accounts/${accountId}/integrations/dbt_cloud/jobs?project_id=${projectId}`,
      keepUnusedDataFor: 0
    }),

    getLiquidConsts: build.query<object, { accountId: number, lookerIntegrationId: number }>({
      query: ({ accountId, lookerIntegrationId }) => `accounts/${accountId}/integrations/looker/${lookerIntegrationId}/liquid_consts`,
    }),
    updateLiquidConsts: build.mutation<void, { accountId: number, lookerIntegrationId: number, liquidConsts: object }>({
      query: ({ accountId, lookerIntegrationId, liquidConsts }) => ({
        url: `accounts/${accountId}/integrations/looker/${lookerIntegrationId}/liquid_consts`,
        method: 'POST',
        body: liquidConsts
      })
    }),
    getSavedTableViews: build.query<TableView[], { accountId: number, tableKey: string }>({
      query: ({ accountId, tableKey }) => `accounts/${accountId}/table_views/${tableKey}`,
      providesTags: ['SavedTableViews']
    }),
    createSavedTableView: build.mutation<TableView, { accountId: number, tableKey: string, name: string, config: string }>({
      query: ({ accountId, tableKey, name, config }) => ({
        url: `accounts/${accountId}/table_views/${tableKey}`,
        method: 'POST',
        body: { name, config }
      }),
      invalidatesTags: ['SavedTableViews']
    }),
    updateSavedTableView: build.mutation<void, { accountId: number, tableKey: string, name?: string, config?: string, viewId: number }>({
      query: ({ accountId, tableKey, name, config, viewId }) => ({
        url: `accounts/${accountId}/table_views/${tableKey}/${viewId}`,
        method: 'PATCH',
        body: { name, config }
      }),
      invalidatesTags: ['SavedTableViews']
    }),
    deleteSavedTableView: build.mutation<void, { accountId: number, tableKey: string, viewId: number }>({
      query: ({ accountId, tableKey, viewId }) => ({
        url: `accounts/${accountId}/table_views/${tableKey}/${viewId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['SavedTableViews']
    }),
    getLuzmoEmbedCredentials: build.query<LuzmoEmbedCredentials, { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/luzmo/embed_credentials`
    }),
    getLuzmoColumns: build.query<{ name: string, id: string }[], { accountId: number, columnIds: string[] }>({
      query: ({ accountId, columnIds }) => `accounts/${accountId}/luzmo/columns?ids=${columnIds.join(',')}`
    }),
    getLuzmoDashboard: build.query<unknown, { accountId: number, dashboardId: string }>({
      query: ({ accountId, dashboardId }) => `accounts/${accountId}/luzmo/dashboard/${dashboardId}`
    }),
  })
});

export const {
  useGetAccountsQuery,
  useGetDbtCloudJobsQuery,
  useGetLiquidConstsQuery,
  useUpdateLiquidConstsMutation,
  useGetSavedTableViewsQuery,
  useCreateSavedTableViewMutation,
  useUpdateSavedTableViewMutation,
  useGetLuzmoEmbedCredentialsQuery,
  useLazyGetLuzmoColumnsQuery,
  useGetLuzmoDashboardQuery,
  useDeleteSavedTableViewMutation,
} = accountsApi;
