const NUMBER_OF_OCTETS_IN_TRACE_ID = 32;

export const generateRandomHexString = (length = NUMBER_OF_OCTETS_IN_TRACE_ID) => {
    const array = new Uint8Array(length / 2);
    crypto.getRandomValues(array);
    return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
};

export const shortenString = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
        return str;
    }
    return str.slice(0, maxLength) + '...';
};

export const validateEmail = (email: string) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
};

export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getFilenameFromPath = (path: string): string => {
    return path.split('/').pop() || '';
};

export const replaceAll = (str: string, search: string, replacement: string) => {
    return str.split(search).join(replacement);
};

export const formatNumber = (value: number | string): string => {
    if (value === null || value === undefined || value === '') {
        return '';
    }

    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(numValue)) {
        return '';
    }

    const absValue = Math.abs(numValue);

    if (absValue < 1000000) {
        return numValue.toLocaleString('en-US');
    } else if (absValue < 1000000000) {
        return (numValue / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (absValue < 1000000000000) {
        return (numValue / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    } else {
        return (numValue / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
    }
};