import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";
import { getPropertyClass } from "src/services/customProperties/transformers";
import { 
    CustomProperty, 
    CustomPropertyClass, 
    CustomPropertyFEType, 
    CustomPropertyContent,
    PROPERTY_VALUE_INFO, 
    PROPERTY_CLASS_INFO 
} from "src/services/customProperties/types";

interface PropertyTypeSelectProps {
    customProperty: CustomProperty;
    setCustomProperty: React.Dispatch<React.SetStateAction<CustomProperty>>;
    disabled?: boolean;
}

export const PropertyTypeSelect = ({ customProperty, setCustomProperty, disabled }: PropertyTypeSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const propertyTypeOptions = useMemo(() => [
        {
            id: CustomPropertyClass.Live,
            title: PROPERTY_CLASS_INFO[CustomPropertyClass.Live].label,
            description: PROPERTY_CLASS_INFO[CustomPropertyClass.Live].description,
            options: [
                CustomPropertyFEType.CategoryAssigned,
                CustomPropertyFEType.LiveBoolean
            ].map(type => ({
                id: type,
                label: PROPERTY_VALUE_INFO[type].label,
                description: PROPERTY_VALUE_INFO[type].description
            }))
        },
        {
            id: CustomPropertyClass.Fixed,
            title: PROPERTY_CLASS_INFO[CustomPropertyClass.Fixed].label,
            description: PROPERTY_CLASS_INFO[CustomPropertyClass.Fixed].description,
            options: [
                CustomPropertyFEType.SingleSelect,
                CustomPropertyFEType.MultiSelect,
                CustomPropertyFEType.Datetime,
                CustomPropertyFEType.FixedBoolean,
                CustomPropertyFEType.Numeric
            ].map(type => ({
                id: type,
                label: PROPERTY_VALUE_INFO[type].label,
                description: PROPERTY_VALUE_INFO[type].description
            }))
        }
    ], []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (type: CustomPropertyFEType) => {
        if (disabled) return;
        const newContent: CustomPropertyContent = (() => {
            switch (type) {
                case CustomPropertyFEType.SingleSelect:
                    return {
                        type: CustomPropertyFEType.SingleSelect,
                        content: { options: [] }
                    };
                case CustomPropertyFEType.MultiSelect:
                    return {
                        type: CustomPropertyFEType.MultiSelect,
                        content: { options: [''] }
                    };
                case CustomPropertyFEType.LiveBoolean:
                    return {
                        type: CustomPropertyFEType.LiveBoolean,
                        content: { scope: 'true', eql: '', options: [] }
                    };
                case CustomPropertyFEType.CategoryAssigned:
                    return {
                        type: CustomPropertyFEType.CategoryAssigned,
                        content: { options: [] }
                    };
                case CustomPropertyFEType.Datetime:
                    return {
                        type: CustomPropertyFEType.Datetime,
                        content: {} as Record<string, never>
                    };
                case CustomPropertyFEType.FixedBoolean:
                    return {
                        type: CustomPropertyFEType.FixedBoolean,
                        content: {} as Record<string, never>
                    };
                case CustomPropertyFEType.Numeric:
                    return {
                        type: CustomPropertyFEType.Numeric,
                        content: {} as Record<string, never>
                    };
                default:
                    return {
                        type: CustomPropertyFEType.SingleSelect,
                        content: { options: [] as string[] }
                    };
            }
        })();
        
        setCustomProperty((prevState: CustomProperty) => {
            const newState = {
                ...prevState,
                class: getPropertyClass(type),
                content: newContent
            };
            return newState;
        });
        
        setIsOpen(false);
    };

    const getDisplayValue = () => {
        return PROPERTY_VALUE_INFO[customProperty.content.type]?.label || "Select property type";
    };

    return (
        <div className="w-full max-w-[600px]">
            <div className="relative">
                <button
                    onClick={toggleDropdown}
                    className="flex w-full items-center justify-between rounded-md border border-gray-300 bg-white px-4 py-2 text-left text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                    aria-haspopup="listbox"
                    aria-expanded={isOpen}
                    disabled={disabled}
                >
                    <span>{getDisplayValue()}</span>
                    <ChevronDownIcon className={`h-4 w-4 transition-transform ${isOpen ? "rotate-180" : ""} ${disabled ? "text-gray-400" : ""}`} />
                </button>
                {isOpen && (
                    <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                        <div className="h-md:max-h-60 h-lg:max-h-[30rem] overflow-y-auto">
                            {propertyTypeOptions.map((category) => (
                                <div key={category.id}>
                                    <div className="border-b border-gray-100 bg-gray-50 px-4 py-3">
                                        <h3 className="text-sm font-medium text-gray-700">{category.title}</h3>
                                        <p className="text-xs text-gray-500 break-words">{category.description}</p>
                                    </div>
                                    <div className="py-1">
                                        {category.options.map((option) => (
                                            <button
                                                key={option.id}
                                                onClick={() => selectOption(option.id)}
                                                className="flex w-full flex-col px-4 py-2 ml-4 text-left hover:bg-lilac-100"
                                                role="option"
                                            >
                                                <span className="text-sm text-gray-700">{option.label}</span>
                                                <span className="text-xs text-gray-500 break-words">{option.description}</span>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
