export type Filter = {
    name: string;
    menuIcon: JSX.Element;
    filterIcon: JSX.Element;
    isDisabled?: boolean;
    value: FilterValue;
    presentValue: (value: FilterValue) => string;
    isActive: (value: FilterValue) => boolean;
    component?: ({ value, onChange }: FilterComponentProps) => JSX.Element;
}

export type FilterComponentProps = {
    value: FilterValue;
    onChange: (value: FilterValue) => void;
}

export type FilterFacets = { [param: string]: FilterValue[] };
export type FilterValue = string | null;
export type SelectFilterOption = { label: string, value: string, icon?: JSX.Element };
export type FilterValues = Record<string, FilterValue>;

export enum FilterSearchEnum {
    IN_MEMORY = 'inMemory',
    CUSTOM = 'custom'
}