import { useCallback, useEffect, useMemo } from 'react';
import Layout from '../../../components/layout/Layout';
import { ModelChangesTab } from './Types';
import { ModelChangesList } from './ModelChangesList';
import { PatsList } from './PatsList';
import { useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const ModelChanges = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = useMemo(() => searchParams.get('tab') as ModelChangesTab, [searchParams]);
  const { legacyProject } = useFlags();

  const setTab = useCallback((tab: ModelChangesTab) => {
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (!tab) {
      setTab(legacyProject ? ModelChangesTab.Proposals : ModelChangesTab.InProgress);
  }
  }, [tab, setTab,legacyProject]);

  return (
    <Layout>
      <div className="py-8 px-14">
        {
          tab === ModelChangesTab.Proposals && (
            <PatsList />
          )
        }
        {
          tab !== ModelChangesTab.Proposals && (
            <ModelChangesList tab={tab} />
          )
        }
      </div>
    </Layout>
  );

};