import { IExpandedNode } from "src/features/models/discover/INode";
import { ResourceSidepaneContentsTabSelector } from "src/features/models/discover/resourceSidepane/contentsTab/ResourceSidepaneContentsTabSelector";
import { ResourceSidepaneRelationshipsTabSelector } from "src/features/models/discover/resourceSidepane/relationshipsTab/ResourceSidepaneRelationshipsTabSelector";
import { ResourceSidepaneTab } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTab";
import { ResourceSidepaneSchemaTabSelector } from "src/features/models/discover/resourceSidepane/schemaTab/ResourceSidepaneSchemaTabSelector";
import { ResourceSidepaneUsageTabSelector } from "src/features/models/discover/resourceSidepane/usageTab/ResourceSidepaneUsageTabSelector";
import { ResourceSidepaneUsedFieldsTabSelector } from "src/features/models/discover/resourceSidepane/usedFieldsTab/ResourceSidepaneUsedFieldsTabSelector";
import { BuiltInResourceTab, SidePaneTabDefinition } from "src/features/models/discover/resourceSidepane/types";
import { useThreads } from '@liveblocks/react';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { useFlags } from "launchdarkly-react-client-sdk";

export const ResourceSidepaneTabs = ({ 
    allTabs,
    activeTab, 
    setActiveTab, 
    resource 
}: {
    allTabs: SidePaneTabDefinition[];
    activeTab: string;
    setActiveTab: (tab: string) => void;
    resource: IExpandedNode;
}) => {
    return (
        <div className="border-l border-border p-4 flex flex-col gap-6">
            {
                allTabs.map((tabDefinition) => <TabMap resource={resource} key={tabDefinition.name} tabDefinition={tabDefinition} activeTab={activeTab} setActiveTab={setActiveTab} />)
            }
        </div>
    );
};

type TabMapProps = {
    tabDefinition: SidePaneTabDefinition;
    activeTab: string;
    setActiveTab: (tab: string) => void;
    resource: IExpandedNode;
}

const TabMap = ({ tabDefinition, activeTab, setActiveTab, resource }: TabMapProps) => {
    const { showDiscussionTab } = useFlags();
    if (tabDefinition.isBuiltIn) {
        switch (tabDefinition.name) {
            case BuiltInResourceTab.Relationships:
                return <ResourceSidepaneRelationshipsTabSelector resource={resource} tabDefinition={tabDefinition} activeTab={activeTab} setActiveTab={setActiveTab} />;
            case BuiltInResourceTab.Schema:
                return <ResourceSidepaneSchemaTabSelector resource={resource} tabDefinition={tabDefinition} activeTab={activeTab} setActiveTab={setActiveTab} />;
            case BuiltInResourceTab.Contents:
                return <ResourceSidepaneContentsTabSelector resource={resource} tabDefinition={tabDefinition} activeTab={activeTab} setActiveTab={setActiveTab} />;
            case BuiltInResourceTab.Usage:
                return <ResourceSidepaneUsageTabSelector resource={resource} tabDefinition={tabDefinition} activeTab={activeTab} setActiveTab={setActiveTab} />;
            case BuiltInResourceTab.UsedFields:
                return <ResourceSidepaneUsedFieldsTabSelector resource={resource} tabDefinition={tabDefinition} activeTab={activeTab} setActiveTab={setActiveTab} />;
            case BuiltInResourceTab.Discussion:
                if (showDiscussionTab) {
                    return (
                        <ResourceSidepaneTab 
                            tabDefinition={{
                            ...tabDefinition,
                            icon: <DiscussionTabIcon resource={resource} />
                        }} 
                        activeTab={activeTab} 
                        setActiveTab={setActiveTab} 
                        />
                    );
                } else {
                    return null;
                }
            default:
                return <ResourceSidepaneTab tabDefinition={tabDefinition} activeTab={activeTab} setActiveTab={setActiveTab} />;
        }
    } else {
        return <ResourceSidepaneTab tabDefinition={tabDefinition} activeTab={activeTab} setActiveTab={setActiveTab} />;
    }
};

function DiscussionTabIcon({ resource }: { resource: IExpandedNode }) {
    const { threads = [] } = useThreads({
        query: {
            metadata: {
                resourceId: resource.id
            }
        }
    });
    const unresolvedCount = threads.filter(thread => !thread.resolved).length;

    return (
        <div className="relative">
            <ChatBubbleLeftRightIcon width="16" height="16" className="mx-auto" />
            {unresolvedCount > 0 && (
                <div className="absolute -top-2 -right-2 bg-primary text-white rounded-full w-4 h-4 flex items-center justify-center text-[10px] font-medium">
                    {unresolvedCount}
                </div>
            )}
        </div>
    );
}