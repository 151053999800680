import { useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
import { invokeText2Eql } from 'src/services/langgraph/langgraphClient';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { useSelector } from 'react-redux';
import { delay } from 'src/utils/timeUtils';
import { ReactComponent as TextLoader } from './text-loader.svg';

interface UseEQLInputProps {
  onSearch?: (query: string) => void;
}

interface EQLInputState {
  inputValue: string;
  sparklesActive: boolean;
  textVisible: boolean;
  isLoading: boolean;
  textOpacity: number;
  isSparkleAnimating: boolean;
  showEqlReturnHint: boolean;
  exitingSearchHint: boolean;
  exitingReturnHint: boolean;
  sparkleInputHistory: string;
  regularInputHistory: string;
  isSparkleIdle: boolean;
}

type EQLInputAction =
  | { type: 'SET_INPUT_VALUE'; payload: string }
  | { type: 'SET_SPARKLES_ACTIVE'; payload: boolean }
  | { type: 'SET_TEXT_VISIBLE'; payload: boolean }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_TEXT_OPACITY'; payload: number }
  | { type: 'SET_SPARKLE_ANIMATING'; payload: boolean }
  | { type: 'SET_SHOW_EQL_RETURN_HINT'; payload: boolean }
  | { type: 'SET_EXITING_SEARCH_HINT'; payload: boolean }
  | { type: 'SET_EXITING_RETURN_HINT'; payload: boolean }
  | { type: 'SET_SPARKLE_INPUT_HISTORY'; payload: string }
  | { type: 'SET_REGULAR_INPUT_HISTORY'; payload: string }
  | { type: 'SET_SPARKLE_IDLE'; payload: boolean }
  | { type: 'TOGGLE_SPARKLES' }
  | { type: 'RESET_LOADING_STATE' }
  | { type: 'UPDATE_INPUT_HISTORY' };

const initialState: EQLInputState = {
  inputValue: '',
  sparklesActive: false,
  textVisible: true,
  isLoading: false,
  textOpacity: 1,
  isSparkleAnimating: false,
  showEqlReturnHint: false,
  exitingSearchHint: false,
  exitingReturnHint: false,
  sparkleInputHistory: '',
  regularInputHistory: '',
  isSparkleIdle: false
};

function eqlInputReducer(state: EQLInputState, action: EQLInputAction): EQLInputState {
  switch (action.type) {
    case 'SET_INPUT_VALUE':
      return { ...state, inputValue: action.payload };
    case 'SET_SPARKLES_ACTIVE':
      return { ...state, sparklesActive: action.payload };
    case 'SET_TEXT_VISIBLE':
      return { ...state, textVisible: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_TEXT_OPACITY':
      return { ...state, textOpacity: action.payload };
    case 'SET_SPARKLE_ANIMATING':
      return { ...state, isSparkleAnimating: action.payload };
    case 'SET_SHOW_EQL_RETURN_HINT':
      return { ...state, showEqlReturnHint: action.payload };
    case 'SET_EXITING_SEARCH_HINT':
      return { ...state, exitingSearchHint: action.payload };
    case 'SET_EXITING_RETURN_HINT':
      return { ...state, exitingReturnHint: action.payload };
    case 'SET_SPARKLE_INPUT_HISTORY':
      return { ...state, sparkleInputHistory: action.payload };
    case 'SET_REGULAR_INPUT_HISTORY':
      return { ...state, regularInputHistory: action.payload };
    case 'SET_SPARKLE_IDLE':
      return { ...state, isSparkleIdle: action.payload };
    case 'TOGGLE_SPARKLES':
      return state.isLoading 
        ? state 
        : { 
            ...state, 
            isSparkleIdle: false,
            sparkleInputHistory: state.sparklesActive ? state.inputValue : state.sparkleInputHistory,
            regularInputHistory: !state.sparklesActive ? state.inputValue : state.regularInputHistory
          };
    case 'UPDATE_INPUT_HISTORY':
      return {
        ...state,
        sparkleInputHistory: state.sparklesActive ? state.inputValue : state.sparkleInputHistory,
        regularInputHistory: !state.sparklesActive ? state.inputValue : state.regularInputHistory
      };
    case 'RESET_LOADING_STATE':
      return {
        ...state,
        isLoading: false,
        textVisible: true,
        isSparkleAnimating: false
      };
    default:
      return state;
  }
}

export const useEQLInput = ({ onSearch }: UseEQLInputProps) => {
  const accountId = useSelector(selectActiveAccountId);
  const [state, dispatch] = useReducer(eqlInputReducer, initialState);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    inputValue,
    sparklesActive,
    textVisible,
    isLoading,
    textOpacity,
    isSparkleAnimating,
    showEqlReturnHint,
    exitingSearchHint,
    exitingReturnHint,
    sparkleInputHistory,
    regularInputHistory,
    isSparkleIdle
  } = state;

  useEffect(() => {
    let hintTimeout: number | undefined;

    if (sparklesActive && inputValue.trim() === '' && !isLoading) {
      dispatch({ type: 'SET_EXITING_RETURN_HINT', payload: false });
      hintTimeout = window.setTimeout(() => {
        dispatch({ type: 'SET_SHOW_EQL_RETURN_HINT', payload: true });
      }, 3000);
    } else if (showEqlReturnHint) {
      dispatch({ type: 'SET_EXITING_RETURN_HINT', payload: true });
      hintTimeout = window.setTimeout(() => {
        dispatch({ type: 'SET_SHOW_EQL_RETURN_HINT', payload: false });
        dispatch({ type: 'SET_EXITING_RETURN_HINT', payload: false });
      }, 300);
    }

    return () => {
      if (hintTimeout) window.clearTimeout(hintTimeout);
    };
  }, [sparklesActive, inputValue, isLoading, showEqlReturnHint]);

  useEffect(() => {
    if (inputValue.trim() === '') {
      dispatch({ type: 'SET_EXITING_SEARCH_HINT', payload: false });
    }
  }, [inputValue]);

  useEffect(() => {
    let idleTimeout: number | undefined;
    if (sparklesActive && inputValue.trim() === '' && !isLoading) {
      idleTimeout = window.setTimeout(() => {
        dispatch({ type: 'SET_SPARKLE_IDLE', payload: true });
      }, 1000);
    } else {
      dispatch({ type: 'SET_SPARKLE_IDLE', payload: false });
    }

    return () => {
      if (idleTimeout) window.clearTimeout(idleTimeout);
    };
  }, [sparklesActive, inputValue, isLoading]);

  // Memoized handlers
  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    dispatch({ type: 'SET_SPARKLE_IDLE', payload: false });

    if (inputValue.trim() !== '' && newValue.trim() === '') {
      dispatch({ type: 'SET_EXITING_SEARCH_HINT', payload: true });
      setTimeout(() => {
        dispatch({ type: 'SET_INPUT_VALUE', payload: newValue });
        dispatch({ type: 'SET_EXITING_SEARCH_HINT', payload: false });
      }, 300);
    } else {
      dispatch({ type: 'SET_INPUT_VALUE', payload: newValue });
    }

    if (sparklesActive) {
      dispatch({ type: 'SET_SPARKLE_INPUT_HISTORY', payload: newValue });
    } else {
      dispatch({ type: 'SET_REGULAR_INPUT_HISTORY', payload: newValue });
    }
  }, [inputValue, sparklesActive]);

  const toggleSparkles = useCallback(() => {
    if (isLoading) return;

    dispatch({ type: 'SET_SPARKLE_IDLE', payload: false });
    dispatch({ type: 'SET_TEXT_VISIBLE', payload: false });
    dispatch({ type: 'TOGGLE_SPARKLES' });

    setTimeout(() => {
      if (sparklesActive) {
        dispatch({ type: 'SET_INPUT_VALUE', payload: regularInputHistory });
      } else {
        dispatch({ type: 'SET_INPUT_VALUE', payload: sparkleInputHistory });
      }
      
      dispatch({ type: 'SET_SPARKLES_ACTIVE', payload: !sparklesActive });
      dispatch({ type: 'SET_TEXT_VISIBLE', payload: true });

      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }, 200);
  }, [isLoading, sparklesActive, regularInputHistory, sparkleInputHistory]);

  const setInputValue = useCallback((newValue: string) => {
    dispatch({ type: 'SET_INPUT_VALUE', payload: newValue });
  }, []);

  const handleKeyDown = useCallback(async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      dispatch({ type: 'SET_SPARKLE_IDLE', payload: false });

      if (sparklesActive && inputValue.trim() === '') {
        dispatch({ type: 'SET_SPARKLE_INPUT_HISTORY', payload: inputValue });
        dispatch({ type: 'SET_SPARKLES_ACTIVE', payload: false });
        dispatch({ type: 'SET_INPUT_VALUE', payload: regularInputHistory });
      }
      
      dispatch({ type: 'RESET_LOADING_STATE' });

      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
    
    if (e.key === 'Enter') {
      if (sparklesActive && inputValue.trim() !== '' && !isLoading) {
        e.preventDefault();

        dispatch({ type: 'SET_TEXT_VISIBLE', payload: false });

        setTimeout(async () => {
          const inputToProcess = inputValue;
          dispatch({ type: 'SET_SPARKLE_INPUT_HISTORY', payload: inputToProcess });
          dispatch({ type: 'SET_INPUT_VALUE', payload: '' });
          dispatch({ type: 'SET_TEXT_VISIBLE', payload: true });
          dispatch({ type: 'SET_LOADING', payload: true });
          dispatch({ type: 'SET_SPARKLE_ANIMATING', payload: true });
          
          try {
            const response = await invokeText2Eql(accountId, inputToProcess);
            if (response && response.eql) {
              dispatch({ type: 'SET_REGULAR_INPUT_HISTORY', payload: response.eql });
              await delay(10);
              
              dispatch({ type: 'SET_TEXT_VISIBLE', payload: false });
              
              setTimeout(() => {
                dispatch({ type: 'SET_SPARKLES_ACTIVE', payload: false });
                dispatch({ type: 'SET_LOADING', payload: false });
                dispatch({ type: 'SET_SPARKLE_ANIMATING', payload: false });
                
                dispatch({ type: 'SET_INPUT_VALUE', payload: response.eql });
                
                dispatch({ type: 'SET_TEXT_VISIBLE', payload: true });
                
                setTimeout(() => {
                  inputRef.current?.focus();
                }, 50);
              }, 200);
            }
          } catch (error) {
            console.error("Error converting text to EQL:", error);
            dispatch({ type: 'SET_LOADING', payload: false });
            dispatch({ type: 'SET_SPARKLE_ANIMATING', payload: false });
            dispatch({ type: 'SET_INPUT_VALUE', payload: inputToProcess });
            dispatch({ type: 'SET_TEXT_VISIBLE', payload: true });
          }
        }, 200);
      } else if (!sparklesActive && inputValue.trim() !== '') {
        if (onSearch) {
          onSearch(inputValue);
        }
      }
    }
  }, [sparklesActive, inputValue, isLoading, regularInputHistory, accountId, onSearch]);

  return useMemo(() => ({
    inputValue,
    sparklesActive,
    textVisible,
    isLoading,
    textOpacity,
    isSparkleAnimating,
    showEqlReturnHint,
    exitingSearchHint,
    exitingReturnHint,
    isSparkleIdle,
    inputRef,
    handleInputChange,
    regularInputHistory,
    toggleSparkles,
    handleKeyDown,
    setInputValue,
    TextLoader
  }), [
    inputValue,
    sparklesActive,
    textVisible,
    isLoading,
    textOpacity,
    isSparkleAnimating,
    showEqlReturnHint,
    exitingSearchHint,
    exitingReturnHint,
    isSparkleIdle,
    handleInputChange,
    regularInputHistory,
    toggleSparkles,
    handleKeyDown,
    setInputValue
  ]);
}; 