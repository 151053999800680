import { useState } from 'react';
import { ClientSideSuspense } from '@liveblocks/react';
import { ThreadData } from '@liveblocks/core';
import { useThreads } from '@liveblocks/react';
import { Thread, Composer } from '@liveblocks/react-ui';
import { IExpandedNode } from '../../INode';
import '@liveblocks/react-ui/styles.css';

// Add custom styles to ensure menus appear above sidepane
import './ResourceSidepaneDiscussionTab.css';

function ThreadWrapper({ thread }: { thread: ThreadData }) {
  return (
    <div className={`rounded-lg border border-gray-200 shadow-sm ${thread.resolved ? 'bg-gray-50 opacity-75' : 'bg-white'}`}>
      {thread.resolved && (
        <div className="px-3 py-1 text-xs text-gray-500 flex items-center gap-1 border-b border-gray-100">
          <svg className="w-3 h-3" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
          Resolved
        </div>
      )}
      <div className="p-2">
        <Thread thread={thread} />
      </div>
    </div>
  );
}

function DiscussionRoom({ resource }: { resource: IExpandedNode }) {
  const { threads = [] } = useThreads({
    query: {
        metadata: {
            resourceId: resource.id
        }
    }
  });
  const [showResolved, setShowResolved] = useState(false);
  const [showComposer, setShowComposer] = useState(false);
  const [sortNewestFirst, setSortNewestFirst] = useState(true);

  const filteredThreads = threads
    .filter(thread => showResolved || !thread.resolved)
    .sort((a, b) => {
      const timeA = new Date(a.createdAt).getTime();
      const timeB = new Date(b.createdAt).getTime();
      return sortNewestFirst ? timeB - timeA : timeA - timeB;
    });

  return (
    <div className="flex flex-col gap-4 h-full discussion-room">
      <div className="flex justify-between items-center">
        <label className="flex items-center gap-2 text-sm text-gray-600">
          <input
            type="checkbox"
            checked={showResolved}
            onChange={(e) => setShowResolved(e.target.checked)}
            className="form-checkbox h-4 w-4 text-primary rounded border-gray-300"
          />
          Show resolved threads
        </label>
        
        <button
          onClick={() => setSortNewestFirst(!sortNewestFirst)}
          className="text-sm text-gray-600 flex items-center gap-1 hover:text-gray-800"
        >
          <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
            {sortNewestFirst ? (
              <path fillRule="evenodd" d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z" clipRule="evenodd" />
            ) : (
              <path fillRule="evenodd" d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm14.707 9.293a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L14.586 14H7a1 1 0 110-2h7.586l-1.293-1.293a1 1 0 011.414-1.414l3 3z" clipRule="evenodd" />
            )}
          </svg>
          {sortNewestFirst ? 'Newest first' : 'Oldest first'}
        </button>
      </div>

      {filteredThreads.map((thread) => (
        <ThreadWrapper key={thread.id} thread={thread} />
      ))}
      {showComposer ? (
        <div className="rounded-lg border border-gray-200 shadow-sm bg-white p-2">
          <Composer 
            placeholder="Start a new thread..." 
            onComposerSubmit={() => setShowComposer(false)}
            onFocus={() => setShowComposer(true)}
            metadata={{
              resourceId: resource.id,
              resourceName: resource.name,
              resourceType: resource.type
            }}
          />
        </div>
      ) : (
        <button
          onClick={() => setShowComposer(true)}
          className="flex items-center gap-2 text-sm text-primary hover:text-primary-dark transition-colors"
        >
          <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
          </svg>
          Start a new thread
        </button>
      )}
    </div>
  );
}

export const ResourceSidepaneDiscussionTab = ({ resource }: { resource: IExpandedNode }) => {
  return (
    <div className="flex-1 bg-surface-light">
      <div className="p-4 h-full overflow-y-auto max-h-[80vh]">
        <ClientSideSuspense fallback={<div>Loading discussions...</div>}>
          {() => <DiscussionRoom resource={resource} />}
        </ClientSideSuspense>
      </div>
    </div>
  );
}; 