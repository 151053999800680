import { SyncJobTriggerType, SyncRunStatus } from "src/features/dataModelSync/types";

export type BackendSyncJobResponse = BackendSyncJob & {
    id: number;
}

export type BackendSyncJob = {
    name: string,
    description: string,
    action_type: 'sync_lookml',
    target_id: number | null;
    run_configuration: SyncLookmlCofiguration,
    trigger_type: BackendTriggerType,
    trigger_content: {
        project_id?: number;
        integration_id?: number;
    };
}

export type SyncLookmlCofiguration = {
    dbt_project_name: string,
    account_id: number,
    sql_dialect: BackendSQLDialect,
    target_branch: string,
    target_directory: string,
    git_action: BackendGitAction,
    model_selector: BackendModelSelector,
    metric_selector: BackendMetricSelector,
    model_selector_tags: string[],
    model_selector_names: string[],
    model_selector_eql: string,
    lookml_explore_mode: BackendLookMLExploreMode,
    create_empty_refinements_for_views_and_explores: boolean,
    refinements_folder: string,
    include_euno_links: BackendIncludeEunoLinks
}

export type BackendSyncTrigger = 'automatic' | 'manual';
export type BackendLookMLExploreMode = "create_explore_for_all_models" | "create_explore_for_specific_tags";
export type BackendMetricSelector = "sync_all_metrics" | "sync_specific_tags" | "sync_no_metrics";
export type BackendModelSelector = "sync_all_models" | "sync_specific_models" | "sync_specific_tags" | "sync_by_eql";
export type BackendGitAction = "commit_to_branch" | "create_pr";
export type BackendSQLDialect = 'snowflake' | 'bigquery' | 'presto' | 'trino';
export type BackendIncludeEunoLinks = 'include_links' | 'include_links_in_description' | 'exclude_links';

export enum BackendTriggerType {
    Manual = 'manual',
    PostBuild = 'post_build',
    PostSourceRun = 'post_source_run'
}

export type BackendSyncTarget = {
    id?: number,
    name: string,
    target_type: 'lookml_repository',
    configuration: {
        target_repository_url: string,
        git_hosting_service: 'github' | 'gitlab',
        allowed_target_branches: string[],
        allow_direct_push: boolean
    }
}

export type BackendActionRun = {
    id: number,
    title: string,
    configuration: SyncLookmlCofiguration,
    target_id: number,
    job_id: number,
    action_type: 'sync_lookml',
    state: SyncRunStatus,
    created_by: string,
    created_at: string,
    done_at: string | null,
    artifacts: {
        name: string,
        type: string
    }[],
    has_run_report: boolean,
    trigger_type: SyncJobTriggerType
}
