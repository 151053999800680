import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLazyCheckEQLQuery } from "src/services/eql/eql";
import { debounce } from "src/utils/debounce";

export enum ValidationType {
    Success = 'success',
    Error = 'error',
    Unknown = 'unknown'
}

export type ValidationState = {
    type: ValidationType;
    message: string;
} | null;

type EQLErrorResponse = {
    data?: {
        detail?: {
            error: string;
            line?: number;
            column?: number;
        };
    };
};

interface UseEQLValidationProps {
    enabled: boolean;
    inputValue: string;
    accountId: number;
    allowCustomProperties?: boolean;
    allowRelationships?: boolean;
    skipValidation?: boolean;
    validationDelay?: number;
}

export const useEQLValidation = ({
    enabled = true,
    inputValue = '',
    accountId,
    allowCustomProperties = true,
    allowRelationships = true,
    skipValidation = false,
    validationDelay = 1500
}: UseEQLValidationProps) => {
    const [validation, setValidation] = useState<ValidationState>(null);
    const [checkEQL] = useLazyCheckEQLQuery();

    const validationParams = useMemo(() => ({
        accountId,
        allowCustomProperties,
        allowRelationships,
        eql: inputValue
    }), [accountId, allowCustomProperties, allowRelationships, inputValue]);

    const handleValidationError = useCallback((error: unknown) => {
        const err = error as EQLErrorResponse;
        if (err?.data?.detail) {
            return {
                type: ValidationType.Error,
                message: `${err.data.detail.error}${err.data.detail.line ? ` (Line ${err.data.detail.line}, Column ${err.data.detail.column})` : ''}`
            };
        }
        return {
            type: ValidationType.Error,
            message: 'Failed to check EQL expression'
        };
    }, []);

    const handleValidationSuccess = useCallback(() => ({
        type: ValidationType.Success,
        message: 'EQL expression is valid'
    }), []);

    const verifyEQL = useCallback(async () => {
        if (!enabled || !inputValue.trim() || skipValidation) {
            setValidation(null);
            return false;
        }

        try {
            await checkEQL(validationParams).unwrap();
            const validationState = handleValidationSuccess();
            setValidation(validationState);
            return true;
        } catch (err) {
            const errorState = handleValidationError(err);
            setValidation(errorState);
            return false;
        }
    }, [enabled, inputValue, skipValidation, checkEQL, validationParams, handleValidationSuccess, handleValidationError]);

    const debouncedValidation = useMemo(
        () => debounce(async () => {
            if (!enabled || !inputValue.trim() || skipValidation) {
                setValidation(null);
                return;
            }

            try {
                await checkEQL(validationParams).unwrap();
                setValidation(handleValidationSuccess());
            } catch (err) {
                setValidation(handleValidationError(err));
            }
        }, validationDelay),
        [enabled, inputValue, skipValidation, checkEQL, validationParams, validationDelay, handleValidationSuccess, handleValidationError]
    );

    useEffect(() => {
        if (!enabled || skipValidation) {
            setValidation(null);
            return;
        }

        if (inputValue.trim()) {
            debouncedValidation();
        } else {
            setValidation(null);
        }

        return () => {
            debouncedValidation.cancel();
        };
    }, [enabled, inputValue, skipValidation, debouncedValidation]);

    const isValid = useMemo(() => 
        validation?.type === ValidationType.Success,
        [validation]
    );

    return {
        validation,
        verifyEQL,
        isValid
    };
}; 