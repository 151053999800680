import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { CustomProperty, CustomPropertyFEType, MultiSelectOption } from "src/services/customProperties/types";
import { Button } from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { ConfigurationItem, PropertyConfigContent } from "./ConfigurationPanelContent";
interface ConfigurationPanelProps {
    property: CustomProperty;
    editMode: boolean;
    onStartEditing: () => void;
    onCancelEditing: () => void;
    onSaveEditing: (updatedProperty: CustomProperty) => Promise<void>;
    editedProperty: CustomProperty;
    originalProperty: CustomProperty;
    onPropertyChange?: (updatedProperty: CustomProperty) => void;
}

export const ConfigurationPanel = ({
    property,
    editMode,
    onStartEditing,
    onCancelEditing,
    onSaveEditing,
    editedProperty,
    originalProperty,
    onPropertyChange
}: ConfigurationPanelProps) => {
    const [items, setItems] = useState<ConfigurationItem[]>([]);
    const [originalItems, setOriginalItems] = useState<ConfigurationItem[]>([]);
    const [eqlValidations, setEqlValidations] = useState<Record<string, boolean>>({ propertyScope: true, liveBooleanValue: true });
    const configPanelRef = useRef<HTMLDivElement>(null);

    const areAllEqlValid = useMemo(() => {
        return Object.values(eqlValidations).every(isValid => isValid);
    }, [eqlValidations]);

    const handleEqlValidationChange = useCallback((key: string, isValid: boolean) => {
        setEqlValidations(prev => ({ ...prev, [key]: isValid }));
    }, []);

    const handleCategoryAssignedValidationChange = useCallback((index: number, isValid: boolean) => {
        handleEqlValidationChange(`categoryAssignedItem${index}`, isValid);
    }, [handleEqlValidationChange]);

    const scrollToBottom = useCallback(() => {
        if (configPanelRef.current) {
            setTimeout(() => {
                configPanelRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 100);
        }
    }, []);

    useEffect(() => {
        let initialItems: ConfigurationItem[] = [];

        switch (property.content.type) {
            case CustomPropertyFEType.CategoryAssigned:
                initialItems = property.content.content.options.map(opt => ({
                    value: opt.value,
                    eql: opt.eql
                }));
                break;

            case CustomPropertyFEType.MultiSelect:
                initialItems = property.content.content.options.map(opt => ({
                    value: typeof opt === 'string' ? opt : (opt as MultiSelectOption).value,
                    eql: typeof opt === 'string' ? '' : (opt as MultiSelectOption).eql || ''
                }));
                break;

            case CustomPropertyFEType.SingleSelect:
                initialItems = property.content.content.options.map(opt => ({
                    value: opt,
                    eql: property.propertyScope || ''
                }));
                break;

            case CustomPropertyFEType.LiveBoolean:
                initialItems = [{
                    eql: property.content.content.scope || 'true',
                    value: property.content.content.eql || ''
                }];
                break;

            case CustomPropertyFEType.Numeric:
            case CustomPropertyFEType.Datetime:
            case CustomPropertyFEType.FixedBoolean:
                initialItems = [{
                    value: 'Property Scope',
                    eql: property.propertyScope || ''
                }];
                break;
            default:
                initialItems = [];
        }
        setItems(initialItems);
        setOriginalItems(initialItems);
    }, [property]);

    const handleMove = useCallback((index: number, direction: 'up' | 'down') => {
        const newItems = [...items];
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex < 0 || newIndex >= newItems.length) return;
        [newItems[index], newItems[newIndex]] = [newItems[newIndex], newItems[index]];
        setItems(newItems);
    }, [items]);

    const handleRemove = useCallback((index: number) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    }, [items]);

    const handleDuplicate = useCallback((index: number) => {
        const itemToDuplicate = items[index];
        const newItems = [...items];
        // Insert duplicate after the current item
        newItems.splice(index + 1, 0, { ...itemToDuplicate });
        setItems(newItems);
    }, [items]);

    const handleChange = useCallback((index: number, field: 'value' | 'eql', value: string) => {
        const newItems = [...items];
        newItems[index] = { ...newItems[index], [field]: value };
        setItems(newItems);
    }, [items]);

    const handleAddValue = useCallback(() => {
        setItems([...items, { value: '', eql: '' }]);
        scrollToBottom();
    }, [items, scrollToBottom]);

    const handleStartEditing = useCallback(() => {
        setOriginalItems([...items]);
        onStartEditing();
    }, [items, onStartEditing]);

    const handleCancel = useCallback(() => {
        setItems(originalItems);
        onCancelEditing();
    }, [originalItems, onCancelEditing]);

    const handleDone = useCallback(async () => {
        const updatedProperty = { ...editedProperty };

        switch (property.content.type) {
            case CustomPropertyFEType.MultiSelect:
                updatedProperty.content = {
                    type: CustomPropertyFEType.MultiSelect,
                    content: {
                        options: items.map(item => item.value).filter(value => value.trim() !== '')
                    }
                };
                break;

            case CustomPropertyFEType.SingleSelect:
                updatedProperty.content = {
                    type: CustomPropertyFEType.SingleSelect,
                    content: {
                        options: items.map(item => item.value).filter(value => value.trim() !== '')
                    }
                };
                break;

            case CustomPropertyFEType.CategoryAssigned:
                updatedProperty.content = {
                    type: CustomPropertyFEType.CategoryAssigned,
                    content: {
                        options: items
                            .filter(item => item.value.trim() !== '' && item.eql.trim() !== '')
                            .map(item => ({
                                value: item.value,
                                eql: item.eql
                            }))
                    }
                };
                break;

            case CustomPropertyFEType.LiveBoolean:
                if (items.length > 0) {
                    updatedProperty.content = {
                        type: CustomPropertyFEType.LiveBoolean,
                        content: {
                            options: [],
                            eql: items[0].value,
                            scope: items[0].eql
                        }
                    };
                }
                break;

            case CustomPropertyFEType.Numeric:
            case CustomPropertyFEType.Datetime:
            case CustomPropertyFEType.FixedBoolean:
                if (items.length > 0) {
                    updatedProperty.propertyScope = items[0].eql;
                }
                break;

            default:
                break;
        }

        try {
            await onSaveEditing(updatedProperty);
            setOriginalItems([...items]);
        } catch (e) {
            console.error('Error saving property configuration:', e);
        }
    }, [editedProperty, property.content.type, items, onSaveEditing]);

    const supportsEditing = useCallback(() => {
        return true;
    }, []);

    const showsEql = useCallback(() => {
        return [
            CustomPropertyFEType.CategoryAssigned,
            CustomPropertyFEType.LiveBoolean,
            CustomPropertyFEType.Numeric,
            CustomPropertyFEType.Datetime,
            CustomPropertyFEType.FixedBoolean,
            CustomPropertyFEType.SingleSelect
        ].includes(property.content.type);
    }, [property.content.type]);

    const isCategoryAssigned = useCallback(() => {
        return property.content.type === CustomPropertyFEType.CategoryAssigned;
    }, [property.content.type]);

    const hasChanges = useCallback(() => {
        const itemsChanged = JSON.stringify(items) !== JSON.stringify(originalItems);

        const propertyScopeChanged = editedProperty.propertyScope !== originalProperty.propertyScope;

        const eqlChanged = (
            items.length > 0 &&
            originalItems.length > 0 &&
            (
                property.content.type === CustomPropertyFEType.SingleSelect ||
                property.content.type === CustomPropertyFEType.Numeric ||
                property.content.type === CustomPropertyFEType.Datetime ||
                property.content.type === CustomPropertyFEType.FixedBoolean
            ) &&
            items[0].eql !== originalItems[0].eql
        );

        const informationPanelChanged = (
            editedProperty.name !== originalProperty.name ||
            editedProperty.description !== originalProperty.description ||
            editedProperty.group !== originalProperty.group ||
            editedProperty.showInOverview !== originalProperty.showInOverview
        );

        return itemsChanged || propertyScopeChanged || eqlChanged || informationPanelChanged;
    }, [items, originalItems, editedProperty, originalProperty, property.content.type]);

    const updateEditedProperty = useCallback((updatedProperty: CustomProperty) => {
        if (onPropertyChange) {
            onPropertyChange(updatedProperty);
            return;
        }

        if (editMode) {
            editedProperty.propertyScope = updatedProperty.propertyScope;
        }
    }, [onPropertyChange, editMode, editedProperty]);

    return (
        <div className="" ref={configPanelRef}>
            <div className="px-6 py-2">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
            <div className="p-1 rounded-md bg-slate-200 border border-slate-300">   
                <Cog6ToothIcon className="h-5 w-5 text-slate-600" />
            </div>
            <h2 className="text-lg font-semibold">Configuration</h2>
                    </div>
                    <div className="flex gap-x-2">
                        {supportsEditing() && (
                            editMode ? (
                                <>
                                    {isCategoryAssigned() && <Button type={ButtonTypes.secondary} text="Add Value" onClick={handleAddValue} />}
                                    <Button
                                        type={ButtonTypes.secondary}
                                        text="Done"
                                        onClick={handleDone}
                                        isDisabled={!hasChanges() || !areAllEqlValid}
                                    />
                                    <Button
                                        className="ml-4 border-transparent hover:border-danger-strong"
                                        type={ButtonTypes.danger}
                                        text="Cancel"
                                        onClick={handleCancel}
                                    />
                                </>
                            ) : (
                                <Button
                                    type={ButtonTypes.secondary}
                                    text="Edit Property"
                                    onClick={handleStartEditing}
                                />
                            )
                        )}
                    </div>
                </div>
            </div>

            <div className="px-6 py-3">
                <PropertyConfigContent 
                    property={property}
                    items={items}
                    editMode={editMode}
                    handleMove={handleMove}
                    handleRemove={handleRemove}
                    handleDuplicate={handleDuplicate}
                    handleChange={handleChange}
                    handleAddValue={handleAddValue}
                    editedProperty={editedProperty}
                    supportsEditing={supportsEditing}
                    showsEql={showsEql}
                    onEqlValidationChange={handleEqlValidationChange}
                    handleCategoryAssignedValidationChange={handleCategoryAssignedValidationChange}
                    updateEditedProperty={updateEditedProperty}
                />
            </div>
        </div>
    );
};  
