import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormField } from "src/components/form/FormField";
import Input from "src/components/form/Input";
import { AllowDenyPattern, GenericIntegration, GenericIntegrationConfiguration, GenericIntegrationType } from "src/services/integrations/types";
import Checkbox from "src/components/form/Checkbox";
import { ADVANCED_FIELDS_BY_TYPE } from "./ConnectedSourcesConsts";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import AllowDeny from "src/components/form/AllowDeny";
import Mapping from "src/components/form/Mapping";
import SnowflakeAdvanceBlock from "./SnowflakeAdvanceBlock";

type AdvancedBlockType = {
  integration: GenericIntegration,
  setIntegration: (integration: GenericIntegration) => void,
  editMode: boolean
}

const AdvancedBlock = ({ integration, setIntegration, editMode }: AdvancedBlockType) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <div className="w-full h-[1px] bg-border mb-4"></div>
      <div onClick={() => setIsExpanded(!isExpanded)} className="cursor-pointer flex items-center justify-between text-secondary">
        <span className="font-semibold">Advanced</span>
        <div>
          {isExpanded ? <ChevronUpIcon width="20" height="20" /> : <ChevronDownIcon width="20" height="20" />}
        </div>
      </div>
      {
        isExpanded && (
          <div className="flex flex-col justify-between mt-4 gap-2">
            {
              ADVANCED_FIELDS_BY_TYPE[integration.integration_type].map(field => {
                const key = `${integration.integration_type}-${field.title}`;
                const value = integration.configuration ? integration.configuration[field.title as keyof GenericIntegrationConfiguration] : '';
                return (
                  <>
                    {field.component === Input && (
                      <FormField
                        key={key}
                        label={field.label}
                        labelClassName='text-secondary mb-1 w-44'
                        helper={field?.optional ? 'Optional' : ''}
                        helperClassName={field?.optional ? 'text-tertiary !text-base' : ''}
                      >
                        <field.component
                          placeholder={editMode && field.sensitive ? '******' : field.placeholder}
                          value={value || ''}
                          type={field?.sensitive ? 'password' : 'text'}
                          onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setIntegration({ ...integration, configuration: { ...integration.configuration, [field.title]: e.target.value } })}
                        />
                      </FormField>
                    )}
                    {field.component === Checkbox && (
                      <field.component
                        value={Boolean(value)}
                        setValue={(value: boolean) =>
                          setIntegration({ ...integration, configuration: { ...integration.configuration, [field.title]: value } })}
                        label={field.label}
                      />
                    )}
                    {field.afterComponent ? <field.afterComponent /> : null}
                  </>
                );
              })
            }
            {integration.integration_type === GenericIntegrationType.snowflake && <SnowflakeAdvanceBlock integration={integration} setIntegration={setIntegration} />}
            <MappingContainer integration={integration} setIntegration={setIntegration} />
            <AllowDenyContainer integration={integration} setIntegration={setIntegration} />
            <AdvancePatternsFilteringBlock integration={integration} setIntegration={setIntegration} />
          </div>
        )
      }
    </div>
  );
};

type MappingContainerProps = {
  setIntegration: (integration: GenericIntegration) => void,
  integration: GenericIntegration,
}

const MappingContainer = ({ setIntegration, integration }: MappingContainerProps) => {

  let mappingLabel: string;
  let mappingField: keyof GenericIntegrationConfiguration;
  let placeholders: string[] = ["Source", "Target"];
  let subtitle: string = '';

  switch (integration.integration_type) {
    case 'looker':
      mappingLabel = "Map your connections";
      mappingField = "connection_mapping" as keyof GenericIntegrationConfiguration;
      placeholders = ["Looker connection name", "Details"];
      break;
    case 'dbt_core':
      mappingLabel = "Map your schema aliases";
      mappingField = "schemas_aliases" as keyof GenericIntegrationConfiguration;
      subtitle = 'Euno ingests dbt resources into the manifest-specified database and schema unless redirected by a database.schema mapping.';
      placeholders = ["Enter regular expression", "Enter regular expression"];
      break;
    default:
      return null;
  }

  return (
    <div className="mt-6">
      <div className="flex flex-col justify-start items-start">
        <span className="text-lg">Mapping</span>
        <span className="text-sm text-tertiary max-w-[600px]">{subtitle}</span>
      </div>
      <div className="flex flex-col justify-between mt-4">
        <Mapping
          label={mappingLabel}
          existingMappings={integration.configuration[mappingField]}
          setMappings={(newMappings) => {
            setIntegration({ ...integration, configuration: { ...integration.configuration, [mappingField]: newMappings } });
          }}
          placeholders={placeholders}
        />
      </div>
    </div>
  );
};

type AllowDenyProps = {
  setIntegration: (integration: GenericIntegration) => void,
  integration: GenericIntegration,
}

const AllowDenyContainer = ({ setIntegration, integration }: AllowDenyProps) => {
  let allowDenyField: keyof GenericIntegrationConfiguration;
  let subtitle: string = '';

  switch (integration.integration_type) {
    case 'tableau':
      allowDenyField = "project_pattern" as keyof GenericIntegrationConfiguration;
      break;
    case 'snowflake':
      allowDenyField = "database_pattern" as keyof GenericIntegrationConfiguration;
      subtitle = "Use a regular expression to allow or exclude specific databases. \".*\" will include or exclude all databases in the warehouse.";
      break;
    default:
      return null;
  }

  return (
    <div className="my-4">
      <div className="flex flex-col justify-start items-start">
        <span className="text-lg">{`${integration.integration_type === "tableau" ? 'Project' : 'Database'} pattern`}</span>
        <span className="text-sm text-tertiary">{subtitle}</span>
      </div>
      <div className="flex flex-col justify-between mt-4">
        <AllowDeny
          allowList={integration.configuration[allowDenyField]["allow"]}
          denyList={integration.configuration[allowDenyField]["deny"]}
          setAllowDeny={({ allow, deny }) => {
            setIntegration({ ...integration, configuration: { ...integration.configuration, [allowDenyField]: { allow, deny } } });
          }}
        />
      </div>
    </div>
  );
};


const getFieldMappings = (integrationType: GenericIntegrationType) => {
  switch (integrationType) {
    case GenericIntegrationType.dbt_cloud:
      return [
        { key: 'name', label: 'Job Name', field: 'match_jobs' as keyof GenericIntegrationConfiguration, child: <span className="text-tertiary">Define patterns to include or exclude jobs based on their names.</span> },
        { key: 'environment', label: 'Job Environment', field: 'match_environments' as keyof GenericIntegrationConfiguration, child: <span className="text-tertiary">Define patterns to include or exclude jobs based on their environment.</span> },
        { key: 'branch', label: 'Job Branch', field: 'match_branches' as keyof GenericIntegrationConfiguration, child: <span className="text-tertiary">Define patterns to include or exclude jobs based on their branch name.</span> }
      ];
    default:
      return null;
  }
};
const AdvancePatternsFilteringBlock = ({ setIntegration, integration }: AllowDenyProps) => {
  const [openPatterns, setOpenPatterns] = useState<Record<FilterKey, boolean>>({ name: false, environment: false, branch: false });
  const fieldMappings = useMemo(() => getFieldMappings(integration.integration_type), [integration.integration_type]);

  const initialPatternState = useMemo(() => {
    if (!fieldMappings) return { name: false, environment: false, branch: false };
    const initialState: Record<string, boolean> = { name: false, environment: false, branch: false };
    fieldMappings.forEach(({ key, field }) => {
      const config = integration.configuration[field] as AllowDenyPattern;
      if (
        config &&
        (
          (config.allow && config.allow.length > 0) ||
          (config.deny && config.deny.length > 0)
        )
      ) {
        initialState[key] = true;
      }
    });
    return initialState;
  }, [fieldMappings, integration.configuration]);

  useEffect(() => {
    setOpenPatterns(initialPatternState);

  }, [initialPatternState]);

  if (!fieldMappings) return null;

  type FilterKey = (typeof fieldMappings)[number]['key'];
  const togglePattern = (key: FilterKey) => {
    const field = fieldMappings.filter((item) => item.key === key)[0].field;
    setOpenPatterns(prev => ({ ...prev, [key]: !prev[key] }));
    if (!integration.configuration[field]) {
      setIntegration({ ...integration, configuration: { ...integration.configuration, [field]: { allow: [], deny: [] } } });
    }
  };

  return (
    <div className="mb-2 mt-1 ">
      <span className="text-tertiary">You can use advanced filtering options to fine-tune the jobs to be processed.<br />
        Please note that this option is available only if no specific jobs have been specified in the section above.</span>
      <div className="flex flex-col justify-between gap-y-2 mt-2">
        {fieldMappings.map(({ key, label, field, child }) => (
          <div key={key}>
            <Checkbox
              label={label}
              value={openPatterns[key]}
              child={child}
              setValue={() => togglePattern(key)}
              className="items-start justify-start pt-1 gap-x-2"

            />
            {openPatterns[key] && (
              <div className="mt-2">
                <AllowDeny
                  allowList={integration.configuration[field]["allow"]}
                  denyList={integration.configuration[field]["deny"]}
                  setAllowDeny={({ allow, deny }) => {
                    setIntegration({ ...integration, configuration: { ...integration.configuration, [field]: { allow, deny } } });
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};


export default AdvancedBlock;
