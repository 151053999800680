import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { SparklesIcon as SparklesIconOutline } from '@heroicons/react/24/outline';
import { SparklesIcon as SparklesIconSolid } from '@heroicons/react/24/solid';
import { BookOpenIcon, MagnifyingGlassIcon, CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { DataModelIcon, EunoLogoLoaderIcon, TwoArrowsPointingInIcon, TwoArrowsPointingOutIcon } from "src/assets/images/icons/DelphiIcons";
import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import ContentEditable from 'react-contenteditable';
import { Tooltip } from 'react-tooltip';
import { EqlSuggestionsPopover } from "../suggestions/EqlSuggestionsPopover";
import { highlightEql } from "../suggestions/highlightEql";
import { getContentEditableCaretPosition, setContentEdtiabelCaretPosition } from "src/utils/domUtils";
import { getPlaceholderOpacity, getPlaceholderText, tokenize, sanitize } from './helpers';
import { useEQLInput } from './useEQLInput';
import { useEQLValidation } from './useEQLValidation';
import { useEqlSuggestions } from './useEqlSuggestions';
import {
    PADDING,
    BUTTON_WIDTH,
    TOOLTIP_DELAY_SHOW,
    TOOLTIP_POSITION,
    Z_INDEX_TOOLTIP
} from './consts';
import './styles.css';
import { eunoLinks } from 'src/features/eunoLinks';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { notify } from "src/components/Toaster";
import { useFlags } from "launchdarkly-react-client-sdk";

enum ValidationType {
    Success = 'success',
    Error = 'error',
    Unknown = 'unknown'
}

interface ButtonConfig {
    id: string;
    icon: React.ReactNode;
    onClick: () => void;
    isVisible: boolean;
    className?: string;
    disabled?: boolean;
    tooltipContent?: string;
}

interface EqlInputProps {
    onSearch?: (value: string) => void;
    showDataModelButton?: boolean;
    showSearchButton?: boolean;
    showExpandedModeButton?: boolean;
    showCopyButton?: boolean;
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    allowCustomProperties?: boolean;
    isDisabled?: boolean;
    allowRelationships?: boolean;
    allowSuggestions?: boolean;
    showDocumentationButton?: boolean;
    enableGenAI?: boolean;
    isEQLLoading?: boolean;
    onValidationChange?: (isValid: boolean, type: ValidationType | null) => void;
    showValidation?: boolean;
}

function EqlInput({
    onSearch,
    showDataModelButton = true,
    showSearchButton = true,
    showExpandedModeButton = true,
    showCopyButton = false,
    value,
    onChange,
    placeholder = "EQL query (e.g database_schema = 'salesforce' AND type = 'looker_dashboard')...",
    allowCustomProperties = true,
    isDisabled = false,
    allowRelationships = true,
    allowSuggestions = true,
    showDocumentationButton = true,
    enableGenAI = true,
    isEQLLoading = false,
    onValidationChange,
    showValidation = true,
}: EqlInputProps) {
    const [expandedMode, setExpandedMode] = useState(false);
    const initialTransitionDone = useRef(false);
    const userEditedGeneratedQuery = useRef(false);
    const accountId = useSelector(selectActiveAccountId);
    const { genai } = useFlags();
    const isGenAIEnabled = genai && enableGenAI;
    const isControlled = value !== undefined && onChange !== undefined;
    
    const {
        inputValue: uncontrolledValue,
        sparklesActive,
        textVisible,
        isLoading,
        textOpacity,
        isSparkleAnimating,
        showEqlReturnHint,
        exitingReturnHint,
        isSparkleIdle,
        inputRef,
        handleInputChange: uncontrolledHandleInputChange,
        toggleSparkles: originalToggleSparkles,
        handleKeyDown: uncontrolledHandleKeyDown,
        setInputValue: uncontrolledSetInputValue,
        regularInputHistory,
        TextLoader
    } = useEQLInput({ onSearch });
    
    const {
        validation,
        verifyEQL,
        isValid
    } = useEQLValidation({
        enabled: showValidation && !sparklesActive,
        inputValue: isControlled ? value : uncontrolledValue,
        accountId,
        allowCustomProperties,
        allowRelationships,
        skipValidation: !showValidation || sparklesActive
    });

    const inputValue = isControlled ? value : uncontrolledValue;

    // Use the suggestion hook
    const {
        showSuggestions,
        setShowSuggestions,
        contentEditableRef,
        handleKeyDown: handleSuggestionKeyDown,
        handleFocus,
        handleSuggestionSelect,
        handleContentChange: handleSuggestionContentChange,
        setCurrentCursorPosition: setSuggestionCursorPosition,
    } = useEqlSuggestions(inputValue, isDisabled, allowSuggestions);
    
    const lastCaretPosition = useRef<number | null>(null);
    const currentCursorPosition = useRef<number | null>(null);
    
    const toggleSparkles = () => {
        if (isLoading) return;
        if (sparklesActive && regularInputHistory) {
            initialTransitionDone.current = true;
        } else if (!sparklesActive) {
            initialTransitionDone.current = false;
            userEditedGeneratedQuery.current = false;
        }
        originalToggleSparkles();
    };
    
    const setInputValue = useCallback((newValue: string) => {
        if (isDisabled) return;

        if (isControlled) {
            onChange?.(newValue);
        } else {
            uncontrolledSetInputValue(newValue);
        }
    }, [isControlled, onChange, uncontrolledSetInputValue, isDisabled]);
    
    const onDocumentationClick = () => {
        if (sparklesActive) {
            window.open(eunoLinks.GENAI_DOCUMENTATION, '_blank');
        } else {
            window.open(eunoLinks.EQL_DOCUMENTATION, '_blank');
        }
    };
    
    const goToDataModel = (value: string) => {
        const url = `/data-model?accountId=${accountId}&eql=${encodeURIComponent(value)}&filterMode=eql`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    
    const copy = () => {
        navigator.clipboard.writeText(inputValue);
        notify('Copied to clipboard', 'success');
    };
    
    const focusContentEditable = useCallback(() => {
        if (isDisabled) return;
        contentEditableRef.current?.focus();
    }, [isDisabled, contentEditableRef]);
    
    const onContentChange = useCallback((evt: React.FormEvent<HTMLDivElement>) => {
        if (isDisabled) return;
        
        const text = evt.currentTarget.innerText;
        const sanitizedText = sanitize(text);
        
        currentCursorPosition.current = getContentEditableCaretPosition(evt.currentTarget);

        if (!sparklesActive && regularInputHistory && initialTransitionDone.current) {
            userEditedGeneratedQuery.current = true;
        }

        handleSuggestionContentChange(sanitizedText, inputValue, setInputValue);
    }, [handleSuggestionContentChange, inputValue, setInputValue, isDisabled, sparklesActive, regularInputHistory]);

    const onEqlKeyDown = useCallback((evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (isDisabled) return;
        if (evt.key === 'Enter' && (evt.metaKey || evt.ctrlKey)) {
            evt.preventDefault();
            
            const currentContent = contentEditableRef.current?.textContent || '';
            
            if (currentContent.trim()) {
                if (showValidation) {
                    verifyEQL();
                }
                onSearch?.(currentContent);
            }
            
            return;
        }
        
        if (evt.key === 'Enter' && !evt.metaKey && !evt.ctrlKey) {
            return;
        }
        
        handleSuggestionKeyDown(evt);
    }, [onSearch, isDisabled, handleSuggestionKeyDown, verifyEQL, showValidation, contentEditableRef]);
    
    const onSuggestionSelection = useCallback((text: string) => {
        handleSuggestionSelect(text, setInputValue);
        focusContentEditable();
    }, [handleSuggestionSelect, setInputValue, focusContentEditable]);
    
    const tokens = useMemo(() => {
        if (currentCursorPosition.current !== null) {
            return tokenize(inputValue, currentCursorPosition.current);
        }
        return tokenize(inputValue);
    }, [inputValue]);
    
    const highlightedEqlInEdit = useMemo(() => {
        if (!sparklesActive) {
            if (contentEditableRef.current ) {
                const position = getContentEditableCaretPosition(contentEditableRef.current);
                lastCaretPosition.current = position;
                currentCursorPosition.current = position;
                setSuggestionCursorPosition(position);
            } 
            const fullTokens = tokenize(inputValue);
            return highlightEql(fullTokens);
        }
        return '';
    }, [inputValue, sparklesActive, contentEditableRef, setSuggestionCursorPosition]);
    
    const hasGeneratedEql = regularInputHistory && initialTransitionDone.current && !userEditedGeneratedQuery.current;
    
    const nlButtons: ButtonConfig[] = [
        {
            id: "sparkle-nl",
            icon: <SparklesIconSolid className={`h-6 w-6 ${isSparkleAnimating ? 'sparkle-color-animation' : ''} ${isSparkleIdle ? 'sparkle-fill-animation' : ''}`} />,
            onClick: toggleSparkles,
            isVisible: sparklesActive,
            disabled: isLoading || isDisabled,
            className: "absolute top-1/2 h-8 w-8 flex items-center justify-center text-lilac-600 hover:text-lilac-800",
            tooltipContent: "Return to EQL mode"
        },
        {
            id: "docs-nl",
            icon: <BookOpenIcon className="h-5 w-5 transition-opacity duration-100" />,
            onClick: onDocumentationClick,
            isVisible: sparklesActive && showDocumentationButton,
            disabled: false,
            className: "absolute top-1/2 h-8 w-8 flex items-center justify-center text-lilac-600 hover:text-lilac-800",
            tooltipContent: "View documentation"
        },
        {
            id: "search-nl",
            icon: isLoading ? <EunoLogoLoaderIcon className="h-4 w-4 transition-opacity duration-100" /> : <MagnifyingGlassIcon className="h-4 w-4 transition-opacity duration-100" />,
            onClick: () => {
                if (uncontrolledValue.trim() === '' || isLoading || isDisabled) {
                    return;
                }
                const enterEvent = {
                    key: 'Enter',
                    preventDefault: () => { },
                    stopPropagation: () => { },
                    metaKey: false,
                    ctrlKey: false
                } as React.KeyboardEvent<HTMLInputElement>;

                uncontrolledHandleKeyDown(enterEvent);
            },
            isVisible: sparklesActive,
            disabled: isLoading || isDisabled || uncontrolledValue.trim() === '',
            className: "absolute top-1/2 h-8 w-8 flex items-center justify-center text-lilac-600 hover:text-lilac-800",
            tooltipContent: "Generate EQL"
        }
    ];
    
    const eqlButtons: ButtonConfig[] = [
        {
            id: "sparkle-eql",
            icon: hasGeneratedEql ?
                <SparklesIconSolid className="h-5 w-5 transition-opacity duration-100" /> :
                <SparklesIconOutline className="h-5 w-5 transition-opacity duration-100" />,
            onClick: toggleSparkles,
            isVisible: !sparklesActive && !isDisabled && isGenAIEnabled,
            className: "flex items-center justify-center text-lilac-600 hover:text-lilac-800 h-6 w-6",
            tooltipContent: "Generate EQL with AI"
        },
        {
            id: "expand-eql",
            icon: expandedMode ?
                <TwoArrowsPointingInIcon className="h-5 w-5 transition-opacity duration-100" /> :
                <TwoArrowsPointingOutIcon className="h-5 w-5 transition-opacity duration-100" />,
            onClick: () => setExpandedMode(!expandedMode),
            isVisible: showExpandedModeButton && !isDisabled,
            className: "flex items-center justify-center text-lilac-600 hover:text-lilac-800 h-6 w-6",
            tooltipContent: expandedMode ? "Collapse editor" : "Expand editor"
        },
        {
            id: "copy-eql",
            icon: <ClipboardDocumentIcon className="h-5 w-5 transition-opacity duration-100" />,
            onClick: copy,
            isVisible: showCopyButton,
            className: "flex items-center justify-center text-lilac-600 hover:text-lilac-800 h-6 w-6",
            tooltipContent: "Copy to clipboard"
        },
        {
            id: "datamodel-eql",
            icon: <DataModelIcon className="h-5 w-5 transition-opacity duration-100" />,
            onClick: () => goToDataModel(inputValue),
            isVisible: showDataModelButton,
            className: "flex items-center justify-center text-lilac-600 hover:text-lilac-800 h-6 w-6",
            tooltipContent: "Open in data model"
        },
        {
            id: "docs-eql",
            icon: <BookOpenIcon className="h-5 w-5 transition-opacity duration-100" />,
            onClick: onDocumentationClick,
            isVisible: showDocumentationButton,
            className: "flex items-center justify-center text-lilac-600 hover:text-lilac-800 h-6 w-6",
            tooltipContent: "View documentation"
        },
        {
            id: "search-eql",
            icon: isEQLLoading ? <EunoLogoLoaderIcon className="h-4 w-4 transition-opacity duration-100" /> : <MagnifyingGlassIcon className="h-4 w-4 transition-opacity duration-100" />,
            onClick: () => {
                if (isDisabled) return;
                if (onSearch) {
                    verifyEQL();
                    onSearch(inputValue);
                }
            },
            isVisible: showSearchButton && !isDisabled,
            className: "flex items-center justify-center bg-lilac-950 text-white hover:text-slate-50 p-1 rounded-lg h-6 w-6",
            tooltipContent: "Search"
        }
    ];
    
    const visibleNlButtons = nlButtons.filter(button => button.isVisible).length;
    
    useEffect(() => {
        if (sparklesActive) {
            setExpandedMode(false);
        }
    }, [sparklesActive]);
    
    useEffect(() => {
        if (!sparklesActive && lastCaretPosition.current !== null && contentEditableRef.current) {
            setContentEdtiabelCaretPosition(contentEditableRef.current, lastCaretPosition.current);
        }
    }, [highlightedEqlInEdit, sparklesActive, contentEditableRef]);
    
    useEffect(() => {
        if (!sparklesActive && inputValue.trim()) {
            verifyEQL();
        }
    }, [inputValue, verifyEQL, sparklesActive]);
    
    useEffect(() => {
        if (!sparklesActive && regularInputHistory && !initialTransitionDone.current) {
            initialTransitionDone.current = true;
            onSearch?.(regularInputHistory);
            if (isControlled) {
                onChange?.(regularInputHistory);
            } else {
                setTimeout(() => {
                    if (contentEditableRef.current) {
                        contentEditableRef.current.innerHTML = highlightEql(tokenize(regularInputHistory));
                        focusContentEditable();
                    }
                }, 50);
            }
        }
    }, [sparklesActive, regularInputHistory, isControlled, focusContentEditable, onChange, onSearch, contentEditableRef]);
    
    useEffect(() => {
        onValidationChange?.(
            isValid,
            validation?.type || null
        );
    }, [validation, onValidationChange, isValid]);

    const onPaste = useCallback((evt: React.ClipboardEvent<HTMLDivElement>) => {
        if (isDisabled) return;
        
        const pastedText = evt.clipboardData.getData('text');
        
        setTimeout(() => {
            const needsSuggestion = 
                pastedText.trim().toLowerCase().startsWith('has') || 
                pastedText.includes('=  ') ||
                pastedText.includes(' and  ') ||
                !pastedText.match(/^[a-zA-Z0-9_]+\s/);
            
            if (needsSuggestion) {
                setShowSuggestions(true);
            }
            
            setTimeout(() => {
                if (contentEditableRef.current) {
                    const length = contentEditableRef.current.innerText.length;
                    setContentEdtiabelCaretPosition(contentEditableRef.current, length);
                }
            }, 0);
        }, 50);
    }, [isDisabled, setShowSuggestions, contentEditableRef]);

    return (
        <div className="container mx-auto">
            <div className="eql-input flex justify-center items-center mx-2">
                <div className="w-full" style={{ maxWidth: '100%' }}>
                    <div className="relative">
                        <div
                            className={`w-full rounded-lg border border-border overflow-hidden focus-within:ring-2 focus-within:ring-lilac-600 ${isDisabled ? 'bg-gray-50 opacity-75' : ''}`}
                            style={{
                                height: expandedMode ? '100px' : 'auto',
                                transition: 'height 0.2s ease-in-out',
                                position: 'relative',
                                background: isDisabled ? '#f9fafb' : 'white'
                            }}
                        >
                            {isGenAIEnabled && (
                                <div className="py-1" style={{
                                    opacity: sparklesActive ? 1 : 0,
                                    position: sparklesActive ? 'relative' : 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    transition: 'opacity 0.1s ease-in-out',
                                    zIndex: sparklesActive ? 2 : 0,
                                    background: 'linear-gradient(to right,rgb(250, 247, 251), #FAF6FF)'
                                }}>
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        value={uncontrolledValue}
                                        onChange={uncontrolledHandleInputChange}
                                        onKeyDown={uncontrolledHandleKeyDown}
                                        className="w-full py-3 px-4 pl-12 text-base focus:outline-none border-0 font-fira-code whitespace-pre-wrap"
                                        style={{
                                            background: 'transparent',
                                            color: textVisible ? '#374151' : 'transparent',
                                            caretColor: textVisible ? 'auto' : 'transparent',
                                            transition: isLoading ? 'color 500ms ease-in-out' : 'color 100ms cubic-bezier(0.4, 0, 0.2, 1), caret-color 100ms cubic-bezier(0.4, 0, 0.2, 1), padding 200ms cubic-bezier(0.4, 0, 0.2, 1), font-family 200ms ease',
                                            fontFamily: "'Inter', sans-serif",
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            height: 'auto',
                                            paddingRight: `${(BUTTON_WIDTH * visibleNlButtons) + PADDING}px`,
                                            boxSizing: 'border-box'
                                        }}
                                        disabled={isLoading || isDisabled}
                                        autoFocus={sparklesActive && !isDisabled}
                                    />

                                    <div
                                        className="absolute pointer-events-none left-12 top-1/2 transform -translate-y-1/2 text-gray-400 font-inter overflow-hidden whitespace-nowrap truncate"
                                        style={{
                                            opacity: (sparklesActive && uncontrolledValue.trim() === '' && !isLoading) ? getPlaceholderOpacity(textVisible, isLoading, textOpacity, '') : 0,
                                            transition: 'opacity 100ms cubic-bezier(0.4, 0, 0.2, 1), left 200ms cubic-bezier(0.4, 0, 0.2, 1), font-family 200ms ease',
                                            fontFamily: "'Inter', sans-serif",
                                            fontSize: '1rem',
                                            fontWeight: 400,
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: `calc(100% - ${(BUTTON_WIDTH * visibleNlButtons) + PADDING * 2}px)`,
                                        }}
                                    >
                                        {getPlaceholderText(isLoading, sparklesActive)}
                                    </div>

                                    {isLoading && (
                                        <div
                                            className="absolute pointer-events-none top-1/2 transform -translate-y-1/2 flex items-center"
                                            style={{
                                                marginTop: '6px',
                                            }}
                                        >
                                            <TextLoader style={{ maxWidth: '250px', width: '250px' }} />
                                        </div>
                                    )}

                                    {nlButtons.filter(btn => btn.isVisible).map((button, index, filteredButtons) => {
                                        const isSparkle = button.id === "sparkle-nl";
                                        const position = isSparkle ?
                                            { left: PADDING } :
                                            { right: PADDING + BUTTON_WIDTH * (filteredButtons.length - index - 1) };

                                        return (
                                            <React.Fragment key={button.id}>
                                                <button
                                                    id={`nl-button-${button.id}`}
                                                    type="button"
                                                    onClick={button.onClick}
                                                    className={button.className}
                                                    style={{
                                                        ...position,
                                                        transform: 'translateY(-50%)',
                                                        zIndex: isSparkle ? 10 : 5
                                                    }}
                                                    disabled={button.disabled}
                                                    data-tooltip-id={`tooltip-nl-${button.id}`}
                                                >
                                                    {button.icon}
                                                </button>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            )}
                            <div style={{
                                opacity: sparklesActive ? 0 : 1,
                                position: sparklesActive ? 'absolute' : 'relative',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                transition: 'opacity 0.3s ease-in-out',
                                zIndex: sparklesActive ? 0 : 2,
                                background: isDisabled ? '#f9fafb' : 'white'
                            }}>
                                <div className="flex items-start h-full w-full">
                                    <div className="flex-1 py-3 pl-4 break-all">
                                        <ContentEditable
                                            onChange={onContentChange}
                                            onFocus={handleFocus}
                                            onKeyDown={onEqlKeyDown}
                                            onPaste={onPaste}
                                            html={highlightedEqlInEdit || (initialTransitionDone.current ? '' : regularInputHistory)}
                                            className="outline-none inline-block w-full font-fira-code whitespace-pre-wrap h-full focus:ring-0"
                                            placeholder={!sparklesActive ? placeholder : ""}
                                            spellCheck={false}
                                            style={{ height: expandedMode ? '100px' : 'auto' }}
                                            disabled={isDisabled}
                                        />
                                    </div>

                                    <div className="flex items-start gap-1 pr-4 py-3">
                                        {eqlButtons.filter(btn => btn.isVisible).map((button) => (
                                            <React.Fragment key={button.id}>
                                                <button
                                                    id={`eql-button-${button.id}`}
                                                    onClick={button.onClick}
                                                    className={button.className}
                                                    disabled={button.disabled}
                                                    data-tooltip-id={`tooltip-${button.id}`}
                                                >
                                                    {button.icon}
                                                </button>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!sparklesActive && allowSuggestions && !isDisabled && (
                            <EqlSuggestionsPopover
                                tokens={tokens}
                                isOpen={showSuggestions}
                                onClose={() => setShowSuggestions(false)}
                                onSelect={onSuggestionSelection}

                            />
                        )}

                        {validation && !sparklesActive && showValidation && (
                            <div className="absolute top-1/2 -right-6 transform -translate-y-1/2 z-20">
                                <div className="group relative flex items-center">
                                    {validation.type === ValidationType.Error &&
                                        <ExclamationCircleIcon
                                            className="h-5 w-5 text-red-500"
                                            id="validation-icon"
                                            data-tooltip-id="validation-tooltip"
                                        />
                                    }
                                    {validation.type === ValidationType.Success &&
                                        <CheckCircleIcon
                                            className="h-5 w-5 text-mint-600"
                                            id="validation-icon"
                                            data-tooltip-id="validation-tooltip"
                                        />
                                    }
                                    {validation.type === ValidationType.Unknown &&
                                        <ExclamationTriangleIcon
                                            className="h-5 w-5 text-yellow-500"
                                            id="validation-icon"
                                            data-tooltip-id="validation-tooltip"
                                        />
                                    }
                                    <Tooltip id="validation-tooltip" place="top">
                                        {validation.message}
                                    </Tooltip>
                                </div>
                            </div>
                        )}

                        <HintElement 
                            isDisabled={isDisabled}
                            sparklesActive={sparklesActive}
                            inputValue={inputValue}
                            hasGeneratedEql={hasGeneratedEql}
                            userEditedGeneratedQuery={userEditedGeneratedQuery}
                            showSearchButton={showSearchButton}
                            showEqlReturnHint={showEqlReturnHint}
                            exitingReturnHint={exitingReturnHint}
                            uncontrolledValue={uncontrolledValue}
                        />
                    </div>

                    {nlButtons.filter(btn => btn.isVisible && btn.tooltipContent).map((button) => (
                        <Tooltip
                            key={`tooltip-nl-${button.id}`}
                            id={`tooltip-nl-${button.id}`}
                            place={TOOLTIP_POSITION}
                            delayShow={TOOLTIP_DELAY_SHOW}
                            className="z-tooltip"
                            style={{ zIndex: Z_INDEX_TOOLTIP}}
                        >
                            {button.tooltipContent}
                        </Tooltip>
                    ))}

                    {eqlButtons.filter(btn => btn.isVisible && btn.tooltipContent).map((button) => (
                        <Tooltip
                            key={`tooltip-${button.id}`}
                            id={`tooltip-${button.id}`}
                            place={TOOLTIP_POSITION}
                            delayShow={TOOLTIP_DELAY_SHOW}
                            className="z-tooltip"
                            style={{ zIndex: Z_INDEX_TOOLTIP }}
                        >
                            {button.tooltipContent}
                        </Tooltip>
                    ))}

                    {validation && !sparklesActive && (
                        <Tooltip
                            id="validation-tooltip"
                            place={TOOLTIP_POSITION}
                            delayShow={TOOLTIP_DELAY_SHOW}
                            className="z-tooltip"
                            style={{ zIndex: Z_INDEX_TOOLTIP}}
                        >
                            {validation.message}
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    );
}

const MemoizedEqlInput = React.memo(EqlInput);

export default MemoizedEqlInput;

interface HintElementProps {
    isDisabled: boolean;
    sparklesActive: boolean;
    inputValue: string;
    hasGeneratedEql: boolean | string;
    userEditedGeneratedQuery: React.MutableRefObject<boolean>;
    showSearchButton: boolean;
    showEqlReturnHint: boolean;
    exitingReturnHint: boolean;
    uncontrolledValue: string;
}

const HintElement: React.FC<HintElementProps> = ({
    isDisabled,
    sparklesActive,
    inputValue,
    hasGeneratedEql,
    userEditedGeneratedQuery,
    showSearchButton,
    showEqlReturnHint,
    exitingReturnHint,
    uncontrolledValue
}) => {
    if (isDisabled) return null;
    const controlKey = window.navigator.userAgent.includes('Mac') ? '⌘' : 'Ctrl';
    
    const hintMessages = {
        search: {
            show: !sparklesActive && inputValue.trim() !== '' && (!hasGeneratedEql || userEditedGeneratedQuery.current) && showSearchButton,
            text: <><span className="font-semibold">{controlKey} Enter</span> to search</>,
            className: inputValue.trim() !== '' ? 'explainer-text-enter' : 'explainer-text-exit'
        },
        return: {
            show: showEqlReturnHint || exitingReturnHint,
            text: <>return to EQL using <span className="font-semibold">esc</span> or press the icon</>,
            className: !exitingReturnHint ? 'explainer-text-enter' : 'explainer-text-exit'
        },
        searchGenAI: {
            show: sparklesActive && uncontrolledValue.trim() !== '',
            text: <>search using <span className="font-semibold">Enter</span></>,
            className: uncontrolledValue.trim() !== '' ? 'explainer-text-enter' : 'explainer-text-exit'
        },
        genAiReturn: {
            show: hasGeneratedEql,
            text: <>This is an AI-generated EQL query</>,
            className: 'explainer-text-enter'
        }
    };

    const activeHint = Object.values(hintMessages).find(hint => Boolean(hint.show));

    if (!activeHint) return null;
    
    return (
        <div
            className={`text-sm text-lilac-400 absolute -bottom-5 right-2 font-inter ${activeHint.className}`}
            style={{
                fontFamily: "'Inter', sans-serif",
            }}
        >
            {activeHint.text}
        </div>
    );
};