import { useState, useRef } from 'react';
import { InboxNotification, InboxNotificationList } from '@liveblocks/react-ui';
import { useInboxNotifications, useInboxNotificationThread, useMarkInboxNotificationAsRead, useMarkAllInboxNotificationsAsRead } from '@liveblocks/react';
import { BellIcon } from '@heroicons/react/24/outline';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { useNavigate } from 'react-router-dom';
import { BaseMetadata, InboxNotificationData } from '@liveblocks/client';
import { ThreadData } from '@liveblocks/client';
import type { InboxNotificationThreadProps } from '@liveblocks/react-ui';

interface TopBarProps {
  children?: React.ReactNode;
}

const ThreadComponent = ({ 
  notification, 
  onNotificationClick, 
  props 
}: { 
  notification: InboxNotificationData;
  onNotificationClick: (notificationId: string, thread: ThreadData<BaseMetadata>) => void;
  props: InboxNotificationThreadProps;
}) => {
  const thread = useInboxNotificationThread(notification.id);

  return (
    <InboxNotification.Thread 
      {...props} 
      overrides={{
        INBOX_NOTIFICATION_THREAD_COMMENTS_LIST: (list) => (
          <div>
            {list}
            &nbsp;commented in <span className="lb-name">{thread.metadata?.resourceName}</span>
          </div>
        ),
        INBOX_NOTIFICATION_THREAD_MENTION: (user) => (
          <div>
            {user} mentioned you in <span className="lb-name">{thread.metadata?.resourceName}</span>
          </div>
        )
      }} 
      onClick={() => onNotificationClick(notification.id, thread)}
    />
  );
};

const TopBar = ({ children }: TopBarProps) => {
  const [showInbox, setShowInbox] = useState(false);
  const [showReadNotifications, setShowReadNotifications] = useState(false);
  const inboxRef = useRef<HTMLDivElement>(null);
  const { 
    inboxNotifications, 
    fetchMore, 
    hasFetchedAll,
    isLoading 
  } = useInboxNotifications();
  const markAsRead = useMarkInboxNotificationAsRead();
  const markAllAsRead = useMarkAllInboxNotificationsAsRead();
  const unreadNotifications = inboxNotifications?.filter(n => !n.readAt);
  const navigate = useNavigate();

  // Close popup when clicking outside
  useOnClickOutside(inboxRef, () => setShowInbox(false));

  // Filter notifications based on toggle
  const filteredNotifications = inboxNotifications?.filter(n => 
    showReadNotifications ? true : !n.readAt
  );

  const handleNotificationClick = (notificationId: string, thread: ThreadData<BaseMetadata>) => {
    markAsRead(notificationId);
    if (thread?.metadata?.resourceId) {
      // Navigate to discover page with the resource selected
      navigate(`/data-model?resourceId=${thread.metadata.resourceId}&tab=Discussion`);
      setShowInbox(false);
    }
  };

  const handleMarkAllAsRead = () => {
    markAllAsRead();
    setShowInbox(false);
  };

  return (
    <div className="sticky top-0 z-[2] flex h-14 items-center border-b border-slate-200 bg-white px-4">
      <div className="flex w-full items-center">
        <div className="flex-1 flex items-center">
          {children}
        </div>
        <div className="w-fit weight-normal flex items-center text-lilac-600 gap-2">
          <div className="relative">
            <button 
              onClick={() => setShowInbox(!showInbox)}
              className="relative p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
            >
              <BellIcon className="w-5 h-5" />
              {unreadNotifications && unreadNotifications.length > 0 && (
                <div className="absolute -top-1 -right-1 bg-primary text-white rounded-full w-4 h-4 flex items-center justify-center text-[10px] font-medium">
                  {unreadNotifications.length}
                </div>
              )}
            </button>

            {/* Popup */}
            {showInbox && (
              <div 
                ref={inboxRef}
                className="absolute right-0 top-full mt-1 w-96 bg-white rounded-lg shadow-lg border border-gray-200 max-h-[80vh] overflow-hidden origin-top-right transform transition-all duration-200 ease-out z-50"
              >
                <div className="absolute -top-2 right-4 w-3 h-3 bg-white border-t border-l border-gray-200 transform rotate-45" />
                <div className="relative bg-white">
                  <div className="p-4 border-b border-gray-200 flex justify-between items-center">
                    <div className="flex items-center gap-4">
                      <h3 className="font-medium">Notifications</h3>
                      <label className="flex items-center gap-2 text-sm text-gray-500">
                        <input
                          type="checkbox"
                          checked={showReadNotifications}
                          onChange={(e) => setShowReadNotifications(e.target.checked)}
                          className="form-checkbox h-4 w-4 text-primary rounded border-gray-300"
                        />
                        Show read
                      </label>
                    </div>
                    <button
                      onClick={handleMarkAllAsRead}
                      className="text-sm text-gray-500 hover:text-gray-700"
                    >
                      Mark all as read
                    </button>
                  </div>
                  
                  <div className="overflow-y-auto max-h-[60vh]">
                    <InboxNotificationList>
                      {isLoading && (
                        <div className="text-center text-gray-500 py-4">
                          Loading notifications...
                        </div>
                      )}
                      {
                        !isLoading && filteredNotifications?.map((notification) => { 
                          return (
                            <div 
                              key={notification.id}
                            className="relative cursor-pointer hover:bg-gray-50 group"
                          >
                            <InboxNotification 
                              key={notification.id}
                              showActions={false}
                              inboxNotification={notification} 
                              kinds={{
                                thread: (props) => (
                                  <ThreadComponent 
                                    notification={notification}
                                    onNotificationClick={handleNotificationClick}
                                    props={props}
                                  />
                                )
                              }}
                            />
                          {!notification.readAt && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                markAsRead(notification.id);
                              }}
                              className="absolute right-3 top-1/2 -translate-y-1/2 px-2 py-1 text-xs text-gray-500 hover:text-gray-700 bg-white rounded border border-gray-200 opacity-0 group-hover:opacity-100 transition-opacity"
                            >
                              Mark as read
                            </button>
                          )}
                          </div>
                        );}
                      )}
                    </InboxNotificationList>

                    {!hasFetchedAll && (
                      <button
                        onClick={() => fetchMore?.()}
                        className="w-full p-3 text-sm text-gray-500 hover:text-gray-700 hover:bg-gray-50"
                      >
                        Load more...
                      </button>
                    )}

                    {filteredNotifications && filteredNotifications.length === 0 && (
                      <div className="text-center text-gray-500 py-8">
                        No notifications
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
