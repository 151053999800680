

export const INPUT_WIDTH = 900;
export const ICON_WIDTH = 24;
export const PADDING = 12;
export const BUTTON_WIDTH = 34;

export const ANIMATION_DURATION = 300;
export const ANIMATION_TIMING = 'cubic-bezier(0.4, 0, 0.2, 1)';

export const PROCESSING_DELAY = 5000; 
export const DOT_ANIMATION_INTERVAL = 300;

export const TOOLTIP_DELAY_SHOW = 200;
export const TOOLTIP_POSITION = 'top';

export const FONT_FAMILY_INTER = "'Inter', sans-serif";

export const Z_INDEX_TOOLTIP = 9999;

export const ANIMATION_CLASS_ENTER = 'explainer-text-enter';
export const ANIMATION_CLASS_EXIT = 'explainer-text-exit'; 