import { transformBackendIntegrationToLocal, transformLocalIntegrationToBackend } from "src/services/integrations/transformers";
import IIntegrationKey from "../../features/account/IIntegrationKey";
import { IGithubIntegration, IMetabaseIntegration, IDbtCloudIntegration, IGitlabIntegration, IJiraIntegration } from "../../features/account/integrations/types";
import { Operation } from "../../features/operations/Operation";
import IRepository from "../../features/projects/IRepository";
import IBranch from "../../features/projects/branches/IBranch";
import api from "../api";
import { GetIngtegrationKeysResponse, GetGithubIntegrationResponse, GetJiraIntegrationResponse, CreateMetabaseIntegrationRequest,
  GenericIntegration, GenericIntegrationConfiguration, GetGitlabIntegrationResponse, GitlabProjectResponse,
  GetRepositoriesResponse, GenericIntegrationType, IntegrationSchedule,
  GenericInvalidationStrategy} from "./types";

export const integrationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIntegrationKeys: build.query<IIntegrationKey[], number>({
      query: (accountId) => `accounts/${accountId}/integrations/keys`,
      providesTags: ['Integrations'],
      transformResponse: (response: GetIngtegrationKeysResponse[]) => {
        return response.map((integrationKey) => ({
          name: integrationKey.name,
          id: integrationKey.id,
          createdAt: integrationKey.created_at,
          createdBy: integrationKey.created_by,
          lastUsedAt: integrationKey.last_used_at
        }));
      }
    }),
    createIntegrationKey: build.mutation<void, { accountId: number; name: string; key: string }>({
      query: ({ accountId, name, key }) => ({
        url: `/accounts/${accountId}/integrations/keys?override_secret=${key}`,
        method: 'POST',
        body: {
          name
        }
      }),
      invalidatesTags: ['Integrations']
    }),
    revokeIntegrationKey: build.mutation<void, { accountId: number; integrationKeyId: number }>({
      query: ({ accountId, integrationKeyId }) => ({
        url: `/accounts/${accountId}/integrations/keys/${integrationKeyId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getGithubIntegrations: build.query<IGithubIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/integrations/github`,
      providesTags: ['Integrations'],
      transformResponse: (response: GetGithubIntegrationResponse) => {
        return response.integrations.map((integration) => ({
          id: integration.installation_id,
          login: integration.account_login,
          githubAccountId: integration.account_id
        }));
      },
      keepUnusedDataFor: 0
    }),
    createGithubIntegration: build.mutation<{ url: string }, { accountId: number; redirectUrl: string }>({
      query: ({ accountId, redirectUrl }) => ({
        url: `/accounts/${accountId}/integrations/github?redirect_url=${encodeURIComponent(redirectUrl)}`,
        method: 'POST'
      }),
      invalidatesTags: ['Integrations']
    }),
    unlinkGithubIntegration: build.mutation<void, { accountId: number; githubAccountId: number }>({
      query: ({ accountId, githubAccountId }) => ({
        url: `/accounts/${accountId}/integrations/github/${githubAccountId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getJiraIntegrations: build.query<IJiraIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/linked_accounts/jira`,
      providesTags: ['Integrations'],
      transformResponse: (response: GetJiraIntegrationResponse[]) => {
        return response.map((integration) => ({
          authorizedBy: integration.authorized_by,
          authorizedAt: integration.authorized_at,
          updatedBy: integration.updated_by,
          updatedAt: integration.updated_at,
          // token: {
          //   accessToken: integration.token.access_token,
          //   expiresIn: integration.token.expires_in,
          //   scope: integration.token.scope
          // },
          // sites: integration.sites.map((site) => ({
          //   id: site.id,
          //   url: site.url,
          //   name: site.name,
          //   scopes: site.scopes,
          //   avatarUrl: site.avatarUrl
          // }))
        }));
      },
      keepUnusedDataFor: 0
    }),
    getJiraIntegrationAccessDetails: build.query<IJiraIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/linked_accounts/jira/access_details`,
      providesTags: ['Integrations'],
      transformResponse: (response: GetJiraIntegrationResponse[]) => {
        return response.map((integration) => ({
          authorizedBy: integration.authorized_by,
          authorizedAt: integration.authorized_at,
          updatedBy: integration.updated_by,
          updatedAt: integration.updated_at,
          // token: {
          //   accessToken: integration.token.access_token,
          //   expiresIn: integration.token.expires_in,
          //   scope: integration.token.scope
          // },
          // sites: integration.sites.map((site) => ({
          //   id: site.id,
          //   url: site.url,
          //   name: site.name,
          //   scopes: site.scopes,
          //   avatarUrl: site.avatarUrl
          // }))
        }));
      },
      keepUnusedDataFor: 0
    }),
    createJiraIntegration: build.mutation<{ url: string }, { accountId: number; redirectUrl: string }>({
      query: ({ accountId, redirectUrl }) => ({
        url: `/accounts/${accountId}/linked_accounts/jira?redirect_url=${encodeURIComponent(redirectUrl)}`,
        method: 'POST'
      }),
      invalidatesTags: ['Integrations']
    }),
    unlinkJiraIntegration: build.mutation<void, { accountId: number }>({
      query: ({ accountId }) => ({
        url: `/accounts/${accountId}/linked_accounts/jira`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getGitlabIntegrations: build.query<IGitlabIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/linked_accounts/gitlab`,
      providesTags: ['Integrations'],
      transformResponse: (response: GetGitlabIntegrationResponse[]) => {
        return response.map((integration) => ({
          id: integration.id,
          domain: integration.domain,
          name: integration.name,
        }));
      },
      keepUnusedDataFor: 0
    }),
    getGitlabBranches: build.query<{ name: string }[], { accountId: number; gitlabProjectId: number }>({
      query: ({ accountId, gitlabProjectId }) => `accounts/${accountId}/linked_accounts/gitlab/projects/${gitlabProjectId}/branches`,
      providesTags: ['Integrations']
    }),
    getGitlabProject: build.query<IRepository, { accountId: number; gitlabProjectId: number }>({
      query: ({ accountId, gitlabProjectId }) => `accounts/${accountId}/linked_accounts/gitlab/projects/${gitlabProjectId}`,
      providesTags: ['Integrations'],
      transformResponse: (response: GitlabProjectResponse) => {
        return {
          id: response.id,
          name: response.name,
          ownerId: response.owner_id,
          cloneUrl: response.clone_url,
          ownerLogin: response.owner_login,
          private: response.private,
          ownerType: response.owner_type,
        };
      }
    }),
    createGitlabIntegration: build.mutation<{ url: string }, { accountId: number; domain: string; name: string; accessToken: string; }>({
      query: (props) => ({
        url: `/accounts/${props.accountId}/linked_accounts/gitlab`,
        method: 'POST',
        body: {
          domain: props.domain,
          name: props.name,
          access_token: props.accessToken
        }
      }),
      invalidatesTags: ['Integrations']
    }),
    unlinkGitlabIntegration: build.mutation<void, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/linked_accounts/gitlab/${integrationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getMetabaseIntegrations: build.query<IMetabaseIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/integrations/metabase`,
      providesTags: ['Integrations'],
      transformResponse: (response: [{ id: number; metabase_host: string; metabase_user: string }]) => {
        return response.map((integration) => ({
          id: integration.id,
          host: integration.metabase_host,
          user: integration.metabase_user
        }));
      }
    }),
    createMetabaseIntegration: build.mutation<void, CreateMetabaseIntegrationRequest>({
      query: ({ accountId, user, password, host }) => ({
        url: `/accounts/${accountId}/integrations/metabase`,
        method: 'POST',
        body: {
          metabase_user: user,
          metabase_password: password,
          metabase_host: host
        }
      }),
      invalidatesTags: ['Integrations']
    }),
    unlinkMetabaseIntegration: build.mutation<void, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/integrations/metabase/${integrationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getDbtCloudIntegrations: build.query<IDbtCloudIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/integrations/dbt_cloud`,
      providesTags: ['Integrations'],
      transformResponse: (response: [{ id: number; dbt_cloud_account_id: number; dbt_cloud_account_name: string }]) => {
        return response.map((integration) => ({
          id: integration.id,
          accountId: integration.dbt_cloud_account_id,
          accountName: integration.dbt_cloud_account_name
        }));
      },
      keepUnusedDataFor: 0
    }),
    createDbtCloudIntegration: build.mutation<void, { accountId: number; token: string; dbtAccountId: number }>({
      query: ({ accountId, dbtAccountId, token }) => ({
        url: `/accounts/${accountId}/integrations/dbt_cloud`,
        method: 'POST',
        body: {
          token,
          account_id: dbtAccountId
        }
      }),
      invalidatesTags: ['Integrations']
    }),
    unlinkDbtCloudIntegration: build.mutation<void, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/integrations/dbt_cloud/${integrationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getGenericIntegrations: build.query<GenericIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/integrations`,
      providesTags: ['Integrations'],
      transformResponse: (integrations: GenericIntegration[]) => integrations.map(transformBackendIntegrationToLocal)
    }),
    getGenericIntegration: build.query<GenericIntegration, { accountId: number; integrationId: string }>({
      query: ({ accountId, integrationId }) => `accounts/${accountId}/integrations/${integrationId}`,
      providesTags: ['Integrations'],
      transformResponse: (integration: GenericIntegration) => transformBackendIntegrationToLocal(integration)
    }),
    createGenericIntegration: build.mutation<GenericIntegration, {
      accountId: number;
      integrationName: string;
      configuration: GenericIntegrationConfiguration,
      invalidationStrategy?: GenericInvalidationStrategy,
      integrationType: GenericIntegrationType,
      schedule?: IntegrationSchedule,
      testBeforeSaving?: boolean
    }>({
      query: ({ accountId, integrationName, configuration, invalidationStrategy = null, integrationType, schedule, testBeforeSaving = true }) => ({
        url: `/accounts/${accountId}/integrations?test_before_saving=${testBeforeSaving}`,
        method: 'POST',
        body: { configuration: configuration, invalidation_strategy: invalidationStrategy, schedule, integration_type: integrationType, name: integrationName }
      }),
      invalidatesTags: ['Integrations']
    }),
    updateGenericIntegration: build.mutation<void, { 
      accountId: number; 
      integrationId: number;
      integrationType: string; 
      integrationName: string; 
      active?: boolean;
      configuration?: GenericIntegrationConfiguration; 
      schedule?: IntegrationSchedule;
      invalidationStrategy?: GenericInvalidationStrategy;
      testBeforeSaving?: boolean
    }>({
      query: ({ accountId, integrationId, integrationType, integrationName, active, configuration, invalidationStrategy = null, schedule, testBeforeSaving = true }) => {
        const body = {
          name: integrationName,
          integration_type: integrationType,
          configuration: configuration || {},
          invalidation_strategy: invalidationStrategy,
          active
        } as GenericIntegration;
        if (schedule) {
          body['schedule'] = schedule;
        }
        return {
          url: `/accounts/${accountId}/integrations/${integrationId}?test_before_saving=${testBeforeSaving}`,
          method: 'PATCH',
          body: transformLocalIntegrationToBackend(body)
        };
      },
      invalidatesTags: ['Integrations']
    }),
    deleteGenericIntegration: build.mutation<void, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/integrations/${integrationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    crawlIntegration: build.mutation<Operation, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/integrations/${integrationId}/crawl`,
        method: 'POST'
      }),
      invalidatesTags: ['Integrations', 'Operations']
    }),
    getGithubRepositories: build.query<IRepository[], { accountId: number; githubAccountId: number }>({
      query: ({ accountId, githubAccountId }) => `accounts/${accountId}/integrations/github/${githubAccountId}/repos`,
      providesTags: ['Integrations'],
      transformResponse: (response: GetRepositoriesResponse) => {
        return response?.repos.map((repo) => ({
          id: repo.id,
          name: repo.name,
          ownerId: repo.owner_id.toString(),
          cloneUrl: repo.clone_url,
          ownerLogin: repo.owner_login,
          private: repo.private,
          ownerType: repo.owner_type
        }));
      }
    }),
    getGithubBranches: build.query<IBranch[], { accountId: number; githubAccountId: number; repo: string }>({
      query: ({ accountId, githubAccountId, repo }) => `accounts/${accountId}/integrations/github/${githubAccountId}/repos/${repo}/branches`,
      providesTags: ['Integrations']
    }),
    testIntegrationConfiguration: build.mutation<Operation, { integrationType: string, configuration: GenericIntegrationConfiguration }>({
      query: ({ integrationType, configuration }) => ({
        url: `/integrations/test_configuration?integration_type=${integrationType}`,
        method: 'POST',
        body: configuration
      }),
      invalidatesTags: ['Integrations']
    }),
    resetTriggerUrl: build.mutation<GenericIntegration, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/integrations/${integrationId}/reset_trigger_secret`,
        method: 'POST'
      }),
      invalidatesTags: ['Integrations']
    })
  })
});

export const {
  useGetIntegrationKeysQuery,
  useCreateIntegrationKeyMutation,
  useRevokeIntegrationKeyMutation,
  useGetGithubIntegrationsQuery,
  useCreateGithubIntegrationMutation,
  useUnlinkGithubIntegrationMutation,
  useGetJiraIntegrationsQuery,
  useGetJiraIntegrationAccessDetailsQuery,
  useCreateJiraIntegrationMutation,
  useUnlinkJiraIntegrationMutation,
  useCreateMetabaseIntegrationMutation,
  useGetMetabaseIntegrationsQuery,
  useUnlinkMetabaseIntegrationMutation,
  useCreateDbtCloudIntegrationMutation,
  useGetDbtCloudIntegrationsQuery,
  useUnlinkDbtCloudIntegrationMutation,
  useGetGenericIntegrationsQuery,
  useGetGenericIntegrationQuery,
  useCreateGenericIntegrationMutation,
  useUpdateGenericIntegrationMutation,
  useDeleteGenericIntegrationMutation,
  useCrawlIntegrationMutation,
  useGetGitlabIntegrationsQuery,
  useCreateGitlabIntegrationMutation,
  useUnlinkGitlabIntegrationMutation,
  useGetGitlabBranchesQuery,
  useGetGitlabProjectQuery,
  useGetGithubRepositoriesQuery,
  useGetGithubBranchesQuery,
  useTestIntegrationConfigurationMutation,
  useLazyGetGitlabBranchesQuery,
  useLazyGetGitlabProjectQuery,
  useResetTriggerUrlMutation
} = integrationsApi;
