import React, { useState, useCallback, useMemo, useRef } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DataModelIcon, ServerIcon, SparklesLeftArrowIcon } from 'src/assets/images/icons/DelphiIcons';
import { ArrowPathIcon, ChartBarIcon, HomeIcon, ChatBubbleLeftRightIcon, TagIcon  } from '@heroicons/react/24/outline';
import { ModelChangesTab } from 'src/features/evolution/changesPage/Types';
import { DataModelSyncTab } from 'src/features/dataModelSync/types';
import { useGetLuzmoEmbedCredentialsQuery } from "src/services/accounts/accounts";
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';


interface NavItem {
  to: string;
  Icon?: React.ReactNode;
  label: string;
  children?: NavItem[];
  tab?: string;
}

interface TooltipProps {
  text: string;
  position: { top: number; left: number };
}

const Tooltip: React.FC<TooltipProps> = ({ text, position }) => {
  return createPortal(
    <div 
      className="fixed px-3 py-1 bg-white shadow-md rounded-md text-text-primary text-base whitespace-nowrap opacity-100 transition-opacity duration-200"
      style={{ 
        top: position.top,
        left: position.left,
        zIndex: 20 
      }}
    >
      {text}
    </div>,
    document.body 
  );
};

interface NavItemComponentProps {
  item: NavItem;
  isCollapsed: boolean;
  expandedItems: Set<string>;
  toggleItem: (label: string) => void;
  currentTab: string | null;
  updateTab: (tab: string) => void;
}

const NavItemComponent: React.FC<NavItemComponentProps> = ({
  item,
  isCollapsed,
  expandedItems,
  toggleItem,
  currentTab,
  updateTab,
}) => {
  const isExpanded = expandedItems.has(item.label);
  const hasChildren = item.children && item.children.length > 0;
  const [isHovered, setIsHovered] = useState(false);
  const itemRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (itemRef.current && isCollapsed) {
      const rect = itemRef.current.getBoundingClientRect();
      setTooltipPosition({ 
        top: rect.top + 2,
        left: rect.right + 10 // 10px to the right of the nav item
      });
    }
  };

  const handleParentClick = () => {
    if (hasChildren) {
      // When expanding, immediately set the first child's tab as active.
      if (!isExpanded && item.children && item.children.length > 0) {
        toggleItem(item.label);
        const firstChild = item.children[0];
        const tabValue = firstChild.tab || firstChild.label;

        updateTab(tabValue);
      } else {
        toggleItem(item.label);
      }
    }
  };

  return (
    <div key={item.label} className="relative" ref={itemRef}>
      <NavLink
        to={item.to}
        className={({ isActive }) =>
          `my-1 py-2 flex cursor-pointer text-text-primary items-center rounded-lg ${
            isActive && !hasChildren ? 'bg-slate-100' : ''
          } ${isCollapsed ? 'justify-center px-0' : 'px-2'} hover:bg-slate-100 transition-all duration-150`
        }
        onClick={handleParentClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsHovered(false)}
      >
        {item.Icon}
        {!isCollapsed && <span className="ml-3 flex-grow">{item.label}</span>}
      </NavLink>
      
      {isCollapsed && isHovered && (
        <Tooltip text={item.label} position={tooltipPosition} />
      )}
      
      {!isCollapsed && hasChildren && isExpanded && (
        <div className="ml-6">
          {item.children!.map((child) => {
            const tabValue = child.tab || child.label;
            // If a child doesn't have its own path, default to the parent's path.
            const childPath = child.to ? `${item.to}/${child.to}` : item.to;
            return (
              <NavLink
                key={child.label}
                to={`${childPath}?tab=${tabValue}`}
                className={({ isActive }) =>
                  `my-1 py-2 flex cursor-pointer text-text-primary items-center rounded-lg ${
                    isActive && currentTab === tabValue ? 'bg-slate-100' : ''
                  } px-2 hover:bg-slate-100 transition-all duration-150`
                }
                onClick={() => updateTab(tabValue)}
              >
                <span className="ml-3 whitespace-nowrap overflow-hidden text-overflow-ellipsis max-w-full">{child.label}</span>
              </NavLink>
            );
          })}
        </div>
      )}
    </div>
  );
};

interface MainNavigationProps {
  isCollapsed?: boolean;
}


export const MainNavigation: React.FC<MainNavigationProps> = ({ isCollapsed = false }) => {
  const { showAssistant, showDataModelSyncTab, hideShiftLeft,showCustomPropertiesManagement } = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const accountId = useSelector(selectActiveAccountId);
  const { data: luzmoData } = useGetLuzmoEmbedCredentialsQuery( { accountId }, { skip: !accountId } ); 
  const dashboardNavigationItems = useMemo(() =>  luzmoData?.dashboards.map((dashboard) => ({ to: '', label: dashboard.name, tab: dashboard.id, })) || [],  [luzmoData] );


  const updateTab = useCallback(
    (tab: string) => {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('tab', tab);
      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  // Toggle expansion state of a navigation item.
  const toggleItem = (label: string) => {
    setExpandedItems((prev) => {
      const newSet = new Set(prev);
      newSet.has(label) ? newSet.delete(label) : newSet.add(label);
      return newSet;
    });
  };

  // Declaratively construct the navigation items in the proper order.
  const navItems: NavItem[] = [
    {
      to: '/',
      Icon: <HomeIcon width="20" height="20" />,
      label: 'Home',
      children: [],
    },
    ...(showAssistant
      ? [
          {
            to: '/assistant',
            Icon: <ChatBubbleLeftRightIcon width="20" height="20" />,
            label: 'AI Assistant',
            children: [],
          },
        ]
      : []),
    ...(!hideShiftLeft
      ? [
          {
            to: '/model-changes',
            Icon: <SparklesLeftArrowIcon width="20" height="20" fill="#0A225C" />,
            label: 'Model changes',
            children: [
              { to: '', label: ModelChangesTab.InProgress, tab: ModelChangesTab.InProgress },
              { to: '', label: ModelChangesTab.Archived, tab: ModelChangesTab.Archived },
            ],
          },
        ]
      : []),
    {
      to: '/data-model',
      Icon: <DataModelIcon width="20" height="20" fill="#0A225C" />,
      label: 'Data model',
      children: [],
    },
    ...(showCustomPropertiesManagement
      ? [
          {
            to: '/properties',
            Icon: <TagIcon width="20" height="20" />,
            label: 'Custom Properties',
            children: [],
          },
        ]
      : []),
    ...(showDataModelSyncTab
      ? [
          {
            to: '/data-model-sync',
            Icon: <ArrowPathIcon width="20" height="20" />,
            label: 'Data model sync',
            children: [
              { to: '', label: DataModelSyncTab.Runs, tab: DataModelSyncTab.Runs },
              { to: '', label: DataModelSyncTab.Jobs, tab: DataModelSyncTab.Jobs },
            ],
          },
        ]
      : []),
    {
      to: '/sources',
      Icon: <ServerIcon width="20" height="20" />,
      label: 'Sources',
      children: [],
    },
    {
      to: '/dashboards',
      Icon: <ChartBarIcon width="20" height="20" />,
      label: 'Dashboards',
      children: dashboardNavigationItems,
    },
  ];

  // Initialize expandedItems based on the current URL parameters
  const [expandedItems, setExpandedItems] = useState<Set<string>>(() => {
    const newSet = new Set<string>();
    const currentTab = searchParams.get('tab');
    const currentPath = window.location.pathname;
    
    // If there's a tab parameter, find and expand its parent
    if (currentTab) {
      for (const item of navItems) {
        if (item.children?.some(child => (child.tab || child.label) === currentTab)) {
          newSet.add(item.label);
          break;
        }
      }
    }
    
    // Also expand based on the current URL path
    if (!currentTab) {
      for (const item of navItems) {
        if (item.children?.length && currentPath.startsWith(item.to)) {
          newSet.add(item.label);
        }
      }
    }
    
    return newSet;
  });

  return (
    <div className="px-2">
      <nav>
        {navItems.map((item) => (
          <NavItemComponent
            key={item.label}
            item={item}
            isCollapsed={isCollapsed}
            expandedItems={expandedItems}
            toggleItem={toggleItem}
            currentTab={searchParams.get('tab')}
            updateTab={updateTab}
          />
        ))}
      </nav>
    </div>
  );
};