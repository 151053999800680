import { Operation, OperationSummary } from 'src/features/operations/Operation';
import api from './api';

export const operationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOperationRunReport: build.query<string, { accountId: number, operationId: number }>({
      query: ({ accountId, operationId }) => ({ url: `accounts/${accountId}/operations/${operationId}/report`, headers: { 'Accept-Content': 'text/plain' } , responseHandler: response => response.text() }),
      providesTags: ['Operations'],
      keepUnusedDataFor: 0,
    }),
    getAccountOperations: build.query<
      { total: number; items: OperationSummary[] },
      { accountId: number; page: number; pageSize: number; integrationId?: string; }
    >({
      query: ({ accountId, page, pageSize, integrationId }) => {
        const url = `accounts/${accountId}/operations?page=${page}&page_size=${pageSize}`;
        if (integrationId) {
          return `${url}&integration_id=${integrationId}`;
        }
        return url;
      },
      keepUnusedDataFor: 0,
      providesTags: ['Operations']
    }),
    getOperationLogs: build.query<string, { accountId: number; operationId: number }>({
      query: ({ accountId, operationId }) => `accounts/${accountId}/operations/${operationId}/logs`,
      keepUnusedDataFor: 0,
      providesTags: ['Operations']
    }),
    getAccountOperation: build.query<Operation, { accountId: number; operationId: number; timestamp?: number }>({
      query: ({ accountId, operationId }) => `accounts/${accountId}/operations/${operationId}`,
      keepUnusedDataFor: 0,
      providesTags: ['Operations']
    }),
  })
});

export const {
  useGetOperationRunReportQuery,
  useGetAccountOperationsQuery,
  useGetOperationLogsQuery,
  useGetAccountOperationQuery,
  useLazyGetAccountOperationQuery
} = operationsApi;
