import { MainNavigation } from './MainNavigation';
import { Toaster } from 'react-hot-toast';
import { useAppSelector } from '../../infrastructure/state/hooks';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import EulaModal from '../../features/eula/EulaModal';
import isMenuCollpasedSlice, { selectIsMenuCollpased } from '../../infrastructure/state/slices/isMenuCollpasedSlice';
import { useDispatch } from 'react-redux';
import { SidebarSourcesStatus } from 'src/features/sources/SidebarSourcesStatus';
import UserMenuButton from './UserMenuButton';
import { Link } from 'react-router-dom';
import eunoIcon from '../../assets/images/icons/euno-icon.svg';
import eunoLogotype from '../../assets/images/icons/euno-logotype.svg';
import { useState, useRef, useEffect } from 'react';

interface LayoutProps {
  children: React.ReactNode;
  withMenu?: boolean;
  withEula?: boolean;
}

const Layout = ({
  children,
  withMenu = true,
  withEula = true
}: LayoutProps) => {
  const isMenuCollapsed = useAppSelector(selectIsMenuCollpased);
  const dispatch = useDispatch();
  const [showChevron, setShowChevron] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  
  const toggleMenu = () => {
    dispatch(isMenuCollpasedSlice.actions.setIsMenuCollapsed(!isMenuCollapsed));
  };

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setShowChevron(true);
  };

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    timeoutRef.current = setTimeout(() => {
      setShowChevron(false);
    }, 100); // Small debounce to prevent flickering
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Determine margin class based on menu state

  function getMarginClass(withMenu: boolean, isMenuCollapsed: boolean): string {
    if (!withMenu) {
      return '';
    }
    if (isMenuCollapsed) {
      return 'ml-[60px]';
    }
    return 'ml-[250px]';
  }
  
  const marginClass = getMarginClass(withMenu, isMenuCollapsed);

  return (
    <div className="flex min-h-screen text-base overflow-x-hidden">
      {withMenu && (
        <>
          {/* Hover detection container with extended area */}
          <div 
            className="fixed top-0 bottom-0 z-[9]"
            style={{ width: isMenuCollapsed ? '100px' : '290px' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* Actual visible menu */}
            <div 
              className={`bg-white top-0 bottom-0 fixed border-r border-slate-200 self-start h-screen overflow-hidden flex flex-col transition-all duration-150 ease-in-out z-[10] ${
                isMenuCollapsed ? 'w-[60px]' : 'w-[250px]'
              }`} 
              id="menu-layout"
            >
              {/* Header icon and title */}
              <div className={`flex items-center h-[56px] ${isMenuCollapsed ? 'justify-center px-2' : 'justify-between px-4'}`}>
                <Link to="/" className="flex items-center">
                  <div className="w-5 h-5 flex items-center justify-center">
                    <img src={eunoIcon} alt="Euno" className="w-full h-full" />
                  </div>
                  {!isMenuCollapsed && (
                    <div className="h-5 flex items-center ml-2">
                      <img src={eunoLogotype} alt="Euno" className="h-3" />
                    </div>
                  )}
                </Link>
                
                {/* Chevron is always in the DOM in expanded mode, but only visible on hover */}
                {!isMenuCollapsed && (
                  <div className="cursor-pointer w-8">
                    <div 
                      className={`w-8 h-8 flex items-center justify-center transition-all duration-150 hover:bg-white hover:rounded-full hover:shadow-md hover:border hover:border-slate-200 ${showChevron ? 'opacity-100' : 'opacity-0'}`}
                    >
                      <ChevronLeftIcon
                        width="20"
                        height="20"
                        className="text-slate-400"
                        onClick={toggleMenu}
                      />
                    </div>
                  </div>
                )}
              </div>
              
              {/* Main content area - grows to fill available space */}
              <div className="flex-grow overflow-y-auto">
                <MainNavigation isCollapsed={isMenuCollapsed} />
                
                {!isMenuCollapsed && (
                  <div className="transition-opacity duration-150">
                    <SidebarSourcesStatus />
                  </div>
                )}
              </div>
              
              {/* User menu button - fixed at bottom */}
              <UserMenuButton 
                isCollapsed={isMenuCollapsed} 
                userName="John Doe"
              />
            </div>
          </div>
          
          {/* Floating chevron button when menu is collapsed - always in DOM but only visible on hover */}
          {isMenuCollapsed && (
            <div 
              className={`fixed top-[10px] left-[55px] z-[9999] w-8 h-8 bg-white rounded-full shadow-md flex items-center justify-center cursor-pointer border border-slate-200 transition-all duration-200 ${showChevron ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
              onClick={toggleMenu}
              style={{ transform: 'translateX(-50%)' }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <ChevronRightIcon width="20" height="20" className="text-slate-400" />
            </div>
          )}
        </>
      )}
      <div className={`w-full bg-surface-light flex flex-col ${marginClass}`}>{children}</div>
      <Toaster />
      {withEula && <EulaModal />}
    </div>
  );
};

export default Layout;
