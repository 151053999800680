import Layout from 'src/components/layout/Layout';
import { PageTabs } from 'src/components/PageTabs';
import Gallery from './Gallery';
import EnabledSources from './EnabledSources';
import { useSearchParams } from 'react-router-dom';


const Sources = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') || 'enabled';
  const setTab = (tab: 'enabled' | 'gallery') => {
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  };

  return (
    <Layout>
      <div className="overflow-y-auto max-h-[100vh]">
        <div className="flex flex-col bg-white border-b">
          <div className="w-[50rem] h-36 flex flex-col m-auto">
            <div className="text-2xl mt-5">Sources</div>
            <div className="text-tertiary mt-1 mb-auto">Add and manage connected sources and set up their schedule.</div>
            <PageTabs
              withUnderscore
              tabs={[
                { name: 'Sources', isActive: tab === 'enabled', onClick: () => setTab('enabled') },
                { name: 'Gallery', isActive: tab === 'gallery', onClick: () => setTab('gallery') }
              ]}
            />
          </div>
        </div>
        <div>
          {
            tab === 'enabled' ? (
              <EnabledSources setTab={setTab} />
            ) : (
              <Gallery />
            )
          }
        </div>
      </div>
    </Layout>
  );
};

export default Sources;
