import { useMemo } from 'react';
import { AccountResponse, useGetAccountsQuery } from '../../services/accounts/accounts';
import { useAppSelector } from '../../infrastructure/state/hooks';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';

// React hook to get active account from accounts array and active account ID
export const useGetActiveAccount = (accounts: AccountResponse[], activeAccountId: number) =>
  useMemo(() => {
    const account = accounts.find((a) => a.id === activeAccountId);
    return account;
  }, [accounts, activeAccountId]);

// React hook to get accounts and active account in one call
export const useAccountInfo = () => {
  const { data: accounts = [] } = useGetAccountsQuery();
  const activeAccountId = useAppSelector(selectActiveAccountId);
  const activeAccount = accounts.find(a => a.id === activeAccountId);
  
  return {
    accounts,
    activeAccountId,
    activeAccount,
    activeAccountName: activeAccount?.name || 'Delphi'
  };
};