import { NodeUsageAppType } from "../../../services/nodes/types";

export enum Swimlane {
    application = 'application',
    transformations_and_metrics = 'transformations & metrics',
    sources = 'sources',
    appModeling = 'app modeling',
}

export type IMeta = {
    [key: string]: string;
}

export type SyncedRawCode = {
    isOutdated: boolean | null;
    rawCode: string | null;
    noRawCodeReason: string | null;
}

export enum NodeType {
    DataModel = 'Model',
    Metric = 'Metric',
    DataSource = 'dbt Source',
    LookerLook = 'LookerLook',
    LookerView = 'LookerView',
    LookerExplore = 'LookerExplore',
    GenericDataTransformation = 'GenericDataTransformation',
    LookerDashboard = 'LookerDashboard',
    LookerTile = 'LookerTile',
    LookerSQLDerivedView = 'LookerSQLDerivedView',
    LookerNativeDerivedView = 'LookerNativeDerivedView',
    LookerCascadingDerivedView = 'LookerCascadingDerivedView',
    TableauWorkbook = 'TableauWorkbook',
    TableauView = 'TableauView',
    TableauCustomQuery = 'TableauCustomQuery',
    TableauPublishedDataSource = 'TableauPublishedDataSource',
    TableauEmbeddedDataSource = 'TableauEmbeddedDataSource',
    TableauDashboard = 'TableauDashboard',
    TableauStory = 'TableauStory',
    Table = 'Table',
    DbtColumn = 'DbtColumn',
    DbtDimension = 'DbtDimension',
    DbtMeasure = 'DbtMeasure',
    LookerDimension = 'LookerDimension',
    LookerMeasure = 'LookerMeasure',
    TableauDimension = 'TableauDimension',
    TableauMeasure = 'TableauMeasure',
    TableCalculation = 'TableCalculation',
    Column = 'Column',
    FivetranConnector = 'FivetranConnector',
    FivetranDestination = 'FivetranDestination',
    DbtSeed = 'DbtSeed',
    TableauFlow = 'TableauFlow',
    ThoughtspotLiveboard = 'ThoughtspotLiveboard',
    ThoughtspotAnswer = 'ThoughtspotAnswer',
    ThoughtspotWorksheet = 'ThoughtspotWorksheet',
    ThoughtspotTable = 'ThoughtspotTable',
    ThoughtspotModel = 'ThoughtspotModel',
    ThoughtspotView = 'ThoughtspotView',
    ThoughtspotFormula = 'ThoughtspotFormula',
    TableauProject = 'TableauProject',
    SnowflakeAccount = 'SnowflakeAccount',
    TableauSite = 'TableauSite',
    LookerFolder = 'LookerFolder'
}

export enum SubnodeType {
    Dimension = 'Dimension',
    Measure = 'Measure',
    Entity = 'Entity',
    Metric = 'RelatedMetric',
    CustomField = 'CustomField',
    Schema = 'Schema',
    Column = 'Column',
    TableCalculation = 'TableCalculation',
    LookerConnectedView = 'LookerConnectedView',
    LookerTile = 'LookerTile',
    LookerLook = 'LookerLook',
    TableauConnectedView = 'TableauConnectedView',
    TableauDimension = 'TableauDimension',
    TableauMeasure = 'TableauMeasure',
}

export const nodeNameMap = new Map<NodeType, string>([
    [NodeType.DataModel, 'Model'],
    [NodeType.Metric, 'Metric'],
    [NodeType.DataSource, 'dbt Source'],
    [NodeType.GenericDataTransformation, 'Table'],
    [NodeType.LookerView, 'View'],
    [NodeType.LookerLook, 'Look'],
    [NodeType.LookerExplore, 'Explore'],
    [NodeType.LookerDashboard, 'Dashboard'],
    [NodeType.LookerTile, 'Tile'],
    [NodeType.LookerSQLDerivedView, 'Derived view'],
    [NodeType.LookerNativeDerivedView, 'Derived view'],
    [NodeType.LookerCascadingDerivedView, 'Derived view'],
    [NodeType.TableauWorkbook, 'Workbook'],
    [NodeType.TableauView, 'View'],
    [NodeType.TableauCustomQuery, 'Custom query'],
    [NodeType.TableauPublishedDataSource, 'Published data source'],
    [NodeType.TableauEmbeddedDataSource, 'Embedded data source'],
    [NodeType.TableauDashboard, 'Dashboard'],
    [NodeType.TableauStory, 'Story'],
    [NodeType.Table, 'Table'],
    [NodeType.DbtColumn, 'dbt column'],
    [NodeType.DbtDimension, 'dbt dimension'],
    [NodeType.DbtMeasure, 'dbt measure'],
    [NodeType.LookerDimension, 'Looker dimension'],
    [NodeType.LookerMeasure, 'Looker measure'],
    [NodeType.TableauDimension, 'Tableau dimension'],
    [NodeType.TableauMeasure, 'Tableau measure'],
    [NodeType.TableCalculation, 'Table calculation'],
    [NodeType.Column, 'Column'],
    [NodeType.FivetranConnector, 'Fivetran Connector'],
    [NodeType.FivetranDestination, 'Fivetran Destination'],
    [NodeType.DbtSeed, 'dbt seed'],
    [NodeType.TableauFlow, 'Flow'],
    [NodeType.ThoughtspotLiveboard, 'Thoughtspot Liveboard'],
    [NodeType.ThoughtspotAnswer, 'Thoughtspot Answer'],
    [NodeType.ThoughtspotWorksheet, 'Thoughtspot Worksheet'],
    [NodeType.ThoughtspotTable, 'Thoughtspot Table'],
    [NodeType.ThoughtspotModel, 'Thoughtspot Model'],
    [NodeType.ThoughtspotView, 'Thoughtspot View'],
    [NodeType.ThoughtspotFormula, 'Thoughtspot Formula'],
    [NodeType.TableauProject, "Tableau Project"],
    [NodeType.SnowflakeAccount, "Snowflake Account"],
    [NodeType.TableauSite, "Tableau Site"],
    [NodeType.LookerFolder, "Looker Folder"],
]);

export const subnodeNameMap = new Map<SubnodeType, string>([
    [SubnodeType.Dimension, 'Dimension'],
    [SubnodeType.Measure, 'Measure'],
    [SubnodeType.Entity, 'Entity'],
    [SubnodeType.Metric, 'Related metric'],
    [SubnodeType.CustomField, 'Custom field'],
    [SubnodeType.Schema, 'Schema'],
    [SubnodeType.Column, 'Column'],
    [SubnodeType.TableCalculation, 'Table calculation'],
    [SubnodeType.LookerConnectedView, 'Connected view'],
    [SubnodeType.LookerTile, 'Tile'],
    [SubnodeType.LookerLook, 'Look'],
    [SubnodeType.TableauConnectedView, 'Connected view'],
    [SubnodeType.TableauDimension, 'Dimension'],
    [SubnodeType.TableauMeasure, 'Measure'],
]);


export type IExpandedNode = {
    subnodes: ISubnode[];
    lastAccessedAt?: string;
    favouriteCount?: number;
    thumbnail?: string | null;
    lastUpdatedAt?: string;
    lastUpdatedBy: string | null;
    createdAt?: string;
    createdBy: string | null;
    withCode: boolean;
    id: string;
    name: string;
    type: NodeType;
    subType: string | null;
    description: string;
    tags?: string[];
    meta?: IMeta;
    generatedByDelphi?: boolean;
    tableauHasExtracts: boolean | null;
    nativeLastDataUpdate: string | null;
    materialization?: boolean;
    package?: string;
    database?: string;
    databaseSchema?: string;
    identifier?: string;
    parentType: string;
    parentName: string;
    repo?: string;
    branch?: string;
    rawCode?: string;
    compiledCode?: string;
    semanticCode?: string;
    sourceDirectory?: string;
    lookerModel?: string;
    lookerFolder?: string;
    lookerProject?: string;
    hasProposals: boolean;
    isTrivialSql?: boolean;
    usage: INodeUsage | null;
    distinctUsers14d: number | null;
    distinctUsers30d: number | null;
    distinctUsers60d: number | null;
    impressions: INodeUsage | null;
    last30DaysViews: number | null;
    last7DaysViews: number | null;
    dbtProjectName: string | null;
    eunoProjectId: number | null;
    syncedRawCode: SyncedRawCode | null;
    typeSpecificInfo: { [key: string]: string };
    containedNodes: IContainedNode[];
    chainedNodes: IChainedNode[];
    databaseTechnology: DatabaseTechnology | null;
    tableauViewInputFields: string[] | null;
    parentUri: string | null;
    refreshFrequency: string | null;
    isCalculated: boolean | null;
    dataType: string | null;
    derivedType: 'native_derived_table' | 'sql_derived_table' | 'cascading_derived_table' | null;
    dbtMaterializationStrategy: string | null;
    externalLinks: { label: string; url: string }[];
    owner: string | null;
    firstSeen: number;
    lastSeen: number;
    isProxyToPublishedDatasource: boolean | null;
    tableauProject: string | null;
    sponsors: string[] | null;
    lookerConnectionName: string | null;
    dbtVersion: string | null;
    nativeId: string | null;
    pdtTotalBuildTime30d: number | null;
    pdtBuildsLast30d: number | null;
    inputFields: string[] | null;
    isIdentityTransformation: boolean | null;
    customProperties: { [key: string]: unknown };
    hasRefinements: boolean | null;
    lookerRefinementChain: string[];
    hasCatalogEntry: boolean | null;
    outOfSyncPreAggregateModel: boolean | null;
    isAutomaticPreAggregatedDbtModel: boolean | null;
    rowCount: number | null;
    volume: number | null;
}

export type ISubnode = {
    name: string;
    description: string;
    type: SubnodeType;
    subType: string;
    tags: string[];
    meta: IMeta;
    withCode: boolean;
    parentType: string;
    parentName: string;
    rawCode: string;
    compiledCode: string;
    semanticCode: string;
    id: string;
    usage: INodeUsage | null;
    impressions: INodeUsage | null;
    last30DaysViews: number | null;
    last7DaysViews: number | null;
    typeSpecificInfo: { [key: string]: string };
    hasProposals: boolean | null;
    isTrivialSql: boolean | null;
    aggType: MeasureAggType | null;
    inputMeasures: string[];
    isCalculated: boolean | null;
}

export type MeasureAggType = 'sum' | 'average' | 'min' | 'max' | 'sum_boolean' | 'count_distinct' | 'median' | 'percentile';

export type IContainedNode = {
    name: string;
    type: NodeType;
    id: string;
}

export type IChainedNode = {
    name: string;
    type: NodeType | string;
    id: string;
}

export type INodeUsage = {
    usage14Days?: number;
    usage30Days?: number;
    usage60Days?: number;
    distinctUsers14d?: number;
    distinctUsers30d?: number;
    distinctUsers60d?: number;
    sources14Days: ISourceNodeUsage[];
    users14Days: IUserNodeUsage[];
    sources30Days?: ISourceNodeUsage[];
    users30Days?: IUserNodeUsage[];
    sources60Days?: ISourceNodeUsage[];
    users60Days?: IUserNodeUsage[];
}

export type ISourceNodeUsage = {
    utl: string | null;
    number: number;
    type: NodeUsageAppType;
    dashboardTitle: string | null;
}

export type IUserNodeUsage = {
    name: string;
    number: number;
    email: string;
}

export type ISuperficialNode = {
    id: string;
    name: string;
    parents: string[];
    type: NodeType;
    description?: string;
    tags?: string[];
    meta?: IMeta;
    generatedByDelphi?: boolean;
    hasProposals?: boolean;
    isTrivialSql?: boolean | null;
    tableauHasExtracts?: boolean | null;
    nativeLastDataUpdate: string | null;
    tableauWorkbook?: string;
    tableauProject?: string;
    dbtMaterializationStrategy?: string;
    materialized?: boolean;
    last30DaysViews?: number;
    last7DaysViews?: number;
    package?: string;
    database?: string;
    databaseSchema?: string;
    identifier?: string;
    parentType?: string;
    parentName?: string;
    repo?: string;
    branch?: string;
    numberOfDimensions?: number;
    numberOfMeasures?: number;
    numberOfEntities?: number;
    numberOfMetrics?: number;
    numberOfCustomFields?: number;
    numberOfColumns?: number;
    subnodes: ISuperficialSubnode[];
    owner: string | null;
    firstSeen: number;
    dbtProject: string | null;
    lookerProject: string | null;
    lookerFolder: string | null;
    lookerModel: string | null;
    sourceDirectory: string | null;
    lookerHost: string | null;
    totalViews7Days: number | null;
    totalViews30Days: number | null;
    totalQueries14Days: number | null;
    databaseTechnology: DatabaseTechnology | null;
    totalQueries30Days: number | null;
    totalQueries60Days: number | null;
    distinctUsers14Days: number | null;
    distinctUsers30Days: number | null;
    distinctUsers60Days: number | null;
    totalImpressions14Days: number | null;
    totalImpressions30Days: number | null;
    totalImpressions60Days: number | null;
    distinctUserImpressions14Days: number | null;
    distinctUserImpressions30Days: number | null;
    distinctUserImpressions60Days: number | null;
    isCalculated: boolean | null;
    refreshFrequency: string | null;
    isIdentityTransformation: boolean | null;
    lookmlViewPersistency: string | null;
    pdtBuildsLast30d: number | null;
    pdtTotalBuildTime30d: number | null;
    derivedType: 'native_derived_table' | 'sql_derived_table' | null;
    sourcePath: string | null;
    hasRefinements: boolean | null;
    nativeSubType: string | null;
    outOfSyncPreAggregateModel: boolean | null;
    isAutomaticPreAggregatedDbtModel: boolean | null;
    rowCount: number | null;
    volume: number | null;
}

export interface ISuperficialSubnode {
    name: string;
    type: SubnodeType;
}

export const nodeWidth = 256;
export const nodeHeight = 26.5;
export const distanceBetweenNodes = 60;

export enum CodeType {
    Raw = 'rawCode',
    Compiled = 'compiledCode',
    Semantic = 'semanticCode',
}

export const swimlaneOrder = [Swimlane.sources, Swimlane.transformations_and_metrics, Swimlane.appModeling, Swimlane.application];

export type MetricDependency = {
    entityName: string,
    entityPaths: string[],
    entityDescription: string | null,
    dimensions: {
        name: string,
        description: string | null,
        type: 'categorical' | 'time';
    }[],
}

export const subnodeTypePlurals = {
    [SubnodeType.Dimension]: 'Dimensions',
    [SubnodeType.Measure]: 'Measures',
    [SubnodeType.CustomField]: 'Custom Fields',
    [SubnodeType.Entity]: 'Entities',
    [SubnodeType.Metric]: 'Related Metrics',
    [SubnodeType.Schema]: 'Schema',
    [SubnodeType.Column]: 'Columns',
    [SubnodeType.TableCalculation]: 'Table Calculations',
    [SubnodeType.LookerConnectedView]: 'Connected Views',
    [SubnodeType.LookerLook]: 'Looks',
    [SubnodeType.LookerTile]: 'Tiles',
    [SubnodeType.TableauConnectedView]: 'Connected Views',
    [SubnodeType.TableauDimension]: 'Dimensions',
    [SubnodeType.TableauMeasure]: 'Measures',
};

export const nodeTypePlurals = {
    [NodeType.DataModel]: 'Data Models',
    [NodeType.DataSource]: 'dbt Sources',
    [NodeType.Metric]: 'Metrics',
    [NodeType.GenericDataTransformation]: 'Tables',
    [NodeType.LookerView]: 'Views',
    [NodeType.LookerSQLDerivedView]: 'Derived Views',
    [NodeType.LookerNativeDerivedView]: 'Derived Views',
    [NodeType.LookerCascadingDerivedView]: 'Derived Views',
    [NodeType.LookerExplore]: 'Explores',
    [NodeType.LookerLook]: 'Looks',
    [NodeType.LookerDashboard]: 'Dashboards',
    [NodeType.LookerTile]: 'Tiles',
    [NodeType.TableauWorkbook]: 'Workbooks',
    [NodeType.TableauView]: 'Views',
    [NodeType.TableauCustomQuery]: 'Custom Queries',
    [NodeType.TableauPublishedDataSource]: 'Published Data Sources',
    [NodeType.TableauEmbeddedDataSource]: 'Embedded Data Sources',
    [NodeType.TableauDashboard]: 'Dashboards',
    [NodeType.TableauStory]: 'Stories',
    [NodeType.Table]: 'Tables',
    [NodeType.DbtColumn]: 'Columns',
    [NodeType.DbtDimension]: 'Dimensions',
    [NodeType.DbtMeasure]: 'Measures',
    [NodeType.LookerDimension]: 'Dimensions',
    [NodeType.LookerMeasure]: 'Measures',
    [NodeType.TableauDimension]: 'Dimensions',
    [NodeType.TableauMeasure]: 'Measures',
    [NodeType.TableCalculation]: 'Table Calculations',
    [NodeType.Column]: 'Columns',
    [NodeType.DbtSeed]: 'dbt seeds',
    [NodeType.FivetranConnector]: 'Fivetran Connectors',
    [NodeType.FivetranDestination]: 'Fivetran Destinations',
    [NodeType.TableauFlow]: 'Flows',
    [NodeType.ThoughtspotLiveboard]: 'Thoughtspot Liveboards',
    [NodeType.ThoughtspotAnswer]: 'Thoughtspot Answers',
    [NodeType.ThoughtspotWorksheet]: 'Thoughtspot Worksheets',
    [NodeType.ThoughtspotTable]: 'Thoughtspot Tables',
    [NodeType.ThoughtspotModel]: 'Thoughtspot Models',
    [NodeType.ThoughtspotView]: 'Thoughtspot Views',
    [NodeType.ThoughtspotFormula]: 'Thoughtspot Formulas',
    [NodeType.TableauProject]: 'Tableau Projects',
    [NodeType.TableauSite]: 'Tableau Sites',
    [NodeType.LookerFolder]: 'Looker Folders',
    [NodeType.SnowflakeAccount]: 'Snowflake Accounts',
};

export enum DatabaseTechnology {
    snowflake = 'snowflake',
}
export const NODES_TO_SHOW_CONTAINERS = [NodeType.TableauDashboard, NodeType.TableauView, NodeType.TableauStory, NodeType.TableauCustomQuery, NodeType.TableauEmbeddedDataSource, NodeType.TableauPublishedDataSource];
export const SUB_RESOURCE_TYPES = [NodeType.Column, NodeType.TableauDimension, NodeType.TableauMeasure, NodeType.DbtColumn, NodeType.DbtDimension, NodeType.DbtMeasure, NodeType.LookerDimension, NodeType.LookerMeasure, NodeType.ThoughtspotFormula];
export const DASHBOARD_TYPES = [NodeType.LookerDashboard, NodeType.TableauDashboard, NodeType.ThoughtspotLiveboard];
export const DBT_TYPES = [NodeType.DataModel, NodeType.DataSource, NodeType.Metric, NodeType.DbtColumn, NodeType.DbtDimension, NodeType.DbtMeasure, NodeType.DbtSeed];
export const LOOKER_TYPES = [NodeType.LookerLook, NodeType.LookerTile, NodeType.LookerView, NodeType.LookerNativeDerivedView, NodeType.LookerSQLDerivedView, NodeType.LookerCascadingDerivedView, NodeType.LookerDashboard, NodeType.LookerExplore, NodeType.LookerFolder];
export const TABLEAU_TYPES = [NodeType.TableauEmbeddedDataSource, NodeType.TableauPublishedDataSource, NodeType.TableauCustomQuery, NodeType.TableauStory, NodeType.TableauWorkbook, NodeType.TableauView, NodeType.TableauDashboard, NodeType.TableauFlow, NodeType.TableauProject, NodeType.TableauSite];
export const CONTAINER_TYPES = [NodeType.TableauWorkbook, NodeType.TableauProject, NodeType.TableauSite, NodeType.LookerFolder, NodeType.SnowflakeAccount];
export const THOUGHTSPOT_TYPES = [
    NodeType.ThoughtspotLiveboard,
    NodeType.ThoughtspotAnswer,
    NodeType.ThoughtspotWorksheet,
    NodeType.ThoughtspotTable,
    NodeType.ThoughtspotModel,
    NodeType.ThoughtspotView,
    NodeType.ThoughtspotFormula,
];