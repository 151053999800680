import {FunctionComponent, SVGProps} from "react";
import {DbtCloudIcon, LookerIcon, SnowflakeIcon, TableauIcon, FivetranIcon, ThoughtspotIcon, BigQueryIcon} from "src/assets/images/icons/DelphiIcons";
import { GenericIntegrationType } from "src/services/integrations/types";
import { eunoLinks } from "../eunoLinks";

export type IntegrationMetadata = {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
  subtitle: string;
  documentationLink: string;
}

export const SOURCE_METADATA: Map<GenericIntegrationType, IntegrationMetadata> = new Map([
  [GenericIntegrationType.looker, { icon: LookerIcon, label: 'Looker', subtitle: 'Explore Looker Views, Explores, Models, Dashboards and Looks in Euno', documentationLink: eunoLinks.LOOKER_DOCUMENTATION }],
  [GenericIntegrationType.tableau, { icon: TableauIcon, label: 'Tableau', subtitle: 'Explore Tableau Views, Explores, Models, Dashboards and Looks in Euno', documentationLink: eunoLinks.TABLEAU_DOCUMENTATION }],
  [GenericIntegrationType.dbt_core, { icon: DbtCloudIcon, label: 'dbt core', subtitle: 'Add and manage connected sources and set up their schedule', documentationLink: eunoLinks.DBT_CORE_DOCUMENTATION }],
  [GenericIntegrationType.dbt_cloud, { icon: DbtCloudIcon, label: 'dbt cloud', subtitle: 'Add and manage connected sources and set up their schedule', documentationLink: eunoLinks.DBT_CLOUD_DOCUMENTATION }],
  [GenericIntegrationType.snowflake, { icon: SnowflakeIcon, label: 'Snowflake', subtitle: 'Explore your Snowflake views, and Snowflake usage patterns in Euno', documentationLink: eunoLinks.SNOWFLAKE_DOCUMENTATION }],
  [GenericIntegrationType.fivetran, { icon: FivetranIcon, label: 'Fivetran', subtitle: 'Add and manage connected sources and set up their schedule', documentationLink: eunoLinks.FIVETRAN_DOCUMENTATION }],
  [GenericIntegrationType.thoughtspot, { icon: ThoughtspotIcon, label: 'ThoughtSpot', subtitle: 'Add and manage connected sources and set up their schedule', documentationLink: eunoLinks.THOUGHT_SPOT_DOCUMENTATION }],
  [GenericIntegrationType.bigquery, { icon: BigQueryIcon, label: 'BigQuery', subtitle: 'Add and manage connected sources and set up their schedule', documentationLink: eunoLinks.BIGQUERY_DOCUMENTATION }],
]);
