import { useState } from 'react';
import Input from 'src/components/form/Input';
import Button from 'src/components/button/Button';
import { ButtonTypes } from 'src/components/button/types';
import {  XMarkIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import EQLInput from 'src/components/eql/input/EQLInput';

type MultiSelectOption = {
    value: string;
    eql: string;
};

type MultiSelectOptionsEditorProps = {
    options: MultiSelectOption[];
    onChange: (options: MultiSelectOption[]) => void;
};

const MultiSelectOptionsEditor = ({ options, onChange }: MultiSelectOptionsEditorProps) => {
    const [newValue, setNewValue] = useState('');
    const [newEql, setNewEql] = useState('');

    const handleAddOption = () => {
        if (!newValue.trim() || !newEql.trim() || options.some(opt => opt.value === newValue.trim())) return;
        
        onChange([...options, { value: newValue.trim(), eql: newEql.trim() }]);
        setNewValue('');
        setNewEql('');
    };

    const handleRemoveOption = (index: number) => {
        onChange(options.filter((_, i) => i !== index));
    };

    const handleMoveOption = (index: number, direction: 'up' | 'down') => {
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex < 0 || newIndex >= options.length) return;

        const newOptions = [...options];
        [newOptions[index], newOptions[newIndex]] = [newOptions[newIndex], newOptions[index]];
        onChange(newOptions);
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2 ">
                <h3 className="text-md font-medium mb-2">Add New Value</h3>
                <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-2">
                        <div className="w-20 flex-shrink-0">Value:</div>
                        <div className="w-full max-w-[600px]">
                            <Input
                                value={newValue}
                                onInputChange={(e) => setNewValue(e.target.value)}
                                placeholder="Enter option value"
                                className='w-full'
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="w-20 flex-shrink-0">EQL:</div>
                        <div className="w-full max-w-[580px] mb-2">
                            <EQLInput
                                value={newEql}
                                onChange={setNewEql}
                                placeholder="Enter EQL expression for this option"
                                showSearchButton={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <Button className=" hover:border-lilac-300" type={ButtonTypes.secondary} text="Add Value +" onClick={handleAddOption} />
            </div>
            {options.length > 0 && (
                <div className="flex flex-col gap-2">
                    <h3 className="text-md font-medium">Assigned Values</h3>
                    {options.map((option, index) => (
                        <div key={index} className="flex items-center gap-2 p-3 border border-gray-200 rounded-md">
                            <div className="flex-1 flex items-start gap-2 pr-6">
                                <div className="w-20 flex-shrink-0 font-medium max-w-[200px] break-words overflow-wrap-anywhere">{option.value}</div>
                                <div className="w-full max-w-[580px]">
                                    <EQLInput
                                        value={option.eql}
                                        onChange={() => { }}
                                        placeholder="Enter EQL query..."
                                        isDisabled={true}
                                        showSearchButton={false}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-1">
                                <button
                                    onClick={() => handleMoveOption(index, 'up')}
                                    disabled={index === 0}
                                    className="p-1 text-slate-400 hover:text-slate-600 disabled:opacity-50 disabled:cursor-default transition-colors"
                                >
                                    <ChevronUpIcon className="h-4 w-4" />
                                </button>
                                <button
                                    onClick={() => handleRemoveOption(index)}
                                    className="p-1 text-slate-400 hover:text-red-600 transition-colors"
                                >
                                    <XMarkIcon className="h-4 w-4" />
                                </button>
                                <button
                                    onClick={() => handleMoveOption(index, 'down')}
                                    disabled={index === options.length - 1}
                                    className="p-1 text-slate-400 hover:text-slate-600 disabled:opacity-50 disabled:cursor-default transition-colors"
                                >
                                    <ChevronDownIcon className="h-4 w-4" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
export default MultiSelectOptionsEditor;