import { useState, useCallback, useRef } from 'react';
import { tokenize } from './helpers';
import { getContentEditableCaretPosition, setContentEdtiabelCaretPosition } from "src/utils/domUtils";


export const hasErrorTokens = (inputVal: string): boolean => {
    const tokens = tokenize(inputVal);
    return tokens.some(t => t.type === '⚠' as string);
};

// Hook that manages suggestions for EQL queries based on cursor position and token context
export const useEqlSuggestions = (
    inputValue: string,
    isDisabled: boolean,
    allowSuggestions: boolean
) => {
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [currentCursorPosition, setCurrentCursorPosition] = useState<number | null>(null);
    const contentEditableRef = useRef<HTMLDivElement | null>(null);

    const checkForSuggestions = useCallback((element: HTMLDivElement | null) => {
        if (isDisabled || !allowSuggestions || !element) return;

        const shouldShowSuggestions = checkCursorBetweenExpressions(inputValue, element);
        if (shouldShowSuggestions) {
            setTimeout(() => setShowSuggestions(true), 10);
        }
    }, [inputValue, isDisabled, allowSuggestions]);

    // Handles keyboard events and analyzes tokens to determine when to show suggestions
    const handleKeyDown = useCallback((evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (isDisabled) return;

        if ((evt.key === ' ' && (evt.ctrlKey || evt.metaKey)) || evt.key === 'Tab') {
            evt.preventDefault();
            setShowSuggestions(true);
            return;
        }

        setTimeout(() => {
            const currentTarget = contentEditableRef.current;
            if (!currentTarget) return;

            const caretPosition = getContentEditableCaretPosition(currentTarget);
            if (caretPosition === null) return;

            const tokensBeforeCursor = tokenize(inputValue, caretPosition);
            const lastToken = tokensBeforeCursor.length > 0 ? tokensBeforeCursor[tokensBeforeCursor.length - 1] : null;
            const secondLastToken = tokensBeforeCursor.length > 1 ? tokensBeforeCursor[tokensBeforeCursor.length - 2] : null;

            // Skip whitespace to find meaningful tokens for analysis
            let lastMeaningfulToken = lastToken;
            let secondLastMeaningfulToken = secondLastToken;

            if (lastMeaningfulToken && lastMeaningfulToken.type === 'WHITESPACE') {
                lastMeaningfulToken = secondLastToken;
                secondLastMeaningfulToken = tokensBeforeCursor.length > 2 ? tokensBeforeCursor[tokensBeforeCursor.length - 3] : null;
            }

            if (lastMeaningfulToken?.type === 'COMPARISON_OP' && secondLastMeaningfulToken?.type === 'CNAME') {
                setShowSuggestions(true);
                return;
            }

            if (lastToken?.type === 'CNAME' || (lastToken?.type === 'WHITESPACE' && secondLastToken?.type === 'CNAME')) {
                setShowSuggestions(true);
                return;
            }

            // Check for field names that don't have an operator yet to suggest appropriate operators
            for (let i = Math.max(0, tokensBeforeCursor.length - 4); i < tokensBeforeCursor.length; i++) {
                if (tokensBeforeCursor[i].type === 'CNAME') {
                    let hasOperator = false;
                    for (let j = i + 1; j < tokensBeforeCursor.length; j++) {
                        if (['=', '!=', '~', '=~', '>', '>=', '<', '<=', 'IS', 'IN', 'BETWEEN'].includes(tokensBeforeCursor[j].text)) {
                            hasOperator = true;
                            break;
                        }
                    }
                    if (!hasOperator) {
                        setShowSuggestions(true);
                        return;
                    }
                }
            }

            if ((evt.key === ' ' || evt.key === 'ArrowLeft' || evt.key === 'ArrowRight')) {
                if (checkCursorBetweenExpressions(inputValue, currentTarget)) {
                    setShowSuggestions(true);
                } else if (allowSuggestions && hasErrorTokens(inputValue)) {
                    setShowSuggestions(true);
                }
            } else if (allowSuggestions) {
                setShowSuggestions(true);
            }

        }, 0);

    }, [isDisabled, allowSuggestions, inputValue]);

    const handleFocus = useCallback((evt: React.FocusEvent<HTMLDivElement>) => {
        if (isDisabled) return;
        contentEditableRef.current = evt.target;

        setTimeout(() => {
            checkForSuggestions(evt.target);
        }, 10);
    }, [isDisabled, checkForSuggestions]);

    // Handles when a user selects a suggestion, inserts or replaces text
    const handleSuggestionSelect = useCallback((
        text: string,
        updateInputValue: (val: string) => void,
    ) => {
        if (isDisabled || !contentEditableRef.current) return;

        const currentCaretPosition = currentCursorPosition ? currentCursorPosition : getContentEditableCaretPosition(contentEditableRef.current);
        if (currentCaretPosition === null) return;
        const tokensUpToCaret = tokenize(inputValue, currentCaretPosition);
        const lastToken = tokensUpToCaret.length > 0 ? tokensUpToCaret[tokensUpToCaret.length - 1] : null;

        const shouldReplaceLastToken = lastToken && text.toLowerCase().replace(/"/g, '').startsWith(lastToken.text.toLowerCase());

        let newEql: string;
        let newCursorPosition: number;

        if (shouldReplaceLastToken && lastToken) {
            const textBeforeToken = inputValue.substring(0, currentCaretPosition - lastToken.text.length);
            const textAfterCaret = inputValue.substring(currentCaretPosition);
            let suggestedText = text;
            
            // handle quotation marks to avoid duplicates
            if (text.startsWith('"') && lastToken.text.endsWith('"')) {
                suggestedText = text.substring(1);
            }
            if (textBeforeToken.endsWith('"') && suggestedText.startsWith('"')) {
                suggestedText = suggestedText.substring(1);
            }
            if (textAfterCaret.startsWith('"') && suggestedText.endsWith('"')) {
                suggestedText = suggestedText.substring(0, suggestedText.length - 1);
            }
            newEql = textBeforeToken + suggestedText + textAfterCaret;
            newCursorPosition = currentCaretPosition + (suggestedText.length - lastToken.text.length);
        } else {
            const textBeforeCursor = inputValue.substring(0, currentCaretPosition);
            const textAfterCursor = inputValue.substring(currentCaretPosition);

            newEql = textBeforeCursor + text + textAfterCursor;
            newCursorPosition = currentCaretPosition + text.length;
        }

        updateInputValue(newEql);

        if (contentEditableRef.current) {
            setContentEdtiabelCaretPosition(contentEditableRef.current, newCursorPosition);
            contentEditableRef.current.focus();
            setCurrentCursorPosition(newCursorPosition);

        }
    }, [inputValue, isDisabled, currentCursorPosition]);

    const handleContentChange = useCallback((
        sanitizedText: string,
        prevValue: string,
        updateInputValue: (val: string) => void
    ) => {
        if (isDisabled) return;

        updateInputValue(sanitizedText);

        setTimeout(() => {
            const currentTarget = contentEditableRef.current;
            if (!currentTarget) return;

            const caretPosition = getContentEditableCaretPosition(currentTarget);
            if (caretPosition === null || prevValue === sanitizedText) return;

            const tokensBeforeCursor = tokenize(sanitizedText, caretPosition);
            const lastToken = tokensBeforeCursor.length > 0 ? tokensBeforeCursor[tokensBeforeCursor.length - 1] : null;
            const secondLastToken = tokensBeforeCursor.length > 1 ? tokensBeforeCursor[tokensBeforeCursor.length - 2] : null;

            let lastMeaningfulToken = lastToken;
            let secondLastMeaningfulToken = secondLastToken;

            if (lastMeaningfulToken && lastMeaningfulToken.type === 'WHITESPACE') {
                lastMeaningfulToken = secondLastToken;
                secondLastMeaningfulToken = tokensBeforeCursor.length > 2 ? tokensBeforeCursor[tokensBeforeCursor.length - 3] : null;
            }

            if (lastMeaningfulToken?.type === 'COMPARISON_OP' && secondLastMeaningfulToken?.type === 'CNAME') {
                setShowSuggestions(true);
                return;
            }

            if (lastToken?.type === 'CNAME' || (lastToken?.type === 'WHITESPACE' && secondLastToken?.type === 'CNAME')) {
                setShowSuggestions(true);
                return;
            }

            // Check for field names that don't have an operator yet to suggest appropriate operators
            for (let i = Math.max(0, tokensBeforeCursor.length - 4); i < tokensBeforeCursor.length; i++) {
                if (tokensBeforeCursor[i].type === 'CNAME') {
                    let hasOperator = false;
                    for (let j = i + 1; j < tokensBeforeCursor.length; j++) {
                        if (['=', '!=', '~', '=~', '>', '>=', '<', '<=', 'IS', 'IN', 'BETWEEN'].includes(tokensBeforeCursor[j].text)) {
                            hasOperator = true;
                            break;
                        }
                    }
                    if (!hasOperator) {
                        setShowSuggestions(true);
                        return;
                    }
                }
            }

            if (checkCursorBetweenExpressions(sanitizedText, currentTarget)) {
                setShowSuggestions(true);
            } else if (allowSuggestions && hasErrorTokens(sanitizedText)) {
                setShowSuggestions(true);
            }
        }, 10);

    }, [isDisabled, allowSuggestions]);

    return {
        showSuggestions,
        setShowSuggestions,
        contentEditableRef,
        handleKeyDown,
        handleFocus,
        handleSuggestionSelect,
        handleContentChange,
        checkForSuggestions,
        currentCursorPosition,
        setCurrentCursorPosition
    };
};

// Determines whether to show suggestions based on cursor position and token context
export const checkCursorBetweenExpressions = (
    inputVal: string,
    contentEditableElement: HTMLDivElement | null
): boolean => {
    if (!contentEditableElement) return false;

    const caretPosition = getContentEditableCaretPosition(contentEditableElement);
    if (caretPosition === null) return false;

    const tokensBeforeCursor = tokenize(inputVal, caretPosition);

    const lastTokenBeforeCursor = tokensBeforeCursor.length > 0 ?
        tokensBeforeCursor[tokensBeforeCursor.length - 1] : null;

    const secondLastTokenBeforeCursor = tokensBeforeCursor.length > 1 ?
        tokensBeforeCursor[tokensBeforeCursor.length - 2] : null;

    if (lastTokenBeforeCursor && lastTokenBeforeCursor.type === 'CNAME') {
        return true;
    }

    if (lastTokenBeforeCursor && lastTokenBeforeCursor.type === 'WHITESPACE' &&
        secondLastTokenBeforeCursor && secondLastTokenBeforeCursor.type === 'CNAME') {
        return true;
    }

    // Check for field names without operators to suggest appropriate operators
    for (let i = Math.max(0, tokensBeforeCursor.length - 3); i < tokensBeforeCursor.length; i++) {
        if (tokensBeforeCursor[i].type === 'CNAME') {
            let hasOperator = false;
            for (let j = i + 1; j < tokensBeforeCursor.length; j++) {
                if (['=', '!=', '~', '=~', '>', '>=', '<', '<=', 'IS', 'IN', 'BETWEEN'].includes(tokensBeforeCursor[j].text)) {
                    hasOperator = true;
                    break;
                }
            }

            if (!hasOperator) {
                return true;
            }
        }
    }

    // Detect if cursor is after a completed expression to suggest AND/OR operators
    const isAfterExpression = lastTokenBeforeCursor &&
        (lastTokenBeforeCursor.type === 'STRING_LITERAL' ||
            lastTokenBeforeCursor.type === 'BOOL' ||
            lastTokenBeforeCursor.type === 'RPAREN');

    return Boolean(isAfterExpression);
};