import { useState } from 'react';
import Modal from 'src/components/Modal/Modal';
import Input from 'src/components/form/Input';
import Button from 'src/components/button/Button';
import { ButtonTypes } from 'src/components/button/types';
import { AISelectContent } from 'src/services/customProperties/types';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { useTestAICustomPropertyMutation } from 'src/services/customProperties/customProperties';

type AISelectTestModalProps = {
    isOpen: boolean;
    onClose: () => void;
    content: AISelectContent;
};

const AISelectTestModal = ({ isOpen, onClose, content }: AISelectTestModalProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const [uri, setUri] = useState('');
    const [testAICustomProperty, { isLoading, data, error, reset }] = useTestAICustomPropertyMutation();
    
    const handleTest = async () => {
        if (!uri.trim()) return;
        
        try {
            await testAICustomProperty({
                accountId,
                uri: uri.trim(),
                content
            });
        } catch (err) {
            console.error('Error testing AI custom property:', err);
        }
    };
    
    const handleClose = () => {
        setUri('');
        reset();
        onClose();
    };
    
    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            title="Test AI Select Property"
        >
            <div className="flex flex-col gap-6 p-6">
                <div className="flex flex-col gap-2">
                    <label className="text-sm font-medium text-slate-700">Resource URI</label>
                    <Input
                        value={uri}
                        onInputChange={(e) => setUri(e.target.value)}
                        placeholder="Enter a resource URI to test"
                        disabled={isLoading}
                    />
                    <p className="text-xs text-slate-500">
                        Enter a URI for a resource that matches the scope of this property.
                    </p>
                </div>
                
                <div className="flex justify-end gap-3">
                    <Button
                        type={ButtonTypes.secondary}
                        text="Cancel"
                        onClick={handleClose}
                        isDisabled={isLoading}
                    />
                    <Button
                        type={ButtonTypes.primary}
                        text={isLoading ? "Testing..." : "Test"}
                        onClick={handleTest}
                        isDisabled={!uri.trim() || isLoading}
                    />
                </div>
                
                {error && (
                    <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-md text-red-700">
                        <p className="font-medium">Error</p>
                        <p className="text-sm">
                            {error instanceof Error 
                                ? error.message 
                                : 'An error occurred while testing the property.'}
                        </p>
                    </div>
                )}
                
                {data && (
                    <div className="mt-4 flex flex-col gap-4">
                        <div className="p-4 bg-slate-50 border border-slate-200 rounded-md">
                            <p className="font-medium text-slate-700 mb-2">Result</p>
                            <p className="text-slate-900">{data.result}</p>
                        </div>
                        
                        <div className="p-4 bg-slate-50 border border-slate-200 rounded-md">
                            <p className="font-medium text-slate-700 mb-2">Explanation</p>
                            <p className="text-slate-900 whitespace-pre-wrap">{data.explanation}</p>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default AISelectTestModal; 