import { useEffect, useRef, useState } from "react";
import { DiscoverFilterProps, Filter } from "./Types";
import { store } from "../../../../../infrastructure/state/store";
import { nodesApi } from "../../../../../services/nodes/nodes";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { useSelector } from "react-redux";
import { LanguageIcon } from "@heroicons/react/24/outline";
import { FilterSearchEnum, SelectFilterOption } from "../../../../../components/Table/TableFilters/types";
import { SelectFilter } from "src/components/Table/TableFilters/SelectFilter";
import { selectShowSubResourcesInDataModelTable } from "src/infrastructure/state/slices/showSubResouresInDataModelTableSlice";

export const NameFilter = (props: DiscoverFilterProps) => {
    const [options, setOptions] = useState<SelectFilterOption[]>([]);
    const [query, setQuery] = useState<string>('');
    const accountId = useSelector(selectActiveAccountId);
    const [isLoading, setIsLoading] = useState(false);
    const showSubResourcesInDataModelTable = useSelector(selectShowSubResourcesInDataModelTable);
    const debouncedSearch = useRef(0);

    useEffect(() => {
        const now = Date.now();
        debouncedSearch.current = now;
        setTimeout(async () => {
            if (now === debouncedSearch.current) {
                setIsLoading(true);
                const options = await getNameOptions(query, accountId, props.filters || [], showSubResourcesInDataModelTable);
                if (now === debouncedSearch.current) {
                    setOptions(options);
                    setIsLoading(false);
                }
            }
        }, 500);
    }, [query, accountId, props.filters, showSubResourcesInDataModelTable]);

    const onQueryChange = async (query: string) => {
        setQuery(query);
    };

    return (
        <SelectFilter
            label={'Name'}
            {...props}
            isMulti={true}
            search={FilterSearchEnum.CUSTOM}
            options={optionsWithSelected(options, props.value?.split(','))}
            onQueryChange={onQueryChange}
            isLoading={isLoading}
        />
    );
};

const getNameOptions = async (query: string, accountId: number, filters: Filter[], showSubResourcesInDataModelTable: boolean): Promise<SelectFilterOption[]> => {
    const typeFilter = filters.find(f => f.name === 'Type');
    const filtersSearchQuery = typeFilter?.value ? `type in (${typeFilter.value.split(',').filter(Boolean).map(v => `'${v}'`).join(',')})` : '';
    const nameSearchQuery = `name =~ '.*(?i)${query}.*'`;
    const eql = [filtersSearchQuery, nameSearchQuery].filter(q => !!q).join(' AND ');

    const { data } = await store.dispatch(nodesApi.endpoints.getDataModelResources.initiate({
        accountId,
        eql,
        page: 1,
        pageSize: 20,
        withSubResources: showSubResourcesInDataModelTable
    }));
    const resourceNames = (data?.items || []).map(r => r.name);
    const distinctResourceNames = [...new Set(resourceNames)];
    const sourcesWithPreferedExactMatch = [...distinctResourceNames].sort((a, b) => {
        const aExact = a === query;
        const bExact = b === query;
        if (aExact && !bExact) {
            return -1;
        }
        if (!aExact && bExact) {
            return 1;
        }
        return 0;
    });
    return sourcesWithPreferedExactMatch.map(name => ({
        label: name,
        value: name,
        icon: <LanguageIcon width="16" height="16" />
    }));
};

const optionsWithSelected = (options: SelectFilterOption[], selected: string[] | undefined): SelectFilterOption[] => {
    const optionsWithoutSelected = options.filter(o => !selected?.includes(o.value));
    const selectedOptions: SelectFilterOption[] = selected?.map(o => ({ label: o, value: o, icon: <LanguageIcon width="16" height="16" /> })) || [];
    return [...selectedOptions, ...optionsWithoutSelected];
};