import Modal from '../../../../components/Modal/Modal';
import FileDiff from '../../../../components/FileDiff';
import { ButtonTypes } from '../../../../components/button/types';

interface RefreshDataModelModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
  oldCode: string;
  newCode: string;
  onContinue: () => void;
  maxWidth?: 'max-w-md' | 'max-w-lg' | 'max-w-xl' | 'max-w-2xl' | 'max-w-3xl' | 'max-w-4xl';
  minWidth?: 'min-w-md' | 'min-w-lg' | 'min-w-xl' | 'min-w-2xl' | 'min-w-3xl' | 'min-w-4xl';
}

const RefreshDataModelModal = ({ isOpen, onClose, fileName, oldCode, newCode, onContinue, minWidth = 'min-w-md', maxWidth = 'max-w-4xl' }: RefreshDataModelModalProps) => {
  const diffFile = {
    fileName,
    oldCode,
    newCode
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Refresh model"
      maxWidth={maxWidth}
      minWidth={minWidth}
      buttons={[
        { type: ButtonTypes.secondary, text: 'Cancel', onClick: onClose },
        { type: ButtonTypes.primary, text: 'Continue', onClick: onContinue }
      ]}>
        <p className="text-sm text-gray-500 mb-4">Review changes in SQL file</p>
        <FileDiff file={diffFile} index={0} />
    </Modal>
  );
};

export default RefreshDataModelModal; 