import { useMemo, useCallback, useEffect } from 'react';
import { HealthBadge, HealthType } from 'src/components/HealthLabel';
import { GenericIntegration, GenericIntegrationType } from 'src/services/integrations/types';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { dateFormats, utcToLocal } from 'src/infrastructure/dateUtilities';
import { SOURCE_METADATA } from 'src/features/sources/Consts';
import { ComponentLoader } from 'src/components/loaders/ComponentLoader';
import { EmptyAccountIntegrations } from './EmptyCardComponents';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { notify } from 'src/components/Toaster';

interface AccountIntegrationsProps {
    accountId: number;
    integrations: GenericIntegration[];
    isLoading: boolean;
    error:  FetchBaseQueryError | SerializedError | undefined;
}

interface IntegrationListItemProps {
    integration: GenericIntegration;
    onNavigate: (source: GenericIntegration) => void;
}

interface IntegrationCardProps {
    integrationType: GenericIntegrationType;
    healthStatuses: Map<HealthType, number>;
}

export const AccountIntegrations = ({ integrations, isLoading, error }: AccountIntegrationsProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (error && 'status' in error && error.status !== 403) {
            notify('Failed to load integrations', 'error');
        }
    }, [error]);

    const goToSource = useCallback((source: GenericIntegration) => {
        navigate(`/sources/${source.id}`);
    }, [navigate]);
    
    const processIntegrations = useCallback(() => {
        try {
            if (error) {
                return { integrationHealthMap: new Map<GenericIntegrationType, Map<HealthType, number>>() };
            }
            
            if (!integrations || integrations.length === 0) {
                return { integrationHealthMap: new Map<GenericIntegrationType, Map<HealthType, number>>() };
            }
            
            const healthMap = new Map<GenericIntegrationType, HealthType[]>();
            
            integrations.forEach(integration => {
                const { integration_type, health } = integration;
                if (!healthMap.has(integration_type)) {
                    healthMap.set(integration_type, []);
                }
                const healthArray = healthMap.get(integration_type);
                if (healthArray && health) {
                    healthArray.push(health as HealthType);
                }
            });
            
            const integrationHealthMap = new Map<GenericIntegrationType, Map<HealthType, number>>();
            
            healthMap.forEach((healthArray, integrationType) => {
                const healthCounts = new Map<HealthType, number>();
                
                healthArray.forEach(health => {
                    healthCounts.set(health, (healthCounts.get(health) || 0) + 1);
                });
                
                integrationHealthMap.set(integrationType, healthCounts);
            });
            
            return { integrationHealthMap };
        } catch (err) {
            return { integrationHealthMap: new Map<GenericIntegrationType, Map<HealthType, number>>() };
        }
    }, [integrations, error]);
    
    const { integrationHealthMap } = useMemo(() => processIntegrations(), [processIntegrations]);
    
    if (error) {
        return <EmptyAccountIntegrations />;
    }
    
    if (integrations.length === 0 && !isLoading) {
        return <EmptyAccountIntegrations />;
    }
    
    return (
        <div className="rounded-lg bg-white p-4 shadow-md">
            <h2 className="mb-4 text-2xl font-bold">Account Source Integrations</h2>
            {isLoading ? (
                <div className="flex items-center justify-center h-full pb-10">
                    <ComponentLoader />
                </div>
            ) : (
                <div className="grid grid-rows-[auto_1fr] gap-y-2">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                        {[...integrationHealthMap].map(([integrationType, healthStatuses]) => (
                            <div key={integrationType} className="flex justify-center">
                                <IntegrationCard 
                                    integrationType={integrationType} 
                                    healthStatuses={healthStatuses} 
                                />
                            </div>
                        ))}
                    </div>
                    <ul className="max-h-72 space-y-4 overflow-auto">
                        {integrations.map((integration) => (
                            <IntegrationListItem 
                                key={integration.id} 
                                integration={integration} 
                                onNavigate={goToSource}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

const IntegrationCard = ({ integrationType, healthStatuses }: IntegrationCardProps) => {
    const sourceMetadata = SOURCE_METADATA.get(integrationType);
    if (!sourceMetadata) return null;
    
    const SourceIcon = sourceMetadata.icon;
    const label = sourceMetadata.label;
    
    return (
        <div className="bg-white shadow-md rounded-xl p-4 border border-gray-200 hover:shadow-lg transition-shadow duration-300 flex flex-col items-center text-center w-full max-w-[300px] sm:max-w-[220px] lg:max-w-[250px] h-full justify-center">
            <div className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full mb-2">
                <SourceIcon width="20" height="20" className="text-black" fill="#FF694A" />
            </div>
            <h3 className="font-semibold text-base text-gray-800 mb-3 truncate w-full px-2">{label}</h3>
            <div className="flex flex-wrap justify-center gap-2 mt-auto">
                {[...healthStatuses].map((health, index) => (
                    <HealthBadge key={index} health={health[0]} count={health[1]} className="text-xs px-2 py-1" />
                ))}
            </div>
        </div>
    );
};

const IntegrationListItem = ({ integration, onNavigate }: IntegrationListItemProps) => {
    const sourceMetadata = SOURCE_METADATA.get(integration.integration_type);
    if (!sourceMetadata) return null;
    
    const SourceIcon = sourceMetadata.icon;
    const label = sourceMetadata.label;
    
    return (
        <li className="flex items-center justify-between border-b p-3 last:border-none">
            <div className="flex w-full items-center gap-x-4">
                <SourceIcon width="24" height="24" className="text-black" fill="#FF694A" />
                <div className="flex flex-1 flex-col">
                    <h3 className="text-base font-semibold">{label}</h3>
                    <div className="flex gap-x-4 text-sm text-gray-500">
                        <p>Last Updated: {utcToLocal(integration?.last_updated_at?.toString(), dateFormats.monthsDaysHoursAndMinutes)}, by {integration?.last_updated_by}</p>
                    </div>
                </div>
            </div>
            <ArrowUpRightIcon 
                className="h-5 w-5 cursor-pointer text-gray-600 hover:text-black" 
                onClick={() => onNavigate(integration)} 
            />
        </li>
    );
};