import { useEffect, useState } from 'react';
import { useGetAccountsQuery } from '../../../services/accounts/accounts';
import { useCreateJiraIntegrationMutation, useGetJiraIntegrationsQuery, useUnlinkJiraIntegrationMutation } from '../../../services/integrations/integrations';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { customToast, notify } from '../../../components/Toaster';
import { extractErrorMessage } from '../../../services/api';
import Container from '../../../components/layout/Container';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import { EllipsisVerticalIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import Modal from '../../../components/Modal/Modal';
import { IJiraIntegration } from './types';
import { JiraIcon } from '../../../assets/images/icons/DelphiIcons';
import { WarningToast } from 'src/features/models/discover/view/DAG/WarningToast';
const jiraRedirectUrl = `${window.location.origin}/account?jiraIntegrationSuccess=true`;

const JiraIntegration = ({ accountId }: { accountId: number }) => {
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const { data: integrations = [], error } = useGetJiraIntegrationsQuery({ accountId }, { skip: !accountId });
  const [searchParams] = useSearchParams();
  const [createIntegration] = useCreateJiraIntegrationMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      notify(extractErrorMessage(error).message, 'error');
    }
  }, [error]);

  useEffect(() => {
    if (searchParams.get('jiraIntegrationSuccess')) {
      setShowConfigurationModal(true);
      navigate('/account');
      let msg = 'Jira integration successful';
      if (searchParams.get('setup_action') === 'request') {
        msg = 'Jira integration request sent successfully';
      }
      setTimeout(() => {
        customToast(<WarningToast toastId={'jiraIntegrationSuccess'} message={`${msg}`} />, { duration: 7000, style: { maxWidth: 'fit-content' }, id: 'jiraIntegrationSuccess' });
      }, 1000);
    }
  }, [navigate, searchParams]);

  const menuOptions = [
    {
      name: 'Reset integration',
      className: 'hover:bg-slate-50',
      onClick: () => {
        setShowConfigurationModal(true);
      }
    }
  ];

  const linkOrganization = async () => {
    try {
      const { url } = await createIntegration({ accountId, redirectUrl: jiraRedirectUrl }).unwrap();
      window.location.href = url;
    } catch (e) {
      notify(`Error setting-up integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <Container className="flex h-40 w-40 flex-col items-center justify-center px-4 py-4">
      <div className="-mr-2 -mt-2 ml-auto">
        <DropdownMenu items={menuOptions} className="bg-white">
          <EllipsisVerticalIcon className="text-slate-400" width={20} height={20} />
        </DropdownMenu>
      </div>
      <JiraIcon fill="#0A225C" width="56" height="56" className="mb-2 text-black" />
      <span className="mt-2 font-medium">Jira</span>
      {integrations.length === 0 ? (
        <Button type={ButtonTypes.secondary} text="Configure" onClick={linkOrganization} className="-mb-2 mt-1 h-6" />
      ) : (
        <span className="text-sm font-thin text-green-600">
          {integrations.length} active integration{integrations.length > 1 ? 's' : ''}
        </span>
      )}
      <IntegrationConfigurationModal
        isOpen={showConfigurationModal}
        onClose={() => setShowConfigurationModal(false)}
        accountId={accountId}
        integrations={integrations}
        linkOrganization={linkOrganization}
      />
    </Container>
  );
};

interface IntegrationConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: number;
  integrations: IJiraIntegration[];
  linkOrganization: () => void;
}

const IntegrationConfigurationModal = ({
  isOpen,
  onClose,
  accountId,
  integrations,
  linkOrganization
}: IntegrationConfigurationModalProps) => {
  const { data: accounts = [] } = useGetAccountsQuery();
  const [unlinkIntegration] = useUnlinkJiraIntegrationMutation();
  const [accountName, setAccountName] = useState('');
  const [, { isLoading: isLoadingCreateIntegration }] = useCreateJiraIntegrationMutation();

  useEffect(() => {
    if (accounts.length > 0) {
      const account = accounts.find((a) => a.id === accountId);
      if (account) {
        setAccountName(account.name);
      }
    }
  }, [accounts, accountId]);

  const onUnlinkClick = async () => {
    try {
      await unlinkIntegration({ accountId }).unwrap();
      notify('Integration unlinked successfully', 'success');
    } catch (e) {
      notify(`Error unlinking integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Reset Jira Integration"
      maxWidth="max-w-lg"
      buttons={[{ type: ButtonTypes.secondary, text: 'Close', onClick: onClose }]}>
      <div className="flex items-center justify-between text-sm">
        <span>
          Jira integration with <span className="font-medium">{accountName}</span>
        </span>
        <Button
          type={ButtonTypes.secondary}
          text="Reset Integration"
          icon={<PlusIcon width="16" height="16" />}
          onClick={linkOrganization}
          isLoading={isLoadingCreateIntegration}
          className="h-8"
        />
      </div>
      <div className="mt-3 rounded border border-slate-200 bg-slate-50">
        {integrations.map(() => (
          <div key="Jira Integration" className="flex items-center justify-between px-4 py-3">
            <span className="flex items-center">
              <JiraIcon fill="#0A225C" width="16" height="16" className="mr-2" /> Jira Integration
            </span>
            <span
              onClick={() => onUnlinkClick()}
              className="text-md flex cursor-pointer items-center font-normal text-danger hover:text-danger-strong">
              <XMarkIcon width="20" height="20" className="mr-1" /> Unlink
            </span>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default JiraIntegration;
