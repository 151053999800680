import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ButtonTypes } from "src/components/button/types";
import Input from "src/components/form/Input";
import Modal from "src/components/Modal/Modal";
import { notify } from "src/components/Toaster";
import { Filter } from "src/features/models/discover/toolbar/filters/Types";
import { DATA_MODEL_TABLE_KEY } from "src/features/models/discover/toolbar/tabs/types";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { selectShowSubResourcesInDataModelTable } from "src/infrastructure/state/slices/showSubResouresInDataModelTableSlice";
import { useCreateSavedTableViewMutation, useGetSavedTableViewsQuery, useUpdateSavedTableViewMutation } from "src/services/accounts/accounts";
import { getTabConfig } from "src/features/models/discover/toolbar/ToolbarUtils";
import { FilterMode } from "src/features/models/discover/types";
type DataModelTabSaveButtonProps = {
    tabId: number | null;
    filterMode: FilterMode;
    filters: Filter[];
    eql: string;
    setSelectedTabId: (tabId: number) => void;
}

export const DataModelTabSaveButton = ({ tabId, filterMode, filters, eql, setSelectedTabId }: DataModelTabSaveButtonProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const [createTab] = useCreateSavedTableViewMutation();
    const showSubResources = useSelector(selectShowSubResourcesInDataModelTable);
    const [saveTab, { isLoading: isLoadingSave }] = useUpdateSavedTableViewMutation();
    const [showNameModal, setShowNameModal] = useState(false);
    const [nameQuery, setNameQuery] = useState('');
    const { data: tabs } = useGetSavedTableViewsQuery({ accountId, tableKey: DATA_MODEL_TABLE_KEY });
    const tab = tabs?.find(t => t.id === tabId) || null;
    const [searchParams] = useSearchParams();
    const columns = (searchParams.get('columns') || '').split(',');
    const wasTabModified = useMemo(() => JSON.stringify(getTabConfig(filterMode, filters, eql, showSubResources, columns)) !== tab?.config, [filterMode, filters, eql, showSubResources, columns, tab?.config]);

    const onSave = async (force = false) => {
        try {
            const config = getTabConfig(filterMode, filters, eql, showSubResources, columns);
            if (tab) {
                await saveTab({ accountId, tableKey: DATA_MODEL_TABLE_KEY, viewId: tab.id, config: JSON.stringify(config) }).unwrap();
                notify('Filter saved', 'success');
                setShowNameModal(false);
            }
            else {
                if (force) {
                    const tab = await createTab({ accountId, tableKey: DATA_MODEL_TABLE_KEY, name: nameQuery, config: JSON.stringify(config) }).unwrap();
                    setSelectedTabId(tab.id);
                    setShowNameModal(false);
                    notify('Filter saved', 'success');
                }
                else {
                    setNameQuery('');
                    setShowNameModal(true);
                }
            }
        } catch (error) {
            console.error(error);
            notify('Failed to save filter', 'error');
        }
    };

    let label = '';
    if (isLoadingSave) {
        label = 'Saving..';
    }
    else if (!wasTabModified) {
        label = 'Saved';
    }
    else {
        label = 'Save filter';
    }

    return (
        <>
            <div className={`${label != 'Save filter' ? 'text-text-primary' : 'text-lilac-700'} w-16 text-center font-semibold ${wasTabModified ? 'cursor-pointer' : 'cursor-not-allowed'}`} onClick={() => onSave()}>
                {label}
            </div>
            <Modal
                title="Save filter"
                isOpen={showNameModal}
                onClose={() => setShowNameModal(false)}
                buttons={[
                    { text: 'Cancel', onClick: () => setShowNameModal(false), type: ButtonTypes.secondary },
                    { text: 'Create', onClick: () => onSave(true), type: ButtonTypes.primary, isLoading: isLoadingSave, isDisabled: !nameQuery }
                ]}
            >
                <Input onEnter={() => onSave(true)} placeholder="Enter name" value={nameQuery} onInputChange={e => setNameQuery(e.target.value)} />
            </Modal>
        </>
    );
};
