import { Bars4Icon } from "@heroicons/react/24/outline";
import { DiscoverSearch } from "./DiscoverSearch";
import { DiscoverView, FilterMode, SetSelectedSubNode, UpdateDataModelStateProps } from "../types";
import { DataModelIcon } from "../../../../assets/images/icons/DelphiIcons";
import { ISuperficialNode } from "../INode";
import { ExpandedNodesBadges } from "./ExpandedNodesBadges";
import { ExpandedNode } from "../view/DAG/types";
import { events, trackEvent } from "src/infrastructure/analytics";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSearchParams } from "react-router-dom";
import Modal from "src/components/Modal/Modal";
import { ButtonTypes } from "src/components/button/types";
import { DataModelTabs } from "src/features/models/discover/toolbar/tabs/DataModelTabs";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetFacetsQuery } from "src/services/nodes/nodes";
import { discoverFilterList, subresourceDiscoverFilters, proposalsFilter } from "src/features/models/discover/toolbar/filters/DiscoverFilterList";
import { mapBackendNodeTypeToLocalNodeType } from "src/services/nodes/transformers";
import { BackendNodeType } from "src/services/nodes/types";
import { FilterPanel } from "src/features/models/discover/toolbar/filters/DiscoveryFilterPanel";
import { DataModelTabSaveButton } from "src/features/models/discover/toolbar/tabs/DataModelTabSaveButton";
import { DATA_MODEL_TABLE_KEY } from "src/features/models/discover/toolbar/tabs/types";
import { useGetSavedTableViewsQuery } from "src/services/accounts/accounts";
import { getTabConfig } from "src/features/models/discover/toolbar/ToolbarUtils";
import { Filter } from "./filters/Types";
import { DataModelEqlInput } from "./DataModelEqlInput";


type DiscoverToolbarProps = {
    view: DiscoverView;
    setSelectedNode: (node: ISuperficialNode | null) => void;
    eql: string;
    setSelectedSubNode: SetSelectedSubNode;
    expandedNodes: ExpandedNode[];
    setExpandedNodes: (expandedNodes: ExpandedNode[]) => void;
    showSubResources: boolean;
    eqlErrorMessage: string | null;
    filterMode: FilterMode;
    isLoadingResources: boolean;
    customFilters: Filter[];
    updateDataModelState: (props: UpdateDataModelStateProps) => void;
}

export const DiscoverToolbar = ({
    view,
    setSelectedNode,
    eql,
    setSelectedSubNode,
    expandedNodes,
    setExpandedNodes,
    showSubResources,
    eqlErrorMessage,
    filterMode,
    isLoadingResources,
    customFilters,
    updateDataModelState
}: DiscoverToolbarProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const lastBasicEql = useRef('');
    const [eqlInEdit, setEqlInEdit] = useState(eql);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [showRevertModal, setShowRevertModal] = useState(false);
    const accountId = useSelector(selectActiveAccountId);
    const { data: facets } = useGetFacetsQuery({ accountId });
    const { hideShiftLeft } = useFlags();
    const [selectedTabId, setSelectedTabId] = useState<number | null>(null);
    const { data: tabs } = useGetSavedTableViewsQuery({ accountId, tableKey: DATA_MODEL_TABLE_KEY });
    const tab = tabs?.find(t => t.id === selectedTabId) || null;
    const columns = (searchParams.get('columns') || '').split(',');

    useEffect(() => {
        setEqlInEdit(eql);
    }, [eql]);

    //Load, filter and populate filters
    const filters = useMemo(() => {
        const availableBackendNodeTypes = facets?.type as BackendNodeType[] || [];
        const availableNodeTypes = availableBackendNodeTypes.map(t => mapBackendNodeTypeToLocalNodeType.get(t));
        const filtersList = showSubResources ? [...discoverFilterList, ...subresourceDiscoverFilters] : discoverFilterList;
        const filtersRelevantToNodeTypes = filtersList.filter(f => !f.nodeTypes || f.nodeTypes.some(t => availableNodeTypes.includes(t)));
        const filtersWithoutValues = [...filtersRelevantToNodeTypes];

        if (!hideShiftLeft) {
            filtersWithoutValues.splice(2, 0, proposalsFilter);
        }
        filtersWithoutValues.splice(2, 0, ...customFilters);
        const filtersWithValues = filtersWithoutValues.map((f) => {
            const value = searchParams.get(f.name) || null;
            return { ...f, value };
        });
        return filtersWithValues;
    }, [facets?.type, showSubResources, customFilters, hideShiftLeft, searchParams]);

    const wasTabModified = useMemo(
        () =>
            JSON.stringify(
                getTabConfig(filterMode, filters, eql, showSubResources, columns)
            ) !== tab?.config,
        [filterMode, filters, eql, showSubResources, columns, tab?.config]
    );


    useEffect(() => setUnsavedChanges(wasTabModified), [wasTabModified]);


    const setFilterMode = (mode: FilterMode, force = false) => {
        if (mode === 'basic') {
            if (eqlInEdit && lastBasicEql.current != eqlInEdit && !force) {
                setShowRevertModal(true);
            }
            else {
                searchParams.delete('eql');
                searchParams.set('filterMode', 'basic');
            }
        }
        else if (mode === 'eql') {
            lastBasicEql.current = eql;
            setEqlInEdit(eql);
            searchParams.set('eql', eql);
            searchParams.set('filterMode', 'eql');
        }
        else {
            console.log('Unknown filter mode', mode, 'setting to basic');
            searchParams.set('filterMode', 'basic');
        }
        setSearchParams(searchParams);
    };

    const updateView = (v: DiscoverView) => {
        updateDataModelState({ view: v });
        trackEvent(events.dataModelViewModeToggled, { view: v });
    };

    const borderColor = useMemo(() => {
        if (unsavedChanges) {
            return 'border-lilac-400';
        }

        if (filterMode === 'eql') {
            if (eqlErrorMessage) {
                return 'border-red-500';
            }
            if (eql !== eqlInEdit) {
                return 'border-lilac-400';
            }
        }

        return 'border-border';
    }, [filterMode, eqlErrorMessage, eql, eqlInEdit, unsavedChanges]);


    return (
        <div className="flex flex-col bg-white border-b px-4 py-4 border-border">
            <div className="flex justify-between items-center gap-2" id="discoverToolbar">
                <DataModelTabs filters={filters} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} updateDataModelState={updateDataModelState} />
                {
                    filterMode === 'eql' && (
                        <div className="flex-1">
                            <DataModelEqlInput eqlInEdit={eqlInEdit} setEqlInEdit={setEqlInEdit} isLoading={isLoadingResources} updateDataModelState={updateDataModelState} />
                        </div>
                    )
                }
                {
                    filterMode === 'basic' && (
                        <div className={`flex gap-1 border ${borderColor} rounded-lg p-1 bg-slate-100 flex-1 min-h-[48px]`}>
                            <FilterPanel filters={filters} updateDataModelState={updateDataModelState} />
                        </div>
                    )
                }
                <div className="flex gap-2 items-center">
                    <DataModelTabSaveButton tabId={selectedTabId} filterMode={filterMode} filters={filters} eql={eqlInEdit} setSelectedTabId={(tabId: number) => setSelectedTabId(tabId)} />
                    {
                        <div className="cursor-pointer flex p-2 bg-slate-100 rounded-lg font-semibold border border-border text-secondary">
                            <div onClick={() => setFilterMode('basic')} className={`px-2 py-1 rounded ${filterMode === 'basic' && 'bg-white text-lilac-900'}`}>
                                Basic
                            </div>
                            <div onClick={() => setFilterMode('eql')} className={`px-2 py-1 rounded ${filterMode === 'eql' && 'bg-white text-lilac-900'}`}>
                                EQL
                            </div>
                        </div>
                    }
                    {
                        view === DiscoverView.Graph && (
                            <>
                                <ExpandedNodesBadges expandedNodes={expandedNodes} setExpandedNodes={setExpandedNodes} />
                                <DiscoverSearch setSelectedNode={setSelectedNode} setSelectedSubNode={setSelectedSubNode} />
                            </>
                        )
                    }
                    <div className='w-fit rounded-lg bg-slate-100 p-1.5 flex items-center border border-border'>
                        {
                            [DiscoverView.Table, DiscoverView.Graph].map((v) => (
                                <div key={v} data-test-id={v} className={`rounded p-2 cursor-pointer hover:bg-white ${view === v && 'bg-white'}`} onClick={() => updateView(v)}>
                                    {v === DiscoverView.Table ? <Bars4Icon width="16" height="16" className={view === v ? "text-primary" : "text-slate-400"} /> : <DataModelIcon width="16" height="16" className={`p-0.5 ${view === v ? "text-primary" : "text-slate-400"}`} />}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Modal
                    title={"Are you sure?"}
                    buttons={[{ text: 'Cancel', type: ButtonTypes.secondary, onClick: () => setShowRevertModal(false) }, { text: 'Revert', onClick: () => { setFilterMode('basic', true); setShowRevertModal(false); }, type: ButtonTypes.primary }]}
                    isOpen={showRevertModal}
                    onClose={() => setShowRevertModal(false)}
                >
                    <div className="p-4">
                        <div>Switching back to Basic mode will revert any changes applied to EQL</div>
                    </div>
                </Modal>
            </div>
            <div className="h-4 mt-1 text-sm ml-14">
                {
                    filterMode === 'eql' && eqlErrorMessage && <div className="text-red-500 ml-24">{eqlErrorMessage}</div>
                }
            </div>
        </div>
    );
};
