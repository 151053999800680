import { GenericIntegrationType } from "src/services/integrations/types";

export enum LayerType {
    Data = 'data',
    BI = 'bi'
}
export type LayerItem = {
    type: string;
    name: string;
    total?: number;
    layer: LayerType;
    integrationType: GenericIntegrationType;
    eql: string;
};


export interface LayerSlice {
    dataLayerItems: LayerItem[];
    biLayerItems: LayerItem[];
    isLoading: boolean;
    error: string | null;
}