import {
  ChangeType,
  IChange,
  NewColumnChangeData,
  NewMeasureChangeData,
  NewModelChangeData,
  RefreshDataModelChangeData,
} from '../IChange';
import { BranchIcon } from '../../../assets/images/icons/DelphiIcons';
import { PencilIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Input from '../../../components/form/Input';
import Textarea from '../../../components/form/Textarea';
import NewColumnForm from '../changeForms/newColumn/NewColumnForm';
import Select, { Option } from '../../../components/form/Select';
import { useGetProjectBranchesQuery, useGetProjectQuery } from '../../../services/projects/projects';
import { notify } from '../../../components/Toaster';
import { BackendErrorType, extractErrorMessage } from '../../../services/api';
import { useUpdateChangeMutation } from '../../../services/changes/changes';
import NewMeasureForm from '../changeForms/newMeasure/NewMeasureForm';
import Modal from '../../../components/Modal/Modal';
import { NewModelForm } from '../changeForms/newModel/NewModelForm';
import { NewAggregateTableForm } from '../changeForms/newAggregateTable/components/NewAggregateTableForm';
import { NewAggregateTableChangeData } from '../changeForms/newAggregateTable/types';
import { events, trackEvent } from '../../../infrastructure/analytics';
import { onBeforeSubmitAggregateTableChange } from '../changeForms/newAggregateTable/utilities/onBeforeSubmitAggregateTableChange';
import { useDefaultTargetBranch } from '../../../services/changes/hooks';
import { RefreshDataModelForm } from '../changeForms/refreshDataModel/RefreshDataModelForm';
import { ButtonTypes } from '../../../components/button/types';
import { CompilationError } from '../CompilationError';
import Button from '../../../components/button/Button';

interface EditChangeSidepaneProps {
  isOpen: boolean;
  onClose: () => void;
  change: IChange;
}

const EditChangeSidepane = ({ isOpen, onClose, change }: EditChangeSidepaneProps) => {
  const projectId = change.projectId;
  const { data: project } = useGetProjectQuery(projectId);
  const [title, setTitle] = useState(change.title);
  const [description, setDescription] = useState(change.description);
  const [changeData, setChangeData] = useState(change.changeData);
  const [changeType, setChangeType] = useState(change.changeType);
  const getProjectBranchesQuery = useGetProjectBranchesQuery({ id: projectId });
  const branchOptions = useMemo(
    () => (getProjectBranchesQuery?.data || []).map((b) => ({ label: b.name, value: b.name })),
    [getProjectBranchesQuery]
  );
  const [targetBranch, setTargetBranch] = useDefaultTargetBranch(project, branchOptions, change);
  const [updateChange] = useUpdateChangeMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [compilationError, setCompilationError] = useState('');

  useEffect(() => {
    setTitle(change.title);
    setDescription(change.description);
    setChangeData(change.changeData);
    setChangeType(change.changeType);
  }, [change, project]);

  const onConfirm = async () => {
    setIsLoading(true);
    const updatedChange = {
      ...change,
      title,
      description,
      changeData: { ...changeData },
      targetBranch
    };
    const typeSpecificEnrichedChange = enrichChangePerType(updatedChange);
    try {
      trackEvent(events.dataModelChangeEdited, { change: updatedChange });
      if (!change.id) {
        throw new Error('Change ID is required for editing');
      }
      await updateChange({
        projectId: change.projectId,
        changeId: change.id,
        change: typeSpecificEnrichedChange
      }).unwrap();
      notify('Change updated successfully', 'success');
      onClose();
    } catch (e) {
      const extractedError = extractErrorMessage(e);
      if (extractedError.type === BackendErrorType.MetricFlow) {
        setCompilationError(extractedError.message);
      } else {
        notify(`Error updating change: ${extractedError.message}`, 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      withHeader={false}
      maxWidth="max-w-2xl"
      title='Edit change'
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex max-h-[75vh] flex-col gap-2 overflow-y-auto">
        <div className="flex items-center gap-1 text-sm text-primary">
          <PencilIcon width="14" height="14" />
          Edit change #{change.id}
        </div>
        <div className="text-sm text-tertiary">Euno has suggested the following change</div>
        <div className="flex items-center gap-2">
          <div className="w-24 text-sm font-medium text-secondary">Change title</div>
          <div className="flex-1">
            <Input
              value={title}
              onInputChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
              placeholder="Enter title"
              className="font-medium"
            />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="w-24 text-sm font-medium text-secondary">Description</div>
          <div className="flex-1">
            <Textarea
              value={description}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
              placeholder="Enter description"
              className=""
              rows={4}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-24 text-sm font-medium text-secondary">Target branch</div>
          <div className="flex-1">
            <Select
              icon={<BranchIcon width="16" height="16" className="ml-2 mr-1" fill="#94A3B8" />}
              className="w-full rounded border shadow"
              options={branchOptions}
              value={targetBranch}
              onChange={(option: Option | Option[]) => setTargetBranch((option as Option).value as string)}
            />
          </div>
        </div>
        {changeType === ChangeType.NewColumn && (
          <NewColumnForm
            sourceName={change.sourceName}
            sourceUtl={change.sourceUtl}
            changeData={changeData as NewColumnChangeData}
            setChangeData={setChangeData}
            sourceType={change.sourceType}
          />
        )}
        {changeType === ChangeType.NewMeasure && (
          <NewMeasureForm
            sourceName={change.sourceName}
            sourceUtl={change.sourceUtl}
            changeData={changeData as NewMeasureChangeData}
            setChangeData={setChangeData}
            sourceType={change.sourceType}
          />
        )}
        {changeType === ChangeType.NewModel && (
          <NewModelForm
            sourceName={change.sourceName}
            sourceUtl={change.sourceUtl}
            changeData={changeData as NewModelChangeData}
            setChangeData={setChangeData}
            sourceType={change.sourceType}
          />
        )}
        {changeType === ChangeType.NewAggregateTable && (
          <NewAggregateTableForm
            proposal={change}
            changeData={changeData as NewAggregateTableChangeData}
            setChangeData={setChangeData}
            setCompilationError={setCompilationError}
          />
        )}
        {changeType === ChangeType.RefreshDataModel && (
          <RefreshDataModelForm
            changeData={changeData as RefreshDataModelChangeData}
          />
        )}
      </div>
      <div className="mt-4 flex items-center">
        <CompilationError error={compilationError} />
        <div className="ml-auto flex gap-4">
          <Button
            type={ButtonTypes.primary}
            text='Update change'
            onClick={onConfirm}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

const enrichChangePerType = (change: IChange): IChange => {
  switch (change.changeType) {
    case ChangeType.NewAggregateTable:
      return onBeforeSubmitAggregateTableChange(change, change.changeData as NewAggregateTableChangeData);
    default:
      return change;
  }
};

export default EditChangeSidepane;
