export function debounce<Args extends unknown[], R>(
    func: (...args: Args) => R,
    wait: number
) {
    let timeout: number;

    const debounced = (...args: Args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };

    debounced.cancel = () => clearTimeout(timeout);

    return debounced;
} 