import { useEffect, useCallback, useMemo } from "react";
import { LightBulbIcon, ExclamationCircleIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { CustomProperty, CustomPropertyFEType, MultiSelectOption, ChartDataItem } from "src/services/customProperties/types";
import { useGetDataModelMultiCountQuery } from "src/services/nodes/nodes";
import { InsightsChart, InsightsFixedChart, InsightsLiveBooleanChart } from "./InsightsChart";
import { notify } from "src/components/Toaster";
import { ComponentLoader } from "src/components/loaders/ComponentLoader";
import { extractErrorMessage } from "src/services/api";

interface InsightsPanelProps {
    property: CustomProperty;
    accountId: number;
}


export const InsightsPanel = ({ property, accountId }: InsightsPanelProps) => {
    const expressions = useMemo(() => {
        try {
            switch (property.content?.type) {
                case CustomPropertyFEType.CategoryAssigned: {
                    return property.content.content.options
                        .filter(option => !!option.eql)
                        .map(option => option.eql as string);
                }
                case CustomPropertyFEType.LiveBoolean: {
                    const { content: booleanContent } = property.content;
                    const scope = booleanContent?.scope || "";
                    const eql = `${booleanContent?.eql} AND ${scope}` || "";
                    return [scope, eql];
                }
                case CustomPropertyFEType.AggregateRelationships: {
                    const { content: aggregateContent } = property.content;
                    const applies_to_eql = aggregateContent?.applies_to_eql || "";
                    const target_eql = aggregateContent?.target_eql || "";
                    return [applies_to_eql, target_eql];
                }
                case CustomPropertyFEType.MultiSelect:{
                    const options = property.content?.content?.options;
                    const expressions = [property.propertyScope];
                    if (options) {
                        expressions.push(...options.map(option => `${property.propertyScope} and ${property.eqlName} in ("${option}")`));
                    }
                    return expressions;
                }
                case CustomPropertyFEType.AISelect:
                case CustomPropertyFEType.Numeric:
                case CustomPropertyFEType.Datetime:
                case CustomPropertyFEType.FixedBoolean: {
                    return [property.propertyScope, `${property.propertyScope} and ${property.eqlName} is not null`];
                }
                case CustomPropertyFEType.SingleSelect: {
                    const options = property.content?.content?.options;
                    const expressions = [property.propertyScope];
                    if (options) {
                        expressions.push(...options.map(option => `${property.propertyScope} and ${property.eqlName} = "${option}"`));
                    }
                    return expressions;
                }
                default:
                    return [];
            }
        } catch (error) {
            console.error("Error generating property expression:", error);
            return [];
        }
    }, [property]);

    const {
        data: multiCountData,
        isLoading: isLoadingMultiCount,
        error: multiCountError,
        refetch: refetchMultiCount
    } = useGetDataModelMultiCountQuery({
        accountId,
        expressions: expressions
    }, {
        skip: !accountId
    });



    useEffect(() => {
        if (multiCountError) {
            console.error("Query error:", multiCountError);
            notify(extractErrorMessage(multiCountError).message, "error");
        }
    }, [multiCountError]);

    const processMultiCountData = useCallback((): ChartDataItem[] => {
        try {
            if (!multiCountData) return [];

            if (multiCountError) {
                notify(`Failed to retrieve multi-count data`, 'error');
                return [];
            }
            const validItems: ChartDataItem[] = [];

            switch (property.content?.type) {
                case CustomPropertyFEType.CategoryAssigned: {
                    multiCountData.forEach(item => {
                        if (!item || item.count === 0) return;
                        let name = item.expression || "";
                        const content = property.content?.content;
                        if ('options' in content && Array.isArray(content.options)) {
                            const matchingOption = content.options.find(opt => (opt as MultiSelectOption).eql === item.expression);
                            if (matchingOption) {
                                const option = matchingOption as MultiSelectOption;
                                name = option.value || "";
                            }
                        }
                        validItems.push({
                            name,
                            value: item.count,
                            increment: undefined,
                            eql: item.expression
                        });
                    });
                    break;
                }

                case CustomPropertyFEType.LiveBoolean: {
                    const { content } = property.content;
                    const scope = content?.scope || "";
                    const trueEql = `${content?.eql} AND ${scope}` || "";
                    const falseEql = `${content?.eql} AND NOT ${scope}` || "";
                    validItems.push(
                        {
                            name: "False",
                            value: multiCountData[0]?.count - multiCountData[1]?.count || 0,
                            increment: undefined,
                            eql: falseEql
                        },
                        {
                            name: "True",
                            value: multiCountData[1]?.count || 0,
                            increment: undefined,
                            eql: trueEql
                        },
                    );
                    break;
                }

                case CustomPropertyFEType.AggregateRelationships: {
                    validItems.push(
                        {
                            name: "Applies To EQL",
                            value: multiCountData[0]?.count || 0,
                            increment: undefined,
                            eql: property.content?.content?.applies_to_eql || ""
                        },
                        {
                            name: "Target EQL",
                            value: multiCountData[1]?.count || 0,
                            increment: undefined,
                            eql: property.content?.content?.target_eql || ""
                        }
                    );
                    break;
                }
                case CustomPropertyFEType.AISelect:
                case CustomPropertyFEType.FixedBoolean:
                case CustomPropertyFEType.Numeric:
                case CustomPropertyFEType.Datetime: {
                    validItems.push(
                        {
                            name: "scope",
                            value: multiCountData[0]?.count || 0,
                            increment: undefined,
                            eql: property.propertyScope || ""
                        },
                        {
                            name: "Set Resources",
                            value: multiCountData[1]?.count || 0,
                            increment: undefined,
                            eql: `${property.propertyScope} and ${property.eqlName} is not null` || ""
                        },
                    );
                    break;
                }
                case CustomPropertyFEType.MultiSelect:
                case CustomPropertyFEType.SingleSelect: {
                    validItems.push(
                        {
                            name: "scope",
                            value: multiCountData[0]?.count || 0,
                            increment: undefined,
                            eql: property.propertyScope || ""
                        },
                    );
                    const options = property.content?.content?.options;
                    if (options) {
                        validItems.push(...options.map((option, index) => ({
                            name: option,
                            value: multiCountData[index + 1]?.count || 0,
                            increment: undefined,
                            eql: `${property.propertyScope} and ${property.eqlName} = "${option}"`
                        })));
                    }
                    break;
                }
            }
            return validItems;
        } catch (err) {
            console.error("Error processing multi-count results:", err);
            notify(`Error processing property data`, 'error');
            return [];
        }
    }, [multiCountData, multiCountError, property]);



    const chartData = useMemo(() => processMultiCountData(), [processMultiCountData]);

    const isLoading = isLoadingMultiCount;

    const hasError = !!multiCountError;
    const errorMessage = "Unable to load property insights. Please try again later.";


    return (
        <div className="w-full p-6">
            <div className="flex items-center gap-2 mb-4">
                <div className="p-1 rounded-md bg-slate-200 border border-slate-300">
                    <LightBulbIcon className="h-5 w-5 text-slate-600" />
                </div>
                <h2 className="text-lg font-semibold">Insights</h2>
            </div>

            {isLoading && (
                <ComponentLoader label="Loading insights data" />
            )}

            {!isLoading && hasError && (
                <ErrorMessage
                    message={errorMessage}
                    onRetry={refetchMultiCount}
                />
            )}

            {!isLoading && !hasError && (
                <div className="flex items-center w-full gap-x-2 mb-2">
                    {(() => {
                        switch (property.content?.type) {
                            case CustomPropertyFEType.LiveBoolean:
                                return <InsightsLiveBooleanChart data={chartData} />;
                            case CustomPropertyFEType.MultiSelect:
                            case CustomPropertyFEType.SingleSelect:
                            case CustomPropertyFEType.FixedBoolean:
                            case CustomPropertyFEType.Numeric:
                            case CustomPropertyFEType.Datetime:
                            case CustomPropertyFEType.AISelect:
                                return <InsightsFixedChart data={chartData} />;
                            case CustomPropertyFEType.AggregateRelationships:
                            case CustomPropertyFEType.CategoryAssigned:
                                return <InsightsChart data={chartData} />;
                            default:
                                return null;
                        }
                    })()}
                </div>
            )}
        </div>
    );
};

interface ErrorMessageProps {
    message?: string;
    onRetry?: () => void;
}

const ErrorMessage = ({ message = "Unable to load insights. Please try again later.", onRetry }: ErrorMessageProps) => {
    return (
        <div className="w-full rounded-lg border border-red-100  p-4 text-center">
            <div className="flex flex-col items-center justify-center gap-3">
                <ExclamationCircleIcon className="h-10 w-10 text-red-400" />
                <p className="text-sm font-medium text-red-500">{message}</p>
                {onRetry && (
                    <button
                        onClick={onRetry}
                        className="mt-2 inline-flex items-center gap-1 rounded-md bg-white px-3 py-1.5 text-xs font-medium text-red-600 shadow-sm border border-red-200 hover:bg-red-50 transition-colors"
                    >
                        <ArrowPathIcon className="h-3.5 w-3.5" />
                        Try Again
                    </button>
                )}
            </div>
        </div>
    );
};