import { createClient } from '@liveblocks/client';
import api from './api';
import { store } from '../infrastructure/state/store';

let liveblocksClient: ReturnType<typeof createClient> | null = null;

interface LiveblocksCredentials {
  token: string;
}

export const liveblocksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLiveblocksCredentials: build.query<LiveblocksCredentials, number>({
      query: (accountId) => `/accounts/${accountId}/liveblocks/credentials`
    })
  })
});

export const { useGetLiveblocksCredentialsQuery } = liveblocksApi;

export const getLiveblocksClient = async (accountId: number) => {
  if (!liveblocksClient) {
    const { data } = await store.dispatch(liveblocksApi.endpoints.getLiveblocksCredentials.initiate(accountId));
    if (data) {
      liveblocksClient = createClient({
        authEndpoint: async () => ({ token: data.token }),
      });
    }
  }
  return liveblocksClient;
}; 