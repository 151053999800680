import { useSelector } from 'react-redux';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import GithubIntegration from './GithubIntegration';
import GitlabIntegration from './GitlabIntegration';
import JiraIntegration from './JiraIntegration.tsx';


const IntegrationsSection = () => {
  const accountId = useSelector(selectActiveAccountId);

  return (
    <div className="mb-10">
      <div className="mb-2 h-8 text-lg">
        <span className="text-text-primary">Linked Accounts</span>
      </div>
      <div className="flex gap-3">
        <GithubIntegration accountId={accountId}/>
        <GitlabIntegration accountId={accountId}/>
        <JiraIntegration accountId={accountId}/>
      </div>
    </div>
  );
};


export default IntegrationsSection;
