import { ChangeData, ChangeStatus, ChangeType, IChange, IChangePage, NewColumnChangeData, NewMeasureChangeData, NewModelChangeData, Pat, RefreshDataModelChangeData, SuperficialPat } from "../../features/evolution/IChange";
import { NewAggregateTableChangeData } from "../../features/evolution/changeForms/newAggregateTable/types";
import { NodeType, SubnodeType } from "../../features/models/discover/INode";
import { transformAggregateTableChangeDataToBackend, transformAggregateTableChangeDataToLocal } from "./aggregateTableTransformers";
import { BackendChangeType, UpdateChangeRequest, NewModelChangeDataBackendMeasure, NewModelChangeDataBackendColumn, BackendChangeData, GetChangesPageResponse, BackendChange, BackedPat, PatResponse, CreationContextBackend, BackendCreationContextType } from "./types";

export const stateToStatus = {
    change_draft: ChangeStatus.Open,
    proposal: ChangeStatus.Proposal,
    pr_rejected: ChangeStatus['PR Rejected'],
    draft_rejected: ChangeStatus['Draft Rejected'],
    pending_merge: ChangeStatus['Pending Merge'],
    merged: ChangeStatus.Merged,
    deployed: ChangeStatus.Deployed,
    completed: ChangeStatus.Published
};

export const mapChangeTypeToBackendChangeType = new Map<ChangeType, BackendChangeType>([
    [ChangeType.NewColumn, BackendChangeType.AddColumnToExistingModel],
    [ChangeType.NewMeasure, BackendChangeType.PromoteMeasure],
    [ChangeType.NewModel, BackendChangeType.AddModel],
    [ChangeType.NewAggregateTable, BackendChangeType.AddAggregateTable],
    [ChangeType.RefreshDataModel, BackendChangeType.RefreshDataModel]
]);

export const mapBackendChangeTypeToChangeType = new Map<BackendChangeType, ChangeType>([
    [BackendChangeType.AddColumnToExistingModel, ChangeType.NewColumn],
    [BackendChangeType.PromoteMeasure, ChangeType.NewMeasure],
    [BackendChangeType.AddModel, ChangeType.NewModel],
    [BackendChangeType.AddAggregateTable, ChangeType.NewAggregateTable],
    [BackendChangeType.RefreshDataModel, ChangeType.RefreshDataModel]
]);

export const transformChangeToRequest = (change: IChange): UpdateChangeRequest => {
    return {
        title: change.title,
        description: change.description,
        change_content: {
            type_specific_content: transformChangeContentToBackend(change.changeData, change.changeType)
        },
        target_branch: change.targetBranch
    };
};

const transformChangeContentToBackend = (changeData: ChangeData, changeType: ChangeType): BackendChangeData => {
    const newColumnChangeData = changeData as NewColumnChangeData;
    const newMeasureChangeData = changeData as NewMeasureChangeData;
    const newModelChangeData = changeData as NewModelChangeData;
    const refreshDataModelChangeData = changeData as RefreshDataModelChangeData;

    switch (changeType) {
        case ChangeType.NewColumn:
            return {
                ...newColumnChangeData,
                column_name: newColumnChangeData.newColumnName,
                change_type: BackendChangeType.AddColumnToExistingModel,
                model_unique_id: newColumnChangeData.modelId,
                column_metadata: {
                    description: newColumnChangeData.description,
                    tags: newColumnChangeData.tags,
                    meta: localMetadataToBackendMetadata(newColumnChangeData.meta),
                    column_is_nullable: newColumnChangeData.isNullable,
                    column_is_unique: newColumnChangeData.isUnique,
                    column_accepted_values: newColumnChangeData.acceptedValues,
                    relationships: localRelationshipToBackendRelationship(newColumnChangeData.relationships)
                },
            };
        case ChangeType.NewMeasure:
            return {
                ...newMeasureChangeData,
                name: newMeasureChangeData.newMeasureName,
                change_type: BackendChangeType.PromoteMeasure,
                model_unique_id: newMeasureChangeData.modelId,
                measure_metadata: {
                    description: newMeasureChangeData.description,
                    tags: newMeasureChangeData.tags,
                    meta: localMetadataToBackendMetadata(newMeasureChangeData.meta)
                },
            };
        case ChangeType.NewModel:
            return {
                ...newModelChangeData,
                change_type: BackendChangeType.AddModel,
                model_name: newModelChangeData.newModelName,
                project_name: newModelChangeData.projectName,
                sql: newModelChangeData.sql,
                meta_data: {
                    meta: localMetadataToBackendMetadata(newModelChangeData.meta),
                    tags: newModelChangeData.tags,
                    description: newModelChangeData.description
                },
                materialization: newModelChangeData.materialization,
                columns: newModelChangeData.subnodes.filter(sn => sn.type === SubnodeType.Column).map(subnode => ({
                    column_name: subnode.name,
                    column_metadata: {
                        description: subnode.description,
                        tags: subnode.tags,
                        meta: localMetadataToBackendMetadata(subnode.meta),
                        column_type: subnode.dataType,
                        column_is_nullable: subnode.isNullable,
                        column_is_unique: subnode.isUnique,
                        column_accepted_values: subnode.acceptedValues,
                        relationships: localRelationshipToBackendRelationship(subnode.relationships)
                    }
                })),
                measures: newModelChangeData.subnodes.filter(sn => sn.type === SubnodeType.Measure).map(subnode => ({
                    name: subnode.name,
                    label: subnode.label,
                    agg: subnode.agg,
                    expr: subnode.expr,
                    measure_metadata: {
                        description: subnode.description,
                        tags: subnode.tags,
                        meta: localMetadataToBackendMetadata(subnode.meta)
                    }
                })),
            };
        case ChangeType.NewAggregateTable:
            return transformAggregateTableChangeDataToBackend(changeData as NewAggregateTableChangeData);
        case ChangeType.RefreshDataModel:
            return {
                ...refreshDataModelChangeData,
                change_type: BackendChangeType.RefreshDataModel,
                model_unique_id: refreshDataModelChangeData.modelUniqueId,
                refreshed_model_sql: refreshDataModelChangeData.refreshedModelSQL
            };
    }
};

export const localMetadataToBackendMetadata = (metadata: { key: string; value: string }[]) => {
    return metadata.reduce(
        (acc, { key, value }) => {
            acc[key] = value;
            return acc;
        },
        {} as { [key: string]: string }
    );
};

const generateChangeDataFromBackendResponse = (changeData: BackendChangeData, changeContext: CreationContextBackend | null): ChangeData => {
    switch (changeData.change_type) {
        case BackendChangeType.AddColumnToExistingModel:
            return {
                ...changeData,
                newColumnName: changeData.column_name,
                modelId: changeData.model_unique_id,
                description: changeData.column_metadata?.description || '',
                tags: changeData.column_metadata?.tags || [],
                customFieldInDataApplication: changeContext?.type_specific_content.expression_name || '',
                meta: backendMetadataToLocalMetadata(changeData.column_metadata.meta),
                isUnique: changeData.column_metadata?.column_is_unique || false,
                isNullable: changeData.column_metadata?.column_is_nullable || false,
                acceptedValues: changeData.column_metadata?.column_accepted_values || [],
                relationships: backendRelationshipToLocalRelationship(changeData.column_metadata?.relationships)
            };
        case BackendChangeType.PromoteMeasure:
            return {
                ...changeData,
                newMeasureName: changeData.name,
                nameInDataApplication: changeContext?.type_specific_content.expression_name || '',
                modelId: changeData.model_unique_id,
                description: changeData.measure_metadata?.description || '',
                tags: changeData.measure_metadata?.tags || [],
                meta: backendMetadataToLocalMetadata(changeData.measure_metadata.meta)
            };
        case BackendChangeType.AddModel:
            return {
                ...changeData,
                modelId: '',
                projectName: changeData.project_name,
                newModelName: changeData.model_name,
                sql: changeData.sql,
                description: changeData.meta_data.description,
                tags: changeData.meta_data.tags,
                meta: backendMetadataToLocalMetadata(changeData.meta_data.meta),
                materialization: changeData.materialization,
                subnodes: [
                    changeData.measures.map((measure: NewModelChangeDataBackendMeasure) => ({
                        originalName: measure.name,
                        type: SubnodeType.Measure,
                        name: measure.name,
                        description: measure.measure_metadata.description,
                        tags: measure.measure_metadata.tags,
                        meta: backendMetadataToLocalMetadata(measure.measure_metadata.meta),
                        agg: measure.agg,
                        expr: measure.expr,
                        label: measure.label,
                        dataType: '',
                        isUnique: false,
                        isNullable: false,
                        acceptedValues: [],
                        relationships: null
                    })),
                    changeData.columns.map((column: NewModelChangeDataBackendColumn) => ({
                        originalName: column.column_name,
                        type: SubnodeType.Column,
                        name: column.column_name,
                        description: column.column_metadata.description,
                        tags: column.column_metadata.tags,
                        meta: backendMetadataToLocalMetadata(column.column_metadata.meta),
                        agg: '',
                        expr: '',
                        label: '',
                        dataType: column.column_metadata.column_type,
                        isUnique: column.column_metadata.column_is_unique,
                        isNullable: column.column_metadata.column_is_nullable,
                        acceptedValues: column.column_metadata.column_accepted_values,
                        relationships: backendRelationshipToLocalRelationship(column.column_metadata.relationships)
                    }))
                ].flat()
            };
        case BackendChangeType.AddAggregateTable:
            return transformAggregateTableChangeDataToLocal(changeData);
        case BackendChangeType.RefreshDataModel:
            return {
                refreshedModelSQL: changeData.refreshed_model_sql,
                modelUniqueId: changeData.model_unique_id,
            };
    }
};

export const backendMetadataToLocalMetadata = (metadata: { [key: string]: string }): { key: string; value: string }[] => {
    return Object.entries(metadata).map(([key, value]) => ({ key, value }));
};

export const backendRelationshipToLocalRelationship = (relationship: { to_model_unique_id: string; to_column_name: string } | null) => {
    return relationship
        ? {
            model: relationship.to_model_unique_id,
            dimension: relationship.to_column_name
        }
        : null;
};

export const localRelationshipToBackendRelationship = (relationship: { model: string; dimension: string } | null) => {
    return relationship
        ? {
            to_model_unique_id: relationship.model,
            to_column_name: relationship.dimension
        }
        : null;
};

export const transformChangePageResponse = (change: GetChangesPageResponse): IChangePage => {
    return {
        id: change.id,
        title: change.title,
        status: stateToStatus[change.state],
        changeType: mapBackendChangeTypeToChangeType.get(change.change_type)!,
        createdAt: change.created_at,
        createdBy: change.created_by_email,
        createdByResourceType: transformBackendCreationContextTypeToLocal(change.creation_context?.type_specific_content.creation_context_type || ''),
        createdByResourceName: change.creation_context?.created_by_resource_name || change.creation_context?.created_by_name_in_data_application || '',
    };
};

const transformBackendCreationContextTypeToLocal = (creationContextType: BackendCreationContextType | string): NodeType => {
    switch (creationContextType) {
        case 'dbt_project':
            return NodeType.DataModel;
        case 'looker_look_dynamic_field':
            return NodeType.LookerLook;
        case 'looker_tile':
            return NodeType.LookerTile;
        case 'looker_view':
            return NodeType.LookerView;
        default:
            return NodeType.GenericDataTransformation;
    }
};

export const transformChangeResponse = (change: BackendChange): IChange => {
    return {
        id: change.id,
        title: change.title,
        description: change.description,
        status: stateToStatus[change.state],
        changeType: mapBackendChangeTypeToChangeType.get(change.change_content.type_specific_content.change_type)!,
        createdAt: change.created_at,
        createdBy: change.created_by_email,
        updatedAt: change.updated_at,
        updatedBy: change.updated_by || '',
        sourceType: transformBackendCreationContextTypeToLocal(change.creation_context?.type_specific_content.creation_context_type || ''),
        sourceName: change.creation_context?.created_by_resource_name || change.creation_context?.created_by_name_in_data_application || '',
        changeData: generateChangeDataFromBackendResponse(change.change_content.type_specific_content, change.creation_context),
        pullRequestUrl: change.associated_pull_request_url || '',
        buildId: change.applied_in_build_id,
        diff: [],
        projectId: change.project_id,
        targetBranch: change.target_branch,
        pullRequestNumber: change.associated_pull_request_number,
        targetUtl: change.target_model_utl || '',
        sourceUtl: change.creation_context?.source_utl || ''
    };
};

export const transformPatResponse = (patResponse: PatResponse): Pat | null => {
    if (!patResponse.shift_left_potential || !patResponse.shift_left_type) {
        return null;
    }
    return {
        projectId: parseInt(patResponse.shift_left_potential.project),
        title: patResponse.shift_left_potential.title,
        description: patResponse.shift_left_potential.description,
        changeType: mapBackendChangeTypeToChangeType.get(patResponse.shift_left_type)!,
        changeData: generateChangeDataFromBackendResponse(patResponse.shift_left_potential.change_content.type_specific_content, patResponse.shift_left_potential.creation_context),
        targetBranch: patResponse.shift_left_potential.target_branch,
        sourceUtl: patResponse.shift_left_potential.creation_context.source_utl,
        sourceName: patResponse.shift_left_potential.creation_context.created_by_resource_name || '',
        rawContext: patResponse.shift_left_potential.creation_context,
        targetUtl: patResponse.shift_left_potential.target_model_utl,
        sourceType: transformBackendCreationContextTypeToLocal(patResponse.shift_left_potential.creation_context.type_specific_content.creation_context_type),
    };
};

export const transformCreateChangeToRequest = (pat: Pat): Partial<BackendChange> => {
    return {
        title: pat.title,
        description: pat.description,
        change_content: {
            type_specific_content: transformChangeContentToBackend(pat.changeData, pat.changeType)
        },
        creation_context: pat.rawContext,
        target_branch: pat.targetBranch,
        target_model_utl: pat.targetUtl
    };
};

export const applicationToCreationContextType = {
    looker: 'looker_look',
    metabase: 'metabase_question',
    '': undefined
};

export const statusToState = {
    [ChangeStatus.Proposal]: 'proposal',
    [ChangeStatus.Open]: 'change_draft',
    [ChangeStatus['PR Rejected']]: 'pr_rejected',
    [ChangeStatus['Draft Rejected']]: 'draft_rejected',
    [ChangeStatus['Pending Merge']]: 'pending_merge',
    [ChangeStatus.Merged]: 'merged',
    [ChangeStatus.Deployed]: 'deployed',
    [ChangeStatus.Published]: 'completed'
};

export const transformBackedPatToLocalPat = (pat: BackedPat): SuperficialPat => {
    return {
        date: pat.shift_left_date,
        owner: pat.shift_left_owner,
        project: parseInt(pat.shift_left_project),
        sourceNode: pat.shift_left_source,
        title: pat.shift_left_title,
        type: mapBackendChangeTypeToChangeType.get(pat.shift_left_type)!,
        usage14: pat.total_queries_14d || null,
        usage30: pat.total_queries_30d || null,
        usage60: pat.total_queries_60d || null,
        uri: pat.uri
    };
};

export const mapLocalChangeTypeToBackendChangeType = new Map<ChangeType, BackendChangeType>([
    [ChangeType.NewAggregateTable, BackendChangeType.AddAggregateTable],
    [ChangeType.NewColumn, BackendChangeType.AddColumnToExistingModel],
    [ChangeType.NewMeasure, BackendChangeType.PromoteMeasure],
    [ChangeType.NewModel, BackendChangeType.AddModel]
]);