import { useState, FC, ReactNode } from "react";
import { makeMarkdownText, Thread } from "@assistant-ui/react-ui";
import { useLangGraphStatus } from "./hooks/useLangGraphStatus";
import "@assistant-ui/react-ui/styles/index.css";
import "@assistant-ui/react-ui/styles/modal.css";
import "@assistant-ui/react-ui/styles/themes/default.css";
import "./Assistant.css";
import { DataResourcesSearchTool } from "./tools/EqlQueryTool";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../infrastructure/state/slices/activeAccountSlice";
import { useGetCustomPropertiesListQuery } from "src/services/customProperties/customProperties";

interface AssistantProps {
  showInSidepane: (resourceId: string) => void;
}

interface CustomTagProps {
  children: React.ReactNode;
  className: string;
}

// Component for handling EQL inline code
const EqlInlineCode = ({ children, showInSidepane }: { children: ReactNode, showInSidepane: (resourceId: string) => void }) => {
  const text = String(children);
  
  // Check if this is an EQL link in the format: `eql://QUERY`
  const eqlLinkMatch = text.match(/^eql:\/\/(.+)$/);
  
  // Check if this is a resource link in the format: `resource://<URI>NAME`
  const resourceLinkMatch = text.match(/^resource:\/\/<(.+?)>(.*)$/);

  // Check if this is a property link in the format: `property://PROPERTY_NAME`
  const propertyLinkMatch = text.match(/^property:\/\/(.+)$/);
  
  const handleEqlClick = (eql: string) => {
    // Open the discover page with the EQL query in a new tab
    const url = `/data-model?eql=${encodeURIComponent(eql)}&filterMode=eql&view=table`;
    window.open(url, '_blank');
  };
  
  if (eqlLinkMatch) {
    const eql = eqlLinkMatch[1];
    return (
      <button 
        onClick={() => handleEqlClick(eql)}
        className="inline-flex items-center text-blue-500 hover:text-blue-600 bg-blue-50 px-1.5 py-0.5 rounded-md text-sm"
      >
        <code className="mr-1">{eql}</code>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
        </svg>
      </button>
    );
  }
  
  if (resourceLinkMatch) {
    const resourceUri = resourceLinkMatch[1];
    const resourceName = resourceLinkMatch[2] || resourceUri;
    return (
      <ResourceLink resourceUri={resourceUri} resourceName={resourceName} showInSidepane={showInSidepane} />
    );
  }
  
  if (propertyLinkMatch) {
    const propertyName = propertyLinkMatch[1];
    return (
      <PropertyLink propertyName={propertyName} />
    );
  }

  // Default inline code rendering
  return <code>{text}</code>;
};

// Component for handling property links
const PropertyLink = ({ propertyName }: { propertyName: string }) => {
  const accountId = useSelector(selectActiveAccountId);
  const { data: allProperties } = useGetCustomPropertiesListQuery({ accountId });
  
  // Find the property description
  const property = allProperties?.find(p => p.name === propertyName);
  const description = property?.description || "No description available";
  
  // Generate a unique ID for the tooltip
  const tooltipId = `property-tooltip-${propertyName}`;
  
  return (
    <>
      <span 
        className="text-cyan-600 hover:text-purple-700 bg-purple-50 px-1.5 py-0.5 rounded-md text-sm cursor-help"
        data-tooltip-id={tooltipId}
        data-tooltip-content={description}
      >
        {propertyName}
      </span>
      <Tooltip id={tooltipId} />
    </>
  );
};

interface EqlCodeBlockProps extends CustomTagProps {
  showInSidepane: (resourceId: string) => void;
}

// Component for handling EQL code blocks
const EqlCodeBlock = ({ className, children, showInSidepane, ...props }: EqlCodeBlockProps) => {
  const match = /language-(\w+)/.exec(className || '');
  const language = match && match[1];
  const text = String(children).replace(/\n$/, '');
  
  // Check if this is an inline EQL link that somehow got passed to the code block renderer
  const eqlLinkMatch = text.match(/^eql:\/\/(.+)$/);
  
  // Check if this is a resource link that somehow got passed to the code block renderer
  const resourceLinkMatch = text.match(/^resource:\/\/<(.+?)>(.*)$/);
  
  const handleEqlClick = (eql: string) => {
    // Open the data-model page with the EQL query in a new tab
    const url = `/data-model?view=table&filterMode=eql&eql=${encodeURIComponent(eql)}`;
    window.open(url, '_blank');
  };
  
  // Handle resource links that got passed to the code block renderer
  if (resourceLinkMatch) {
    const resourceUri = resourceLinkMatch[1];
    const resourceName = resourceLinkMatch[2] || resourceUri;
    return (
      <ResourceLink resourceUri={resourceUri} resourceName={resourceName} showInSidepane={showInSidepane} />
    );
  }
  
  // Handle inline EQL links that got passed to the code block renderer
  if (eqlLinkMatch) {
    const eql = eqlLinkMatch[1];
    return (
      <button 
        onClick={() => handleEqlClick(eql)}
        className="inline-flex items-center text-blue-500 hover:text-blue-600 bg-blue-50 px-1.5 py-0.5 rounded-md text-sm"
      >
        <code className="mr-1">{text}</code>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
        </svg>
      </button>
    );
  }
  
  // Handle EQL code blocks
  if (language === 'eql') {
    return (
      <div className="relative group">
        <pre className="bg-slate-100 p-3 rounded-md">
          <code className={className} {...props}>
            {children}
          </code>
        </pre>
        <button 
          onClick={() => handleEqlClick(text)}
          className="absolute top-0 right-0 text-slate-400 hover:text-blue-500 transition-colors p-1 rounded-md opacity-90 group-hover:opacity-100"
          title="Run EQL query in new tab"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg>
        </button>
      </div>
    );
  }
  
  // For other code blocks, use collapsible code block component
  return <CollapsibleCodeBlock className={className} {...props}>{children}</CollapsibleCodeBlock>;
};

// Collapsible code block component for non-EQL code
interface CollapsibleCodeBlockProps extends CustomTagProps {
  className: string;
}

const CollapsibleCodeBlock = ({ className, children, ...props }: CollapsibleCodeBlockProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const text = String(children);
  const lines = text.split('\n');
  const hasMoreLines = lines.length > 8;
  
  // Determine which lines to show based on expanded state
  const visibleLines = isExpanded ? lines : lines.slice(0, 8);
  const visibleText = visibleLines.join('\n');
  
  return (
    <div className="relative bg-slate-100 rounded-md overflow-hidden shadow-sm border border-slate-200">
      <pre className="p-3 m-0">
        <code className={className} {...props}>
          {visibleText}
        </code>
      </pre>
      
      {hasMoreLines && (
        <div 
          className="flex justify-center items-center py-1 bg-slate-200 cursor-pointer hover:bg-slate-300 transition-colors"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-slate-600" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-slate-600" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          )}
        </div>
      )}
    </div>
  );
};

// Component for resource links
const ResourceLink = ({ resourceUri, resourceName, showInSidepane }: { resourceUri: string, resourceName: string, showInSidepane: (resourceId: string) => void }) => {
  const handleClick = () => {
    showInSidepane(resourceUri);
  };
  
  return (
    <button 
      onClick={handleClick}
      className="inline-flex items-center text-purple-600 hover:text-purple-700 bg-purple-50 px-1.5 py-0.5 rounded-md text-sm"
    >
      <span className="mr-1 font-medium">{resourceName}</span>
    </button>
  );
};

// Custom markdown renderer that handles EQL code blocks
const CustomMarkdownText = (showInSidepane: (resourceId: string) => void) => {
  // Create wrapper components that handle the type conversion
  const PreWrapper: React.FC<React.HTMLAttributes<HTMLElement>> = (props: React.HTMLAttributes<HTMLElement>) => {
    // Pass the showInSidepane prop and forward all other props
    return <pre {...props}>{props.children}</pre>;
  };

  const CodeWrapper: React.FC<React.HTMLAttributes<HTMLElement>> = (props: React.HTMLAttributes<HTMLElement>) => {
    // Check if this code is inside a pre tag (code block) or standalone (inline code)
    const isInline = props.className === "" || props.className === undefined;
    
    if (isInline) {
      // This is inline code, use EqlInlineCode
      return <EqlInlineCode showInSidepane={showInSidepane}>
        {props.children}
      </EqlInlineCode>;
    } else {
      // This is a code block, use EqlCodeBlock with required props
      return <EqlCodeBlock className={props.className || ""} showInSidepane={showInSidepane} >
        {props.children}
      </EqlCodeBlock>;
    }
  };

  // Create the markdown text component with properly typed components
  return makeMarkdownText({
    components: {
      pre: PreWrapper,
      code: CodeWrapper
    }
  });
};

export const Assistant: FC<AssistantProps> = ({ showInSidepane }) => {
  const { status, error } = useLangGraphStatus();
  const apiUrl = import.meta.env.VITE_LANGGRAPH_API_URL || '';
  const MarkdownText = CustomMarkdownText(showInSidepane);
  
  // If we're still checking the connection or there's an error, show a status message
  if (status === 'checking' || status === 'error') {
    return (
      <div className="flex h-full flex-col items-center justify-center p-4">
        {status === 'checking' && (
          <div className="text-center">
            <div className="animate-pulse text-lg text-text-primary mb-2">
              Connecting to AI Assistant...
            </div>
            <p className="text-tertiary">
              Checking connection to LangGraph server at: {apiUrl}
            </p>
          </div>
        )}
        
        {status === 'error' && (
          <div className="text-center">
            <div className="text-lg text-danger-strong mb-2">
              Failed to connect to AI Assistant
            </div>
            <p className="text-tertiary mb-4">
              {error || "There was an error connecting to the AI Assistant. Please try again later."}
            </p>
            <div className="text-sm text-tertiary mb-4">
              <p className="mb-2">Make sure the LangGraph server is running at: {apiUrl}</p>
              <p className="mb-2">Common issues:</p>
              <ul className="list-disc pl-5 text-left">
                <li>LangGraph server is not running</li>
                <li>CORS is not enabled on the LangGraph server</li>
                <li>The URL in .env.local is incorrect</li>
                <li>Network connectivity issues</li>
              </ul>
            </div>
            <div className="text-sm text-tertiary p-3 bg-slate-100 rounded-lg text-left">
              <p className="font-medium mb-1">Troubleshooting:</p>
              <ol className="list-decimal pl-5">
                <li>Start the LangGraph server with <code className="bg-slate-200 px-1 rounded">langchain serve --cors-allow-origins &quot;*&quot;</code></li>
                <li>Verify the server is running with <code className="bg-slate-200 px-1 rounded">curl {apiUrl}/info</code></li>
                <li>Check that the URL in .env.local is correct (should be <code className="bg-slate-200 px-1 rounded">http://localhost:2024/</code>)</li>
                <li>Try using HTTP instead of HTTPS for localhost</li>
                <li>Check browser console for detailed error messages</li>
              </ol>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="h-full w-full assistant-container">
      <Thread
        welcome={{
          suggestions: [
            {
              prompt: "What can you help me with?",
            },
            {
              prompt: "Show me my data model",
            },
            {
              prompt: "How do I connect a new data source?",
            },
          ],
          message: "Hello! I'm your assistant. I can help you with your data model, assist with tasks, or provide information about Delphi features."
        }}
        assistantMessage={{
          components: {
            Text: MarkdownText
          }
        }}
        tools={[DataResourcesSearchTool]}
      />
    </div>
  );
}; 