import React, { useState, useEffect, useRef } from 'react';
import {
    CalendarIcon,
    CheckIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import { CustomProperty, CustomPropertyFEType } from 'src/services/customProperties/types';
import { IExpandedNode } from 'src/features/models/discover/INode';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { useSetCustomPropertyValueMutation } from 'src/services/customProperties/customProperties';
import { notify } from 'src/components/Toaster';
import RemovePropertyModal from './RemovePropertyModal';
import { format } from 'date-fns';

type PropertiesMenuProps = {
    property: CustomProperty;
    resource: IExpandedNode;
    onClose: () => void;
};

type SubmenuHeaderProps = {
    property: CustomProperty;
    resource: IExpandedNode;
    onClose: () => void;
    onRemove: () => void;
};

const SubmenuHeader: React.FC<SubmenuHeaderProps> = ({ property, resource, onClose, onRemove }) => {
    const hasValue = resource.customProperties[property.eqlName] !== null;
    
    return (
        <div className="flex justify-between items-center p-2 border-b border-gray-100">
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 flex items-center">
                <XMarkIcon className="w-4 h-4 mr-1" />
                <span className="text-sm font-medium">Close</span>
            </button>
            <h3 className="text-sm font-medium">{property.name}</h3>
            {hasValue ? (
                <button 
                    onClick={onRemove} 
                    className="text-red-500 hover:text-red-700"
                >
                    UNSET
                </button>
            ) : (
                <div className="w-16 opacity-0"></div>
            )}
        </div>
    );
};

export const PropertiesMenu: React.FC<PropertiesMenuProps> = ({ property, resource, onClose }) => {
    const accountId = useSelector(selectActiveAccountId);
    const [setCustomPropertyValue] = useSetCustomPropertyValueMutation();
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (showRemoveModal) {
                return;
            }
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose, showRemoveModal]);

    const handleSetPropertyValue = async <T extends string | boolean | number | string[] | null>(
        value: T
    ) => {
        try {
            await setCustomPropertyValue({
                accountId,
                nodeId: resource.id,
                propertyId: property.id,
                value
            }).unwrap();
            
            notify('Property updated successfully', 'success');
            if (value !== null) {
                onClose();
            }
        } catch (error) {
            console.error(`Error setting ${property.content.type} property:`, error);
            notify('Failed to update property', 'error');
        }
    };

    const handleRemoveProperty = () => {
        setShowRemoveModal(true);
    };

    const confirmRemoveProperty = async () => {
        setIsLoading(true);
        try {
            await setCustomPropertyValue({
                accountId,
                nodeId: resource.id,
                propertyId: property.id,
                value: null
            }).unwrap();
            
            notify('Property removed successfully', 'success');
            setShowRemoveModal(false);
            onClose();
        } catch (error) {
            console.error(`Error removing property:`, error);
            notify('Failed to remove property', 'error');
            setShowRemoveModal(false);
        } finally {
            setIsLoading(false);
        }
    };

    const cancelRemoveProperty = () => {
        console.log('Cancel remove property clicked');
        setShowRemoveModal(false);
    };

    const renderSubmenu = () => {
        switch (property.content.type) {
            case CustomPropertyFEType.Datetime:
                return (
                    <DatetimeSubmenu
                        property={property}
                        resource={resource}
                        onSetValue={handleSetPropertyValue}
                        onRemove={handleRemoveProperty}
                        onClose={onClose}
                    />
                );
            case CustomPropertyFEType.FixedBoolean:
                return (
                    <BooleanSubmenu
                        property={property}
                        resource={resource}
                        onSetValue={handleSetPropertyValue}
                        onRemove={handleRemoveProperty}
                        onClose={onClose}
                    />
                );
            case CustomPropertyFEType.SingleSelect:
                return (
                    <SingleSelectSubmenu
                        property={property}
                        resource={resource}
                        onSetValue={handleSetPropertyValue}
                        onRemove={handleRemoveProperty}
                        onClose={onClose}
                    />
                );
            case CustomPropertyFEType.MultiSelect:
                return (
                    <MultiSelectSubmenu
                        property={property}
                        resource={resource}
                        onSetValue={handleSetPropertyValue}
                        onRemove={handleRemoveProperty}
                        onClose={onClose}
                    />
                );
            case CustomPropertyFEType.Numeric:
                return (
                    <NumericSubmenu
                        property={property}
                        resource={resource}
                        onSetValue={handleSetPropertyValue}
                        onRemove={handleRemoveProperty}
                        onClose={onClose}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="bg-white shadow-xl border border-gray-200 rounded-md w-80 max-h-96 overflow-y-auto z-[9999]" ref={menuRef}>
            {renderSubmenu()}

            <RemovePropertyModal
                isOpen={showRemoveModal}
                property={property}
                resource={resource}
                onClose={cancelRemoveProperty}
                onConfirm={confirmRemoveProperty}
                isLoading={isLoading}
            />
        </div>
    );
};

type NumericSubmenuProps = {
    property: CustomProperty;
    resource: IExpandedNode;
    onSetValue: (value: number) => void;
    onRemove: () => void;
    onClose: () => void;
};

const NumericSubmenu: React.FC<NumericSubmenuProps> = ({ property, resource, onSetValue, onRemove, onClose }) => {
    const [currentValue, setCurrentValue] = useState<number>(Number(resource.customProperties[property.eqlName]));
    const [isChanged, setIsChanged] = useState(false);

    const handleDoneClick = () => {
        onSetValue(Number(currentValue));
    };

    return (
        <>
            <SubmenuHeader 
                property={property}
                resource={resource}
                onClose={onClose}
                onRemove={onRemove}
            />
            <div className="p-2">
                <input
                    type="number"
                    className="w-full p-2 border border-gray-200 rounded-md text-sm"
                    value={currentValue}
                    onChange={(e) => {
                        setCurrentValue(Number(e.target.value));
                        setIsChanged(true);
                    }}
                />
            </div>
            <div className="p-2 border-t border-gray-100">
                <button 
                    className={`w-full py-2 ${isChanged ? 'bg-lilac-600 hover:bg-lilac-700' : 'bg-lilac-300 cursor-not-allowed'} text-white rounded-md text-sm font-medium`}
                    onClick={handleDoneClick}
                    disabled={!isChanged}
                >
                    Done
                </button>
            </div>
        </>
    );
};

type DatetimeSubmenuProps = {
    property: CustomProperty;
    resource: IExpandedNode;
    onSetValue: (value: string) => void;
    onRemove: () => void;
    onClose: () => void;
};

const DatetimeSubmenu: React.FC<DatetimeSubmenuProps> = ({ property, resource, onSetValue, onRemove, onClose }) => {
    const currentValue = resource.customProperties[property.eqlName] as string;
    const getTodayDate = () => {
        return format(new Date(), 'yyyy-MM-dd') + 'T00:00:00';
    };
    
    const [selectedDate, setSelectedDate] = useState(currentValue || getTodayDate());
    const [isChanged, setIsChanged] = useState(false);
    const [selection, setSelection] = useState<'current' | 'today' | 'custom'>(currentValue ? 'current' : 'custom');

    const formatDateForDisplay = (dateStr: string) => {
        try {
            return format(new Date(dateStr), 'dd/MM/yyyy');
        } catch (error) {
            console.error("Error formatting date for display:", error);
            return "";
        }
    };
    
    const formatDateForInput = (dateStr: string) => {
        try {
            return format(new Date(dateStr), 'yyyy-MM-dd');
        } catch (error) {
            console.error("Error formatting date for input:", error);
            return "";
        }
    };
    
    const handleDoneClick = () => {
        onSetValue(format(new Date(selectedDate), 'yyyy-MM-dd') + 'T00:00:00');
    };
    
    const handleDateChange = (value: string) => {
        setSelectedDate(value + 'T00:00:00');
        setIsChanged(true);
        setSelection('custom');
    };
    
    const selectToday = () => {
        const todayDate = getTodayDate();
        setSelectedDate(todayDate);
        setSelection('today');
        setIsChanged(true);
    };
    
    const selectCurrent = () => {
        if (currentValue) {
            setSelectedDate(currentValue);
            setSelection('current');
            setIsChanged(true);
        }
    };

    return (
        <>
            <SubmenuHeader 
                property={property}
                resource={resource}
                onClose={onClose}
                onRemove={onRemove}
            />
            
            <div className="p-2">
                {currentValue && (
                    <div className="flex items-center p-1.5 rounded hover:bg-gray-50 cursor-pointer mb-2" onClick={selectCurrent}>
                        {selection === 'current' && <CheckIcon className="w-4 h-4 mr-2 text-lilac-600" />}
                        <span className="text-sm">{formatDateForDisplay(currentValue)}</span>
                    </div>
                )}
                <div 
                    className="flex items-center p-1.5 rounded hover:bg-gray-50 cursor-pointer border-t border-gray-100"
                    onClick={selectToday}
                >
                    {selection === 'today' && <CheckIcon className="w-4 h-4 mr-2 text-lilac-600" />}
                    <span className="text-sm">Today: {formatDateForDisplay(getTodayDate())}</span>
                </div>
                <div className="border-t border-gray-100 pt-3 ">
                    <div className="flex items-center mb-2">
                        <CalendarIcon className="w-4 h-4 mr-2 text-gray-500" />
                        <span className="text-sm text-lilac-600">Pick new date</span>
                    </div>
                    <input 
                        type="date"
                        className="w-full p-2 border border-gray-200 rounded-md text-sm"
                        value={selectedDate ? formatDateForInput(selectedDate) : ''}
                        onChange={(e) => handleDateChange(e.target.value)}
                    />
                </div>
            </div>
            
            <div className="p-2 border-t border-gray-100">
                <button 
                    className={`w-full py-2 ${isChanged ? 'bg-lilac-600 hover:bg-lilac-700' : 'bg-lilac-300 cursor-not-allowed'} text-white rounded-md text-sm font-medium`}
                    onClick={handleDoneClick}
                    disabled={!isChanged}
                >
                    Done
                </button>
            </div>
        </>
    );
};

type BooleanSubmenuProps = {
    property: CustomProperty;
    resource: IExpandedNode;
    onSetValue: (value: boolean) => void;
    onRemove: () => void;
    onClose: () => void;
};

const BooleanSubmenu: React.FC<BooleanSubmenuProps> = ({ property, resource, onSetValue, onRemove, onClose }) => {
    const currentValue = resource.customProperties[property.eqlName] as boolean;

    return (
        <>
            <SubmenuHeader 
                property={property}
                resource={resource}
                onClose={onClose}
                onRemove={onRemove}
            />
            <div className="p-2">
                <div
                    className="flex items-center p-1.5 rounded hover:bg-gray-50 cursor-pointer"
                    onClick={() => onSetValue(true)}
                >
                    {currentValue === true && <CheckIcon className="w-4 h-4 mr-2 text-lilac-600" />}
                    <span className="text-sm">True</span>
                </div>
                <div
                    className="flex items-center p-1.5 rounded hover:bg-gray-50 cursor-pointer"
                    onClick={() => onSetValue(false)}
                >
                    {currentValue === false && <CheckIcon className="w-4 h-4 mr-2 text-lilac-600" />}
                    <span className="text-sm">False</span>
                </div>
            </div>
        </>
    );
};

type SingleSelectSubmenuProps = {
    property: CustomProperty;
    resource: IExpandedNode;
    onSetValue: (value: string | null) => void;
    onRemove: () => void;
    onClose: () => void;
};

const SingleSelectSubmenu: React.FC<SingleSelectSubmenuProps> = ({ property, resource, onSetValue, onRemove, onClose }) => {
    let options: string[] = [];
    const currentValue: string | null = resource.customProperties[property.eqlName] as string || null;

    if (property.content.type === CustomPropertyFEType.SingleSelect) {
        options = property.content.content.options || [];
    }

    return (
        <>
            <SubmenuHeader 
                property={property}
                resource={resource}
                onClose={onClose}
                onRemove={onRemove}
            />
            <div className="p-2">
                {options.map((option, index) => (
                    <div
                        key={index}
                        className="flex items-center p-1.5 rounded hover:bg-gray-50 cursor-pointer"
                        onClick={() => onSetValue(option)}
                    >
                        <div className="w-4 h-4 rounded-full border border-gray-300 mr-2 flex items-center justify-center">
                            {currentValue === option && <div className="w-2 h-2 bg-lilac-600 rounded-full"></div>}
                        </div>
                        <span className="text-sm">{option}</span>
                    </div>
                ))}
            </div>
        </>
    );
};

type MultiSelectSubmenuProps = {
    property: CustomProperty;
    resource: IExpandedNode;
    onSetValue: (value: string[] | null) => void;
    onRemove: () => void;
    onClose: () => void;
};

const MultiSelectSubmenu: React.FC<MultiSelectSubmenuProps> = ({ property, resource, onSetValue, onRemove, onClose }) => {
    let options: string[] = [];
    const currentResourceValue = resource.customProperties[property.eqlName] as string[] || [];

    const [selectedValues, setSelectedValues] = useState<string[]>([...currentResourceValue]);

    if (property.content.type === CustomPropertyFEType.MultiSelect) {
        options = property.content.content.options || [];
    }

    const toggleOption = (option: string) => {
        if (selectedValues.includes(option)) {
            setSelectedValues(selectedValues.filter(val => val !== option));
        } else {
            setSelectedValues([...selectedValues, option]);
        }
    };

    const handleDoneClick = () => {
        const valueToSend = selectedValues.length > 0 ? selectedValues : null;
        onSetValue(valueToSend);
    };

    return (
        <>
            <SubmenuHeader 
                property={property}
                resource={resource}
                onClose={onClose}
                onRemove={onRemove}
            />
            <div className="p-2 max-h-64 overflow-y-auto">
                {options.map((option, index) => (
                    <div
                        key={index}
                        className="flex items-center p-1.5 rounded hover:bg-gray-50 cursor-pointer"
                        onClick={() => toggleOption(option)}
                    >
                        <div className="w-4 h-4 rounded border border-gray-300 mr-2 flex items-center justify-center">
                            {selectedValues.includes(option) && (
                                <CheckIcon className="w-3 h-3 text-lilac-600" />
                            )}
                        </div>
                        <span className="text-sm">{option}</span>
                    </div>
                ))}
            </div>
            <div className="p-2 border-t border-gray-100">
                <button
                    className="w-full py-2 bg-lilac-600 hover:bg-lilac-700 text-white rounded-md text-sm font-medium"
                    onClick={handleDoneClick}
                >
                    Done
                </button>
            </div>
        </>
    );
}; 