import { memo } from 'react';

const OverlayLoader = memo(({ label, labelLocation = "right" }: { label?: string, labelLocation?: "top" | "bottom" | "left" | "right" }) => {
    const labelCssLocation = {
        top: 'flex flex-col-reverse min-h-screen fixed inset-0 items-center justify-center bg-black bg-opacity-50 z-50',
        bottom: 'flex flex-col min-h-screen fixed inset-0 items-center justify-center bg-black bg-opacity-50 z-50',
        left: 'flex flex-row min-h-screen fixed inset-0 items-center justify-center bg-black bg-opacity-50 z-50',
        right: 'flex min-h-screen fixed inset-0 items-center justify-center bg-black bg-opacity-50 z-50'
    };

    return (
        <div className={labelCssLocation[labelLocation]}>
            <div className="h-24 w-24 animate-spin rounded-full border-t-2 border-lilac-500 border-opacity-60"></div>
            {label && <div className="m-4 text-primary">{label}</div>}
        </div>
    );
});

OverlayLoader.displayName = 'OverlayLoader';

export default OverlayLoader;