import { DatabaseTechnology } from "../../features/models/discover/INode";
import { MeasureAggType } from "src/features/models/discover/INode";

export enum BackendNodeType {
    DBTModel = 'dbt_model',
    DBTMetric = 'dbt_metric',
    DataSource = 'dbt_source',
    LookerView = 'looker_view',
    LookerDerivedView = 'looker_derived_view',
    LookerExplore = 'looker_explore',
    LookerLook = 'looker_look',
    GenericDataTransformation = 'generic_data_transformation',
    LookerDashboard = 'looker_dashboard',
    LookerTile = 'looker_tile',
    TableauWorkbook = 'tableau_workbook',
    TableauView = 'tableau_view',
    TableauCustomQuery = 'tableau_custom_sql',
    TableauPublishedDataSource = 'tableau_published_datasource',
    TableauEmbeddedDataSource = 'tableau_embedded_datasource',
    TableauDashboard = 'tableau_dashboard',
    TableauStory = 'tableau_story',
    Table = 'table',
    TableauDimension = 'tableau_dimension',
    DBTColumn = 'dbt_column',
    TableauMeasure = 'tableau_measure',
    Column = 'column',
    DBTDimension = 'dbt_dimension',
    DBTMeasure = 'dbt_measure',
    LookerMeasure = 'looker_measure',
    LookerDimension = 'looker_dimension',
    FivetranConnector = 'fivetran_connector',
    FivetranDestination = 'fivetran_destination',
    DBTSeed = 'dbt_seed',
    TableauFlow = 'tableau_flow',
    ThoughtspotLiveboard = 'thoughtspot_liveboard',
    ThoughtspotAnswer = 'thoughtspot_answer',
    ThoughtspotWorksheet = 'thoughtspot_worksheet',
    ThoughtspotTable = 'thoughtspot_table',
    ThoughtspotModel = 'thoughtspot_model',
    ThoughtspotView = 'thoughtspot_view',
    ThoughtspotFormula = 'thoughtspot_formula',
    TableauProject = 'tableau_project',
    SnowflakeAccount = 'snowflake_account',
    TableauSite = 'tableau_site',
    LookerFolder = 'looker_folder'
}

export enum BackendSubnodeType {
    Dimension = 'dimension',
    Measure = 'measure',
    Entity = 'entity',
    Metric = 'metric',
    CustomField = 'custom_field',
    Column = 'column',
    TableCalculation = 'table_calculation',
    LookerLook = 'looker_look',
    LookerConnectedView = 'looker_view',
    LookerTile = 'looker_tile',
    TableauConnectedView = 'tableau_view',
}

export type BackendSuperficialResource = {
    uri: string;
    name: string;
    generated_by_delphi: boolean;
    is_trivial_sql: boolean;
    type: BackendNodeType;
    description: string;
    tags: string[];
    database: string;
    schema: string;
    database_schema: string | null;
    materialized: boolean;
    tableau_has_extracts: boolean | null;
    native_last_data_update: string | null;
    dbt_materialization_strategy: string;
    git_repo_url: string;
    git_repo_branch: string;
    package_name: string;
    parent_type: string;
    parent_name: string;
    parent_container_name: string;
    dbt_project: string;
    unique_id: string;
    utl: string;
    number_of_dimensions: number;
    number_of_columns?: number;
    number_of_measures: number;
    number_of_entities: number;
    number_of_metrics: number;
    number_of_custom_fields: number;
    parents?: string[];
    meta: { [key: string]: string } | null;
    first_seen_at: number;
    has_shift_left_potential: boolean;
    subnodes?: {
        name: string;
        subnode_type: BackendSubnodeType;
    }[];
    owner: string | null;
    tableau_workbook: string | null;
    looker_folder: string | null;
    looker_model: string | null;
    looker_project: string | null;
    source_directory: string | null;
    looker_host: string | null;
    last_7d_views: number | null;
    last_30d_views: number | null;
    total_queries_14d: number | null;
    database_technology?: DatabaseTechnology;
    total_queries_30d: number | null;
    total_queries_60d: number | null;
    distinct_users_14d: number | null;
    distinct_users_30d: number | null;
    distinct_users_60d: number | null;
    total_impressions_14d: number | null;
    total_impressions_30d: number | null;
    total_impressions_60d: number | null;
    distinct_impressions_users_14d: number | null;
    distinct_impressions_users_30d: number | null;
    distinct_impressions_users_60d: number | null;
    tableau_project: string | null;
    is_calculated: boolean | null;
    tableau_extract_refresh_frequency: string | null;
    is_identity_transformation?: boolean;
    lookml_view_persistency?: string;
    pdt_builds_last_30d?: number;
    pdt_total_build_time_30d?: number;
    derived_type?: "native_derived_table" | "sql_derived_table";
    source_path?: string;
    has_refinements?: boolean;
    native_data_type?: string;
    metric_type?: string;
    dimension_type?: string;
    out_of_sync_pre_aggregate_model: boolean | null;
    is_automatic_pre_aggregated_dbt_model: boolean | null;
    row_count: number | null;
    volume: number | null;
}

export type SyncedRawCodeBackend = {
    is_outdated: boolean
    raw_code: string | null
    no_raw_code_reason: string | null
}

export type BackendExpandedNodeResponse = {
    uri: string;
    name: string;
    parents: string[];
    subnodes?: BackendExpandedSubnode[];
    type: BackendNodeType;
    description: string;
    tags: string[] | null;
    meta: { [key: string]: string } | null;
    generated_by_delphi: boolean;
    hasProposals: number;
    is_trivial_sql: boolean;
    thumbnail: string | null;
    materialized: boolean;
    tableau_has_extracts: boolean | null;
    native_last_data_update: string | null;
    last_30d_views: number;
    last_7d_views: number;
    last_accessed_at: string;
    favourite_count: number;
    git_repo_url: string;
    git_repo_branch: string;
    updated_at: string;
    updated_by: string;
    created_at: string;
    created_by: string;
    package_name: string;
    database: string;
    database_schema: string;
    utl: string;
    parent_type: string;
    parent_name: string;
    parent_container_name: string;
    allow_promote: boolean;
    raw_code: string;
    compiled_code: string;
    semantic_code: string;
    source_directory: string | null;
    looker_model: string | null;
    looker_folder: string | null;
    looker_project: string | null;
    looker_queries?: ExternalNodeUsage | null;
    total_queries_14d: number | null;
    total_queries_30d: number | null;
    total_queries_60d: number | null;
    distinct_users_14d: number | null;
    distinct_users_30d: number | null;
    distinct_users_60d: number | null;
    tableau_queries_summary?: ExternalNodeUsage | null;
    tableau_impressions_summary?: ExternalNodeUsage | null;
    dbt_project: string | null;
    owner: string | null;
    tableau_workbook: string | null;
    euno_project_id: number | null;
    native_updated_by: {
        email: string | null
    } | null,
    native_created_by: {
        email: string | null
    } | null,
    table_schema: {
        columns: {
            name: string,
            description: string,
            normalized_data_type: string,
            native_tags: string[],
            native_meta: { [key: string]: string }
            semantic_role: BackendSubnodeType,
            native_code: {
                code: string | null
            } | null,
            is_calculated?: boolean,
            uri?: string,
        }[] | null
    } | null,
    contained_resources: {
        uri: string,
        name: string,
        normalized_type: BackendNodeType
    }[] | null;
    container_chain: {
        uri: string,
        name: string,
        normalized_type: BackendNodeType
    }[] | null;
    table_properties: {
        view_native_code: {
            code: string | null
        } | null,
        materialized?: boolean
    } | null;
    database_technology?: DatabaseTechnology;
    has_shift_left_potential: boolean | null;
    is_proxy_to_published_datasource?: boolean;
    metric_type?: string;
    tableau_view_input_fields_legacy_uri?: string[];
    normalized_data_type?: string;
    native_data_type?: string;
    dimension_type?: string;
    dbt_measure_type?: string;
    parent_container?: string;
    tableau_extract_refresh_frequency?: string;
    is_calculated?: boolean;
    dbt_materialization_strategy?: string;
    derived_type?: "native_derived_table" | "sql_derived_table";
    external_links?: {
        label: string;
        url: string;
    }[];
    first_seen_at: number;
    last_seen_at: number;
    tableau_project?: string;
    native_id?: string;
    dbt_version?: string;
    looker_connection_name?: string;
    sponsors?: string[];
    pdt_total_build_time_30d?: number;
    pdt_builds_last_30d?: number;
    input_fields?: { uri: string | null }[];
    is_identity_transformation?: boolean;
    has_refinements?: boolean;
    has_catalog_entry?: boolean;
    looker_refinement_chain?: string[];
    synced_raw_code?: SyncedRawCodeBackend | null;
    unique_id?: string;
    out_of_sync_pre_aggregate_model?: boolean;
    is_automatic_pre_aggregated_dbt_model?: boolean;
    row_count: number | null;
    volume: number | null;
}

export type BackendExpandedSubnode = {
    name: string,
    description: string,
    type: string,
    subnode_type: BackendSubnodeType,
    tags: string[],
    meta: { [key: string]: string },
    tableau_workbook: string | null,
    parent_type: string,
    parent_name: string,
    parent_container_name: string,
    source_code: string | null,
    compiled_code: string | null,
    uri: string | null,
    looker_queries: ExternalNodeUsage | null;
    last_30d_views: number | null;
    last_7d_views: number | null;
    filter: string | null;
    alias: string | null;
    has_shift_left_potential: boolean | null;
    is_trivial_sql: boolean | null;
    agg?: MeasureAggType;
    type_params?: {
        input_measures?: { name: string }[]
    };
    is_calculated?: boolean;
};

export type UsageUserBreakdown = {
    user: {
        user_email: string;
        user_name: string;
    };
    cnt: number;
};

export type UsageAppBreakdown = {
    app: {
        dashboard_id: null;
        dashboard_element_id: null;
        type: NodeUsageAppType;
        utl: string | null;
    };
    cnt: number;
};

export type ExternalNodeUsage = {
    total_queries_14d: number;
    total_queries_30d: number;
    total_queries_60d: number;
    breakdown_by_user: UsageUserBreakdown[];
    breakdown_by_app?: UsageAppBreakdown[];
    breakdown_by_user_30d?: UsageUserBreakdown[];
    breakdown_by_app_30d?: UsageAppBreakdown[];
    breakdown_by_user_60d?: UsageUserBreakdown[];
    breakdown_by_app_60d?: UsageAppBreakdown[];
}

export enum NodeUsageAppType {
    look = 'look',
    dashboard_element = 'dashboard_element',
    api_query = 'api_query',
    explore = 'explore',
    other = 'other'
}

export type MetricDependenciesResponse = {
    entity_name: string;
    entity_path: string | null;
    dimensions: {
        name: string;
        type: 'categorical' | 'time';
    }[];
}[];
