import React, { useState, useRef, useEffect } from 'react';
import { ChevronUpIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { Cog6ToothIcon, ArrowRightOnRectangleIcon, BellIcon, UserPlusIcon, BuildingOfficeIcon, ArrowPathIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useInboxNotifications, useInboxNotificationThread, useMarkInboxNotificationAsRead, useMarkAllInboxNotificationsAsRead } from '@liveblocks/react';
import { InboxNotification, InboxNotificationList } from '@liveblocks/react-ui';
import { BaseMetadata, InboxNotificationData } from '@liveblocks/client';
import { ThreadData } from '@liveblocks/client';
import type { InboxNotificationThreadProps } from '@liveblocks/react-ui';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useGetAccountsQuery } from '../../services/accounts/accounts';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';
import { useGetActiveAccount } from '../../features/account/AccountHooks';
import { useDescope } from '@descope/react-sdk';
import { toast } from 'react-hot-toast';
import StakeholdersInviteModal from '../../features/stakeholders/InviteStakeholdersModal';
import stakeholdersEntityTypes from '../../features/stakeholders/stakeholdersEntityTypes';
import activeProjectSlice, { selectActiveProject } from '../../infrastructure/state/slices/activeProjectSlice';
import activeAccountSlice from '../../infrastructure/state/slices/activeAccountSlice';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useUser } from '@descope/react-sdk';
import { useGetAccountProjectsQuery } from '../../services/projects/projects';
import { ProjectIcon } from '../../assets/images/icons/DelphiIcons';
import CreateProjectModal from '../../features/projects/create/CreateProjectModal';
import { useFlags } from "launchdarkly-react-client-sdk";


interface UserMenuButtonProps {
  isCollapsed?: boolean;
  userName?: string;
  organizationName?: string;
  onToggleMenu?: () => void;
  onInviteUsers?: () => void;
}

const ThreadComponent = ({ 
  notification, 
  onNotificationClick, 
  props 
}: { 
  notification: InboxNotificationData;
  onNotificationClick: (notificationId: string, thread: ThreadData<BaseMetadata>) => void;
  props: InboxNotificationThreadProps;
}) => {
  const thread = useInboxNotificationThread(notification.id);

  return (
    <InboxNotification.Thread 
      {...props} 
      overrides={{
        INBOX_NOTIFICATION_THREAD_COMMENTS_LIST: (list) => (
          <div>
            {list}
            &nbsp;commented in <span className="lb-name">{thread.metadata?.resourceName}</span>
          </div>
        ),
        INBOX_NOTIFICATION_THREAD_MENTION: (user) => (
          <div>
            {user} mentioned you in <span className="lb-name">{thread.metadata?.resourceName}</span>
          </div>
        )
      }} 
      onClick={() => onNotificationClick(notification.id, thread)}
    />
  );
};

// Define types for LaunchDarkly contexts
enum ContextKind {
  Account = 'account',
  Person = 'person',
  User = 'user',
  Multi = 'multi'
}

interface PersonContext {
  key: string;
  kind: ContextKind.Person;
  email: string;
  name: string;
}

interface AccountContext {
  key: string;
  kind: ContextKind.Account;
}

interface UserContext {
  key: string;
  kind: ContextKind.User;
}

interface MultiContext {
  kind: ContextKind.Multi;
  account: AccountContext;
  person?: PersonContext;
  user: UserContext;
}

const UserMenuButton: React.FC<UserMenuButtonProps> = ({ 
  isCollapsed = false, 
  userName = 'User Name',
  organizationName = 'Organization',
  onToggleMenu,
  onInviteUsers
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showInbox, setShowInbox] = useState(false);
  const [showReadNotifications, setShowShowReadNotifications] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showOrganizations, setShowOrganizations] = useState(false);
  const [showAccountSettings, setShowAccountSettings] = useState(false);
  const [showProjects, setShowProjects] = useState(false);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const inboxRef = useRef<HTMLDivElement>(null);
  const organizationsRef = useRef<HTMLDivElement>(null);
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const accountSettingsRef = useRef<HTMLDivElement>(null);
  const projectsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const sdk = useDescope();
  const dispatch = useDispatch();
  const activeAccountId = useSelector(selectActiveAccountId);
  const activeProjectId = useSelector(selectActiveProject);
  const { data: accounts = [] } = useGetAccountsQuery();
  const activeAccount = useGetActiveAccount(accounts, activeAccountId);
  const { data: projects = [] } = useGetAccountProjectsQuery({ accountId: activeAccountId });
  const ldClient = useLDClient();
  const { user } = useUser();
  const { legacyProject } = useFlags();


  // Notifications
  const { 
    inboxNotifications, 
    fetchMore, 
    hasFetchedAll,
    isLoading 
  } = useInboxNotifications();
  const markAsRead = useMarkInboxNotificationAsRead();
  const markAllAsRead = useMarkAllInboxNotificationsAsRead();
  const unreadNotifications = inboxNotifications?.filter(n => !n.readAt);

  // Filter notifications based on toggle
  const filteredNotifications = inboxNotifications?.filter(n => 
    showReadNotifications ? true : !n.readAt
  );

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (onToggleMenu) {
      onToggleMenu();
    }
  };

  const handleMenuLeave = () => {
    // Clear any existing timeout
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    // Set a new timeout to close the menu with a small delay
    const timeout = setTimeout(() => {
      // Only close if we're not hovering over organizations menu, account settings menu, or projects menu
      if (!showOrganizations && !showAccountSettings && !showProjects) {
        setIsMenuOpen(false);
      }
    }, 300); // Increased delay to 300ms
    setHoverTimeout(timeout);
  };

  const handleMenuEnter = () => {
    // Clear any existing timeout when mouse enters
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
  };

  const handleLogout = () => {
    // Directly log out using the SDK
    toast.success('Logging out...');
    sdk.logout();
    setIsMenuOpen(false);
  };
  
  const handleInviteUsers = () => {
    if (onInviteUsers) {
      onInviteUsers();
    } else {
      // Open the invite modal
      setShowInviteModal(true);
    }
    setIsMenuOpen(false);
  };

  const handleInviteModalClose = () => {
    setShowInviteModal(false);
  };

  const handleNotificationClick = (notificationId: string, thread: ThreadData<BaseMetadata>) => {
    markAsRead(notificationId);
    if (thread?.metadata?.resourceId) {
      // Navigate to discover page with the resource selected
      navigate(`/data-model?resourceId=${thread.metadata.resourceId}&tab=Discussion`);
      setShowInbox(false);
      setIsMenuOpen(false);
    }
  };

  const handleNotificationsToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowInbox(!showInbox);
  };

  const handleMarkAllAsRead = () => {
    markAllAsRead();
    setShowInbox(false);
  };

  // Add LaunchDarkly context update effect
  useEffect(() => {
    if (ldClient && activeAccountId) {
      const contexts: MultiContext = {
        kind: ContextKind.Multi,
        account: {
          key: `${activeAccountId}`,
          kind: ContextKind.Account
        },
        user: {
          key: `${activeAccountId}`,
          kind: ContextKind.User
        }
      };
      
      if (user) {
        contexts.person = {
          key: user.userId,
          kind: ContextKind.Person,
          email: user.email || '',
          name: user.name || ''
        };
      }
      
      ldClient.identify(contexts);
    }
  }, [activeAccountId, ldClient, user]);

  // Modify the click outside handler to include projects dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
      if (inboxRef.current && !inboxRef.current.contains(event.target as Node)) {
        setShowInbox(false);
      }
      if (organizationsRef.current && !organizationsRef.current.contains(event.target as Node)) {
        setShowOrganizations(false);
      }
      if (accountSettingsRef.current && !accountSettingsRef.current.contains(event.target as Node)) {
        setShowAccountSettings(false);
      }
      if (projectsRef.current && !projectsRef.current.contains(event.target as Node)) {
        setShowProjects(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Modify the escape key handler to include projects dropdown
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (isMenuOpen) setIsMenuOpen(false);
        if (showInbox) setShowInbox(false);
        if (showOrganizations) setShowOrganizations(false);
        if (showAccountSettings) setShowAccountSettings(false);
        if (showProjects) setShowProjects(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isMenuOpen, showInbox, showOrganizations, showAccountSettings, showProjects]);

  // Add handler for organization selection
  const handleOrganizationSelect = (accountId: string) => {
    const numericAccountId = parseInt(accountId, 10);
    if (numericAccountId !== activeAccountId) {
      dispatch(activeProjectSlice.actions.setActiveProject(null));
      dispatch(activeAccountSlice.actions.setActiveAccount(numericAccountId));
      window.location.href = '/';
    }
    setShowOrganizations(false);
  };

  // Handle account settings items
  const handleAccountSettingsClick = (setting: string) => {
    console.log(`Account settings ${setting} clicked`);
    setShowAccountSettings(false);
    setIsMenuOpen(false);
    
    // Navigate based on selected setting
    if (setting === 'Linked Accounts') {
      navigate('/account?tab=linked-accounts');
    } else if (setting === 'Custom Properties') {
      navigate('/account?tab=custom-properties');
    }
  };

  // Add handler for project selection
  const handleProjectSelect = (id: number) => {
    if (id !== activeProjectId) {
      dispatch(activeProjectSlice.actions.setActiveProject(id));
      navigate(`/project/${id}`);
    }
    setShowProjects(false);
    setIsMenuOpen(false);
  };

  // Handle project creation
  const handleCreateProject = () => {
    setShowCreateProjectModal(true);
    setShowProjects(false);
    setIsMenuOpen(false);
  };

 // Include the Projects item only if legacyProject is true
const menuItems = [
  ...(accounts.length > 1
    ? [
        {
          label: 'Organization',
          subLabel: activeAccount?.name || 'Select Organization',
          icon: <BuildingOfficeIcon className="w-5 h-5 mr-2" />,
          onClick: () => setShowOrganizations(!showOrganizations),
          hasDropdown: true,
          onMouseLeave: () => {
            if (showOrganizations) {
              handleMenuEnter();
            }
          }
        }
      ]
    : []),
  ...(legacyProject
    ? [
        {
          label: 'Projects',
          icon: <ProjectIcon className="w-3.5 h-3.5 mr-3" fill="#94A3B8" />,
          onClick: () => setShowProjects(!showProjects),
          hasDropdown: true,
          onMouseLeave: () => {
            if (showProjects) {
              handleMenuEnter();
            }
          }
        }
      ]
    : []),
  {
    label: 'Invite Users',
    icon: <UserPlusIcon className="w-5 h-5 mr-2" />,
    onClick: handleInviteUsers,
    onMouseLeave: handleMenuEnter
  },
  {
    label: 'Account Settings',
    icon: <Cog6ToothIcon className="w-5 h-5 mr-2" />,
    onClick: () => setShowAccountSettings(!showAccountSettings),
    hasDropdown: true,
    onMouseLeave: () => {
      if (showAccountSettings) {
        handleMenuEnter();
      }
    }
  },
  {
    label: 'Logout',
    icon: <ArrowRightOnRectangleIcon className="w-5 h-5 mr-2" />,
    onClick: handleLogout,
    onMouseLeave: handleMenuEnter
  }
];

  return (
    // the bordershould not have a border top
    <div className="mt-auto px-2 py-4 border-slate-200" ref={menuRef}>
      <div 
        className={`flex items-center cursor-pointer rounded-lg p-2 border hover:bg-slate-100 ${
          isCollapsed ? 'justify-center' : 'justify-between'
        }`}
      >
        <div className="flex items-center flex-1">
          <div 
            className="relative cursor-pointer" 
            onClick={(e) => {
              e.stopPropagation();
              handleNotificationsToggle(e);
            }}
          >
            <BellIcon className="w-6 h-6 text-gray-500" />
            {unreadNotifications && unreadNotifications.length > 0 && (
              <div className="absolute -top-1 -right-1 bg-primary text-white rounded-full w-4 h-4 flex items-center justify-center text-[10px] font-medium">
                {unreadNotifications.length}
              </div>
            )}
          </div>
          
          {!isCollapsed && (
            <div 
              className="ml-3 flex-1 overflow-hidden cursor-pointer"
              onClick={handleToggleMenu}
            >
              <p className="text-sm font-medium text-gray-800 truncate">
                {user?.name || userName}
              </p>
              <p className="text-xs text-gray-500 truncate">{activeAccount?.name || organizationName}</p>
            </div>
          )}
        </div>

        {!isCollapsed && (
          <div onClick={handleToggleMenu}>
            {isMenuOpen ? (
              <ChevronUpIcon width="16" height="16" className="text-gray-500" />
            ) : (
              <ChevronDownIcon width="16" height="16" className="text-gray-500" />
            )}
          </div>
        )}
      </div>
      
      {/* User menu dropdown */}
      {isMenuOpen && !isCollapsed && (
        <div 
          ref={dropdownContainerRef}
          className="fixed bottom-[5rem] left-2 bg-white shadow-lg rounded-lg border border-slate-200 overflow-hidden z-[9999] w-64 dropdown-container"
          onMouseLeave={handleMenuLeave}
          onMouseEnter={handleMenuEnter}
        >
          <div className="py-1">
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                {/* Add divider before Logout item */}
                {item.label === 'Logout' && <hr className="my-1 border-t border-slate-200" />}
                <div 
                  className="flex items-center px-5 py-3 mx-2 my-1 text-sm text-gray-700 hover:bg-slate-100 cursor-pointer relative rounded-md"
                  onClick={item.onClick}
                  onMouseLeave={item.onMouseLeave}
                >
                  <div className="relative">
                    {item.icon}
                  </div>
                  <div className="flex flex-col">
                    <span>{item.label}</span>
                    {item.subLabel && (
                      <span className="text-xs text-gray-500">{item.subLabel}</span>
                    )}
                  </div>
                  {item.hasDropdown && (
                    <ChevronRightIcon className="w-4 h-4 ml-auto text-gray-400" />
                  )}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
      
      {/* Simplified menu for collapsed state */}
      {isMenuOpen && isCollapsed && (
        <div 
          className="fixed bottom-[5rem] left-2 bg-white shadow-lg rounded-lg border border-slate-200 overflow-hidden z-[9999] w-40"
          onMouseLeave={handleMenuLeave}
          onMouseEnter={handleMenuEnter}
        >
          <div className="py-1">
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                {/* Add divider before Logout item */}
                {item.label === 'Logout' && <hr className="my-1 border-t border-slate-200" />}
                <div 
                  className="flex items-center px-5 py-3 mx-2 my-1 text-sm text-gray-700 hover:bg-slate-100 cursor-pointer rounded-md"
                  onClick={item.onClick}
                  onMouseLeave={item.onMouseLeave}
                >
                  <div className="relative">
                    {item.icon}
                  </div>
                  {item.label}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
      
      {/* Notifications panel */}
      {showInbox && (
        <div 
          ref={inboxRef}
          className="fixed bottom-[5rem] left-2 w-96 bg-white shadow-lg rounded-lg border border-gray-200 max-h-[80vh] overflow-hidden z-[9999]"
        >
          <div className="p-4 border-b border-gray-200 flex justify-between items-center">
            <div className="flex items-center gap-4">
              <h3 className="font-medium">Notifications</h3>
              <label className="flex items-center gap-2 text-sm text-gray-500">
                <input
                  type="checkbox"
                  checked={showReadNotifications}
                  onChange={(e) => setShowShowReadNotifications(e.target.checked)}
                  className="form-checkbox h-4 w-4 text-primary rounded border-gray-300"
                />
                Show read
              </label>
            </div>
            <button
              onClick={handleMarkAllAsRead}
              className="text-sm text-gray-500 hover:text-gray-700"
            >
              Mark all as read
            </button>
          </div>
          
          <div className="overflow-y-auto max-h-[60vh]">
            <InboxNotificationList>
              {isLoading && (
                <div className="text-center text-gray-500 py-4">
                  Loading notifications...
                </div>
              )}
              {
                !isLoading && filteredNotifications?.map((notification) => { 
                  return (
                    <div 
                      key={notification.id}
                      className="relative cursor-pointer hover:bg-gray-50 group"
                    >
                      <InboxNotification 
                        key={notification.id}
                        showActions={false}
                        inboxNotification={notification} 
                        kinds={{
                          thread: (props) => (
                            <ThreadComponent 
                              notification={notification}
                              onNotificationClick={handleNotificationClick}
                              props={props}
                            />
                          )
                        }}
                      />
                      {!notification.readAt && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            markAsRead(notification.id);
                          }}
                          className="absolute right-3 top-1/2 -translate-y-1/2 px-2 py-1 text-xs text-gray-500 hover:text-gray-700 bg-white rounded border border-gray-200 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          Mark as read
                        </button>
                      )}
                    </div>
                  );
                })
              }
            </InboxNotificationList>

            {!hasFetchedAll && (
              <button
                onClick={() => fetchMore?.()}
                className="w-full p-3 text-sm text-gray-500 hover:text-gray-700 hover:bg-gray-50"
              >
                Load more...
              </button>
            )}

            {filteredNotifications && filteredNotifications.length === 0 && (
              <div className="text-center text-gray-500 py-8">
                No notifications
              </div>
            )}
          </div>
        </div>
      )}
      
      {/* Invite Users Modal */}
      {showInviteModal && activeAccount && (
        <StakeholdersInviteModal
          isOpen={showInviteModal}
          onClose={handleInviteModalClose}
          entityType={stakeholdersEntityTypes.folder}
          entityId={activeAccountId}
          entityName={activeAccount.name}
        />
      )}

      {/* Organizations dropdown */}
      {showOrganizations && accounts && accounts.length > 0 && (
        <div 
          ref={organizationsRef}
          className="fixed bottom-[5rem] left-[16.5rem] bg-white shadow-lg rounded-lg border border-slate-200 overflow-hidden z-[9999] w-64 dropdown-container"
          style={{
            marginLeft: '-1px',
            marginTop: '-1px',
            position: 'fixed',
            top: 'auto',
            bottom: '5rem',
            left: '16.5rem'
          }}
          onMouseLeave={() => {
            setShowOrganizations(false);
            // Don't trigger handleMenuLeave here, let the main menu handle its own timeout
          }}
          onMouseEnter={handleMenuEnter}
        >
          <div className="py-1">
            <div className="px-5 py-3 mb-2 text-sm font-medium text-gray-500 border-b border-slate-200">
              Organizations
            </div>
            {accounts.map((account) => (
              <div
                key={account.id}
                className={`flex items-center px-5 py-3 mx-2 my-1 text-sm text-gray-700 hover:bg-slate-100 cursor-pointer rounded-md ${
                  account.id === activeAccountId ? 'bg-slate-50' : ''
                }`}
                onClick={() => handleOrganizationSelect(String(account.id))}
              >
                <BuildingOfficeIcon className="w-5 h-5 mr-2 text-gray-400" />
                <span className="truncate">{account.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Add Account Settings dropdown */}
      {showAccountSettings && (
        <div 
          ref={accountSettingsRef}
          className="fixed bottom-[5rem] left-[16.5rem] bg-white shadow-lg rounded-lg border border-slate-200 overflow-hidden z-[9999] w-64 dropdown-container"
          style={{
            marginLeft: '-1px',
            marginTop: '-1px',
            position: 'fixed',
            top: 'auto',
            bottom: '5rem',
            left: '16.5rem'
          }}
          onMouseLeave={() => {
            setShowAccountSettings(false);
            // Don't trigger handleMenuLeave here, let the main menu handle its own timeout
          }}
          onMouseEnter={handleMenuEnter}
        >
          <div className="py-1">
            <div className="px-5 py-3 mb-2 text-sm font-medium text-gray-500 border-b border-slate-200">
              Account Settings
            </div>
            {[
              { name: 'Linked Accounts', icon: <ArrowPathIcon className="w-5 h-5 mr-2 text-gray-400" /> },
              { name: 'Custom Properties', icon: <Cog6ToothIcon className="w-5 h-5 mr-2 text-gray-400" /> }
            ].map((setting) => (
              <div
                key={setting.name}
                className="flex items-center px-5 py-3 mx-2 my-1 text-sm text-gray-700 hover:bg-slate-100 cursor-pointer rounded-md"
                onClick={() => handleAccountSettingsClick(setting.name)}
              >
                {setting.icon}
                <span className="truncate">{setting.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Projects dropdown */}
      { legacyProject && showProjects && (
        <div 
          ref={projectsRef}
          className="fixed bottom-[5rem] left-[16.5rem] bg-white shadow-lg rounded-lg border border-slate-200 overflow-hidden z-[9999] w-64 dropdown-container"
          style={{
            marginLeft: '-1px',
            marginTop: '-1px',
            position: 'fixed',
            top: 'auto',
            bottom: '10rem',
            left: '16.5rem'
          }}
          onMouseLeave={() => {
            setShowProjects(false);
          }}
          onMouseEnter={handleMenuEnter}
        >
          <div className="py-1">
            <div className="px-5 py-3 mb-2 text-sm font-medium text-gray-500 border-b border-slate-200">
              Projects
            </div>
            {(projects || []).map((project) => (
              <div
                key={project.id}
                className={`flex items-center px-5 py-3 mx-2 my-1 text-sm text-gray-700 hover:bg-slate-100 cursor-pointer rounded-md ${
                  project.id === activeProjectId ? 'bg-slate-50' : ''
                }`}
                onClick={() => handleProjectSelect(project.id)}
              >
                <ProjectIcon className="w-3 h-3 mr-2 text-gray-400" fill="#94A3B8" />
                <span className="truncate">{project.name}</span>
              </div>
            ))}
            <div className="w-full h-[1px] bg-border my-2"></div>
            <div 
              className="flex items-center px-5 py-3 mx-2 my-1 text-sm text-gray-700 hover:bg-slate-100 cursor-pointer rounded-md"
              onClick={handleCreateProject}
            >
              <PlusIcon className="w-5 h-5 mr-2 text-gray-400" />
              <span className="truncate">Create project</span>
            </div>
          </div>
        </div>
      )}

      {/* Create Project Modal */}
      {showCreateProjectModal && (
        <CreateProjectModal
          isOpen={showCreateProjectModal}
          onClose={() => setShowCreateProjectModal(false)}
        />
      )}
    </div>
  );
};

export default UserMenuButton; 