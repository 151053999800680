import { GenericIntegrationType } from 'src/services/integrations/types';
import { LayerItem, LayerType } from './types';

export const LAYER_QURIES: LayerItem[] = [
    { type: 'dbt_seed', name: 'dbt Seed', eql: `(type IN ('dbt_seed'))`, layer: LayerType.Data, integrationType: GenericIntegrationType.dbt_core },
    { type: 'dbt_metric', name: 'dbt Metric', eql: `(type IN ('dbt_metric'))`, layer: LayerType.Data, integrationType: GenericIntegrationType.dbt_core },
    { type: 'dbt_model', name: 'dbt Model', eql: `(type IN ('dbt_model'))`, layer: LayerType.Data, integrationType: GenericIntegrationType.dbt_core },
    { type: 'dbt_source', name: 'dbt Source', eql: `(type IN ('dbt_source'))`, layer: LayerType.Data, integrationType: GenericIntegrationType.dbt_core },
    // Looker
    { type: 'looker_dashboard', name: 'Looker Dashboard', eql: `(type IN ('looker_dashboard'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.looker },
    { type: 'looker_explore', name: 'Looker Explore', eql: `(type IN ('looker_explore'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.looker },
    { type: 'looker_look', name: 'Looker Look', eql: `(type IN ('looker_look'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.looker },
    { type: 'looker_tile', name: 'Looker Tile', eql: `(type IN ('looker_tile'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.looker },
    { type: 'looker_view', name: 'Looker View', eql: `(type IN ('looker_view'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.looker },
    // Tableau
    { type: 'tableau_custom_sql', name: 'Tableau Custom SQL', eql: `(type IN ('tableau_custom_sql'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.tableau },
    { type: 'tableau_dashboard', name: 'Tableau Dashboard', eql: `(type IN ('tableau_dashboard'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.tableau },
    { type: 'tableau_embedded_datasource', name: 'Tableau Embedded Datasource', eql: `(type IN ('tableau_embedded_datasource'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.tableau },
    { type: 'tableau_published_datasource', name: 'Tableau Published Datasource', eql: `(type IN ('tableau_published_datasource'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.tableau },
    { type: 'tableau_view', name: 'Tableau View', eql: `(type IN ('tableau_view'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.tableau },
    { type: 'tableau_workbook', name: 'Tableau Workbook', eql: `(type IN ('tableau_workbook'))`, layer: LayerType.BI, integrationType: GenericIntegrationType.tableau },
    // Snowflake
    { type: 'snowflake_view', name: 'Snowflake View', eql: `(type IN ('snowflake_view'))`, layer: LayerType.Data, integrationType: GenericIntegrationType.snowflake },
];