import { useEffect, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "src/components/layout/Layout";
import { DataModelSyncTab } from "./types";
// import TopBar from "src/components/layout/TopBar";
// import { Tabs } from "src/components/Tabs";
import { DataModelSyncJobs } from "src/features/dataModelSync/syncJobs/DataModelSyncJobs";
import { DataModelSyncRuns } from "src/features/dataModelSync/syncRuns/DataModelSyncRuns";
import { useFlags } from 'launchdarkly-react-client-sdk';


export const DataModelSyncPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { legacyProject } = useFlags();


    const tab = useMemo(() => searchParams.get('tab') as DataModelSyncTab, [searchParams]);

    const setTab = useCallback((tab: DataModelSyncTab) => {
        searchParams.set('tab', tab);
        setSearchParams(searchParams);
      }, [searchParams, setSearchParams]);
    
      useEffect(() => {
        if (!tab) {
          setTab(legacyProject ? DataModelSyncTab.Jobs : DataModelSyncTab.Runs);
      }
      }, [tab, setTab,legacyProject]); 

    return (
        <Layout>
            <div className="bg-slate-50 h-full">
                {
                    tab === DataModelSyncTab.Runs && (<DataModelSyncRuns />)
                }
                {
                    tab === DataModelSyncTab.Jobs && (<DataModelSyncJobs />)
                }
            </div>
        </Layout>
    );
};
