import { useCallback } from "react";
import EqlInput from "src/components/eql/input/EQLInput";
import { UpdateDataModelStateProps } from "../types";
import { useFlags } from "launchdarkly-react-client-sdk";


type DataModelEqlInputProps = {
    eqlInEdit: string;
    setEqlInEdit: (eql: string) => void;
    isLoading: boolean;
    updateDataModelState: (props: UpdateDataModelStateProps) => void;
}

export const DataModelEqlInput = ({ eqlInEdit, setEqlInEdit, isLoading, updateDataModelState }: DataModelEqlInputProps) => {
    const { genai } = useFlags();
    const onSubmit = useCallback((eql: string) => {
        updateDataModelState({ eql });
    }, [updateDataModelState]);

    return (
        <EqlInput value={eqlInEdit} onSearch={onSubmit} isEQLLoading={isLoading} onChange={eql => {
            setEqlInEdit(eql);
        }} showDataModelButton={false} enableGenAI={genai} showValidation={false} />
    );
};
