import { useState, useEffect, SetStateAction, useCallback, useRef } from "react";
import { FormField } from "src/components/form/FormField";
import Input from "src/components/form/Input";
import EQLInput  from "src/components/eql/input/EQLInput";
import { CustomProperty, CustomPropertyFEType, MultiSelectOption } from "src/services/customProperties/types";
import { ChevronUpIcon, ChevronDownIcon, MinusCircleIcon, PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";

export interface PropertyInputProps {
    customProperty: CustomProperty;
    setCustomProperty: (value: SetStateAction<CustomProperty>) => void;
    onValidityChange?: (isValid: boolean) => void;
    type?: CustomPropertyFEType;
}

export const CategoryAssignedInput = ({ customProperty, setCustomProperty, onValidityChange }: PropertyInputProps) => {
    const [options, setOptions] = useState<MultiSelectOption[]>(() => {
        return customProperty.content.type === CustomPropertyFEType.CategoryAssigned
            ? [...customProperty.content.content.options]
            : [];
    });
    const [newValue, setNewValue] = useState('');
    const [newEql, setNewEql] = useState('');
    const [eqlValidations, setEqlValidations] = useState<Record<string, boolean>>(() => {
        const initialValidations: Record<string, boolean> = {};
        if (customProperty.content.type === CustomPropertyFEType.CategoryAssigned) {
            customProperty.content.content.options.forEach((_, index) => {
                initialValidations[`option-${index}`] = true;
            });
        }
        return initialValidations;
    });
    const [newEqlValid, setNewEqlValid] = useState(true);

    const eqlValidationCallbacks = useRef<Record<string, (isValid: boolean) => void>>({});
    
    useEffect(() => {
        const allOptionsValid = Object.values(eqlValidations).every(isValid => isValid);
        const validNewEql = !newEql.trim() || newEqlValid;

        if (onValidityChange) {
            onValidityChange(allOptionsValid && validNewEql);
        }
    }, [eqlValidations, newEqlValid, newEql, onValidityChange]);

    useEffect(() => {
        const currentOptions = customProperty.content.type === CustomPropertyFEType.CategoryAssigned
            ? customProperty.content.content.options
            : [];
        
        const hasValidNewValue = newValue.trim() && newEql.trim() && newEqlValid;
        const isDuplicate = options.some(opt => opt.value === newValue.trim());
        
        let combinedOptions = [...options];
        if (hasValidNewValue && !isDuplicate) {
            combinedOptions = [...options, { value: newValue.trim(), eql: newEql.trim() }];
        }

        if (JSON.stringify(currentOptions) !== JSON.stringify(combinedOptions)) {
            setCustomProperty(prev => ({
                ...prev,
                content: {
                    type: CustomPropertyFEType.CategoryAssigned,
                    content: { options: combinedOptions }
                }
            }));
        }
    }, [options, newValue, newEql, newEqlValid, setCustomProperty, customProperty.content]);

    const handleRemoveOption = (index: number) => {
        if (options.length <= 1) return;
        
        setOptions(options.filter((_, i) => i !== index));

        const newValidations = { ...eqlValidations };
        delete newValidations[`option-${index}`];

        const reindexedValidations: Record<string, boolean> = {};
        options.forEach((_, i) => {
            if (i < index) {
                reindexedValidations[`option-${i}`] = eqlValidations[`option-${i}`];
            } else if (i > index) {
                reindexedValidations[`option-${i - 1}`] = eqlValidations[`option-${i}`];
            }
        });

        setEqlValidations(reindexedValidations);
    };

    const handleMoveOption = (index: number, direction: 'up' | 'down') => {
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex < 0 || newIndex >= options.length) return;

        const newOptions = [...options];
        
        const temp = newOptions[index];
        newOptions[index] = newOptions[newIndex];
        newOptions[newIndex] = temp;
        
        setOptions(newOptions);

        const newValidations = { ...eqlValidations };
        
        const tempValid = newValidations[`option-${index}`];
        newValidations[`option-${index}`] = newValidations[`option-${newIndex}`];
        newValidations[`option-${newIndex}`] = tempValid;
        
        setEqlValidations(newValidations);
    };

    const handleUpdateOption = (index: number, field: 'value' | 'eql', value: string) => {
        const newOptions = [...options];
        newOptions[index][field] = value;
        setOptions(newOptions);

        if (field === 'eql') {
            setEqlValidations(prev => ({
                ...prev,
                [`option-${index}`]: true
            }));
        }
    };

    const getEqlValidationHandler = useCallback((index: number) => {
        if (!eqlValidationCallbacks.current[`option-${index}`]) {
            eqlValidationCallbacks.current[`option-${index}`] = (isValid: boolean) => {
                setEqlValidations(prev => ({
                    ...prev,
                    [`option-${index}`]: isValid
                }));
            };
        }
        return eqlValidationCallbacks.current[`option-${index}`];
    }, []);

    const handleNewEqlValidationChange = useCallback((isValid: boolean) => {
        setNewEqlValid(isValid);
    }, []);

    const handleNewValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewValue(e.target.value);
    };

    const handleNewEqlChange = (value: string) => {
        setNewEql(value);
    };

    const handleAddOption = () => {
        if (!newValue.trim() || !newEql.trim() || !newEqlValid || options.some(opt => opt.value === newValue.trim())) return;
        const newOptions = [...options, { value: newValue.trim(), eql: newEql.trim() }];
        setOptions(newOptions);

        setEqlValidations(prev => ({
            ...prev,
            [`option-${newOptions.length - 1}`]: newEqlValid
        }));

        setNewValue('');
        setNewEql('');
    };
    
    return (
        <div className="flex flex-col gap-2 max-w-[680px]">
            <h3 className="text-md font-medium">Add Values</h3>
            {options.length > 0 && (
                options.map((option, index) => (
                    <div key={index} className="flex items-center gap-2  rounded-md bg-slate-200 p-3">
                        <div className="flex flex-col w-full gap-2">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-center w-full">
                                    <div className="w-44">Value Name</div>
                                    <Input
                                        placeholder="Enter property value"
                                        value={option.value}
                                        onInputChange={(e) => handleUpdateOption(index, 'value', e.target.value)}
                                        className="flex-1 mr-5 max-w-[450px]"
                                    />
                                </div>
                                <div className="flex items-center mb-4 ">
                                    <div className="w-40 mr-2">EQL</div>
                                    <div className="flex-1 max-w-[480px] mr-5">
                                        <EQLInput
                                            value={option.eql}
                                            onChange={(e) => handleUpdateOption(index, 'eql', e)}
                                            placeholder="Enter EQL query..."
                                            onValidationChange={getEqlValidationHandler(index)}
                                            showSearchButton={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <button
                                onClick={() => handleMoveOption(index, 'up')}
                                disabled={index === 0}
                                className="p-1 text-slate-500 hover:text-slate-800 disabled:opacity-50 disabled:cursor-default transition-colors"
                            >
                                <ChevronUpIcon className="h-4 w-4" />
                            </button>
                            <button
                                onClick={() => handleRemoveOption(index)}
                                disabled={options.length <= 1}
                                className="p-1 text-slate-500 hover:text-red-600 disabled:opacity-50 disabled:cursor-default transition-colors"
                            >
                                <XMarkIcon className="h-4 w-4" />
                            </button>
                            <button
                                onClick={() => handleMoveOption(index, 'down')}
                                disabled={index === options.length - 1}
                                className="p-1 text-slate-500 hover:text-slate-800 disabled:opacity-50 disabled:cursor-default transition-colors"
                            >
                                <ChevronDownIcon className="h-4 w-4" />
                            </button>
                        </div>
                    </div>
                ))
            )}
            <div className="flex items-center gap-2 mt-2  rounded-md bg-slate-200 p-3">
                <div className="flex flex-col w-full gap-2">
                    <div className="flex flex-col gap-3">
                        <div className="flex items-center w-full">
                            <div className="w-44">Value Name</div>
                            <Input
                                placeholder="Enter property value"
                                value={newValue}
                                onInputChange={handleNewValueChange}
                                className="flex-1 mr-5 max-w-[450px]"
                            />
                        </div>
                        <div className="flex items-center mb-4 ">
                            <div className="w-40 mr-2">EQL</div>
                            <div className="flex-1 max-w-[450px] mr-5">
                                <EQLInput
                                    value={newEql}
                                    onChange={handleNewEqlChange}
                                    placeholder="Enter EQL query..."
                                    onValidationChange={handleNewEqlValidationChange}
                                    showSearchButton={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <button
                        disabled={true}
                        className="p-1 text-slate-400 hover:text-slate-600 disabled:opacity-50 disabled:cursor-default transition-colors"
                    >
                        <ChevronUpIcon className="h-4 w-4" />
                    </button>
                    <button
                        disabled={true}
                        className="p-1 text-slate-400 hover:text-red-600 disabled:opacity-50 disabled:cursor-default transition-colors"
                    >
                        <XMarkIcon className="h-4 w-4" />
                    </button>
                    <button
                        disabled={true}
                        className="p-1 text-slate-400 hover:text-slate-600 disabled:opacity-50 disabled:cursor-default transition-colors"
                    >
                        <ChevronDownIcon className="h-4 w-4" />
                    </button>
                </div>
            </div>
            <div className="flex justify-end">
                <Button 
                    className="ml-4 hover:border-lilac-300" 
                    type={ButtonTypes.secondary} 
                    text="Add Value +" 
                    onClick={handleAddOption} 
                    isDisabled={!newValue.trim() || !newEql.trim() || !newEqlValid}
                />
            </div>
        </div>
    );
};

export const SingleSelectInput = ({ customProperty, setCustomProperty }: PropertyInputProps) => {
    return (
        <FormField label="Value" labelClassName="w-44">
            <Input
                placeholder="Enter property value"
                value={customProperty.content.type === CustomPropertyFEType.SingleSelect
                    ? customProperty.content.content.options[0]
                    : ""}
                onInputChange={(e) => {
                    setCustomProperty({
                        ...customProperty,
                        content: {
                            type: CustomPropertyFEType.SingleSelect,
                            content: { options: [e.target.value] }
                        }
                    });
                }}
            />
        </FormField>
    );
};

export const MultiSelectInput = ({ 
    customProperty, 
    setCustomProperty, 
    type 
}: PropertyInputProps & { 
    type: CustomPropertyFEType.MultiSelect | CustomPropertyFEType.SingleSelect 
}) => {
    const [values, setValues] = useState<string[]>(() => {
        if (customProperty.content.type === CustomPropertyFEType.MultiSelect && 'options' in customProperty.content.content) {
            const options = customProperty.content.content.options;
            const mappedOptions = options.map(opt => typeof opt === 'string' ? opt : (opt as { value: string }).value);
            return mappedOptions.length > 0 ? mappedOptions : [''];
        }
        return [''];
    });

    useEffect(() => {
        if (customProperty.content.type === CustomPropertyFEType.MultiSelect && values.length === 0) {
            setValues(['']);
        }
    }, [customProperty.content.type, values.length]);

    const currentOptionsRef = useRef<string[]>([]);
    useEffect(() => {
        if (customProperty.content.type === CustomPropertyFEType.MultiSelect && 'options' in customProperty.content.content) {
            const options = customProperty.content.content.options;
            const mappedOptions = options.map(opt => typeof opt === 'string' ? opt : (opt as { value: string }).value);
            currentOptionsRef.current = mappedOptions;
        } else {
            currentOptionsRef.current = [];
        }
    }, [customProperty.content.type,customProperty.content.content]);

    useEffect(() => {
        const nonEmptyValues = values.filter(v => v.trim().length > 0);
        
        const currentValues = currentOptionsRef.current;
        const hasChanged = 
            currentValues.length !== nonEmptyValues.length || 
            nonEmptyValues.some((val, idx) => val !== currentValues[idx]);

        if (hasChanged) {
            setCustomProperty(prev => ({
                ...prev,
                content: {
                    type,
                    content: {
                        options: nonEmptyValues
                    }
                }
            }));
            
            currentOptionsRef.current = nonEmptyValues;
        }
    }, [values, setCustomProperty, type]);

    const onValueChange = (index: number, value: string) => {
        const newValues = [...values];
        newValues[index] = value;
        setValues(newValues);
    };

    const onDelete = (index: number) => {
        if (values.length === 1) {
            setValues(['']);
            return;
        }
        const newValues = values.filter((_, i) => i !== index);
        setValues(newValues);
    };

    const onAdd = () => {
        setValues([...values, '']);
    };

    return (
        <div className="flex flex-col gap-2 mt-3 max-w-[680px]">
            <div className="flex justify-between items-start whitespace-nowrap gap-4">
                <div className="w-56">Values</div>
                <div className="flex flex-col gap-2 w-full">
                    {values.length > 0 ? (
                        values.map((value, index) => (
                            <div key={index} className="flex items-center gap-2">
                                <Input
                                    value={value}
                                    onInputChange={(e) => onValueChange(index, e.target.value)}
                                    placeholder="Enter value"
                                    className="w-full border border-border rounded shadow hover:border-primary"
                                />
                                <div className="flex gap-1">
                                    <MinusCircleIcon
                                        className='text-slate-400 cursor-pointer'
                                        onClick={() => onDelete(index)}
                                        height={16}
                                        width={16}
                                    />
                                    {index === values.length - 1 && (
                                        <PlusCircleIcon
                                            className={`${value.length > 0 ? 'text-slate-400' : 'text-slate-300'} cursor-pointer`}
                                            onClick={value.length > 0 ? onAdd : undefined}
                                            height={16}
                                            width={16}
                                        />
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="flex items-center gap-2">
                            <Input
                                value=""
                                onInputChange={(e) => onValueChange(0, e.target.value)}
                                placeholder="Enter value"
                                className="w-full border border-border rounded shadow hover:border-primary"
                            />
                            <div className="flex gap-1">
                                <PlusCircleIcon
                                    className="text-slate-300 cursor-pointer"
                                    onClick={() => onAdd()}
                                    height={16}
                                    width={16}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export const LiveBooleanInput = ({ customProperty, setCustomProperty, onValidityChange }: PropertyInputProps) => {
    const eqlValue = customProperty.content.type === CustomPropertyFEType.LiveBoolean
        ? customProperty.content.content.eql || ''
        : '';

    const [isEqlValid, setIsEqlValid] = useState(true);
    const eqlValidationCallback = useRef<(isValid: boolean) => void>();

    useEffect(() => {
        eqlValidationCallback.current = (isValid: boolean) => {
            setIsEqlValid(isValid);
        };
    }, []);

    useEffect(() => {
        if (onValidityChange) {
            onValidityChange(isEqlValid);
        }
    }, [isEqlValid, onValidityChange]);

    const handleEqlChange = useCallback((newValue: string) => {
        if (customProperty.content.type === CustomPropertyFEType.LiveBoolean) {
            setCustomProperty({
                ...customProperty,
                content: {
                    type: CustomPropertyFEType.LiveBoolean,
                    content: {
                        options: [],
                        eql: newValue,
                        scope: customProperty.propertyScope || 'true'
                    }
                }
            });
        }
    }, [customProperty, setCustomProperty]);

    return (
        <div className="flex flex-col justify-between mt-4 gap-4">
            <FormField label="True If" labelClassName="w-40 mr-2">
                <EQLInput
                    placeholder="Add EQL expression"
                    value={eqlValue}
                    onChange={handleEqlChange}
                    onValidationChange={eqlValidationCallback.current}
                    showSearchButton={false}
                />
            </FormField>
        </div>
    );
};
