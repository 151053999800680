import React from 'react';
import { CustomProperty } from 'src/services/customProperties/types';
import { IExpandedNode } from 'src/features/models/discover/INode';
import Modal from 'src/components/Modal/Modal';
import { ButtonTypes } from 'src/components/button/types';

type RemovePropertyModalProps = {
    isOpen: boolean;
    property: CustomProperty;
    resource: IExpandedNode;
    onClose: () => void;
    onConfirm: () => void;
    isLoading?: boolean;
};

const RemovePropertyModal: React.FC<RemovePropertyModalProps> = ({ 
    isOpen, 
    property, 
    resource, 
    onClose, 
    onConfirm,
    isLoading
}) => {
    return (
        <Modal
            title="Confirm Removal"
            isOpen={isOpen}
            onClose={onClose}
            buttons={[
                {
                    text: "Cancel",
                    onClick: onClose,
                    type: ButtonTypes.secondary,
                    position: "left",
                    isDisabled: isLoading
                },
                {
                    text: isLoading ? "Removing..." : "Remove property",
                    onClick: onConfirm,
                    type: ButtonTypes.primary,
                    position: "right",
                    isDisabled: isLoading
                }
            ]}
        >   
            <div className="text-gray-600 my-4">
                <p>You are about to remove <span className="bg-lilac-200 text-lilac-800 px-2 py-1 rounded text-sm font-semibold">{property.name}</span> from <span className="font-bold">{resource.name}</span>.</p>
                <p className="mt-2">Removing this property may affect the resource&apos;s visibility in filters and reports.</p>
            </div>
        </Modal>
    );
};

export default RemovePropertyModal; 