import { parser } from "../suggestions/eql";
import { EqlToken, LezerToken } from "../suggestions/types";
import { TreeCursor } from '@lezer/common';
import { replaceAll } from "src/utils/stringsUtils";
import sanitizeHtml from "sanitize-html";

export const getTextColor = (
    textVisible: boolean,
    sparklesActive: boolean,
    isLoading: boolean,
    textOpacity: number
): string => {
    if (!textVisible) return 'transparent';
    if (sparklesActive) return 'rgb(55, 65, 81)'; // gray-700
    if (isLoading) return `rgba(0, 0, 0, ${textOpacity})`;
    return 'black';
};

export const getPlaceholderOpacity = (
    textVisible: boolean,
    isLoading: boolean,
    textOpacity: number,
    inputValue: string
): number => {
    if (!textVisible) return 0;
    if (isLoading) return textOpacity * 0.7;
    if (inputValue) return 0;
    return 1;
};


export const getPlaceholderText = (
    isLoading: boolean,
    sparklesActive: boolean
): string => {
    if (isLoading) {
        return '';  // Empty string since we're using SVG loader
    } else if (sparklesActive) {
        return 'Ask a question about your data in plain English...';
    } else {
        return '';
    }
};


export const tokenize = (eql: string, cursorPosition?: number): EqlToken[] => {
    const tokens: EqlToken[] = [];
    const printTree = (cursor: TreeCursor, source: string) => {
        do {
            const nodeName = cursor.node.type.name as LezerToken;
            const nodeText = source.slice(cursor.from, cursor.to);
            const pos = `${cursor.from}-${cursor.to}`;

            if (cursorPosition !== undefined && cursor.from >= cursorPosition) {
                continue;
            }

            if (
                nodeName !== 'start' &&
                nodeText !== '' &&
                pos !== tokens[tokens.length - 1]?.pos
            ) {
                tokens.push({ text: nodeText, type: nodeName, pos });
            }
            if (cursor.firstChild()) {
                printTree(cursor, source);
                cursor.parent();
            }
        } while (cursor.nextSibling());
    };
    const tree = parser.parse(eql);
    printTree(tree.cursor(), eql);

    if (cursorPosition !== undefined) {
        return tokens.filter(token => {
            const [start] = token.pos.split('-').map(Number);
            return start < cursorPosition;
        });
    }

    return tokens;
};

export const sanitize = (text: string) => {
    const entitiesToReplace = [
        { entity: '&gt;', replacement: '>' },
        { entity: '&lt;', replacement: '<' },
        { entity: '&amp;', replacement: '&' },
    ];
    const sanitized = sanitizeHtml(text, { allowedTags: [], allowedAttributes: {} });
    const sanitizedWithEntities = entitiesToReplace.reduce((acc, { entity, replacement }) => replaceAll(acc, entity, replacement), sanitized);
    return sanitizedWithEntities;
};