import { TableView } from "src/services/accounts/types";
import { FilterMode } from "src/features/models/discover/types";
export type DataModelTableView = TableView & { config: DataModelTabConfig };

export type DataModelTabConfig = {
    showSubResources?: boolean;
    eql?: string;
    filters?: { name: string; value: string | null }[];
    filterMode: FilterMode;
    columns?: string[];
}

export const DATA_MODEL_TABLE_KEY = 'dataModel';