import { DBT_TYPES, LOOKER_TYPES, NodeType, TABLEAU_TYPES } from "src/features/models/discover/INode";

export type DamaProperty = {
    name: string;
    type: 'string' | 'number' | 'boolean' | 'date' | 'object' | 'array';
    filterable: boolean;
    relevantResources: NodeType[];
    nullable: boolean;
    options?: string[];
}

export type DamaPropertyType = 'string' | 'number' | 'boolean' | 'date' | 'object' | 'array';

export const damaProperties: DamaProperty[] = [
    {
        name: 'type',
        type: 'string',
        filterable: true,
        relevantResources: [],
        nullable: false
    },
    {
        name: 'name',
        type: 'string',
        filterable: true,
        relevantResources: [],
        nullable: false
    },
    {
        name: 'is_trivial_sql',
        type: 'boolean',
        filterable: true,
        relevantResources: TABLEAU_TYPES,
        nullable: true
    },
    {
        name: 'last_7d_views',
        type: 'number',
        filterable: true,
        relevantResources: LOOKER_TYPES,
        nullable: true,
    },
    {
        name: 'last_30d_views',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'total_queries_14d',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: [...LOOKER_TYPES, ...TABLEAU_TYPES]
    },
    {
        name: 'total_queries_30d',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: [...LOOKER_TYPES, ...TABLEAU_TYPES]
    },
    {
        name: 'total_queries_60d',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: [...LOOKER_TYPES, ...TABLEAU_TYPES]
    },
    {
        name: 'total_impressions_14d',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'total_impressions_30d',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'total_impressions_60d',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'distinct_users_14d',
        type: 'number',
        nullable: true,
        filterable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'distinct_users_30d',
        type: 'number',
        nullable: true,
        filterable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'distinct_users_60d',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'distinct_impressions_users_14d',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'distinct_impressions_users_30d',
        type: 'number',
        nullable: true,
        filterable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'distinct_impressions_users_60d',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'first_observation',
        type: 'date',
        filterable: true,
        nullable: false,
        relevantResources: []
    },
    {
        name: 'dbt_materialization_strategy',
        type: 'string',
        nullable: true,
        filterable: true,
        relevantResources: DBT_TYPES
    },
    {
        name: 'materialized',
        type: 'boolean',
        nullable: true,
        filterable: true,
        relevantResources: []
    },
    {
        name: 'tags',
        type: 'array',
        filterable: true,
        nullable: false,
        relevantResources: [...DBT_TYPES, ...TABLEAU_TYPES]
    },
    {
        name: 'meta',
        type: 'object',
        filterable: true,
        nullable: false,
        relevantResources: DBT_TYPES
    },
    {
        name: 'dbt_project',
        type: 'string',
        nullable: true,
        filterable: true,
        relevantResources: DBT_TYPES
    },
    {
        name: 'database_schema',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: DBT_TYPES
    },
    {
        name: 'database',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: DBT_TYPES
    },
    {
        name: 'looker_refinement_chain',
        type: 'array',
        filterable: true,
        nullable: false,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'looker_project',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'looker_folder',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'looker_model',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'looker_host',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'derived_type',
        type: 'string',
        nullable: true,
        filterable: true,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'has_refinements',
        type: 'boolean',
        filterable: true,
        nullable: true,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'source_directory',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: [...DBT_TYPES, ...LOOKER_TYPES]
    },
    {
        name: 'uri',
        type: 'string',
        filterable: true,
        nullable: false,
        relevantResources: []
    },
    {
        name: 'parent_type',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: []
    },
    {
        name: 'parent_name',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: []
    },
    {
        name: 'parent_container',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: []
    },
    {
        name: 'tableau_project',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'tableau_has_extracts',
        type: 'boolean',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'tableau_extract_refresh_frequency',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'tableau_workbook',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'owner',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: TABLEAU_TYPES
    },
    {
        name: 'is_identity_transformation',
        type: 'boolean',
        filterable: true,
        nullable: true,
        relevantResources: []
    },
    {
        name: 'lookml_view_persistency',
        type: 'string',
        filterable: true,
        nullable: true,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'pdt_builds_last_30d',
        type: 'number',
        filterable: true,
        nullable: true,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'pdt_total_build_time_30d',
        type: 'number',
        nullable: true,
        filterable: true,
        relevantResources: LOOKER_TYPES
    },
    {
        name: 'source_path',
        type: 'string',
        filterable: true,
        nullable: false,
        relevantResources: []
    },
    {
        name: 'out_of_sync_pre_aggregate_model',
        type: 'boolean',
        filterable: true,
        nullable: true,
        relevantResources: DBT_TYPES
    },
    {
        name: 'is_automatic_pre_aggregated_dbt_model',
        type: 'boolean',
        filterable: true,
        nullable: false,
        relevantResources: DBT_TYPES
    }
];
