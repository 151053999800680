import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import Input from "src/components/form/Input";
import TextArea from "src/components/form/Textarea";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useDeleteCustomPropertyMutation, useGetCalculationEventsQuery, useCreateAccountsCustomPropertyMutation, useUpdateAccountsCustomPropertyMutation } from "src/services/customProperties/customProperties";
import { notify } from "src/components/Toaster";
import Select, { Option } from "src/components/form/Select";
import Modal from "src/components/Modal/Modal";
import { useGetUserQuery } from "src/services/users";
import ScopedBooleanEditor from "./propertyTypes/ScopedBooleanEditor";
import SelectOptionsEditor from "./propertyTypes/SelectOptionsEditor";
import MultiSelectOptionsEditor from "./propertyTypes/MultiSelectOptionsEditor";
import AISelectEditor from "./propertyTypes/AISelectEditor";
import AggregateRelationshipEditor from "./propertyTypes/AggregateRelationshipEditor";
import { AggregateRelationshipContent, AggregateType, AISelectContent, ClosedListManualPropertyContent, CustomPropertyContentType, CustomPropertyResponse, CustomPropertyType, ManualPropertyContent, MultiSelectContent, MultiSelectOption, PROPERTY_TYPE_LABELS, RelationshipType, ScopedBooleanContent } from "src/services/customProperties/types";
import Checkbox from "src/components/form/Checkbox";
import  EQLInput  from "src/components/eql/input/EQLInput";
import { useFlags } from "launchdarkly-react-client-sdk";
import { eunoLinks } from '../../../features/eunoLinks';


type CustomPropertyFormProps = {
    property: CustomPropertyResponse | null;
    isNew: boolean;
    onCancel: () => void;
};

type ValidationErrorItem = {
    loc: string[];
    msg: string;
    type: string;
    ctx?: Record<string, unknown>;
};

type EQLErrorDetails = {
    error: string;
    line?: number;
    column?: number;
    allowed?: string[] | null;
};

type APIError = {
    status: number;
    data: {
        detail:
        | ValidationErrorItem[]
        | string
        | {
            message: string;
            'error details': EQLErrorDetails;
        };
    };
};

const BUILTIN_OWNER = "euno";

// Add a new component for editing scope
const ScopeEditor = ({ scope, onChange }: { scope: string, onChange: (scope: string) => void }) => {
    return (
        <div className="mb-6">
            <div className="text-sm font-medium text-slate-700 mb-4">Scope Configuration</div>
            <div className="flex flex-col gap-4">
                <div className="flex items-start gap-4">
                    <div className="w-32 pt-2">Scope RFEQL</div>
                    <div className="flex-1 mr-4 max-w-[480px] mb-1">
                        <EQLInput
                            value={scope}
                            onChange={onChange}
                            placeholder="Enter RFEQL expression to determine which resources this property applies to (default: 'true' for all resources)"
                            allowRelationships={false}
                            showDocumentationButton={true}
                            showSearchButton={false}
                        />
                    </div>
                </div>
                <div className="text-xs text-slate-500 ml-36">
                    RFEQL (relationship-free EQL) expression that determines which resources this property applies to.
                    Use &apos;true&apos; to apply to all resources.
                </div>
            </div>
        </div>
    );
};

const CustomPropertyForm = ({ property, isNew, onCancel }: CustomPropertyFormProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const { data: currentUser } = useGetUserQuery();
    const [createProperty, { isLoading: isCreating }] = useCreateAccountsCustomPropertyMutation();
    const [updateProperty, { isLoading: isUpdating }] = useUpdateAccountsCustomPropertyMutation();
    const [deleteProperty, { isLoading: isDeleting }] = useDeleteCustomPropertyMutation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { manageLlmCustomProperty, manageRelationshipAggregationProperty } = useFlags();

    // Fetch calculation events if we have a property
    const { data: calculationEvents } = useGetCalculationEventsQuery(
        { accountId, propertyId: property?.id || 0 },
        { skip: isNew || !property?.id }
    );

    // Check if the last calculation timed out
    const lastCalculationTimedOut = useMemo(() => {
        if (!calculationEvents || calculationEvents.length === 0) return false;
        return calculationEvents[0].timeout;
    }, [calculationEvents]);

    const validTypes = useMemo(() => {
        const types = [
            CustomPropertyType.manual_numeric_field,
            CustomPropertyType.manual_datetime_field,
            CustomPropertyType.manual_boolean_field,
            CustomPropertyType.manual_single_select,
            CustomPropertyType.manual_multi_select,
            CustomPropertyType.multi_select,
            CustomPropertyType.scoped_boolean,
        ];

        if (manageLlmCustomProperty) {
            types.push(CustomPropertyType.ai_select);
        }

        if (manageRelationshipAggregationProperty) {
            types.push(CustomPropertyType.aggregate_relationships);
        }

        return types;
    }, [manageLlmCustomProperty, manageRelationshipAggregationProperty]);

    const getDefaultContentForType = useCallback((type: CustomPropertyType): CustomPropertyContentType => {
        switch (type) {
            case CustomPropertyType.manual_multi_select:
            case CustomPropertyType.manual_single_select:
                return {
                    scope: 'true',
                    options: []
                };
            case CustomPropertyType.manual_text_field:
            case CustomPropertyType.manual_numeric_field:
            case CustomPropertyType.manual_datetime_field:
            case CustomPropertyType.manual_boolean_field:
                return {
                    scope: 'true'
                };
            case CustomPropertyType.scoped_boolean:
                return {
                    eql: '',
                    scope: 'true'
                };
            case CustomPropertyType.multi_select:
                return {
                    options: [],
                } as unknown as CustomPropertyContentType;
            case CustomPropertyType.aggregate_relationships:
                if (manageRelationshipAggregationProperty) {
                    return {
                        applies_to_eql: '',
                        type_of_relationship: RelationshipType.parent,
                        target_eql: '',
                        aggregation: AggregateType.count,
                        target_property: '',
                        path_length: 1
                    };
                }
                return {} as CustomPropertyContentType;
            case CustomPropertyType.ai_select:
                if (manageLlmCustomProperty) {
                    return {
                        options: [],
                        prompt: '',
                        properties: [],
                        scope: '',
                        is_open_ended: false
                    };
                }
                return {} as CustomPropertyContentType;
            default:
                return {} as CustomPropertyContentType;
        }
    }, [manageLlmCustomProperty, manageRelationshipAggregationProperty]);

    // Helper function to get initial form data
    const getInitialFormData = useCallback((prop: CustomPropertyResponse | null): Partial<CustomPropertyResponse> => {
        if (prop) {
            return { ...prop };
        }
        return {
            name: '',
            metadata: {
                description: null,
                properties_group: null,
                order_in_group: 0,
                display_name: null,
                owner: '',
                show_in_overview: false,
            },
            content: {
                type: CustomPropertyType.manual_text_field,
                content: getDefaultContentForType(CustomPropertyType.manual_text_field)
            }
        };
    }, [getDefaultContentForType]);

    const [formData, setFormData] = useState<Partial<CustomPropertyResponse>>(() => getInitialFormData(property));

    const isBuiltInCustomizable = property?.metadata?.owner === BUILTIN_OWNER;

    // Update form data when property changes
    useEffect(() => {
        setFormData(getInitialFormData(property));
    }, [property, getInitialFormData]);

    // Initialize owner to current user for new properties
    useEffect(() => {
        if (isNew && currentUser?.email && !formData.metadata?.owner) {
            setFormData(prev => ({
                ...prev,
                metadata: {
                    description: prev.metadata?.description ?? null,
                    display_name: prev.metadata?.display_name ?? null,
                    properties_group: prev.metadata?.properties_group ?? null,
                    order_in_group: prev.metadata?.order_in_group ?? 0,
                    owner: currentUser.email,
                    show_in_overview: prev.metadata?.show_in_overview ?? false
                }
            }));
        }
    }, [isNew, currentUser, formData.metadata?.owner]);

    const canTakeOwnership = !isNew && formData.metadata?.owner !== currentUser?.email;

    const handleTypeChange = (option: Option | Option[]) => {
        if (!option || Array.isArray(option)) return;

        const newType = option.value as CustomPropertyType;
        setFormData({
            ...formData,
            content: {
                type: newType,
                content: getDefaultContentForType(newType)
            }
        });
    };

    const handleSave = async () => {
        try {
            if (isNew) {
                await createProperty({
                    accountId,
                    property: formData as Omit<CustomPropertyResponse, 'id' | 'last_updated_at' | 'last_updated_by' | 'created_by' | 'created_at' | 'last_calculation_timeout'>
                }).unwrap();
                notify('Custom property created successfully', 'success');
            } else if (property?.id) {
                await updateProperty({
                    accountId,
                    propertyId: property.id,
                    property: formData
                }).unwrap();
                notify('Custom property updated successfully', 'success');
            }
            onCancel();
        } catch (error: unknown) {
            const apiError = error as APIError;
            if ((apiError?.status === 422 || apiError?.status === 400 || apiError?.status === 402 || apiError?.status === 403) && apiError.data?.detail) {
                let details: string;
                if (Array.isArray(apiError.data.detail)) {
                    details = apiError.data.detail.map((err) => {
                        const field = err.loc[err.loc.length - 1];
                        const fieldName = field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, ' ');
                        return `${fieldName}: ${err.msg}`;
                    }).join(', ');
                } else if (typeof apiError.data.detail === 'object' && 'message' in apiError.data.detail) {
                    const eqlError = apiError.data.detail['error details'];
                    details = `EQL Error: ${eqlError.error}${eqlError.line ? ` (Line ${eqlError.line}, Column ${eqlError.column})` : ''}`;
                } else {
                    details = typeof apiError.data.detail === 'string' ? apiError.data.detail : 'Validation error';
                }
                notify(`Validation error: ${details}`, 'error');
            } else {
                notify(`Failed to ${isNew ? 'create' : 'update'} custom property: ${error instanceof Error ? apiError.data.detail : 'Unknown error'}`, 'error');
            }
        }
    };

    const handleDelete = async () => {
        if (!property?.id) return;

        if (isBuiltInCustomizable) {
            notify('Cannot delete built-in customizable properties. These properties are managed by the system.', 'error');
            setShowDeleteModal(false);
            return;
        }

        try {
            await deleteProperty({
                accountId,
                propertyId: property.id
            }).unwrap();
            notify('Custom property deleted successfully', 'success');
            onCancel();
        } catch (error) {
            notify(`Failed to delete custom property: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
        }
        setShowDeleteModal(false);
    };

    const updateMetadata = (
        formData: Partial<CustomPropertyResponse>,
        updates: Partial<typeof formData.metadata>
    ) => ({
        ...formData,
        metadata: {
            description: formData.metadata?.description ?? null,
            display_name: formData.metadata?.display_name ?? null,
            properties_group: formData.metadata?.properties_group ?? null,
            order_in_group: formData.metadata?.order_in_group ?? 0,
            owner: formData.metadata?.owner ?? '',
            show_in_overview: formData.metadata?.show_in_overview ?? false,
            ...updates
        }
    });

    // Helper function to get multi_select options from content
    const getMultiSelectOptions = () => {
        if (!formData.content || formData.content.type !== CustomPropertyType.multi_select) {
            return [];
        }
        return (formData.content.content as MultiSelectContent).options as MultiSelectOption[];
    };

    return (
        <>
            <div className="border border-border rounded-lg p-6">
                <div className="flex justify-between items-center mb-6">
                    <div className="text-lg">
                        {isNew ? 'Create New Property' : 'Edit Property'}
                    </div>
                    {!isNew && (
                        <Button
                            type={ButtonTypes.danger}
                            text="Delete"
                            onClick={() => setShowDeleteModal(true)}
                            isLoading={isDeleting}
                            isDisabled={isBuiltInCustomizable}
                            tooltip={isBuiltInCustomizable ? 'Cannot delete built-in customizable properties. These properties are managed by the system.' : ''}
                        />
                    )}
                </div>

                <div className="flex flex-col gap-4">
                    {/* Metadata Info Box - only show when editing */}
                    {!isNew && property && (
                        <div className="bg-slate-50 p-4 rounded-lg mb-4">
                            <div className="text-sm font-medium text-slate-700 mb-2">Property Information</div>
                            <div className="grid grid-cols-2 gap-2 text-sm">
                                <div className="text-slate-500">Created by</div>
                                <div>{property.created_by}</div>
                                <div className="text-slate-500">Created at</div>
                                <div>{new Date(property.created_at).toLocaleString()}</div>
                                <div className="text-slate-500">Last updated by</div>
                                <div>{property.last_updated_by}</div>
                                <div className="text-slate-500">Last updated at</div>
                                <div>{new Date(property.last_updated_at).toLocaleString()}</div>
                            </div>
                        </div>
                    )}

                    {/* Display warning if last calculation timed out */}
                    {!isNew && lastCalculationTimedOut && (
                        <div className="bg-yellow-50 border border-yellow-200 rounded-md p-4 mb-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-yellow-700">
                                        The property timed out while calculating and therefore the values might lag. Consider simplifying the EQL conditions.{' '}
                                        <a href={eunoLinks.OPTIMIZE_COMPLEX_QUERIES_DOCUMENTATION} target="_blank" rel="noopener noreferrer" className="font-medium underline text-yellow-700 hover:text-yellow-600">
                                            Learn more about optimizing search
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Basic Information Section */}
                    <div className="mb-6">
                        <div className="text-sm font-medium text-slate-700 mb-4">Basic Information</div>
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-4">
                                <div className="w-32">Name</div>
                                <Input
                                    value={formData.name || ''}
                                    onInputChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    disabled={!isNew}
                                    placeholder="Enter property name"
                                />
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="w-32">Type</div>
                                <Select
                                    options={validTypes.map((type) => ({
                                        value: type,
                                        label: PROPERTY_TYPE_LABELS[type] || type
                                    }))}
                                    value={formData.content?.type ?? null}
                                    onChange={handleTypeChange}
                                    isDisabled={!isNew}
                                    className="w-full"
                                />
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="w-32">Display Name</div>
                                <Input
                                    value={formData.metadata?.display_name || ''}
                                    onInputChange={(e) => setFormData(updateMetadata(formData, { display_name: e.target.value }))}
                                    placeholder="Enter display name"
                                />
                            </div>

                            <div className="flex items-start gap-4">
                                <div className="w-32 pt-2">Description</div>
                                <TextArea
                                    value={formData.metadata?.description || ''}
                                    onChange={(e) => setFormData(updateMetadata(formData, { description: e.target.value }))}
                                    placeholder="Enter property description"
                                    rows={4}
                                />
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="w-32">Owner</div>
                                <div className="flex-1 flex gap-2">
                                    <Input
                                        value={formData.metadata?.owner || ''}
                                        disabled={true}
                                        placeholder="Property owner"
                                    />
                                    {canTakeOwnership && (
                                        <Button
                                            type={ButtonTypes.secondary}
                                            text="Take Ownership"
                                            onClick={() => setFormData(updateMetadata(formData, { owner: currentUser?.email || '' }))}
                                            isDisabled={isBuiltInCustomizable}
                                            tooltip={isBuiltInCustomizable ? 'Cannot take ownership of built-in customizable properties' : ''}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="w-32">Show in Overview</div>
                                <Checkbox
                                    value={formData.metadata?.show_in_overview || false}
                                    setValue={(checked) => setFormData(updateMetadata(formData, { show_in_overview: checked }))}
                                />
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="w-32">Group</div>
                                <div className="flex-1 flex gap-2">
                                    <Input
                                        value={formData.metadata?.properties_group || ''}
                                        onInputChange={(e) => setFormData(updateMetadata(formData, { properties_group: e.target.value || null }))}
                                        placeholder="Enter group name (or leave blank to hide the property from the side panel)"
                                    />
                                    {formData.metadata?.properties_group && (
                                        <Button
                                            type={ButtonTypes.secondary}
                                            text="Hide"
                                            onClick={() => setFormData(updateMetadata(formData, { properties_group: null, order_in_group: 0 }))}
                                        />
                                    )}
                                </div>
                            </div>

                            {formData.metadata?.properties_group && (
                                <div className="flex items-center gap-4">
                                    <div className="w-32">Order in Group</div>
                                    <Input
                                        type="number"
                                        value={formData.metadata?.order_in_group?.toString() || '0'}
                                        onInputChange={(e) => setFormData(updateMetadata(formData, { order_in_group: parseInt(e.target.value) || 0 }))}
                                        placeholder="Enter order (0-999)"
                                        min="0"
                                        max="999"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Type-specific Configuration */}
                    {formData.content?.type === CustomPropertyType.scoped_boolean && (
                        <ScopedBooleanEditor
                            content={formData.content.content as ScopedBooleanContent || { eql: '', scope: 'true' }}
                            onChange={(newContent) => setFormData({
                                ...formData,
                                content: {
                                    type: CustomPropertyType.scoped_boolean,
                                    content: newContent
                                }
                            })}
                        />
                    )}
                    {manageLlmCustomProperty && formData.content?.type === CustomPropertyType.ai_select && (
                        <AISelectEditor
                            content={formData.content.content as AISelectContent || { options: [], prompt: '', properties: [], scope: '' }}
                            onChange={(newContent) => setFormData({
                                ...formData,
                                content: {
                                    type: CustomPropertyType.ai_select,
                                    content: newContent
                                }
                            })}
                        />
                    )}
                    {(formData.content?.type === CustomPropertyType.manual_single_select ||
                        formData.content?.type === CustomPropertyType.manual_multi_select) && formData.content && (
                            <>
                                <ScopeEditor
                                    scope={(formData.content.content as ClosedListManualPropertyContent)?.scope || 'true'}
                                    onChange={(newScope) => {
                                        const content = formData.content!.content as ClosedListManualPropertyContent;
                                        setFormData({
                                            ...formData,
                                            content: {
                                                type: formData.content!.type,
                                                content: {
                                                    ...content,
                                                    scope: newScope
                                                }
                                            }
                                        });
                                    }}
                                />
                                <SelectOptionsEditor
                                    options={(formData.content.content as ClosedListManualPropertyContent)?.options || []}
                                    onChange={(newOptions) => {
                                        const content = formData.content!.content as ClosedListManualPropertyContent;
                                        setFormData({
                                            ...formData,
                                            content: {
                                                type: formData.content!.type,
                                                content: {
                                                    ...content,
                                                    options: newOptions
                                                }
                                            }
                                        });
                                    }}
                                />
                            </>
                        )}
                    {formData.content?.type === CustomPropertyType.multi_select && (
                        <>
                            <MultiSelectOptionsEditor
                                options={getMultiSelectOptions()}
                                onChange={(newOptions) => setFormData({
                                    ...formData,
                                    content: {
                                        type: CustomPropertyType.multi_select,
                                        content: {
                                            options: newOptions,
                                        } as unknown as CustomPropertyContentType
                                    }
                                })}
                            />
                        </>
                    )}
                    {(formData.content?.type === CustomPropertyType.manual_text_field ||
                        formData.content?.type === CustomPropertyType.manual_numeric_field ||
                        formData.content?.type === CustomPropertyType.manual_datetime_field ||
                        formData.content?.type === CustomPropertyType.manual_boolean_field) && formData.content && (
                            <ScopeEditor
                                scope={(formData.content.content as ManualPropertyContent)?.scope || 'true'}
                                onChange={(newScope) => {
                                    setFormData({
                                        ...formData,
                                        content: {
                                            type: formData.content!.type,
                                            content: {
                                                scope: newScope
                                            }
                                        }
                                    });
                                }}
                            />
                        )}
                    {manageRelationshipAggregationProperty && formData.content?.type === CustomPropertyType.aggregate_relationships && (
                        <AggregateRelationshipEditor
                            content={(formData.content.content as AggregateRelationshipContent) || { applies_to_eql: '', type_of_relationship: '', target_eql: '', aggregation: AggregateType.count }}
                            onChange={(newContent) => setFormData({
                                ...formData,
                                content: {
                                    type: CustomPropertyType.aggregate_relationships,
                                    content: newContent
                                }
                            })}
                        />
                    )}

                    <div className="flex justify-end gap-2 mt-4">
                        <Button
                            type={ButtonTypes.secondary}
                            text="Cancel"
                            onClick={onCancel}
                        />
                        <Button
                            type={ButtonTypes.primary}
                            text={isNew ? 'Create' : 'Save'}
                            onClick={handleSave}
                            isLoading={isCreating || isUpdating}
                        />
                    </div>
                </div>
            </div>

            <Modal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                title={`Delete ${property?.metadata.display_name || property?.name}?`}
                buttons={[
                    {
                        text: "Cancel",
                        onClick: () => setShowDeleteModal(false),
                        type: ButtonTypes.secondary
                    },
                    {
                        text: "Delete",
                        onClick: handleDelete,
                        type: ButtonTypes.danger
                    }
                ]}
            >
                <div className="text-text-primary">
                    Are you sure you want to delete this custom property? This action cannot be undone.
                </div>
            </Modal>
        </>
    );
};

export default CustomPropertyForm; 