import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { ButtonTypes } from 'src/components/button/types';

export const EmptyAccountIntegrations = () => {
    const navigate = useNavigate();

    const goToSources = () => {
        navigate(`/sources/`);
    };
    return (
        <div className="rounded-lg bg-white p-6 shadow-md ">
            <h2 className="mb-4 text-2xl font-bold">Account Source Integrations</h2>
            <div className='mt-8 text-lilac-950'>
                You have no enabled sources
            </div>
            <Button
                dataTestId="open-create-project-modal"
                className="mt-4"
                onClick={goToSources}
                type={ButtonTypes.secondary}
                text="Browse sources"
            />
        </div>
    );
};

export const EmptyDataLayers = () => {
    const navigate = useNavigate();

    const goToDataModel = () => {
        navigate(`/data-model/`);
    };
    return (
        <div className="rounded-lg bg-white p-6 shadow-md ">
            <h2 className="mb-4 text-2xl font-bold">Data Model Overview</h2>
            <div className='mt-8 text-lilac-950'>
                No data layers found
            </div>
            <Button
                dataTestId="open-create-project-modal"
                className="mt-4"
                onClick={goToDataModel}
                type={ButtonTypes.secondary}
                text="Browse Data Model"
            />
        </div>
    );
};