import { ChangeEvent, useEffect } from 'react';
import Input from "src/components/form/Input";
import { FormField } from "src/components/form/FormField";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";

type MappingType = {
	label: string,
	existingMappings: { [key: string]: string; }
	setMappings: (newMapping: { [key: string]: string | object; }) => void
	placeholders: string[]
}

const Mapping = ({ label, existingMappings, setMappings, placeholders }: MappingType) => {

	//Insert empty key for first row
	useEffect(() => {
		if (!existingMappings || Object.keys(existingMappings).length === 0) {
			setMappings({ '': '' });
		}
	}, [existingMappings, setMappings]);

	const onRowChange = (index: number, source: string, target: string) => {
		const newMappings = Object.entries(existingMappings).map((entry, i) => {
			if (i === index) {
				return [source, target];
			}
			return entry;
		});
		setMappings(Object.fromEntries(newMappings));
	};

	const onDelete = (index: number) => {
		const newMappings = Object.entries(existingMappings).filter((_, i) => i !== index);
		setMappings(Object.fromEntries(newMappings));
	};

	const onAdd = () => {
		setMappings({ ...existingMappings, '': '' });
	};

	return (
		<div>
			<FormField
				label={label}
				className="flex-col !items-start"
				fieldWrapperClassName='w-full'
				labelClassName='text-secondary mb-1'
			>
				{
					Object.entries(existingMappings || []).map((entry, index) => (
						<Row
							key={index}
							source={entry[0]}
							target={entry[1]}
							onChange={onRowChange}
							index={index}
							totalMappings={Object.keys(existingMappings).length}
							onDelete={() => onDelete(index)}
							onAdd={() => onAdd()}
							placeholders={placeholders}
						/>
					))
				}
			</FormField>
		</div>
	);
};

type RowProps = {
	source: string,
	target: string,
	onChange: (index: number, source: string, target: string) => void,
	index: number,
	totalMappings: number,
	onDelete: () => void,
	onAdd: () => void,
	placeholders: string[]

}

const Row = ({ source, target, onChange, index, totalMappings, onDelete, onAdd, placeholders }: RowProps) => {
	const isLastMapping = index === totalMappings - 1;
	return (
		<div className='flex flex-row gap-2 mt-2'>
			<Input
				placeholder={placeholders[0]}
				value={source}
				onInputChange={(e: ChangeEvent<HTMLInputElement>) => onChange(index, e.target.value, target)}
			/>
			<Input
				placeholder={placeholders[1]}
				value={target}
				onInputChange={(e: ChangeEvent<HTMLInputElement>) => onChange(index, source, e.target.value)}
			/>
			<div className="flex gap-1 w-20 items-center">
				<MinusCircleIcon
					className='text-slate-400 cursor-pointer'
					onClick={onDelete}
					height={16}
					width={16}
				/>
				{isLastMapping && (
					<PlusCircleIcon
						className={`${source.length > 0 && target.length > 0 ? 'text-slate-400' : 'disabled text-slate-300'} cursor-pointer`}
						onClick={onAdd}
						height={16}
						width={16}
					/>
				)}
			</div>
		</div>
	);
};

export default Mapping;
