import EQLInput from "src/components/eql/input/EQLInput";
import Input from "src/components/form/Input";
import Select, { Option } from "src/components/form/Select";
import { AggregateType, AggregateRelationshipContent, RelationshipType } from "src/services/customProperties/types";
import { ExternalProperty, ExternalFacingType } from "src/services/eql/types";
import PropertySelector from "./PropertySelector";

// Define the relationship type for the relationships query
interface Relationship {
    name: string;
}

// Mock the relationships query until it's implemented
const useGetRelationshipsQuery = () => {
    // Return some mock relationships for now
    return {
        data: [
            { name: "upstream" },
            { name: "downstream" },
            { name: "parent" },
            { name: "child" }
        ] as Relationship[],
        isLoading: false
    };
};

type AggregateRelationshipEditorProps = {
    content: AggregateRelationshipContent;
    onChange: (content: AggregateRelationshipContent) => void;
};

const AggregateRelationshipEditor = ({ content, onChange }: AggregateRelationshipEditorProps) => {
    const { data: relationships, isLoading: isLoadingRelationships } = useGetRelationshipsQuery();
    
    // Create options for the aggregation type dropdown
    const aggregationOptions: Option[] = [
        { label: 'Count', value: AggregateType.count },
        { label: 'Sum', value: AggregateType.sum },
        { label: 'Maximum', value: AggregateType.max },
        { label: 'Minimum', value: AggregateType.min },
        { label: 'List of Distinct Values', value: AggregateType.list_of_distinct_values },
        { label: 'Map of Values', value: AggregateType.map_of_values }
    ];

    // Create options for the relationship type dropdown
    const relationshipOptions: Option[] = relationships?.map((rel: Relationship) => ({
        label: rel.name,
        value: rel.name
    })) || [];

    // Handle changes to the applies_to_eql field
    const handleAppliesToEqlChange = (value: string) => {
        onChange({
            ...content,
            applies_to_eql: value
        });
    };

    // Handle changes to the relationship type
    const handleRelationshipTypeChange = (option: Option | Option[]) => {
        if (!Array.isArray(option)) {
            onChange({
                ...content,
                type_of_relationship: option.value as RelationshipType
            });
        }
    };

    // Handle changes to the path length
    const handlePathLengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value) && value > 0) {
            onChange({
                ...content,
                path_length: value
            });
        } else if (e.target.value === '') {
            // If the input is empty, remove the path_length property
            onChange({
                ...content,
                path_length: undefined
            } as unknown as AggregateRelationshipContent);
        }
    };

    // Handle changes to the target EQL
    const handleTargetEqlChange = (value: string) => {
        onChange({
            ...content,
            target_eql: value
        });
    };

    // Handle changes to the aggregation type
    const handleAggregationChange = (option: Option | Option[]) => {
        if (!Array.isArray(option)) {
            onChange({
                ...content,
                aggregation: option.value as AggregateType,
                target_property: '' // Reset target property when changing aggregation type
            });
        }
    };

    // Handle adding a target property
    const handleAddProperty = (property: string) => {
        onChange({
            ...content,
            target_property: property
        });
    };

    // Handle removing the target property
    const handleRemoveProperty = () => {
        onChange({
            ...content,
            target_property: undefined
        } as unknown as AggregateRelationshipContent);
    };

    // Create a filter predicate based on the selected aggregation type
    const getPropertyFilterPredicate = () => {
        return (property: ExternalProperty) => {
            switch (content.aggregation) {
                case AggregateType.sum:
                    return property.type === ExternalFacingType.numeric;
                case AggregateType.max:
                case AggregateType.min:
                    return property.type === ExternalFacingType.numeric || property.type === ExternalFacingType.datetime;
                case AggregateType.list_of_distinct_values:
                case AggregateType.map_of_values:
                    return property.type === ExternalFacingType.text;
                default:
                    return true;
            }
        };
    };

    // Determine if a target property is required
    const isTargetPropertyRequired = content.aggregation !== AggregateType.count;

    return (
        <div className="border-t border-slate-200 pt-6 mt-6">
            <div className="text-sm font-medium text-slate-700 mb-4">Relationship Aggregation Configuration</div>
            
            <div className="flex flex-col gap-6">
                {/* Applies To EQL */}
                <div className="flex items-start gap-4">
                    <div className="w-24 pt-2">Applies To</div>
                    <div className="flex-1 max-w-[580px] mr-4">
                        <EQLInput
                            value={content.applies_to_eql}
                            onChange={handleAppliesToEqlChange}
                            placeholder="Enter RFEQL expression to determine which resources this property applies to"
                            showSearchButton={false}
                        />
                        <div className="text-xs text-slate-500 mt-1 ml-6">
                            RFEQL expression that determines which resources this property applies to.
                        </div>
                    </div>
                </div>

                {/* Relationship Type */}
                <div className="flex items-start gap-4">
                    <div className="w-32 pt-2">Relationship</div>
                    <div className="flex-1">
                        <Select
                            options={relationshipOptions}
                            value={content.type_of_relationship || null}
                            onChange={handleRelationshipTypeChange}
                            placeholder="Select relationship type"
                            isLoading={isLoadingRelationships}
                            isDisabled={isLoadingRelationships}
                        />
                        <div className="text-xs text-slate-500 mt-1">
                            The type of relationship to follow.
                        </div>
                    </div>
                </div>

                {/* Path Length */}
                <div className="flex items-start gap-4">
                    <div className="w-32 pt-2">Path Length</div>
                    <div className="flex-1">
                        <Input
                            value={content.path_length?.toString() || ''}
                            onInputChange={handlePathLengthChange}
                            placeholder="Optional: Enter path length (default: 1)"
                            type="number"
                            min="1"
                        />
                        <div className="text-xs text-slate-500 mt-1">
                            Optional: The length of the relationship path to follow. If not specified, a path length of 1 is used.
                        </div>
                    </div>
                </div>

                {/* Target EQL */}
                <div className="flex items-start gap-4">
                    <div className="w-24 pt-2">Target Filter</div>
                    <div className="flex-1 max-w-[580px] mr-4">
                        <EQLInput
                            value={content.target_eql}
                            onChange={handleTargetEqlChange}
                            placeholder="Enter RFEQL expression to filter target resources"
                            showSearchButton={false}
                        />
                        <div className="text-xs text-slate-500 mt-1 ml-6">
                            RFEQL expression that filters the target resources to include in the aggregation.
                        </div>
                    </div>
                </div>

                {/* Aggregation Type */}
                <div className="flex items-start gap-4">
                    <div className="w-32 pt-2">Aggregation</div>
                    <div className="flex-1">
                        <Select
                            options={aggregationOptions}
                            value={content.aggregation || null}
                            onChange={handleAggregationChange}
                            placeholder="Select aggregation type"
                        />
                        <div className="text-xs text-slate-500 mt-1">
                            The type of aggregation to perform on the target resources.
                        </div>
                    </div>
                </div>

                {/* Target Property (only shown if aggregation is not count) */}
                {isTargetPropertyRequired && (
                    <div className="flex items-start gap-4">
                        <div className="w-32 pt-2">Target Property</div>
                        <div className="flex-1">
                            <PropertySelector
                                selectedProperties={content.target_property ? [content.target_property] : []}
                                onAddProperty={handleAddProperty}
                                onRemoveProperty={handleRemoveProperty}
                                filterPredicate={getPropertyFilterPredicate()}
                                includeNonIndexed={false}
                            />
                            <div className="text-xs text-slate-500 mt-1">
                                {content.aggregation === AggregateType.sum && 
                                    "The property to aggregate. Must be a numeric property."}
                                {(content.aggregation === AggregateType.max || content.aggregation === AggregateType.min) && 
                                    "The property to aggregate. Must be a numeric or datetime property."}
                                {(content.aggregation === AggregateType.list_of_distinct_values || content.aggregation === AggregateType.map_of_values) && 
                                    "The property to aggregate. Must be a text property."}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AggregateRelationshipEditor; 