import { makeAssistantToolUI } from "@assistant-ui/react";

type DataResourcesSearchToolArgs = {
  query: string;
  properties_to_return: string[];
  page: number;
};

export const DataResourcesSearchTool = makeAssistantToolUI<
  DataResourcesSearchToolArgs,
  string
>({
  toolName: "data_resources_search",
  render: function DataResourcesSearchUI({ args, result }) {
    const handleEqlClick = (eql: string) => {
      // Open the discover page with the EQL query in a new tab
      const url = `/data-model?eql=${encodeURIComponent(eql)}&filterMode=eql&view=table`;
      window.open(url, '_blank');
    };

    const isRunning = result === null;
    
    return (
      <div className="mb-4">
        <div className="relative group">
          <div className="flex items-center mb-2">
            <div className="text-sm font-medium text-slate-700 mr-2">EQL Query</div>
            {isRunning ? (
              <div className="flex items-center text-amber-500 text-xs">
                <svg className="animate-spin -ml-1 mr-2 h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Running...
              </div>
            ) : (
              <div className="flex items-center text-green-500 text-xs">
                <svg className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Completed
              </div>
            )}
          </div>
          
          <pre className="bg-slate-100 p-3 rounded-md text-sm overflow-x-auto">
            <code>{args.query}</code>
          </pre>
          
          <button 
            onClick={() => handleEqlClick(args.query)}
            className="absolute top-10 right-0 text-slate-400 hover:text-blue-500 transition-colors p-1 rounded-md opacity-90 group-hover:opacity-100"
            title="Open in new tab"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </button>
        </div>
      </div>
    );
  },
});