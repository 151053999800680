import { DocumentTextIcon } from "@heroicons/react/24/solid";
import { useState, useMemo, useEffect, ChangeEvent } from "react";
import { BranchIcon } from "../../../assets/images/icons/DelphiIcons";
import Modal from "../../../components/Modal/Modal";
import { notify } from "../../../components/Toaster";
import { ButtonTypes } from "../../../components/button/types";
import Textarea from "../../../components/form/Textarea";
import { trackEvent, events } from "../../../infrastructure/analytics";
import { BackendErrorType, extractErrorMessage } from "../../../services/api";
import { useCreateChangeMutation, useGenerateChangeDiffMutation } from "../../../services/changes/changes";
import { useGetProjectBranchesQuery, useGetProjectQuery } from "../../../services/projects/projects";
import { ChangeType, NewColumnChangeData, NewMeasureChangeData, NewModelChangeData, Pat, RefreshDataModelChangeData } from "../IChange";
import { NewAggregateTableChangeData } from "../changeForms/newAggregateTable/types";
import { NewAggregateTableForm } from "../changeForms/newAggregateTable/components/NewAggregateTableForm";
import Input from "../../../components/form/Input";
import Select, { Option } from "../../../components/form/Select";
import { onBeforeSubmitAggregateTableChange } from "../changeForms/newAggregateTable/utilities/onBeforeSubmitAggregateTableChange";
import { validateNewAggregateTableChange } from "../changeForms/newAggregateTable/utilities/validateNewAggregateTableChange";
import Button from "../../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { CompilationError } from "../CompilationError";
import { useDefaultTargetBranch } from "../../../services/changes/hooks";
import NewColumnForm from "../changeForms/newColumn/NewColumnForm";
import NewMeasureForm from "../changeForms/newMeasure/NewMeasureForm";
import { NewModelForm } from "../changeForms/newModel/NewModelForm";
import PageLoader from "../../../components/loaders/PageLoader";
import { RefreshDataModelForm } from "../changeForms/refreshDataModel/RefreshDataModelForm";

interface CreateChangeSidepaneProps {
  isOpen: boolean;
  onClose: () => void;
  pat: Pat;
}

const CreateChangeSidepane = ({ isOpen, onClose, pat }: CreateChangeSidepaneProps) => {
  const [title, setTitle] = useState(pat.title);
  const [description, setDescription] = useState(pat.description);
  const [changeData, setChangeData] = useState(pat.changeData);
  const [changeType, setChangeType] = useState(pat.changeType);
  const getProjectBranchesQuery = useGetProjectBranchesQuery({ id: pat.projectId });
  const { data: project, isLoading: isLoadingProject } = useGetProjectQuery(pat.projectId);

  const branchOptions = useMemo(
    () => (getProjectBranchesQuery?.data || []).map((b) => ({ label: b.name, value: b.name })),
    [getProjectBranchesQuery]
  );
  const [targetBranch, setTargetBranch] = useDefaultTargetBranch(project, branchOptions);
  const [createChange] = useCreateChangeMutation();
  const [isCreatingChange, setIsCreatingChange] = useState(false);
  const [compilationError, setCompilationError] = useState('');
  const [generateDiff] = useGenerateChangeDiffMutation();
  const navigate = useNavigate();
  const isLoading = isLoadingProject;

  useEffect(() => {
    setTitle(pat.title);
    setDescription(pat.description);
    setChangeData(pat.changeData);
    setChangeType(pat.changeType);
  }, [pat]);

  const onConfirm = async () => {
    setIsCreatingChange(true);
    const updatedChange = { ...pat, title, description, targetBranch, changeData: { ...changeData } };
    const changeTypeSpecificEnrichment = enrichPatPerType(updatedChange);
    const changeTypeSpecificValidationError: string | null = validatePatPerType(changeTypeSpecificEnrichment);
    const changeValidationError: string | null = validatePat(updatedChange);
    if (changeTypeSpecificValidationError) {
      notify(changeTypeSpecificValidationError, 'error');
    }
    else if (changeValidationError) {
      notify(changeValidationError, 'error');
    }
    else {
      try {
        trackEvent(events.dataModelChangeCreated, { change: changeTypeSpecificEnrichment });
        const change = await createChange({ projectId: pat.projectId, change: changeTypeSpecificEnrichment }).unwrap();
        generateDiff({ changeId: change.id, projectId: change.projectId });
        onClose();
        navigate(`/model-changes?tab=In+progress&projectId=${pat.projectId}`);
        setTimeout(() => {
          notify('Creating change draft. It may take a few minutes.', 'success', 10000);
        }, 1000);
      } catch (e) {
        const extractedError = extractErrorMessage(e);
        if (extractedError.type === BackendErrorType.MetricFlow) {
          setCompilationError(extractedError.message);
        }
        else {
          notify(`Error creating change: ${extractedError.message}`, 'error');
        }
      }
    }
    setIsCreatingChange(false);
  };

  
  return (
    <Modal withHeader={false} maxWidth='max-w-4xl' title='New proposal' isOpen={isOpen} onClose={onClose}>
      {
        isLoading && (
          <PageLoader />
        )
      }
      {
        !isLoading && pat && (
          <>
            <div className="px-4 my-4 overflow-y-auto max-h-[75vh] flex flex-col gap-2">
              <div className="flex items-center gap-1 text-primary text-sm"><DocumentTextIcon width="14" height="14" /> PROPOSAL</div>
              <div className="text-sm text-tertiary">Euno has suggested the following change</div>
              <div className="flex gap-2 items-center">
                <div className="text-secondary text-sm font-medium w-24">Change title</div>
                <div className="flex-1">
                  <Input
                    value={title}
                    onInputChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                    placeholder="Enter title"
                    className="font-medium"
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <div className="text-secondary text-sm font-medium w-24">Description</div>
                <div className="flex-1">
                  <Textarea
                    value={description}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
                    placeholder="Enter description"
                    className=""
                    rows={4}
                  />
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="text-secondary text-sm font-medium w-24">Target branch</div>
                <div className="flex-1">
                  <Select
                    icon={<BranchIcon width="16" height="16" className="ml-2 mr-1" fill="#94A3B8" />}
                    className="w-full rounded border shadow"
                    options={branchOptions}
                    value={targetBranch}
                    onChange={(option: Option | Option[]) => setTargetBranch((option as Option).value as string)}
                  />
                </div>
              </div>
              {
                changeType === ChangeType.NewAggregateTable && (
                  <NewAggregateTableForm
                    proposal={pat}
                    changeData={changeData as NewAggregateTableChangeData}
                    setChangeData={setChangeData}
                    setCompilationError={setCompilationError}
                  />
                )
              }
              {
                changeType === ChangeType.NewColumn && (
                  <NewColumnForm
                    sourceUtl={pat.sourceUtl}
                    sourceName={pat.sourceName}
                    changeData={changeData as NewColumnChangeData}
                    setChangeData={setChangeData}
                    sourceType={pat.sourceType}
                  />
                )
              }
              {
                changeType === ChangeType.NewMeasure && (
                  <NewMeasureForm
                    sourceUtl={pat.sourceUtl}
                    sourceName={pat.sourceName}
                    changeData={changeData as NewMeasureChangeData}
                    setChangeData={setChangeData}
                    sourceType={pat.sourceType}
                  />
                )
              }
              {
                changeType === ChangeType.NewModel && (
                  <NewModelForm
                    sourceUtl={pat.sourceUtl}
                    sourceName={pat.sourceName}
                    changeData={changeData as NewModelChangeData}
                    setChangeData={setChangeData}
                    sourceType={pat.sourceType}
                  />
                )
              }
              {
                changeType === ChangeType.RefreshDataModel && (
                  <RefreshDataModelForm
                    changeData={changeData as RefreshDataModelChangeData}
                  />
                )
              }
            </div>
            <div className="flex items-center mt-4 m-4">
              <CompilationError error={compilationError} />
              <div className="flex gap-4 ml-auto">
                <Button
                  type={ButtonTypes.primary}
                  text="Create change draft"
                  onClick={onConfirm}
                  isLoading={isCreatingChange}
                />
              </div>
            </div>
          </>
        )
      }
    </Modal>
  );
};

const enrichPatPerType = (pat: Pat): Pat => {
  let enrichedPat = { ...pat };
  
  // Only filter meta for specific change types
  if ([ChangeType.NewColumn, ChangeType.NewMeasure, ChangeType.NewModel, ChangeType.NewAggregateTable].includes(pat.changeType)) {
    const metaWithoutEmptyKeys = ((pat.changeData as unknown as NewColumnChangeData).meta || []).filter(({ key }) => key);
    enrichedPat = {
      ...enrichedPat,
      changeData: {
        ...enrichedPat.changeData,
        meta: metaWithoutEmptyKeys
      }
    };
  }

  // Apply change type specific enrichment
  switch (pat.changeType) {
    case ChangeType.NewAggregateTable:
      return onBeforeSubmitAggregateTableChange(enrichedPat, enrichedPat.changeData as NewAggregateTableChangeData);
    default:
      return enrichedPat;
  }
};

const validatePatPerType = (pat: Pat): string | null => {
  switch (pat.changeType) {
    case ChangeType.NewAggregateTable:
      return validateNewAggregateTableChange(pat.changeData as NewAggregateTableChangeData);
    default:
      return null;
  }
};

const validatePat = (pat: Pat): string | null => {
  if (!pat.title) {
    return 'Title is required';
  }
  if (!pat.description) {
    return 'Description is required';
  }
  if (!pat.targetBranch) {
    return 'Target branch is required';
  }
  return null;
};

export default CreateChangeSidepane;
