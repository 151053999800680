import DataTable from 'react-data-table-component';
import type { TableColumn, TableRow } from 'react-data-table-component';
import { PaginationProps, TableColumnProps } from './types';
import { useMemo } from 'react';
import { getTableStyles } from './getTableStyles';
import { ComponentLoader } from '../loaders/ComponentLoader';

interface TableProps<T> {
  data: T[];
  pagination?: PaginationProps;
  isLoading?: boolean;
  columns: TableColumnProps<T>[];
  onRowClicked?: (row: T) => void;
  maxBodyHeight?: string;
  className?: string;
  fixedHeight?: boolean;
  onSort?: (field: string, direction: 'asc' | 'desc') => void;
}

const EmptyDataComponent = () => (
  <div style={{ textAlign: 'center', padding: '10px' }} className='text-slate-600'>
    No data available
  </div>
);

const Table = <T,>({
  data,
  columns,
  onRowClicked,
  pagination,
  isLoading = false,
  maxBodyHeight = '80vh',
  className = '',
  fixedHeight = false,
  onSort
}: TableProps<T>) => {
  const styles = useMemo(() => getTableStyles(!!onRowClicked, fixedHeight ? maxBodyHeight : null), [onRowClicked, maxBodyHeight, fixedHeight]);

  return (
    <div className={`rounded-md border shadow ${className} relative`}>
      <DataTable<T>
        columns={data.length > 0 ? columns.map(c => ({ ...c, sortable: !!c.sortBy })): []}
        data={data as T[]}
        persistTableHead={data.length > 0}
        customStyles={styles}
        striped
        onRowClicked={(row: T) => onRowClicked && onRowClicked(row)}
        pagination={!!pagination}
        paginationServer={!!pagination}
        paginationTotalRows={pagination?.total}
        onChangeRowsPerPage={pagination?.setPageSize}
        onChangePage={pagination?.setPage}
        paginationPerPage={pagination?.pageSize}
        fixedHeader
        fixedHeaderScrollHeight={maxBodyHeight}
        noDataComponent={isLoading ? <ComponentLoader label='Loading Resources' className='flex-col' /> : <EmptyDataComponent />}
        sortFunction={(rows) => rows}
        onSort={({ name }, direction) => {
          const sortBy = columns.find(c => c.name === name)?.sortBy;
          sortBy && onSort?.(sortBy, direction);
        }}
        disabled={isLoading}
      />
    </div>
  );
};

export type { TableColumn, TableRow };
export default Table;
