import { ChangeMeta, Materialization, ColumnTests } from "../../IChange";

export type NewAggregateTableChangeData = {
    newTableName: string;
    description: string;
    tags: string[];
    meta: ChangeMeta[];
    materialization: Materialization;
    databaseSchema: string | null;
    dbtProjectName: string;
    columns: NewAggregateTableChangeColumn[];
    filters: string;
    date: { start: string | null, end: string | null };
    metricTime: AggregateTableTimeGranularity | null;
}

export type NewAggregateTableChangeColumn = ColumnTests & {
    type: NewAggregateTableChangeColumnType;
    alias: string | null;
    name: string;
    description: string | null;
    tags: string[];
    meta: ChangeMeta[];
    isTimeField: boolean;
    timeGranularity: AggregateTableTimeGranularity | null;
    parentEntity: string | null;
    dataType: string | null;
    entityPath: string | null;
}

export enum AggregateTableTimeGranularity {
    day = 'day',
    week = 'week',
    month = 'month',
    quarter = 'quarter',
    year = 'year'
}

export enum NewAggregateTableChangeColumnType {
    entity = 'entity',
    dimension = 'dimension',
    metric = 'metric',
    metric_time = 'metric_time'
}
