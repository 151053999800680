import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { FormField } from "src/components/form/FormField";
import Input from "src/components/form/Input";
import { Toggle } from "src/components/Toggle";
import EQLInput  from "src/components/eql/input/EQLInput";
import { CustomProperty, CustomPropertyFEType } from "src/services/customProperties/types";
import { PropertyTypeSelect } from "./PropertyTypeSelect";
import { ChevronDownIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import {
    CategoryAssignedInput,
    MultiSelectInput,
    LiveBooleanInput
} from "./PropertyFormInputs";
import { useGetUserQuery } from "src/services/users";

interface CreatePropertyFormProps {
    customProperty: CustomProperty;
    setCustomProperty: Dispatch<SetStateAction<CustomProperty>>;
    groups: string[];
    isEditMode: boolean;
    onValidityChange?: (isValid: boolean) => void;
}


export const CreatePropertyForm = ({ customProperty, setCustomProperty, groups, isEditMode, onValidityChange }: CreatePropertyFormProps) => {
    const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [newGroupName, setNewGroupName] = useState('');
    const [isEqlValid, setIsEqlValid] = useState(true);
    const [isNameValid, setIsNameValid] = useState(!!customProperty.name);
    const [isPropertyTypeInputValid, setIsPropertyTypeInputValid] = useState(true);
    const { data: currentUser } = useGetUserQuery();
    
    useEffect(() => {
        if (customProperty.content.type === CustomPropertyFEType.CategoryAssigned) {
            setIsEqlValid(true);
        }
    }, [customProperty.content.type]);

    useEffect(() => {
        if (onValidityChange) {
            onValidityChange(isEqlValid && isNameValid && isPropertyTypeInputValid);
        }
    }, [isEqlValid, isNameValid, isPropertyTypeInputValid, onValidityChange]);


    const handleEqlValidationChange = (isValid: boolean) => {
        if (customProperty.content.type !== CustomPropertyFEType.CategoryAssigned) {
            setIsEqlValid(isValid);
        }
    };

    const handlePropertyTypeInputValidationChange = (isValid: boolean) => {
        setIsPropertyTypeInputValid(isValid);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        setIsNameValid(!!name.trim());
        setCustomProperty({
            ...customProperty,
            name: name,
            eqlName: createEqlName(name)
        });
    };

    const handleAddNewGroup = () => {
        if (newGroupName.trim()) {
            setCustomProperty(prevState => {
                const newState = { ...prevState, group: newGroupName.trim() };
                return newState;
            });
            setNewGroupName('');
            setIsAddingNewGroup(false);
        }
    };

    const createEqlName = (name: string) => {
        const eqlName = name.toLowerCase().replace(/[^a-z0-9]/g, '_');
        return eqlName.length > 0 ? eqlName : '';
    };

    const allowRelationships = (type: CustomPropertyFEType) => {
        return [CustomPropertyFEType.LiveBoolean, CustomPropertyFEType.CategoryAssigned].includes(type);
    };

    return (
        <div className="p-6 ">
            <div className="mb-8">
                <h1 className="text-2xl font-medium text-gray-800">{isEditMode ? 'Edit Property' : 'Create a new Property'}</h1>
                <p className="text-gray-500">Manage and create Euno properties</p>
            </div>

            <div className="flex flex-col justify-between mt-4 gap-2">
                <FormField label="Name" labelClassName="w-44">
                    <Input
                        placeholder="Enter name"
                        type="text"
                        value={customProperty.name}
                        onInputChange={handleNameChange}
                        disabled={isEditMode}
                    />
                </FormField>
                <FormField label="EQL name" labelClassName="w-44">
                    <Input
                        className="pointer-events-none"
                        placeholder="EQL name will be automatically converted from name"
                        type="text"
                        disabled={true}
                        value={customProperty.eqlName}
                    />
                </FormField>
                <FormField label="Description" labelClassName="w-44">
                    <Input
                        placeholder="Enter description"
                        type="text"
                        value={customProperty.description}
                        onInputChange={(e) => setCustomProperty({ ...customProperty, description: e.target.value })}
                    />
                </FormField>
                <FormField label="Owner" labelClassName="w-44">
                    <Input
                        placeholder="Default to editor"
                        type="text"
                        value={currentUser?.email || 'Euno'}
                        disabled={true}
                    />
                </FormField>
                {customProperty.showInOverview && (
                    <FormField label="Group" labelClassName="w-44" className="w-full">
                        <div className="w-full">
                            {isAddingNewGroup ? (
                                <div className="flex items-center gap-2 w-full">
                                    <div className="relative w-full">
                                        <Input
                                            placeholder="Enter new group name"
                                            type="text"
                                            value={newGroupName}
                                            onInputChange={(e) => setNewGroupName(e.target.value)}
                                            className="w-full"
                                        />
                                        <button
                                            onClick={() => {
                                                setIsAddingNewGroup(false);
                                                setNewGroupName('');
                                            }}
                                            className="absolute right-2 top-1/2 -translate-y-1/2 p-1 text-slate-400 hover:text-slate-600"
                                        >
                                            <XMarkIcon className="h-4 w-4" />
                                        </button>
                                    </div>
                                    <Button
                                        type={ButtonTypes.secondary}
                                        text="Add"
                                        onClick={handleAddNewGroup}
                                        className="px-4 whitespace-nowrap"
                                    />
                                </div>
                            ) : (
                                <div className="relative w-full">
                                    <button
                                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                        className="flex w-full items-center justify-between rounded-md border border-border bg-white px-3 py-2 text-left text-sm text-slate-700 shadow hover:border-primary"
                                    >
                                        <span>{customProperty.group || 'Select a group'}</span>
                                        <ChevronDownIcon className="h-4 w-4 flex-shrink-0 ml-2" />
                                    </button>
                                    {isDropdownOpen && (
                                        <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                            <div className="py-1">
                                                <button
                                                    onClick={() => {
                                                        setCustomProperty(prevState => {
                                                            const newState = { ...prevState, group: 'General' };
                                                            return newState;
                                                        });
                                                        setIsDropdownOpen(false);
                                                    }}
                                                    className="flex w-full items-center px-3 py-2 text-left text-sm hover:bg-lilac-100"
                                                >
                                                    <span>General</span>
                                                </button>
                                                {groups.filter(group => group !== 'General').map((group) => (
                                                    <button
                                                        key={group}
                                                        onClick={() => {
                                                            setCustomProperty(prevState => {
                                                                const newState = { ...prevState, group };
                                                                return newState;
                                                            });
                                                            setIsDropdownOpen(false);
                                                        }}
                                                        className="flex w-full items-center px-3 py-2 text-left text-sm hover:bg-lilac-100"
                                                    >
                                                        <span>{group}</span>
                                                    </button>
                                                ))}
                                                <button
                                                    onClick={() => {
                                                        setIsAddingNewGroup(true);
                                                        setIsDropdownOpen(false);
                                                    }}
                                                    className="flex w-full items-center px-3 py-2 text-left text-sm hover:bg-lilac-100 border-b border-slate-100"
                                                >
                                                    <PlusIcon className="h-4 w-4 mr-2" />
                                                    <span>Add new group</span>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </FormField>
                )}

                <div className="flex justify-between items-center whitespace-nowrap mt-2">
                    <div>
                        <div>Visible in Data Model</div>
                        <div className="text-sm text-tertiary">Hidden properties are not shown in the app (side panel / data model), they can only be used in EQL</div>
                    </div>
                    <Toggle
                        onChange={() => {
                            const newShowInOverview = !customProperty.showInOverview;
                            const updatedGroup = newShowInOverview ? customProperty.group : '';
                            setCustomProperty({
                                ...customProperty,
                                showInOverview: newShowInOverview,
                                group: updatedGroup
                            });
                        }}
                        value={customProperty.showInOverview}
                    />
                </div>
            </div>
            <div className="flex flex-col justify-between mt-8 gap-2">
                <h2 className="text-lg font-medium text-gray-800 mb-4">Details</h2>

                <FormField label="Type" labelClassName="w-44">
                    <PropertyTypeSelect customProperty={customProperty} setCustomProperty={setCustomProperty} disabled={isEditMode} />
                </FormField>
                {customProperty.content.type !== CustomPropertyFEType.CategoryAssigned &&

                    <div className={`flex gap-4 items-start text-text-primary`}>
                        <div className="w-36 mr-2"> <div className="text-text-primary">Property scope</div>
                            <div className="text-sm text-slate-500 mt-1 ">{allowRelationships(customProperty.content.type) ? "The EQL expression to which the aggregation applies." : "Relationship-Free EQL (RFEQL) expression that determines which resources this property applies to. Use 'true' to apply to all resources."}</div></div>
                        <div className="flex-1">
                            <EQLInput
                                value={customProperty.propertyScope}
                                onChange={(value) => setCustomProperty({ ...customProperty, propertyScope: value })}
                                allowRelationships={allowRelationships(customProperty.content.type)}
                                onValidationChange={(isValid) => handleEqlValidationChange(isValid)}
                                showSearchButton={false}
                            />
                        </div>
                    </div>
                }
                <div className="mt-2">
                    {getPropertyTypeInput(customProperty.content.type, customProperty, setCustomProperty, handlePropertyTypeInputValidationChange)}
                </div>
            </div>
        </div>
    );
};

const getPropertyTypeInput = (
    type: CustomPropertyFEType,
    customProperty: CustomProperty,
    setCustomProperty: Dispatch<SetStateAction<CustomProperty>>,
    onValidityChange?: (isValid: boolean) => void
) => {
    switch (type) {
        case CustomPropertyFEType.SingleSelect:
            return <MultiSelectInput customProperty={customProperty} setCustomProperty={setCustomProperty} type={type} />;
        case CustomPropertyFEType.MultiSelect:
            return <MultiSelectInput customProperty={customProperty} setCustomProperty={setCustomProperty} type={type} />;
        case CustomPropertyFEType.LiveBoolean:
            return <LiveBooleanInput
                customProperty={customProperty}
                setCustomProperty={setCustomProperty}
                onValidityChange={onValidityChange}
            />;
        case CustomPropertyFEType.CategoryAssigned:
            return <CategoryAssignedInput
                customProperty={customProperty}
                setCustomProperty={setCustomProperty}
                onValidityChange={onValidityChange}
            />;
        case CustomPropertyFEType.Datetime:
        case CustomPropertyFEType.Numeric:
        case CustomPropertyFEType.FixedBoolean:
            return null;
        default:
            return null;
    }
};