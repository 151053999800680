import { useMemo, useCallback } from 'react';
import { ComponentLoader } from 'src/components/loaders/ComponentLoader';
import { useGetDataModelMultiCountQuery } from 'src/services/nodes/nodes';
import { ChartBarIcon, CircleStackIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { LayerItem } from '../types';
import { SOURCE_METADATA } from 'src/features/sources/Consts';
import { LAYER_QURIES } from '../consts';
import { notify } from 'src/components/Toaster';
import { EmptyDataLayers } from './EmptyCardComponents';
import { eunoLinks } from 'src/features/eunoLinks';
import { formatNumber } from 'src/utils/stringsUtils';

interface DataModelOverviewProps {
    accountId: number;
}

interface EmptyLayerCardProps {
    icon: JSX.Element;
    title: string;
    description: string;
    linkText: string;
}

interface LayerCardProps {
    title: string;
    icon: JSX.Element;
    items: LayerItem[];
    onItemClick: (eql: string) => void;
}
export const DataModelOverview = ({ accountId }: DataModelOverviewProps) => {
    const navigate = useNavigate();
    const expressions = useMemo(() => LAYER_QURIES.map(query => query.eql), []);
    const { data: results, isLoading, error } = useGetDataModelMultiCountQuery({
        accountId,
        expressions
    }, {
        skip: !accountId
    });

    const goToDataModel = (eql: string) => {
        navigate(`/data-model?eql=${eql}&filterMode=eql`);
    };

    const processResults = useCallback(() => {
        try {
            if (!results) return { dataLayerItems: [], biLayerItems: [] };
            
            if (error) {
                notify(`Failed to retrieve data overview`, 'error');
                return { dataLayerItems: [], biLayerItems: [] };
            }

            const acc = { dataLayerItems: [] as LayerItem[], biLayerItems: [] as LayerItem[] };

            results.forEach((result, index) => {
                if (!result || result.count === 0) return;

                const query = LAYER_QURIES[index];
                if (!query) return;

                const item = {
                    type: query.type,
                    name: query.name,
                    total: result.count,
                    layer: query.layer,
                    integrationType: query.integrationType,
                    eql: query.eql
                };

                if (query.layer === 'data') {
                    acc.dataLayerItems.push(item);
                } else {
                    acc.biLayerItems.push(item);
                }
            });

            return {
                dataLayerItems: sortLayerItems(acc.dataLayerItems),
                biLayerItems: sortLayerItems(acc.biLayerItems)
            };
        } catch (err) {
            notify(`Error processing data model results`, 'error');
            return { dataLayerItems: [], biLayerItems: [] };
        }
    }, [results, error]);

    const { dataLayerItems, biLayerItems } = useMemo(() => processResults(), [processResults]);

    if (error) {
        return <EmptyDataLayers />;
    }

    if (dataLayerItems.length === 0 && biLayerItems.length === 0 && !isLoading) {
        return <EmptyDataLayers />;
    }

    return (
        <div className="rounded-lg bg-white p-6 shadow-md">
            <h2 className="mb-4 text-2xl font-bold">Data Model Overview</h2>
            {isLoading ? (
                <div className="flex items-center justify-center h-full pb-10">
                    <ComponentLoader />
                </div>
            ) : (
                <div className="grid gap-2 py-3 px-1 md:grid-cols-2">
                    <LayerCard
                        title="Data Layer"
                        icon={<CircleStackIcon className="h-5 w-5 text-primary" />}
                        items={dataLayerItems}
                        onItemClick={goToDataModel}
                    />
                    <LayerCard
                        title="BI Layer"
                        icon={<ChartBarIcon className="h-5 w-5 text-primary" />}
                        items={biLayerItems}
                        onItemClick={goToDataModel}
                    />
                </div>
            )}
        </div>
    );
};

const DATA_LAYER_EMPTY_PROPS = {
    icon: <CircleStackIcon className="h-12 w-12 text-gray-300 mb-4" />,
    title: "No Data Resources Yet",
    description: "Connect your dbt models or Snowflake resources to start managing your data.",
    linkText: "Learn how to connect data resources"
};

const BI_LAYER_EMPTY_PROPS = {
    icon: <ChartBarIcon className="h-12 w-12 text-gray-300 mb-4" />,
    title: "No BI Resources Yet",
    description: "Connect your Looker or Tableau resources to start analyzing your data.",
    linkText: "Learn how to connect BI tools"
};

const EmptyLayerCard = ({ icon, title, description, linkText }: EmptyLayerCardProps) => (
    <div className="flex flex-col items-center justify-center p-6 text-center">
        {icon}
        <h3 className="text-lg font-semibold text-gray-700 mb-2">{title}</h3>
        <p className="text-sm text-gray-500 mb-4">{description}</p>
        <a
            href={eunoLinks.SOURCE_DOCUMENTATION}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center text-primary hover:text-primary/80 text-sm font-medium"
        >
            {linkText}
            <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
        </a>
    </div>
);

const LayerCard = ({ title, icon, items, onItemClick }: LayerCardProps) => {
    const isDataLayer = title === "Data Layer";
    const emptyLayerProps = isDataLayer ? DATA_LAYER_EMPTY_PROPS : BI_LAYER_EMPTY_PROPS;

    const totalItems = useMemo(() =>
        items.reduce((acc, item) => acc + (item.total || 0), 0),
        [items]
    );

    return (
        <div className="rounded-lg border border-border bg-card p-6 shadow-sm">
            <div className="mb-4 flex items-center gap-2">
                {icon}
                <h2 className="text-lg font-semibold">{title}</h2>
                <span className="ml-auto rounded-md bg-primary/10 px-2 py-1 text-xs font-medium text-primary">
                    {formatNumber(totalItems)}
                </span>
            </div>
            {items.length > 0 ? (
                <ul className="space-y-2">
                    {items.map((item, index) => {
                        const sourceMetadata = SOURCE_METADATA.get(item.integrationType);
                        if (!sourceMetadata) return null;
                        const SourceIcon = sourceMetadata.icon;
                        return (
                            <li
                                key={`${item.integrationType}-${index}`}
                                onClick={() => onItemClick(item.eql)}
                                className="flex items-center justify-between rounded-md border border-border/50 bg-background px-4 py-2 transition-colors hover:bg-primary/10 cursor-pointer"
                            >
                                <div className="flex items-center gap-x-1">
                                    <SourceIcon width="16" height="16" className="text-black" fill="#FF694A" />
                                    <span className="text-sm">{item.name}</span>
                                </div>
                                <span className="text-sm text-tertiary">{formatNumber(item.total || 0)}</span>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <EmptyLayerCard {...emptyLayerProps} />
            )}
        </div>
    );
};

const sortLayerItems = (items: LayerItem[]): LayerItem[] => {
    return [...items].sort((a, b) => {
        if (a.integrationType !== b.integrationType) {
            return a.integrationType.localeCompare(b.integrationType);
        }
        return (b.total || 0) - (a.total || 0);
    });
};

